import {BaseDataProvider} from "../baseDataProvider";
import GenericSearch from "../genericSearch";
import Variable from "./variable";
import axios from "axios";
import {BASE_URL} from "../../App";


export class VariablesDataProvider extends BaseDataProvider<Variable, GenericSearch> {

    constructor() {
        super('variables');
    }
    list = async (page_size?: number, filter?: GenericSearch ): Promise<Variable[]> => {
        return axios.get(`${BASE_URL}/${this.resourceId}`, { headers:  await this.getHeaders({"Accept": "application/json"}), params: {page_size, ...filter} }).then(r => {
            return r.data;
        });
    }
}
