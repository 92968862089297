import axios from "axios";
import {BASE_URL} from "../../../App";
import {IAuthenticationProvider} from "../../../lib/types/auth";
import {CustomAuthData} from "../../../auth/provider";
import {useAuthenticationContext} from "../../../lib/context/AuthenticationContext";

export interface ApiKey {
    access_key: string,
    name: string,
    created_at: Date,
    user_id: string,
    token: string,
    expiration_date?: Date,
    revoked_at?: string,
}

export const useApiKeysDataProvider = () => {
    const authCtx = useAuthenticationContext<CustomAuthData>();

    return new ApiKeysDataProvider(authCtx.getProvider());
}

export class ApiKeysDataProvider {
    constructor(private authProvider: IAuthenticationProvider<CustomAuthData>) {
    }

    getHeaders = async (): Promise<Record<string, string>> => {
        let headers = {Accept: "application/json"};
        if (this.authProvider) {
            await this.authProvider.getAuthHeaders().then((authHeaders) => {
                headers = {...headers, ...authHeaders}
            })
        }
        return Promise.resolve(headers)
    }

    getList = async (page_size?: number, page_token?: string): Promise<{api_keys: ApiKey[], page_token: string }> => {
        return axios.get(`${BASE_URL}/api_keys`, {headers: await this.getHeaders(), params: {page_token}})
            .then(r => {
                return {
                    page_token: r.data.page_token,
                    api_keys: r.data.api_keys,
                }
            })
    }

    create = async (name: string, expiration?: Date): Promise<ApiKey> => {
       return axios.put(`${BASE_URL}/api_keys`, {name: name, expiration: expiration}, {headers: await this.getHeaders()})
            .then(r => {
                return r.data
            })
    }

    delete = async (key: string): Promise<boolean> => {
        return axios.delete(`${BASE_URL}/api_keys/${key}`, {headers: await this.getHeaders()})
            .then(r => {
                return r.data;
            });
    }
}
