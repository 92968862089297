import {ResourceConfig} from "../../../lib/types/resource";
import {SimpleTextMeta} from "../../../lib/meta/simpleText/simpleText";
import {DefaultListView} from "../../../lib/views/crud/list/default";
import React from "react";
import {LabTest, LabTestFilter, LabTestStatus} from "./labTest";
import {labTestFilter} from "./labTestFilter";
import {IDMeta} from "../../../lib/meta/id/id";
import {createTheme, Typography} from "@mui/material";
import {SelectBadgeMeta} from "../../../lib/meta/select/selectBadge";
import {ButtonsVariant, CustomTheme} from "../../../theme";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {ActionType} from "../../../lib/types/action";
import DownloadIcon from "@mui/icons-material/Download";
import {LabTestDataProvider} from "./dataProvider";
import {LabTestDetailView} from "../../../components/labTest/labTest/labTestDetailView";
import {NoArgsAction} from "../../../lib/actions/noArgsAction";
import {SingleArgsAction} from "../../../lib/actions/singleArgsAction";
import {UploadTestModal} from "../../../components/labTest/modals/UploadModal";
import {FileUpload, Warning} from "@mui/icons-material";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import {Verb} from "../../../lib/context/AuthorizationContext";
import {BulkDownloadTestModal} from "../../../components/labTest/modals/BulkDownloadModal";
//import {DownloadTestModal} from "../../../components/labTest/modals/DownloadModal";
import {LongTextMeta} from "../../../lib/meta/longText/longText";
import {AxiosError} from "axios";
import {ErrorDTO} from "../../../hoc/axiosHandlerProvider/AxiosHandlerProvider";

const theme = createTheme(CustomTheme(), ButtonsVariant());

interface ConfirmAction {
    text: string
}

export const LabTestResource: ResourceConfig<LabTest, LabTestFilter> = {
    id: "labTest",
    label: "Lab Tests",
    filter: labTestFilter,
    dataProvider: new LabTestDataProvider(),
    actions: [
        new SingleArgsAction<LabTest, LabTest>({
            id: "upload",
            label: "Upload",
            actionType: ActionType.Bulk,
            actionStyle: {
                buttonVariant: 'contained'
            },
            choiceAction: {
                confirmLabel: "Upload",
                cancelLabel: "Cancel",
                description: "",
            },
            hidden: (_data, authNCtx) => {
                return !authNCtx?.hasAccess("lab", "labTest", Verb.WRITE)
            },
            modalDescription: () => {
                return "Select the lab test to upload."
            },
            primary: () => true,
            customModal: <UploadTestModal/>,
            icon: <FileUpload/>
        }),
        new NoArgsAction<{}, LabTest>({
            id: "download",
            label: "Download",
            choiceAction: {
                confirmLabel: "Get Link",
                cancelLabel: "Cancel",
                description: "",
            },
            hidden: () => false,
            modalDescription: (data) => {
                return `Get the link to download ${(data as LabTest[]).length} files.`
            },
            actionStyle: {
                buttonVariant: 'contained'
            },
            primary: () => true,
            actionType: ActionType.Bulk,
            icon: <DownloadIcon fontSize={"medium"}/>,
            customModal: (data, filters) =>
                <BulkDownloadTestModal
                    ids={(data as LabTest[]).map((test) => test.id)}
                    filters={filters as LabTestFilter}
                />
        }),
        /*new NoArgsAction<ConfirmAction, LabTest>({
            id: "download",
            label: "Download",
            hidden: (data) => !data || (data as LabTest).status === LabTestStatus.UPLOADING,
            customModal: (data) => <DownloadTestModal data={data as LabTest}/>,
            choiceAction: {
                confirmLabel: "Download",
                cancelLabel: "Cancel",
                description: "",
            },
            modalDescription: () => {
                return "Do you want to download this laboratory test?"
            },
            actionStyle: {
                buttonVariant: 'contained'
            },
            primary: () => true,
            actionType: ActionType.Single
        }),*/
        new SingleArgsAction<LabTest, LabTest>({
            id: "delete",
            label: "Delete",
            hidden: (data, authNCtx) => {
                const testData = data as LabTest;
                return !testData || testData.status === LabTestStatus.ASSOCIATED || !authNCtx?.hasAccess("lab", "labTest", Verb.WRITE)
            },
            handler: (data, dataProvider, _authProvider, feedbackCtx, formData) => {
                const ltDataProvider = dataProvider as unknown as LabTestDataProvider;
                const ltData = data as LabTest;
                if (ltData.status === LabTestStatus.CANCELED) {
                    return ltDataProvider
                        .hardDelete("id" in data ? data.id : "", true, formData)
                        .then((r) => {
                            feedbackCtx?.openBottomSuccessSnackbar("Lab test file successfully deleted.")
                            return r;
                        }).catch((err) => {
                            const axiosError = err as AxiosError<ErrorDTO, unknown>;
                            feedbackCtx?.openBottomErrorSnackbar(`Error: ${axiosError.response?.data.msg}`)
                            return err;
                        })
                } else {
                    return ltDataProvider &&
                        ltDataProvider
                            .hardDelete("id" in data ? data.id : "", false)
                            .then(r => {
                                feedbackCtx?.openBottomSuccessSnackbar("Lab test file successfully deleted.")
                                return r;
                            }).catch((err) => {
                                const axiosError = err as AxiosError<ErrorDTO, unknown>;
                                feedbackCtx?.openBottomErrorSnackbar(`Error: ${axiosError.response?.data.msg}`)
                                return err;
                            })
                }
            },
            choiceAction: {
                confirmLabel: "Delete",
                cancelLabel: "Cancel",
                description: "",
            },
            modalDescription: (data) => {
                const ltData = data as LabTest;
                return (ltData.status === LabTestStatus.CANCELED  ? "Are you sure you want to PERMANENTLY delete this lab test? " : "Do you want to delete this laboratory test?")},
            actionStyle: {
                buttonVariant: 'outlined',
                buttonColor: 'error'
            },
            primary: () => true,
            primaryActionColor: 'error',
            actionType: ActionType.Single,
            fields: [
                {id: "id", label: "Why do you want to delete this lab test?*",
                    meta: new LongTextMeta<any>(),
                    hidden: (data?: LabTest) => data?.status !== LabTestStatus.CANCELED,
                    validationOptions: {
                        required: {
                            value: true,
                            message: "This field is required."
                        }
                    }}
            ],
        })
    ],
    fields: [
        {   id: "id",
            label:"ID",
            meta: new SimpleTextMeta({})
        },
        {
            id: "name",
            label: "Test name",
            meta: new SimpleTextMeta({}),
        },
        {
            id: "size",
            label: "Dimensione"
        },
        {
            id: "laboratory_name",
            label: "Laboratory",
            meta: new SimpleTextMeta({})
        },
        {
            id: "patient_id",
            label: "Patient ID",
            meta: new IDMeta()
        },
        {
            id: "clinical_study_name",
            label: "Clinical Study",
            meta: new SimpleTextMeta({})
        },
        {
            id: "status",
            label: "Status",
            meta: new SelectBadgeMeta({
                badgeColor: (data?: LabTest) => (
                    data && data.status === LabTestStatus.ASSOCIATED ? theme.palette.success.main : theme.palette.error.main
                ),
                valuer: (data: LabTest) => data.status === LabTestStatus.ASSOCIATED ? "ASSOCIATED" : "NOT ASSOCIATED"
            }),
        }
    ],
    tabPosition: "left",
    list: {
        component: <DefaultListView
            fields={[{id: "name"}, {id: "laboratory_name"}, {id: "patient_id"}, {id: "clinical_study_name"}, {id: "status"}]}
            pageSize={20}
            specialConditions={[{key: "selection", value: (data) => data?.status !== LabTestStatus.UPLOADING}]}
        />,
        id: "lab_test",
        pageSize: 20,
        fields: [{id: "name"}, {id: "laboratory_name"}, {id: "patient_id"}, {id: "clinical_study_name"}, {id: "status"}]
    },
    show: {
        component: <LabTestDetailView id={"labTest"}
                       getTitle={(data?: LabTest) => {
                           const badgeValue = data?.status;
                           return (
                               <>
                                   <div style={{ display: "flex", alignItems: "center" }}>
                                       <Typography
                                           variant={"pageTitle"}
                                           style={{
                                               width: "fit-content",
                                               maxWidth: "15em",
                                               whiteSpace: "nowrap",
                                               overflow: "hidden",
                                               textOverflow: "ellipsis",
                                               marginRight: "0.3em",
                                           }}
                                       >
                                           / {data?.name}
                                       </Typography>
                                       <div style={{ marginTop: "0.5em" }}>
                                       {badgeValue === LabTestStatus.ASSOCIATED ? <CheckCircleIcon color={"success"} fontSize={"medium"} />
                                       : badgeValue === LabTestStatus.NOT_ASSOCIATED ? <Warning color={"warning"} fontSize={"medium"} />
                                       : badgeValue === LabTestStatus.UPLOADING ? <ArrowCircleUpIcon color={"primary"} fontSize={"medium"} />
                                       : <CancelIcon color={"error"} fontSize={"medium"} />
                                       }
                                       </div>
                                   </div>
                               </>
                           )
                       }}
                       tabs={[
                           {
                               id: "main",
                               label: "Test",
                               fields: [
                                   {
                                       id: "name",
                                       label: "Test name",
                                       meta: new SimpleTextMeta({}),
                                   },
                                   {
                                       id: "size",
                                       label: "Size",
                                       meta: new SimpleTextMeta({
                                           valuer: (data) => {
                                               if (data === null) return "--";
                                               const bytes = parseFloat(data.toString());

                                               if (bytes >= Math.pow(2, 40)) {
                                                   const tb = bytes / Math.pow(2, 40);
                                                   return tb.toFixed(2) + " TB";
                                               } else if (bytes >= Math.pow(2, 30)) {
                                                   const gb = bytes / Math.pow(2, 30);
                                                   return gb.toFixed(2) + " GB";
                                               } else if (bytes >= Math.pow(2, 20)) {
                                                   const mb = bytes / Math.pow(2, 20);
                                                   return mb.toFixed(2) + " MB";
                                               } else if (bytes >= Math.pow(2, 10)) {
                                                   const kb = bytes / Math.pow(2, 10);
                                                   return kb.toFixed(2) + " KB";
                                               } else {
                                                   return bytes.toString() + " bytes";
                                               }
                                           }
                                       }),
                                   },
                                   {
                                       id: "patient_id",
                                       label: "Patient ID",
                                       meta: new IDMeta()
                                   },
                                   {
                                       id: "clinical_study_name",
                                       label: "Clinical Study",
                                       meta: new SimpleTextMeta({})
                                   },
                                   {
                                       id: "laboratory_name",
                                       label: "Laboratory",
                                       meta: new SimpleTextMeta({})
                                   }]
                           }
                       ]}
                       previousPage={-1}
        />,
        fields: []
    },
    primaryKey: "id",
    description: "List of the laboratory tests directly uploaded to miWeb.",
    groupId: "lab",
    isDeletable: () => true
}
