import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {Button, Divider, Grid, ListItem, Typography} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { Control, Controller, Path, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from "react-router-dom";
import { useResourceContext } from "../../../context/ResourceContext";
import { Field } from "../../../types/field";
import { DefaultStyles } from "./defaultStyles";
import {HyperlinkMeta} from "../../../meta/hyperlink/hyperlink";
import {AxiosError} from "axios";
import {ErrorDTO} from "../../../../hoc/axiosHandlerProvider/AxiosHandlerProvider";
import {useFeedbackContext} from "../../../context/FeedbackContext";

interface DefaultDetailProps<T> {
    id: string;
    fields: Field<T>[];
    getTitle?: (data?: T) => ReactElement;
}

export function DefaultEditView<T extends Record<string, any>, V>(props: DefaultDetailProps<T>): ReactElement {
    const classes = DefaultStyles();
    const resourceContext = useResourceContext<T, V>();
    const [data, setData] = useState<T | undefined>(undefined);
    const navigate = useNavigate();
    const {id} = useParams();
    const resource = resourceContext.getConfig();
    const {handleSubmit, reset, control, formState} = useForm<T>({mode: "all"});
    const feedbackCtx = useFeedbackContext();

    useEffect(() => {
        setData(undefined)
        initData();
    }, [resource])

    function initData() {
        if (!id) return;
        resource?.dataProvider?.getById(id)
            .then((data) => {
                setData(data);
                reset(data);
            });
    }

    const onSubmit: SubmitHandler<T> = data => {
        resource.dataProvider?.edit(data).then((data) => {
            navigate(-1);
        }).catch((err) => {
            const axiosError = err as AxiosError<ErrorDTO, unknown>;
            feedbackCtx.openBottomErrorSnackbar(`Error: ${axiosError.response?.data.msg}`)
        })
        reset();
    };
    const isDisabled = () => {
        return !formState.isValid
    }

    function mergeField(field: Field<T>): Field<T> {
        const propsField = props.fields?.find(f => f.id === field.id);
        if (!propsField) return field;
        if (propsField.validationOptions) field.validationOptions = propsField.validationOptions;
        if (propsField.meta) field.meta = propsField.meta;
        if (propsField.label) field.label = propsField.label;
        return field;
    }

    return (
        <>
        <Grid container className={classes.mainContainer}>
                <Button onClick={() => navigate(-1)}>
                    <KeyboardBackspaceIcon color={"primary"}></KeyboardBackspaceIcon>
                    <Typography variant={"backButtonText"}>Go back</Typography>
                </Button>
                <Grid container direction={"column"}>
                    <Grid item>
                        <Grid container direction={"row"} className={classes.titleContainer}>
                            <Grid item display={"flex"} alignItems={"center"}>
                                <Typography variant={"pageTitlePrefix"}>{resource?.label} &nbsp;</Typography>
                                <>
                                    {props.getTitle ? (data) && props.getTitle(data) : ""}
                                </>
                            </Grid>
                            <Grid container className={classes.actionsContainer}>
                                <Button variant='contained'
                                    type="submit"
                                    disabled={isDisabled()}>
                                    Save
                                </Button>
                                <Button variant='outlined'
                                    onClick={() => {
                                        navigate(-1)
                                    }}>
                                    Discard
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <form autoComplete="on" onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                        {resource.fields.filter((f: Field<T>) => props.fields.find(ff => ff.id === f.id))
                            .map((f) => {
                                const mergedField = mergeField(f);
                                return (
                                        <>
                                            <ListItem className={classes.showListItem}>
                                                <Grid item>
                                                    <Typography variant={"dataTitle"}>
                                                        {mergedField.label}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Controller
                                                        name={mergedField.id as Path<T>}
                                                        control={control}
                                                        render={() => {
                                                            if (!mergedField.meta) return <></>;
                                                            if (mergedField.meta instanceof HyperlinkMeta) {
                                                                return <>
                                                                    {
                                                                        mergedField.meta.childMeta.getFormComponent(
                                                                            control as Control,
                                                                            mergedField.id as Path<T>,
                                                                            mergedField
                                                                        )
                                                                    }
                                                                </>
                                                            } else {
                                                                return <>
                                                                    {
                                                                        mergedField.meta?.getFormComponent(
                                                                            control as Control,
                                                                            mergedField.id as Path<T>,
                                                                            mergedField
                                                                        )
                                                                    }
                                                                </>
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            </ListItem>
                                        <Divider/>
                                        </>
                                );
                        })}
                </form>
        </Grid>
        </>
    )
}
