import {ResourceConfig} from "../../../lib/types/resource";
import {SimpleTextMeta} from "../../../lib/meta/simpleText/simpleText";
import {DownloadRequest, DownloadRequestStatus, FileRequestStatus} from "./downloadRequest";
import {DownloadRequestDataProvider} from "./downloadRequestDataProvider";
import GenericSearch from "../../genericSearch";
import {StateMeta} from "../../../lib/meta/state/state";
import React from "react";
import {DateMeta} from "../../../lib/meta/date/date";
import {LongTextMeta} from "../../../lib/meta/longText/longText";
import {DefaultShowTabView} from "../../../lib/views/crud/show/defaultTab";
import {createTheme, Typography} from "@mui/material";
import {associatedFilesLink} from "./hyperlinkConfigs/associatedRequestedFiles";
import {ButtonsVariant, CustomTheme} from "../../../theme";
import {NumberMeta} from "../../../lib/meta/number/number";

const theme = createTheme(CustomTheme(), ButtonsVariant());

export const DownloadRequestsListResource: ResourceConfig<DownloadRequest, GenericSearch> = {
    id: "downloadRequests",
    label: "Downloads",
    actions: [],
    dataProvider: new DownloadRequestDataProvider(),
    primaryKey: "id",
    description: "List of download requests",
    groupId: "home",
    fields: [
        {
            id: "id",
            label: "Id",
            meta: new SimpleTextMeta({}),
        },
        {
            id: "created_at",
            label: "Created at",
            meta: new DateMeta({}),
        },
        {
            id: "description",
            label: "Description",
            meta: new LongTextMeta(),
        },
        {
            id: "status",
            label: "Status",
            meta: new StateMeta({
                stateBadge: (data: DownloadRequest) => {
                    return (
                        <Typography
                            variant={"stateBadge"}
                            style={{
                                color: data.status === DownloadRequestStatus.NOT_AVAILABLE ? theme.palette.error.main :
                                    data.status === DownloadRequestStatus.AVAILABLE ? theme.palette.success.main :
                                        data.status === DownloadRequestStatus.WAITING ? theme.palette.warning.main :  "",
                            }}>{data.status?.toUpperCase()}
                        </Typography>
                    )
                }
            })
        },
        {
            id: "files",
            label: "Requested files",
            meta: associatedFilesLink()
        },
        {
            id: "total_size",
            label: "Total size",
            meta: new SimpleTextMeta({})
        }
    ],
    list: {
        id: "download_requests",
        pageSize: 10,
        fields:[{id:"id"}, {id: "created_at"}, {id: "description"}, {id: "status"}],
    },
    show: {
        component: <DefaultShowTabView
            id={"download_requests"}
            tabs={[{label: "info", fields: ["id", "created_at", "description", "status", "files", "total_size"]}]}
            fields={[
                {id: "id"},
                {id: "created_at"},
                {id: "description"},
                {id: "status"},
                {id: "files"},
                {id: "total_size"}
            ]}
            previousPage={-1}
            getTitle={(data?:DownloadRequest) => {
                return (
                    <>
                        <Typography variant={"pageTitle"}
                                    style={{
                                        width: "fit-content",
                                        maxWidth: "15em",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}
                        >/ {data?.id}</Typography>
                    </>
                )
            }}
        />,
        fields:[
            {id: "id"},
            {id: "created_at"},
            {id: "description"},
            {id: "status"},
            {id: "files"},
            {id: "total_size"}
        ]
    }
}

