import {LabTest} from "../../labTest/labTest/labTest";

export enum FileRequestStatus {
    RESTORED = "restored",
    RESTORING = "restoring",
    INVALID = "invalid",
    EXPIRED = 'expired'
}

export enum DownloadRequestStatus {
    AVAILABLE = "available",
    WAITING = "waiting",
    NOT_AVAILABLE = "not available"
}

export interface FileRequest {
    lab_test_file: LabTest
    status: string
    available_at: Date | null
    available_until: Date | null
}

export interface DownloadRequest {
    id: string;
    created_at: Date;
    updated_at: Date;
    description: string;
    user_id: string;
    files: FileRequest[];

    status: string;
    total_size: string
}

export interface DownloadRequestFilter {
}