import axios from "axios";
import {BASE_URL} from "../../../App";
import {BaseDataProvider} from "../../baseDataProvider";
import {ClinicalStudy} from "./clinicalStudy";
import GenericSearch from "../../genericSearch";
import {SourceFile} from "../../clinicalData/sourceFile/sourceFile";


export interface Membership {
    file_id: string,
    index_in_file: number,
    name_in_file: string
}

export interface Column extends Membership {
    id: string;
    name: string;
    clinicalStudyId: string;
    dataType: string;
    memberships: Membership[]
}

export class ClinicalStudiesDataProvider extends BaseDataProvider<ClinicalStudy, GenericSearch> {

    constructor() {
        super("clinical_studies")
    }

    getUploadHeaders = async (): Promise<Record<string, string>> => {
        let headers = {Accept: 'multipart/form-data'};
        if (this.authProvider) {
            await this.authProvider.getAuthHeaders().then((authHeaders) => {
                headers = {...headers, ...authHeaders}
            })
                .catch(() => {
                    this.authProvider?.setAuthData({isAuthenticated: () => false})
                });
        }
        return Promise.resolve(headers)
    }

    getColumns = async (id: string, filter?: Column): Promise<Column[]> => {
        return axios.get<Column[]>(`${BASE_URL}/clinical_studies/${id}/columns`, {
            headers: await this.getHeaders({"Accept": "application/json"}),
            params: {...filter}
        }).then(r => {
            return r.data;
        });
    }
    getFiles = async (id: string): Promise<{data: SourceFile[], page_token: string}> => {
        return axios.get(`${BASE_URL}/clinical_studies/${id}/files`, {
            headers: await this.getHeaders({"Accept": "application/json"})
        }).then(r => {
            return {
                page_token: r.data.page_token,
                data: r.data.data,
            };
        });
    }
    upload = async (clinicalStudyId: string, data: FormData): Promise<SourceFile> => {
        return axios.post<SourceFile>(`${BASE_URL}/clinical_studies/${clinicalStudyId}/upload_file`,
            data,
            {headers: await this.getUploadHeaders()}).then(r => {
            return r.data
        });
    }

}
