import {GroupConfig} from "../lib/types/group";
import UsersIcon from "../images/icons/tabs/users.svg";

export const usersGroup: GroupConfig = {
    id: 'usersG',
    label: 'Users',
    defaultResource: () => "users",
    image: UsersIcon,
    hidden: false
}
