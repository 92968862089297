import {Audit} from "../appManager/audit/audit";

export enum DocumentType {
    FILE = "file",
    DIRECTORY = "directory"
}
export interface Access {
    access: {document_id: string, document_name: string},
    pattern: string,
    resource: string,
    rule: "READ" | "WRITE" | "ADMIN"
}

export interface Document {
    id: string;
    name: string;
    tag_name: string;
    created_at: Date;
    uploaded_by: string;
    type: DocumentType;
    parent_id?: string;
    log?: Audit[];
    file: Document;
    access_rule: string;
    size: number;
}

export interface DocumentFilter {
    q: string,
    root?: boolean;
    parent_id?: string;
    depth?: number;
}
