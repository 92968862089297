import {FC, ReactElement} from "react";
import {Field} from "./field";
import IDataProvider from "../../hoc/dataProvider/IDataProvider";
import {IAuthenticationProvider} from "./auth";
import {CustomAuthData} from "../../auth/provider";
import {AuthorizationContextValue} from "../context/AuthorizationContext";
import {FeedbackContextValue} from "../context/FeedbackContext";

export enum ActionType {
    Single,
    Bulk,
    Step,
    Redirect
}

export interface Action<ActionArgument, Resource> {
    id: string;
    label: string;
    type: ActionType;
    primary?: (res?: Resource | Resource[]) => boolean;
    fields?: Field<ActionArgument>[];
    getActionComponent: (res?: Resource | Resource[], isDisabled?: boolean, filter?: any) => FC;
    hidden: (res?: Resource | Resource[], authNCtx?: AuthorizationContextValue) => boolean;
    handler?: (res: Resource | Resource[], dataProvider?: IDataProvider<any, any>, authProvider?: IAuthenticationProvider<CustomAuthData>, feedbackCtx?: FeedbackContextValue, formData?: string, args?: ActionArgument) => ((Promise<Resource | boolean | undefined>) | undefined);
    icon?: ReactElement;
    onComplete?: () => void;
    redirectionRoute?: string;
}

