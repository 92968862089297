import {Grid, Typography} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { ReactElement } from "react";
import { Control, FieldValues, Path, RegisterOptions, useController } from "react-hook-form";
import { Field } from "../../types/field";
import { ListMeta, MetaField } from "../../types/meta";

interface DateProps {
    label?: string;
    data?: Date;
    defaultDate?: Date | dayjs.Dayjs;
}
function DateShow({label, data, defaultDate}: DateProps): ReactElement {
    const date = new Date(data?.toString()!);

    return (
        <>
            <Grid item paddingBottom={"1em"}>
                <Typography variant={"dataTitle"}>
                    {label}
                </Typography>
            </Grid>
            <Grid item paddingBottom={"1em"}>
                <Typography variant={"data"}>
                    {data ? dayjs(date).format("DD/MM/YYYY, HH:mm:ss").toString() : defaultDate ? dayjs(defaultDate).format("DD/MM/YYYY, HH:mm").toString() : undefined}
                </Typography>
            </Grid>
        </>
    )
}

function DateForm<T>(
    formControl: Control,
    name: Path<FieldValues>,
    data?: T,
    validationOptions?: RegisterOptions,
    minDate?: Date,
    maxDate?: Date,
    valuer?: (data: T) => string,
    defaultDate?: Date | dayjs.Dayjs | undefined,
    clearable?: boolean
): ReactElement {

    const { field, fieldState } = useController({
        name: name,
        control: formControl,
        rules: validationOptions
    });

    return (
        <DatePicker
            {...field}
            format="DD-MM-YYYY"
            sx={{width:"20em"}}
            defaultValue={(data && data[field.name as keyof T] !== null) ? valuer && valuer(data) : defaultDate}
            minDate={minDate? dayjs(minDate) : undefined}
            maxDate={maxDate? dayjs(maxDate) : undefined}
            slotProps={{
                textField: {
                    helperText: fieldState.error?.message,
                    placeholder: data && data[field.name as keyof T] ? valuer && valuer(data) : "DD/MM/YYYY",
                },
                actionBar: {
                    actions: clearable === true ? ['clear'] : [],
                }
            }}
        />
    );
}

interface DateMetaProps<T> {
    defaultDate?: (data?: T) => Date | dayjs.Dayjs | undefined,
    minDate?: Date,
    maxDate?: Date,
    valuer? : (data: T) => string,
    clearable?: boolean
}


export class DateMeta<T> implements MetaField<T> {
    defaultDate?: (data?: T) => Date | dayjs.Dayjs | undefined;
    minDate?: Date;
    maxDate?: Date;
    valuer? : (data: T) => string;
    clearable?: boolean;
    constructor(props: DateMetaProps<T>) {
        this.defaultDate = props.defaultDate;
        this.minDate = props.minDate;
        this.maxDate = props.maxDate;
        this.valuer = props.valuer;
        this.clearable = props.clearable;
    }

    getListMeta(): ListMeta {
        return ({
            type: 'string',
            value: (arg: Date) => {
                const date = new Date(arg)
                return dayjs(date).format("DD/MM/YYYY, HH:mm:ss").toString();
            }
        })
    };

    getListComponent(data: T | undefined, field: Field<T>) {
        const d =  data && data[field.id]
        const date = new Date(d!.toString())
        return () => {
            return <Typography noWrap variant={"body1"}>
                {dayjs(date).format("DD/MM/YYYY, HH:mm:ss").toString()}
            </Typography>
        }
    }

    getFormComponent(
        control: Control,
        name: Path<FieldValues>,
        field: Field<T>,
        data?: T,
        defaultDate?: (date?: T) => Date | dayjs.Dayjs | undefined,
        clearable?: boolean): ReactElement {
        return DateForm<T>(control, name, data, field.validationOptions, this.minDate, this.maxDate, this.valuer, this.defaultDate && this.defaultDate(data), this.clearable);
    }

    getShowComponent(data: T | undefined, field: Field<T>){
        return () => {
            return DateShow({label: field.label, data: data ? data[field.id] as Date : undefined, defaultDate: this.defaultDate && this.defaultDate(data)})
        }
    }
}
