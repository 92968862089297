import {GroupConfig} from "../lib/types/group";
import ManagerIcon from "../images/icons/tabs/manager.svg";

export const appManager: GroupConfig = {
    id: 'appManager',
    label: 'App Manager',
    defaultResource: () => "entities",
    image: ManagerIcon,
    hidden: false
}

