import {ReactElement} from "react";
import {CircularProgress} from "@mui/material";

export const getDetailErrorCause = (errorCode: number): ReactElement => {
    switch (errorCode) {
        case 400:
            return <>{"Invalid resource ID."}</>;
        case 404:
            return <>{"This resource does not exist."}</>;
        case 401:
            return <>{"Not authorized."}</>;
        default:
            return <>{"This resource is temporarily unavailable."}<CircularProgress/></>;
    }
}
