import {createStyles, makeStyles} from "@mui/styles";

const DocumentsModalsStyles = makeStyles(() =>
    createStyles({
        modalContainer: {
            //marginTop: "-3em !important"
        },
        permissionsModalDescription: {
            marginBottom: "2em !important",
            textAlign: "center"
        },
        uploadContainer: {
            justifyContent:"center",
            alignItems:"center !important",
            height:"200px",
            backgroundColor:"#D9D9D9"
        },
        fileName: {
            alignItems:"center",
            textAlign: "center",
            alignSelf: "center !important",
            width: "fit-content !important"
        },
        uploadLabel: {
            fontColor: "#12668A !important",
            cursor: "pointer",
            textDecoration: "underline"
        },
        permissionModalHeader: {
            marginBottom:"2em !important"
        },
        permissionModalList: {
            marginTop:"2em",
            border:"1px solid #DDDDDD",
            borderRadius: "10px !important",
            height: "fit-content",
            maxHeight: "400px",
            overflowY: "scroll"
        },
        scrollableList: {
            height: "100%",
            paddingTop: 0,
        },
        listItem: {
            justifyContent: "space-between !important"
        },
        menuItem: {
            backgroundColor: "#DDDDDD"
        },
        roleWrapper: {
            width:"60%",
            display: "flex",
            alignItems: "center"
        },
        roleIcon: {
            marginRight: "0.3em"
        },
        roleName: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        }
    }));

export {DocumentsModalsStyles};
