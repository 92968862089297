import {Grid, TextField, Typography} from "@mui/material";
import React, { ReactElement } from "react";
import {
    Control,
    FieldValues,
    Path, RegisterOptions,
    useController
} from "react-hook-form";
import { Field } from "../../types/field";
import { ListMeta, MetaField } from "../../types/meta";

interface EmailProps {
    label?: string;
    data?: string;
}

function EmailShow({label, data}: EmailProps): ReactElement {
    return (
        <>
            <Grid item paddingBottom={"1em"}>
                <Typography variant={"dataTitle"}>
                    {label}
                </Typography>
            </Grid>
            <Grid item paddingBottom={"1em"}>
                <Typography variant={"data"}>
                    {!data? "--" : data}
                </Typography>
            </Grid>
        </>
    )
}

function EmailInput<T>(
    formControl: Control,
    name: Path<FieldValues>,
    validationOptions?: RegisterOptions): React.ReactElement {
    const {field, fieldState} = useController({
        name: name,
        control: formControl,
        rules: {
            ...validationOptions,
            pattern: {
                value: /^[\S]+@([\w-]+\.)+[\w-]{2,4}(\.[a-zA-Z]{2,4})?$/,
                message: "Invalid email format."
            }
        }
    });

    return (
        <>
            <TextField
                {...field}
                style={{width:"20em"}}
                error={fieldState.error !== undefined}
                helperText={fieldState.error?.message}
            />
        </>
    );
}

export class EmailMeta<T> implements MetaField<T> {

    getListMeta(): ListMeta {
        return {
            type: "string",
            value: (arg: any) => arg,
        };
    }

    getFormComponent(
        control: Control,
        name: Path<FieldValues>,
        field: Field<T>
    ): React.ReactElement {

        return EmailInput<T>(control, name, field.validationOptions);
    }

    getShowComponent(data: T | undefined, field: Field<T>) {
        return () => {
            return EmailShow({label: field.label, data: data ? data[field.id] as string : ''})
        }
    }
}
