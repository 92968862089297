import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid, IconButton, InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React, {ReactElement, useEffect, useRef, useState} from "react";
import {SourceFileResource} from "../../../../resources/clinicalData/sourceFile/sourceFileResource";
import {Controller, FieldValues, SubmitHandler, useForm} from "react-hook-form";
import {SourceFileDataProvider} from "../../../../resources/clinicalData/sourceFile/sourceFileDataProvider";
import {StepView} from "../../../../lib/views/other/step/step";
import {ClinicalStudiesDataProvider} from "../../../../resources/appManager/clinicalStudies/clinicalStudiesDataProvider";
import {ClinicalStudiesResource} from "../../../../resources/appManager/clinicalStudies/clinicalStudyResource";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import {DefaultModal} from "../../../../lib/components/modal/defaultModal";
import {SourceFileUploadStyles} from "./uploadStyles";
import {ClinicalStudy} from "../../../../resources/appManager/clinicalStudies/clinicalStudy";
import CancelIcon from "@mui/icons-material/Cancel";
import {FileRow, PreloadedColumn} from "../../../preload";
import {EntitiesResource} from "../../../../resources/entities/entitiesResource";
import {EntitiesDataProvider} from "../../../../resources/entities/entitiesDataProvider";
import Entity from "../../../../resources/entities/entities";
import {VariablesResource} from "../../../../resources/variables/variablesResource";
import {VariablesDataProvider} from "../../../../resources/variables/variablesDataProvider";
import Variable from "../../../../resources/variables/variable";
import {useModalContext} from "../../../../lib/context/ModalContext";
import {useNavigate} from "react-router-dom";
import { StarBorderOutlined } from '@mui/icons-material';
export interface SelectedColumn {
    name: string,
    index: number,
    id?: string,
    data_type?: string
}

export enum FileType {
    MAIN_CLINICAL = "mainClinical",
    OTHER = "other"
}

interface SourceFileMetadata {
    clinicalStudyId: string;
    fileName: string;
    fileType: FileType;
    entityId: string;
}

export function SourceFileUploadView(): ReactElement {
    const classes = SourceFileUploadStyles();

    const navigate = useNavigate();

    const fileInputRef = useRef<HTMLInputElement>(null)

    const [uploading, setUploading] = useState<boolean>(false);

    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const [preloadedColumns, setPreloadedColumns] = useState<PreloadedColumn[]>([]);
    const [fileMetadata, setFileMetadata] = useState<SourceFileMetadata>()
    const preloadForm =
        useForm<SourceFileMetadata>({
            mode: "all"
        });

    const [clinicalStudies, setClinicalStudies] = useState<ClinicalStudy[]>([])
    const [entities, setEntities] = useState<Entity[]>([])
    const [variables, setVariables] = useState<Variable[]>([])

    const [loaded, setLoaded] = useState<boolean>(false)

    const modalContext = useModalContext();

    const sourceFileDataProvider = (SourceFileResource.dataProvider as unknown as SourceFileDataProvider);
    const clinicalStudiesDataProvider = (ClinicalStudiesResource.dataProvider as unknown as ClinicalStudiesDataProvider);
    const entitiesDataProvider = (EntitiesResource.dataProvider as unknown as EntitiesDataProvider);
    const variablesDataProvider = (VariablesResource.dataProvider as unknown as VariablesDataProvider);

    const availableVariables = () =>{
        const usedVars = preloadedColumns.map(c => c.variable_id);
        return variables.filter(v => !usedVars.includes(v.id))
    }

    useEffect(() => {
        Promise.all([
            clinicalStudiesDataProvider.pagedList().then(c => {
                setClinicalStudies(c.data)
            }),
            entitiesDataProvider.pagedList(-1).then(e => {
                setEntities(e.data)
            }),
            variablesDataProvider.pagedList(-1).then(e => {
                setVariables(e.data)
            })
        ]).then(() => {
            setLoaded(true)
        })

    }, [])

    useEffect(() => {
        if (selectedFile) {
            preloadFile(selectedFile)
            preloadForm.trigger()
        }
    }, [selectedFile])

    const preloadFile = (file: File) => {
        const blob = new Blob([file], {type: "multipart/form-data"});
        const formData = new FormData();
        formData.append("document", blob, file.name);

        sourceFileDataProvider.postPreload(formData).then((data: FileRow[]) => {
            if(data.length === 0){
                //TODO Err
                return
            }
            setPreloadedColumns([
                ...data[0].Cells.map(c => {
                    return {
                        name: c.name,
                        index: c.index
                    }
                })
            ])

        })
    }

    const onSubmit = (data: SourceFileMetadata) => {
        setFileMetadata(data)
    }

    const uploadFile: SubmitHandler<FieldValues> = () => {
        if (!fileMetadata) {
            return;
        }
        setUploading(true);

        if (!selectedFile) return;
        const blob = new Blob([selectedFile], {type: "multipart/form-data"})

        const formData = new FormData();
        formData.append("fileType", fileMetadata.fileType);
        formData.append("tagName", fileMetadata.fileName );
        //TODO
        // ormData.append("primaryKeyColumnIndex", pkIndex?.toString()!);
        formData.append("columns", JSON.stringify(preloadedColumns))
        formData.append("entityId", preloadForm.getValues().entityId)
        formData.append("document", blob, selectedFile?.name)

        setTimeout(() => {
            clinicalStudiesDataProvider.upload(fileMetadata.clinicalStudyId, formData).then((r) => {
                setUploading(false)
                modalContext?.openModal({
                    component: <DefaultModal title={r.error !== null ? "ATTENTION!" : "Upload status"}
                                             description={r.error !== null ?
                                                 `Error: ${r.error}. The file ${fileMetadata.fileName} is uploaded with status ERROR.` :
                                                 `File ${fileMetadata.fileName} uploaded successfully`}
                                             confirmLabel={"Back to files"}
                                             handleConfirm={() => {
                                                 navigate(-1)
                                                 return modalContext?.closeModal()
                                             }}
                    />
                })
            }).catch((e) => {
                setUploading(false)
                modalContext?.openModal({
                    component: <DefaultModal title={"ATTENTION!"}
                                             description={`Unexpected error: ${e.response.data.msg}`}
                                             confirmLabel={"Back to files"}
                                             handleConfirm={() => {
                                                 navigate(-1)
                                                 return modalContext?.closeModal()
                                             }}
                    />
                })
            })
        }, 1000);
    }

    const isPreloadDisabled = () => {
        return (!selectedFile || !preloadForm.formState.isValid)
    }

    if (!loaded) {
        return <CircularProgress/>
    }

    return (
        <StepView
            title={<Typography variant={"pageTitlePrefix"}>{"Source files"} </Typography>}
            steps={[
                {
                    title: <Typography variant={"pageTitle"} className={classes.title}>/ File metadata</Typography>,
                    description: "Please, fill in the data of the file you want to upload.",
                    button: {
                        text: "NEXT",
                        isDisabled: isPreloadDisabled(),
                        isProcessing: false,
                        onClick: () => {
                            onSubmit(preloadForm.getValues())
                        }
                    },
                    component: <>
                        <form>
                            <Grid container className={classes.formContainer}>
                                <Grid item md={6}>
                                    <Grid container direction={"row"} justifyContent={"space-between"}>
                                        <Grid item marginLeft={"1em"} display={"flex"} alignItems={"center"}>
                                            <Typography variant={"dataTitle"}>
                                                {"File name*"}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name={"fileName"}
                                                control={preloadForm.control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: "file name is required"
                                                    }
                                                }}
                                                render={({field, fieldState}) => {
                                                    return (
                                                        <Grid item>
                                                            <FormControl>
                                                                <TextField
                                                                    {...field}
                                                                    className={classes.input}
                                                                    error={fieldState.isTouched && fieldState.invalid}
                                                                />
                                                                {
                                                                    fieldState.isTouched && fieldState.invalid ?
                                                                        <FormHelperText
                                                                            error={true}
                                                                        >{fieldState.error?.message}</FormHelperText>
                                                                        : null
                                                                }
                                                            </FormControl>
                                                        </Grid>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6}>
                                    <Grid container direction={"row"} justifyContent={"space-between"}>
                                        <Grid item marginLeft={"1em"} display={"flex"} alignItems={"center"}>
                                            <Typography variant={"dataTitle"}>
                                                {"Clinical Study*"}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name={"clinicalStudyId"}
                                                control={preloadForm.control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: "Clinical study is required"
                                                    }
                                                }}
                                                render={({field, fieldState}) => {
                                                    return (
                                                        <Grid item>
                                                            <Grid item>
                                                                <FormControl>
                                                                    <InputLabel id="cs-label">Select...</InputLabel>
                                                                    <Select
                                                                        className={classes.input}
                                                                        labelId={"cs-label"}
                                                                        label={"Select..."}
                                                                        {...field}
                                                                    >
                                                                        {
                                                                            clinicalStudies.map(c => {
                                                                                return <MenuItem
                                                                                    key={c.id}
                                                                                    value={c.id}
                                                                                >
                                                                                    {c.name}
                                                                                </MenuItem>
                                                                            })
                                                                        }

                                                                    </Select>
                                                                    {
                                                                        fieldState.isTouched && fieldState.invalid ?
                                                                            <FormHelperText
                                                                                error={true}
                                                                            >{fieldState.error?.message}</FormHelperText>
                                                                            : null
                                                                    }
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6}>
                                    <Grid container direction={"row"} justifyContent={"space-between"}>
                                        <Grid item marginLeft={"1em"} display={"flex"} alignItems={"center"}>
                                            <Typography variant={"dataTitle"}>
                                                {"Entity*"}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name={"entityId"}
                                                control={preloadForm.control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: "Entity is required"
                                                    }
                                                }}
                                                render={({field, fieldState}) => {
                                                    return (
                                                        <Grid item>
                                                            <Grid item>
                                                                <FormControl>
                                                                    <InputLabel id="cs-label">Select...</InputLabel>
                                                                    <Select
                                                                        className={classes.input}
                                                                        labelId={"cs-label"}
                                                                        label={"Select..."}
                                                                        {...field}
                                                                    >
                                                                        {
                                                                            entities.map(c => {
                                                                                return <MenuItem
                                                                                    key={c.id}
                                                                                    value={c.id}
                                                                                >
                                                                                    {c.name} - {c.fileType}
                                                                                </MenuItem>
                                                                            })
                                                                        }

                                                                    </Select>
                                                                    {
                                                                        fieldState.isTouched && fieldState.invalid ?
                                                                            <FormHelperText
                                                                                error={true}
                                                                            >{fieldState.error?.message}</FormHelperText>
                                                                            : null
                                                                    }
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={5.5}>

                                </Grid>
                                <Grid item md={12} sx={{
                                    backgroundColor: "primary.light",
                                    borderRadius: "0.5em",
                                    display: "flex",
                                    alignItems: "center",
                                    pl: 2
                                }}>
                                    <Grid container>
                                        <Grid item md={3}>
                                            <Button variant={"contained"}>
                                                <FolderOpenIcon/>
                                                <label className={classes.fileSelectButton}
                                                       htmlFor="fileInput">&nbsp; Select file
                                                </label>
                                                <input id="fileInput"
                                                       onChange={(ev) => {
                                                           if (ev.target.files !== null && ev.target.files.length > 0) {
                                                               setSelectedFile(ev.target.files[0])
                                                           }
                                                       }}
                                                       hidden
                                                       ref={fileInputRef}
                                                       type="file"
                                                       accept={".csv, .xls, .xlsx"}
                                                />
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{display: "flex", alignItems: "center"}}>
                                            {
                                                selectedFile ?
                                                    <>
                                                        <IconButton onClick={() => {
                                                            setSelectedFile(undefined)
                                                            if (fileInputRef.current) {
                                                                fileInputRef.current.files = null
                                                                fileInputRef.current.value = ""
                                                            }
                                                        }}>
                                                            <CancelIcon/>
                                                        </IconButton>
                                                        <Typography variant={"body1"}>
                                                            {selectedFile.name}
                                                        </Typography>
                                                    </>
                                                    : null
                                            }
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </form>
                    </>
                },
                {
                    title: <Typography variant={"pageTitle"}>/ Upload source file</Typography>,
                    description: `To complete the upload of ${fileMetadata?.fileName}`,
                    button: {
                        text: "Complete Upload",
                        isDisabled: preloadedColumns.some(c => c.variable_id === undefined)
                            || !preloadedColumns.some(c => c.variable_id === "patientIds"),
                        isProcessing: uploading,
                        onClick: uploadFile
                    },
                    component: <Grid container className={classes.formContainer}>
                        {
                            preloadedColumns.map((c, index) => {
                                return (
                                    <Grid item key={index} md={4}>
                                        <Typography variant={"dataTitle"} mr={3}>
                                            {c.name}
                                        </Typography>
                                        <Select
                                            label={c.name}
                                            value={c.variable_id !== undefined ? c.variable_id : ""}
                                            placeholder={"Select..."}
                                            onChange={(val)=>{
                                                const oldState = [...preloadedColumns]
                                                oldState[index].variable_id = val.target.value as string;
                                                setPreloadedColumns([...oldState])
                                            }}
                                        >
                                            <MenuItem value={"patientIds"}
                                                      disabled={preloadedColumns.map(c => c.variable_id).includes('patientIds')}
                                            >
                                                Patients {'\u2606'}
                                            </MenuItem>
                                            {
                                                variables.map(v => {
                                                    return (
                                                        <MenuItem
                                                            disabled={preloadedColumns.map(c => c.variable_id).includes(v.id)}
                                                            value={v.id}
                                                        >
                                                            {v.name}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                }

            ]
            }
        />
    )
}
