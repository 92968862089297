import axios from "axios";
import { BASE_URL } from "../../../App";
import { BaseDataProvider } from "../../baseDataProvider";
import {Access} from "./access";
import {PagedResponse} from "../../../types/pagedResponse";


export class AccessDataProvider extends BaseDataProvider<Access, Access> {

    constructor(){
        super('access');
    }

    pagedList = async <R extends PagedResponse<Access>>(page_size?: number, page_token?: string, filter?: Access): Promise<{ data: Access[], page_token: any } | R> => {
        return axios.get(`${BASE_URL}/access`, { headers: await this.getHeaders({"Accept": "application/json"}), params: { page_size, page_token, ...filter } }).then(r => {
            return {
                page_token: r.data.next_page_token,
                data: r.data.accesses,
            };
        });
    }

}
