import {createStyles, makeStyles} from "@mui/styles";

const ProfileStyles = makeStyles(() =>
    createStyles({
        mainContainer: {
            marginTop: "7em",
            width: "90% !important",
            marginLeft: "auto",
            marginRight: "auto"
        },
        titleContainer: {
            marginTop: "2em !important",
            justifyContent: "space-between"
        },
        title: {
            display: 'flex',
            alignItems: 'center !important'
        },
        icon: {
            fontSize: "2.5em !important",
            marginRight: "0.3em"
        },
        actionsContainer: {
            width: "fit-content !important",
            justifyContent: "space-between !important"
        },
        dataContainer: {
            marginTop: "3em !important",
            width: "100%!important"
        },
        showListItem: {
            marginTop: "2em",
            justifyContent: "space-between !important"
        },
        modalContainer: {
            //
        },
        modalInnerContainer: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
        },
        modalList: {
            marginTop:"2em",
            border:"1px solid #DDDDDD",
            borderRadius: "10px !important",
            height: "fit-content",
            maxHeight: "400px",
            overflow: "hidden"
        },
        scrollableList: {
            overflowY: "scroll",
            height: "100%",
            paddingTop: 0,
        },
        cell: {
            width: "10em"
        },
        smallCell: {
            width: "2em",
            maxWidth: "2em"
        },
        cellWrapper: {
            display: "flex",
            maxWidth: "10em",
            width: "10em",
            overflow: "hidden"
        },
        accessKeyContainer: {
            overflow: "hidden"
        },
        modalIcon: {
            cursor: "pointer",
        },
        longText: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
        },
        modalFormWrapper: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center !important"
        },
        modalFormInput: {
            width: "70% !important",
            justifyContent: "space-between !important"
        },
        modalFormLabel: {
            width: "10em"
        },
        modalFormButton: {
            marginTop: "2em",
            marginBottom: "-2em",
            width: "60%",
            justifyContent: "center !important"
        },
        buttonContainer: {
            display: "flex",
            width: "2em",
            marginLeft: "-0.7em"
        },
        box: {
            backgroundColor: "#D9D9D9",
            padding: "1.5em",
            paddingRight: "1em",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        token: {
            width: "20em",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        copyButton: {
            width: "fit-content",
            fontSize: "0.7em !important",
            paddingLeft: "1em",
            paddingRight: "1em"
        },
        highlightedRow: {
            backgroundColor: "#DAEBF2"
        },
        copiedText: {
            marginLeft: "-1em !important",
            fontSize: "11px !important"
        }
    }));

export {ProfileStyles};
