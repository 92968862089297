import {ResourceConfig} from "../../../lib/types/resource";
import {SimpleTextMeta} from "../../../lib/meta/simpleText/simpleText";
import {DateMeta} from "../../../lib/meta/date/date";
import {LongTextMeta} from "../../../lib/meta/longText/longText";
import {Role} from "./role";
import {Typography} from "@mui/material";
import React from "react";
import {roleFilter} from "./roleFilter";
import {RolesDataProvider} from "./rolesDataProvider";
import {RolesDetailsTabView} from "../../../components/roles/details/roleDetails";
import GenericSearch from "../../genericSearch";
import {associatedMembersLink} from "./hyperlinkConfigs/associatedMembers";


export const RoleResource: ResourceConfig<Role, GenericSearch> = {
    id: "roles",
    label: "Roles",
    actions: [],
    filter: roleFilter,
    dataProvider: new RolesDataProvider(),
    fields: [
        {
            id: "name",
            label: "Name",
            meta: new SimpleTextMeta({})
        },
        {
            id: "created_at",
            label: "Created at",
            meta: new DateMeta({}),
        },
        {
            id: "description",
            label: "Description",
            meta: new LongTextMeta()
        },
        {
            id: "members",
            label: "Users",
            meta: associatedMembersLink()
        }
    ],
    tabPosition: "left",
    list: {
        id: "groups",
        pageSize: 20,
        fields: [{id: "name"}, {id: "created_at"}, {id: "description"}, {id: "members"}]
    },
    show: {
        component: <RolesDetailsTabView
            editMode={false}
            getTitle={(data?: Role) => {
                return (
                    <>
                        <Typography variant={"pageTitle"}
                                    style={{
                                        width: "fit-content",
                                        maxWidth: "15em",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}
                        >/ {data?.name}</Typography>
                    </>)
            }}
        />,
        fields: []
    },
    edit: {
        component: <RolesDetailsTabView
            editMode={true}
            getTitle={(data?: Role) => {
                return (
                    <>
                        <Typography variant={"pageTitle"}>/ {data?.name}</Typography>
                    </>)
            }}
        />,
        fields: []
    },
    create: {
        fields: [
            {
                id: "name",
                validationOptions: {
                    required: true
                }
            },
            {
                id: "description",
                validationOptions: {
                    minLength: {
                        value: 50,
                        message: "Description is too short."
                    },
                    required: {
                        value: true,
                        message: "Description is required."
                    },
                }
            }
        ]
    },
    description: "List of the available roles assignable to each user.",
    isDeletable: (data?: Role) => data?.members.valueOf() !== 0,
    primaryKey: "id",
    groupId: "appManager"
}
