export interface Audit {
    id: string;
    created_at: Date;
    user_id: string;
    action: AuditAction;
    object: AuditObject | AuditTarget;
    object_id: string;
    path: string;
    body: JSON;
}

export interface AuditInfo {
    Key: string,
    Value: string[]
}

export enum AuditAction {
    GET_RESOURCE = "VIEW",
    UPDATE_RESOURCE = "UPDATE",
    ADD_RESOURCE = "CREATE",
    DELETE_RESOURCE = "DELETE",
    DOWNLOAD_RESOURCE = "DOWNLOAD",
    SIGN_CONTRACT = "SIGN CONTRACT"
}

export enum AuditObject {
    GROUP = "ROLE",
    ACCESS = "ACCESS",
    PERMISSION = "PERMISSION",
    CLINICAL_STUDY = "CLINICAL STUDY",
    SOURCE_FILE = "SOURCE FILE",
    USER_LOG = "USER LOG",
    USER = "USER",
    LABORATORY = "LABORATORY",
    CLINICAL_DATA = "CLINICAL DATA",
    FILE_TYPE = "FILE TYPE",
    DOCUMENT = "DOCUMENT",
    LAB_TEST_FILE = "LAB TEST",
    LAB_SOURCE_FILE = "METADATA FILE"
}

export enum AuditTarget {
    GROUP = "GROUP",
    ACCESS = "ACCESS",
    PERMISSION = "PERMISSION",
    CLINICAL_STUDY = "CLINICAL_STUDY",
    SOURCE_FILE = "SOURCE_FILE",
    USER = "USER",
    LABORATORY = "LABORATORY",
    CLINICAL_DATA = "CLINICAL DATA",
    DOCUMENT = "DOCUMENT",
    LAB_TEST_FILE = "LAB_TEST_FILE",
    LAB_SOURCE_FILE = "LAB_SOURCE_FILE"
}

export interface AuditFilter {
    q: string,
    action: string,
    user_id: string,
    object_id: string,
    object: string
}
