import {HyperlinkMeta} from "../../../../lib/meta/hyperlink/hyperlink";
import {Role} from "../role";
import {NumberMeta} from "../../../../lib/meta/number/number";
import {UserDataProvider} from "../../users/userDataProvider";
import {User} from "../../users/user";
import IDataProvider from "../../../../hoc/dataProvider/IDataProvider";
import {Box, Divider, Link, List, ListItem} from "@mui/material";
import React from "react";

export const associatedMembersLink = () => {
    return new HyperlinkMeta<Role>({
        childMeta: new NumberMeta({isLink: true}),
        title: "Users",
        dataProvider: new UserDataProvider(),
        modalConfig: {
            getAdditionalData: async (dataProvider?): Promise<User[] | undefined> => {
                if (!dataProvider) return;
                try {
                    const res = await (dataProvider as IDataProvider<User, User>).pagedList();
                    return res.data as User[];
                } catch (error) {
                    console.error('Failed to fetch users:', error);
                    return [];
                }
            },
            getBody: (role?: Role, users?: User[]) => {
                return (
                    <>
                        <Box style={{
                            justifyContent: "center",
                            width: "auto",
                            height: "70vh",
                            overflow: "hidden"
                        }}>
                            <Box style={{
                                overflowY: "scroll",
                                justifyContent: "center",
                                height: "100%",
                                paddingTop: 0,
                                paddingRight: "1em"
                            }}>
                                <List>
                                    {users?.filter((user) => role?.members?.indexOf(user.id) !== -1)
                                        .map((user) => {
                                            return (
                                                <>
                                                    <ListItem style={{justifyContent: "space-between", height: "3em"}}>
                                                        <div>
                                                            <Link href={`/appManager/users/${user.id}`}>
                                                                {user.first_name} {user.last_name}
                                                            </Link>
                                                        </div>
                                                        <div>{user.email}</div>
                                                    </ListItem>
                                                    <Divider/>
                                                </>
                                            );
                                        })}
                                </List>
                            </Box>
                        </Box>
                    </>
                );
            },
        }
    });
}
