import {useAuthenticationContext} from "../../lib/context/AuthenticationContext";
import {CustomAuthData} from "../../auth/provider";
import {LabTestDataProvider} from "./labTest/dataProvider";
import {MetadataFilesDataProvider} from "./metadataFiles/dataProvider";

export const useLabTestDataProvider = () => {
    const authCtx = useAuthenticationContext<CustomAuthData>();
    const labTestProvider = new LabTestDataProvider();
        labTestProvider.setAuthProvider(authCtx.getProvider());

    return labTestProvider;
}

export const useMetadataFilesDataProvider = () => {
    const authCtx = useAuthenticationContext<CustomAuthData>();
    const metadataFilesProvider = new MetadataFilesDataProvider();
        metadataFilesProvider.setAuthProvider(authCtx.getProvider());

    return metadataFilesProvider;
}
