import {createStyles, makeStyles} from "@mui/styles";

const ModalStyles = makeStyles(() =>
    createStyles({
        root: {
            //
        },
        container: {
            justifyContent: "center",
            justifySelf: "center !important",
        },
        fields: {
            alignItems: "center !important",
            justifySelf: "center !important"
        },
        mainContainer: {
            height: "fit-content !important",
            maxHeight: "70vh",
            overflowY: "scroll",
            alignItems: "center !important",
            justifyContent: "center !important"
        },
        headerContainer: {
            marginTop: "5% !important",
            width: "100%",
        },
        sideModalTitle: {
            paddingLeft: "1em",
            paddingTop: "1em",
            width: "90%"
        },
        closeIcon: {
            paddingTop: "0.4em",
            width: "7%",
            cursor: "pointer",
            fontSize: "2.5rem !important"
        },
        actionContainer: {
            justifyContent:"center",
            alignItems:"center"
        },
        modalContent: {
            marginTop: 50,
            width: "90%",
            alignSelf:"center"
        },
        defaultModalTitle: {
            textAlign: "center",
            paddingBottom: "5%"
        },
        defaultModalDescription: {
            textAlign: "center",
            paddingBottom: "2%"
        },
        buttonsContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            paddingTop: "5%"
        },
        formButtonsContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            paddingTop: "5%"
        }
    }));

export {ModalStyles};
