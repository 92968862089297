import React, { FC, ReactElement, useEffect, useState } from "react";
import Table from "../../components/table/ListTable";
import { useResourceContext } from "../../context/ResourceContext";
import { MetaField } from "../../types/meta";
import { ResourceConfig } from "../../types/resource";
import { Field } from "../../types/field";
import { useNavigate } from "react-router-dom";
import { PagedResponse } from "../../../types/pagedResponse";
import { Button, Divider, Grid, List, ListItem} from "@mui/material";
import {useModalContext} from "../../context/ModalContext";
import {OTMStyles} from "./oneToManyStyles";
import {Filter} from "../../types/filter";
import {DefaultSideModal} from "../../components/modal/defaultSideModal";

/*
R = parent res cnfg
T = child res cnfg
V = child res filter
*/
interface OneToManyProps<R, T, V> {
    resource: ResourceConfig<T, V>;
    defaultFilter: (res: R) => V;
    otherFilters?: Filter<V>[]
    fields: Field<T>[];
    pageSize?: number;
}

export class OneToMany<R, T, V> implements MetaField<R> {
    resource: ResourceConfig<T, V>;
    defaultFilter: (res: R) => V;
    otherFilters?: Filter<V>[];
    fields: Field<T>[];
    pageSize?: number;

    constructor(props: OneToManyProps<R, T, V>) {
        this.resource = props.resource;
        this.defaultFilter = props.defaultFilter;
        this.otherFilters = props.otherFilters;
        this.fields = props.fields;
        this.pageSize = props.pageSize;
    }
    getFormComponent(): ReactElement {
        return <></>;
    }
    getShowComponent(data: R | undefined, field: Field<R>): FC {
        return () => {
            const navigate = useNavigate();

            const [filter, setFilter] = useState<V | undefined>(undefined);

            const [nextPageToken, setNextPageToken] = useState("");
            const [dataList, setDataList] = useState<T[] | undefined>(undefined);
            const parentResourceCnfg = useResourceContext().getConfig();
            const parentResPK = parentResourceCnfg.primaryKey;
            const isPaginated = this.pageSize !== undefined;

            const classes = OTMStyles();

            const modalCtx = useModalContext();

            useEffect(() => {
                if (!data) return;
                const filters = {
                    ...this.defaultFilter({ [parentResPK]: data[parentResPK] } as R),
                    ...filter
                };

                if (isPaginated) {
                    this.resource.dataProvider?.pagedList(
                            this.pageSize,
                            undefined,
                        filters)
                        .then((data: PagedResponse<T>) => {
                            setDataList(data.data);
                            setNextPageToken(data.page_token);
                        })
                }
                else {
                    this.resource.dataProvider?.list(
                            undefined,
                            filters)
                        .then((res) => {
                            setDataList(res);
                        })
                }
            }, [data, filter])

            const handleNextPage = () => {
                if (!data) return;
                this.resource.dataProvider?.pagedList(this.pageSize, nextPageToken, this.defaultFilter({[parentResPK]: data[parentResPK]} as R)).then((data: PagedResponse<T>) => {
                    setDataList((prevData) => {
                        return prevData?.concat(data.data);
                    })
                    setNextPageToken(data.page_token);
                });
            }

            const renderFilters = () => {
                return <Grid item>
                    {this.otherFilters?.filter(f => !f.hidden() && f.main === true)
                        .map((f, index) => {
                            if (!f.field.meta) return <></>;
                            if (!f.field.meta.getFilterComponent)
                                return <></>;
                            let FilterComponent;
                            FilterComponent = f.field.meta.getFilterComponent(((dataList) => {
                                setFilter({...filter, [f.field.id]: dataList} as V);
                            }), undefined, filter ? filter[f.field.id as keyof V] : '');
                            return <Grid item
                                         key={index}
                                         className={classes.filter}>{FilterComponent({})}</Grid>;
                        })}
                </Grid>
            }

            const generateSideModal = (row: T) => {
                return (
                    <DefaultSideModal title={"Additional info"} handleClose={() => modalCtx?.closeModal()}>
                        <>
                            <List>
                                {
                                    this.fields
                                        .map((f) => {
                                            if (!f.meta) return <></>
                                            return (
                                                <>
                                                <ListItem className={classes.listItem}>{f.meta.getShowComponent(row, f)({})}</ListItem>
                                                <Divider/>
                                                </>
                                            )
                                        })
                                }
                            </List>
                            <Grid item style={{marginTop: "2em"}}>
                                <Button variant={"outlined"}
                                        onClick={() => {
                                            modalCtx?.closeSideModal();
                                            navigate(`/${this.resource.groupId}/${this.resource.id}/${row[this.resource.primaryKey]}`, {replace: true});
                                        }}>
                                    View detail
                                </Button>
                            </Grid>
                        </>
                    </DefaultSideModal>
                )
            }

            return (
                <Grid container direction={'column'} width={'100%'}>
                    {dataList && this.otherFilters &&
                        <Grid container direction={'column'} marginBottom={"2em"}>
                            <Grid container direction={'row'} alignItems={"center"}>
                                <>
                                    {renderFilters()}
                                </>
                            </Grid>
                        </Grid>
                    }

                        <Grid item>
                            <Table<T>
                                pageSize={this.pageSize || 10}
                                data={dataList ?? []}
                                showFields={this.fields}
                                primaryKey={this.resource.primaryKey}
                                handleViewDetail={(row) => {
                                    return modalCtx?.openSideModal(
                                        {
                                            component: generateSideModal(row),
                                            anchor: 'right'
                                        }
                                )}}
                                loadMore={isPaginated ? {
                                    loadMoreCallback: handleNextPage,
                                    loadMoreText: () => nextPageToken === "" ? "No more data to show" : "LOAD MORE",
                                    disableLoadMore: !nextPageToken
                                } : undefined}
                            />
                        </Grid>
                    </Grid>
            )
        }
    };

}
