import {Button, CircularProgress, Grid, Typography} from "@mui/material";
import React, {ReactElement, useState} from "react";
import {stepStyles} from "./stepStyles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {useNavigate} from "react-router-dom";
interface StepButton {
    text?: string;
    isDisabled: boolean;
    isProcessing: boolean;
    onClick?: any;
}

interface Step{
    title?: ReactElement;
    description: string;
    component: ReactElement;
    button?: StepButton;
}

interface StepViewProps {
    title: ReactElement;
    steps: Step[];
}

export function StepView(props: StepViewProps): ReactElement {
    const classes = stepStyles();
    const navigate = useNavigate();
    const [step, setStep] = useState<number>(0)

    const renderStep = () => {
        if (step !== undefined) {
            return (
                <>
                    <Typography variant={"pageSubtitle"}
                                className={classes.subtitle}>{props.steps[step].description}</Typography>
                    <Grid>
                        {props.steps[step].component}
                    </Grid>
                </>
            )
        }
    }

    const renderButton = () => {
        return (
            <>
                <Grid container className={classes.buttonBox}>
                    {props.steps[step].button?.isProcessing?
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                        :
                        <Button
                            variant={"contained"}
                            disabled={props.steps[step].button?.isDisabled}
                            onClick={ () => {
                                if (step !== props.steps.length-1) {
                                    setStep(step + 1)
                                }
                                if (props.steps[step].button?.onClick) {
                                    return props.steps[step].button?.onClick();
                                }
                            }
                            }
                        >
                            {props.steps[step].button?.text}
                        </Button>}
                </Grid>
            </>
        )
    }

    return (
        <>
            <Grid container className={classes.mainContainer}>
                <Button onClick={() => navigate(-1)}>
                    <KeyboardBackspaceIcon color={"primary"}></KeyboardBackspaceIcon>
                    <Typography variant={"backButtonText"}>Exit</Typography>
                </Button>
                <Grid container direction={"column"}>
                    <>
                    <Grid container direction={"row"} className={classes.titleContainer}>
                        <Grid item>
                            <Grid item>
                                {props.title}
                                {props.steps[step].title && props.steps[step].title}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant={"pageTitlePrefix"}>{(step+1).toString()} /</Typography>
                            <Typography variant={"pageTitle"}>&nbsp;{props.steps.length}</Typography>
                        </Grid>
                    </Grid>
                    {renderStep()}
                    {renderButton()}
                    </>
                </Grid>
            </Grid>
        </>
    )
}
