import {ClinicalDataProvider} from "../../../resources/clinicalData/clinicalData/clinicalDataProvider";
import {useAuthenticationContext} from "../../../lib/context/AuthenticationContext";
import {CustomAuthData} from "../../../auth/provider";

// TODO: review

export const useClinicalDataProvider = () => {
    const authCtx = useAuthenticationContext<CustomAuthData>();
    const clinicalDataProvider = new ClinicalDataProvider();
        clinicalDataProvider.setAuthProvider(authCtx.getProvider())

    return clinicalDataProvider;
}
