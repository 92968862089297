import {createContext, ReactElement, ReactNode, useContext} from "react";
import {enqueueSnackbar, SnackbarProvider} from "notistack";


export interface FeedbackContextValue {
    openSuccessSnackbar: (msg: string) => void;
    openBottomSuccessSnackbar: (msg: string) => void;
    openErrorSnackbar: (msg: string) => void;
    openBottomErrorSnackbar: (msg: string) => void;
}

interface FeedbackContextProps {
    children: ReactNode;
}

const FeedbackContext = createContext<FeedbackContextValue>({
    openBottomErrorSnackbar(msg: string): void {
    }, openBottomSuccessSnackbar(msg: string): void {
    }, openErrorSnackbar(msg: string): void {
    }, openSuccessSnackbar(msg: string): void {
    }
});
export default function FeedbackContextProvider(props: FeedbackContextProps): ReactElement {
    return <FeedbackContext.Provider value={{
        openSuccessSnackbar: (msg) => {
            enqueueSnackbar(msg, {variant: "success"})
        },
        openBottomSuccessSnackbar: (msg) => {
            enqueueSnackbar(msg, {variant: "success", anchorOrigin: {vertical: "bottom", horizontal: "center"}})
        },
        openErrorSnackbar: (msg) => {
            enqueueSnackbar(msg, {variant: "error"})
        },
        openBottomErrorSnackbar: (msg) => {
            enqueueSnackbar(msg, {variant: "error", anchorOrigin: {vertical: "bottom", horizontal: "center"}})
        }
    }}>
        {props.children}
        <SnackbarProvider
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            autoHideDuration={2000}
        />
    </FeedbackContext.Provider>
}

export function useFeedbackContext(): FeedbackContextValue {
    return useContext<FeedbackContextValue>(FeedbackContext);
}



