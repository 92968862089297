import {ResourceConfig} from "../../lib/types/resource";
import {DocumentsView} from "../../components/documents/DocumentsView";
import {DocumentsDataProvider} from "../../components/documents/dataProvider";
import {documentFilter} from "./documentFilter";
import {NoArgsAction} from "../../lib/actions/noArgsAction";
import {ActionType} from "../../lib/types/action";
import {AddFolderModal} from "../../components/documents/modals/AddFolderModal";
import {SingleArgsAction} from "../../lib/actions/singleArgsAction";
import {EditPermissionsModal} from "../../components/documents/modals/EditPermissionsModal";
import {UploadFileModal} from "../../components/documents/modals/UploadFileModal";
import {IAuthenticationProvider} from "../../lib/types/auth";
import {CustomAuthData} from "../../auth/provider";
import {Document, DocumentFilter, DocumentType} from "./document";
import React from "react";
import {LongTextMeta} from "../../lib/meta/longText/longText";

interface ConfirmAction {
    text: string
}

export const DocumentsResource: ResourceConfig<Document, DocumentFilter> = {
    id: "documents",
    label: "Documents",
    primaryKey: "id",
    groupId: "documents",
    filter: documentFilter,
    tabPosition: "left",
    fields: [{
        id: "id",
        label: "Document ID",
    }],
    list: {
        id: "documentsList",
        fields: [{id: "id"}],
        component: <DocumentsView/>
    },
    show: {
        fields: [{id: "id"}],
        component: <DocumentsView/>
    },
    actions: [
        new NoArgsAction<ConfirmAction, Document>({
            id: "download",
            label: "Download",
            actionType: ActionType.Single,
            actionStyle: {
                buttonVariant: 'outlined',
                buttonColor: 'primary'
            },
            hidden: (data) => {
                if (!data) return true;
                const docData = (data as Document[])[0];
                return !docData || docData.type !== DocumentType.FILE;
            },
            handler: (data, dataProvider, authProvider, feedbackCtx?) => {
                if (!data) return;
                const docData = (data as Document[])[0];
                const docDataProvider = new DocumentsDataProvider(authProvider as IAuthenticationProvider<CustomAuthData>);
                return docDataProvider &&
                    docDataProvider
                        .download(docData.id)
                        .then(r => {
                            const blob = new Blob([r], {type: "application/json"});
                            const href = URL.createObjectURL(blob);
                            const download = Object.assign(document.createElement('a'), {
                                href: href,
                                download: docData.name
                            });
                            document.body.append(download);
                            download.click();
                            download.remove();
                            URL.revokeObjectURL(href);
                            feedbackCtx?.openBottomSuccessSnackbar("Download started successfully.")
                            return r
                        })
            },
            choiceAction: {
                confirmLabel: "Download",
                cancelLabel: "Cancel",
                description: "",
            },
            modalDescription: () => {
                return "Do you want to download this document?"
            }
        }),
        new SingleArgsAction<Document, Document>({
            id: "createFolder",
            label: "Create folder",
            actionType: ActionType.Single,
            hidden: (data) => {
                if (!data) return false;
                const docData = (data as Document[])[0];
                return docData && (docData.type === DocumentType.FILE || (docData.access_rule !== "ADMIN" && docData.access_rule !== "WRITE"))
            },
            actionStyle: {
                buttonVariant: "text"
            },
            modalType: "sideModal",
            customModal: (data) => {
                if (!data) return <AddFolderModal parentId={undefined}/>
                const docDetail = (data as Document[])[0];
                return <AddFolderModal parentId={docDetail ? docDetail.id : ""}/>
            },
            primary: (data) => {
                if (!data) return true;
                const docData = ((data as Document[])[0]);
                return !docData;
            }
        }),
        new SingleArgsAction<Document, Document>({
            id: "managePermissions",
            label: "Manage permissions",
            actionType: ActionType.Single,
            hidden: (data) => {
                if (!data) return true;
                const docData = (data as Document[])[0];
                return !docData || docData.access_rule !== "ADMIN"
            },
            actionStyle: {
                buttonVariant: "text"
            },
            modalType: "defaultModal",
            customModal: (data) => {
                const docDetail = (data as Document[])[0];
                if (!docDetail) return <></>
                return <EditPermissionsModal
                    parentId={docDetail.id}
                    parentName={docDetail.tag_name}
                    documentType={docDetail.type}/>
            },
        }),
        new SingleArgsAction<Document, Document>({
            id: "upload",
            label: "Upload file",
            actionType: ActionType.Single,
            actionStyle: {
                buttonVariant: 'contained'
            },
            choiceAction: {
                confirmLabel: "Upload",
                cancelLabel: "Cancel",
                description: "",
            },
            hidden: (data) => {
                    if (!data) return true;
                    const docData = (data as Document[])[0];
                    return !docData || docData.type !== DocumentType.DIRECTORY || (docData.access_rule !== "ADMIN" && docData.access_rule !== "WRITE")
            },
            modalDescription: () => {
                return "Select the document to upload."
            },
            primary: () => true,
            customModal: (data) => {
                const docDetail = (data as Document[])[0];
                return <UploadFileModal parentId={docDetail ? docDetail.id : ""}/>
            }
        }),
        new SingleArgsAction<Document, Document>({
            id: "delete",
            label: "Delete",
            actionType: ActionType.Single,
            choiceAction: {
                confirmLabel: "Confirm",
                cancelLabel: "Cancel",
                description: "Are you sure you want to delete this role? This action is irreversible."
            },
            actionStyle: {
                buttonVariant: 'outlined',
                buttonColor: 'error'
            },
            hidden: (data) => {
                if (!data) return true;
                const docData = (data as Document[])[0];
                return !docData || (docData.access_rule !== "ADMIN" && docData.access_rule !== "WRITE")
            },
            handler: (data, _dataProvider, authProvider, feedbackCtx) => {
                if (!data) return;
                const docData = (data as Document[])[0];
                const docDataProvider = new DocumentsDataProvider(authProvider as IAuthenticationProvider<CustomAuthData>);
                return docDataProvider &&
                    docDataProvider.delete("id" in docData ? docData.id : "")
                        .then(r => {
                            feedbackCtx?.openBottomSuccessSnackbar("Document successfully removed.")
                                return r
                            }
                        ).catch((e) => {
                            feedbackCtx?.openBottomErrorSnackbar("Error: the document was not removed.")
                            return e
                    })
            },
            fields: [
                {id: "id", label: "Why do you want to delete this document?*",
                    meta: new LongTextMeta<any>(),
                    validationOptions: {
                        required: {
                            value: true,
                            message: "This field is required."
                        }
                    }}
            ]
        }),
    ]
}
