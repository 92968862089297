import React, {ReactNode} from "react";
import AdminContextProvider from "../../context/AdminContext";
import AuthenticationConfig, {AuthenticationData, IAuthorizationProvider} from "../../types/auth";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ModalContextProvider from "../../context/ModalContext";
import AuthenticationContextProvider from "../../context/AuthenticationContext";
import AuthorizationContextProvider from "../../context/AuthorizationContext";
import FeedbackContextProvider from "../../context/FeedbackContext";

interface AdminProps {
    authentication?: AuthenticationConfig<AuthenticationData>;
    authorization?: IAuthorizationProvider;
    children: ReactNode;
}

export const Admin = (props: AdminProps) => {
    return <>
        <AdminContextProvider>
            <FeedbackContextProvider>
                <ModalContextProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path={"*"} element={
                                props.authentication
                                    ?
                                    props.authorization ?
                                        <AuthenticationContextProvider config={props.authentication}>
                                            <AuthorizationContextProvider provider={props.authorization}>
                                                {props.children}
                                            </AuthorizationContextProvider>
                                        </AuthenticationContextProvider>
                                        :
                                        <AuthenticationContextProvider config={props.authentication}>
                                            {props.children}
                                        </AuthenticationContextProvider>
                                    :
                                    <>
                                        {props.children}
                                    </>
                            }>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </ModalContextProvider>
            </FeedbackContextProvider>
        </AdminContextProvider>
    </>
}
