import {AppBar, Box, Grid, Tab, Tabs} from '@mui/material';
import React from 'react';
import {createStyles, makeStyles} from "@mui/styles";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  export interface SingleTab {
    children?: React.ReactNode;
    label?: string;
    isDisabled?: boolean;
  }

  export interface NavTabProps {
    onTabChange?: (index: number)=>void;
    defaultTab?: number
    tabs: SingleTab[];
    isEditMode?: boolean;
  }

export const TabStyles = makeStyles((theme: any) =>
    createStyles({
        tab: {
            "&:disabled": {
                opacity: 0.2
            }
        },
        selectedTab: {
            borderBottom: "0.3em solid " +  theme.palette.primary.main + " !important",
            backgroundColor: theme.palette.secondary.hover + "!important"
        },
        wrapper: {
            width: "auto",
            height: "3em !important",
            backgroundColor: theme.palette.secondary.main + "!important",
            borderBottom: "1px solid #0000001A",
            boxShadow: "0px 1px 7px -5px #000000 !important",
        },
        internalWrapper: {
            justifyContent: "space-between !important"
        },
    })
);

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

function NavTabs(props: NavTabProps) {
    const classes = TabStyles();
    const [value, setValue] = React.useState<number | null>(props.defaultTab || 0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        if (props.onTabChange) {
            props.onTabChange(newValue)
        }
    };

    return (
        <>
            <AppBar className={classes.wrapper} elevation={0} position={"static"}>
                <Grid container direction={"row"} className={classes.internalWrapper}>
                <Tabs value={value} onChange={handleChange}>
                    {
                        props.tabs.map((tab, index) => {
                            return <Tab
                                key={index}
                                className={`${classes.tab} ${index === value ? classes.selectedTab : ""}`}
                                label={tab.label ? tab.label : 'default'}
                                id={`simple-tab-${index}`}
                                aria-controls={`simple-tabpanel-${index}`}
                                disabled={tab.isDisabled || (props.isEditMode && value !== index)}
                            />
                        })
                    }
                </Tabs>
            </Grid>
            </AppBar>
            {
                props.tabs.map((tab, index) => {
                    return (<TabPanel
                        value={value as number}
                        key={`navTab-${index}`}
                        index={index}
                    >
                        {tab.children}
                    </TabPanel>)
                })
            }
        </>

    )
}
export default NavTabs;
