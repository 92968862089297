import {createStyles, makeStyles} from "@mui/styles";

const DefaultModalStyle = makeStyles((theme: any) =>
    createStyles({
    modalContainer: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '50%',
        width: '30% !important',
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 10,
        padding: '3em',
        paddingLeft: '3em',
        paddingRight: '3em',
    }
}));

export {DefaultModalStyle};
