import React from 'react';
import {createTheme, ThemeProvider} from '@mui/material';
import {ButtonsVariant, CustomTheme} from '../../theme';

type ThemeModeProviderProps = {
    children: React.ReactNode;
};
  const CustomThemeProvider = ({ children }: ThemeModeProviderProps) => {
    const theme = createTheme(CustomTheme(), ButtonsVariant());
    return (
            <ThemeProvider theme={theme}>
                { children }
            </ThemeProvider>
    )
}
export default CustomThemeProvider;
