import {createStyles, makeStyles} from "@mui/styles";


const ClinicalDataShowTabStyles = makeStyles(() =>
    createStyles({
        mainContainer: {
            marginTop: "7em",
            width: "90% !important",
            marginLeft: "auto",
            marginRight: "auto"
        },
        titleContainer: {
            justifyContent: "space-between !important"
        },
        title: {
            width: "fit-content",
            maxWidth: "15em",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        noTabsContainer: {
            width: "100% !important",
            marginTop: "3em !important"
        },
        listContainer: {
            columns: 2,
            alignItems: "start !important",
            justifyContent: "space-between !important"
        },
        showListItem: {
            marginTop: "1em",
            justifyContent: "space-between !important"
        },
        accordionSummary: {
            width: "95% !important",
            marginLeft: "1rem",
            justifyContent: "space-between !important",
            alignItems: "center !important"
        },
        errorContainer: {
            marginTop: "-1em",
            marginBottom: "-2em"
        },
    }));

export {ClinicalDataShowTabStyles};
