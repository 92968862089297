import { IDMeta } from "../../../lib/meta/id/id";
import { NumberMeta } from "../../../lib/meta/number/number";
import { SimpleTextMeta } from "../../../lib/meta/simpleText/simpleText";
import { ResourceConfig } from "../../../lib/types/resource";
import { Laboratory } from "./laboratory";
import {LongTextMeta} from "../../../lib/meta/longText/longText";
import {Typography} from "@mui/material";
import React from "react";
import {labFilter} from "./labFilter";
import GenericSearch from "../../genericSearch";
import {LaboratoryDataProvider} from "./laboratoryDataProvider";
import {DefaultShowTabView} from "../../../lib/views/crud/show/defaultTab";

export const LaboratoryResource: ResourceConfig<Laboratory, GenericSearch> = {
    id: "laboratories",
    label: "Laboratories",
    actions: [],
    dataProvider: new LaboratoryDataProvider(),
    filter: labFilter,
    fields: [
        {
            id:"id",
            label: "ID",
            meta: new IDMeta()
        },
        {
            id:"name",
            label: "Name",
            meta: new SimpleTextMeta({}),
        },
        {
            id:"description",
            label: "Description",
            meta: new LongTextMeta(),
        },
        {
            id:"test_files_count",
            label: "Tests",
            meta: new NumberMeta({}),
        },
    ],
    create:{
        fields:[
            {
                id:"name",
                validationOptions: {
                    required: {
                        value: true,
                        message: "Name is required."
                    }
                },
            },
            {
                id:"description",
                validationOptions: {
                    minLength: {
                        value: 50,
                        message: "Description is too short."
                    },
                    required: {
                        value: true,
                        message: "Description is required."
                    },
                }
            }
        ]
    },
    list:{
        id: "laboratories",
        pageSize: 10,
        fields: [{id:"id"},{id:"name"},{id:"description"},{id:"test_files_count"}]
    },
    show:{
        component: <DefaultShowTabView
                        id={"laboratory"}
                        tabs={[{label: "info", fields: ["id", "name", "description", "test_files_count"]}]}
                        fields={[{id: "id"}, {id: "name"}, {id: "description"}]}
                        previousPage={-1}
                        getTitle={(data?:Laboratory) => {
                            return (
                                <>
                                    <Typography variant={"pageTitle"}
                                                style={{
                                                    width: "fit-content",
                                                    maxWidth: "15em",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }}
                                    >/ {data?.name}</Typography>
                                </>)
                        }}
                    />,
        fields: [{id: "id"}, {id: "name"}, {id: "description"}]
    },
    description: "List of the enabled laboratories operating via miWeb.",
    isDeletable: () => false,
    primaryKey: "id",
    groupId: "appManager"

}
