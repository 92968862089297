import {Divider, Grid, ListItem, Typography} from "@mui/material";
import React, {Fragment, ReactElement, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import NavTabs from "../../../lib/components/tabs/NavTabs";
import {DefaultStyles} from "../../../lib/views/crud/show/defaultStyles";
import {Field} from "../../../lib/types/field";
import {DefaultShowLayout} from "../../../lib/views/crud/show/showLayout";
import {LabTestResource} from "../../../resources/labTest/labTest/labTestResource";
import {LabTest, LabTestMetadata, LabTestStatus} from "../../../resources/labTest/labTest/labTest";
import {useLabTestDataProvider} from "../../../resources/labTest/hooks";
import {getDetailErrorCause} from "../../../resources/messages";

interface SubTab {
    id: string
    label: string
    fields: Field<LabTest>[]
}

interface DefaultShowTabViewProps {
    id: string
    tabs: SubTab[]
    getTitle: (data?: LabTest) => ReactElement
    previousPage: number
}

export function LabTestDetailView(props: DefaultShowTabViewProps): ReactElement {
    const [error, setError] = useState<number>()
    const classes = DefaultStyles({error: error});
    const resource = LabTestResource;
    const dataProvider = useLabTestDataProvider();
    const [data, setData] = useState<LabTest>();
    const [metadata, setMetadata] = useState<LabTestMetadata[]>();
    const {id} = useParams();

    const mainLabTestTab = props.tabs.find((tab) => tab.id === "main")

    useEffect(() => {
        setData(undefined)
        initData();
    }, [resource])

    function initData() {
        if (!id) return;
        dataProvider.getById(id)
            .then((data) => {
                setData(data);
            }).catch((error) => {
            setError(error.response.status)
        });
        dataProvider.getDetails(id)
            .then((data) => (
                setMetadata(data)
            )).catch((error) => {
            setError(error.response.status)
        })
    }

    return (
        <DefaultShowLayout
            data={data}
            getTitle={props.getTitle}
            previousPage={props.previousPage}
            error={error}
        >
            <>
            {
                error &&
                <Grid className={classes.errorContainer}>
                    <Typography variant={"dataTitle"} color={"error"}>
                        <strong>ATTENTION!</strong> {getDetailErrorCause(error)}
                    </Typography>
                </Grid>
            }
            {data &&
            <Grid item className={classes.tabDataContainer}>
                <NavTabs
                    tabs={[
                            {
                                label: "Main Lab Test",
                                children: (
                                    <Grid marginTop={"-1em"}>
                                    {mainLabTestTab && mainLabTestTab.fields.map((field, index ) => {
                                        const ShowComponent = field.meta?.getShowComponent(data, field);
                                        return (
                                            <Fragment key={index}>
                                                <ListItem className={classes.showListItem}>
                                                    {ShowComponent && ShowComponent({})}
                                                </ListItem>
                                                <Divider/>
                                            </Fragment>
                                        );
                                    })}</Grid>
                                )
                            },
                            {
                                label: "Metadata",
                                isDisabled: data?.status === LabTestStatus.NOT_ASSOCIATED || data?.status === LabTestStatus.CANCELED || data?.status === LabTestStatus.UPLOADING,
                                children: (
                                    <Grid marginTop={"-1em"}>
                                        {metadata && metadata.map((meta) => {
                                            return meta.metadata.map((m) => {
                                                return (
                                                    <>
                                                        <ListItem className={classes.showListItem}>
                                                            <div className={classes.customData}>
                                                            <Typography variant={"dataTitle"}>{m.name}</Typography>
                                                            </div>
                                                            <div className={classes.customData}>
                                                                <Typography>{m.value}</Typography>
                                                            </div>
                                                        </ListItem>
                                                        <Divider/>
                                                    </>
                                                )
                                            })})}
                                    </Grid>
                                )
                            }
                    ]}
                ></NavTabs>
            </Grid>
            }
            </>
        </DefaultShowLayout>
    )
}
