import axios from "axios";
import { BASE_URL } from "../App";
import IDataProvider from "../hoc/dataProvider/IDataProvider";
import {AuthenticationData, IAuthenticationProvider} from "../lib/types/auth";


export class BaseDataProvider<T, V> implements IDataProvider<T, V> {
    resourceId: string;
    authProvider: IAuthenticationProvider<AuthenticationData> | undefined;

    constructor(resId: string, authProvider?: IAuthenticationProvider<AuthenticationData>) {
        this.resourceId = resId;
        this.authProvider = authProvider;
    }

    setAuthProvider = (authProvider?: IAuthenticationProvider<AuthenticationData>) => {
        this.authProvider = authProvider;
    }
    getAuthProvider = ():IAuthenticationProvider<AuthenticationData>|undefined => {
        return this.authProvider;
    }
    getHeaders = async (p?: { Accept: string }): Promise<Record<string, string>> => {
        let headers = p ?? { 'Accept': 'application/json' };
        if (this.authProvider) {
            await this.authProvider.getAuthHeaders().then((authHeaders) => {
                headers = { ...headers, ...authHeaders }
            })
            .catch(() => {
                this.authProvider?.setAuthData({isAuthenticated:()=>false})
            });
        }
        return Promise.resolve(headers)
    }

    list = async (page_size?: number, filter?: V): Promise<T[]> => {
        const headers = await this.getHeaders();
        return axios.get(`${BASE_URL}/${this.resourceId}`, { headers: headers, params: { page_size, ...filter } })
            .then(r => { return r.data; })
            .catch((err) => {
                if (err.response) {
                    throw err.response.status;
                }
                throw err;
            });
    }
    pagedList = async (page_size?: number, page_token?: string, filter?: V): Promise<{ data: T[], page_token: string}> => {
        const headers = await this.getHeaders();
        return axios.get(`${BASE_URL}/${this.resourceId}`, { headers: headers, params: { page_size, page_token, ...filter } })
            .then(r => { return r.data })
            .catch((err) => {
                if (err.response) {
                    throw err.response.status;
                }
                throw err;
            });
    }
    getById = async (id: string): Promise<T | undefined> => {
        return axios.get<T>(`${BASE_URL}/${this.resourceId}/${id}`, { headers: await this.getHeaders()})
            .then(r => { return r.data; });
    }
    create = async (data: T): Promise<T> => {
        return axios.post<T>(`${BASE_URL}/${this.resourceId}`, data, { headers: await this.getHeaders()})
            .then(r => { return r.data; });
    }
    edit = async (data: any): Promise<T> => {
        return axios.put<T>(`${BASE_URL}/${this.resourceId}/${data.id}`, data, { headers: await this.getHeaders()})
            .then(r => { return r.data; });
    }
    delete = async (id: string): Promise<boolean> => {
        return axios.delete(`${BASE_URL}/${this.resourceId}/${id}`, { headers: await this.getHeaders()})
            .then(r => { return r.data; });
    }
    bulkEdit = (): Promise<T[]> => {
        throw new Error("Function not implemented.");
    }
    actionEdit = (): Promise<T> => {
        throw new Error("Function not implemented.");
    }
}
