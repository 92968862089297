import { Filters } from "../../../lib/types/filter"
import {ClinicalStudiesResource} from "../../appManager/clinicalStudies/clinicalStudyResource";
import SimpleTextFilter from "../../../lib/components/filters/singleFilters/simpleText/simpleTextFilter";
import {SourceFileFilter} from "./sourceFile";
import {SelectFilter as AutocompleteSelectFilter} from "../../../lib/meta/select/autocompleteSelect";
import {ClinicalStudy} from "../../appManager/clinicalStudies/clinicalStudy";
import React from "react";
import {StaticSelectFilter} from "../../../lib/meta/select/staticSelect";

export const sourceFileFilter: Filters<SourceFileFilter> = {
    filters: [
        {
            field: {
                id: "q",
                meta: {
                    getFilterComponent: (handleFilter: any, res: any, value: any) => {
                        return () => (
                            <SimpleTextFilter fieldLabel={"Search..."} handleResult={handleFilter} value={value}/>)
                    },

                    getFormComponent: () => {
                        return <></>
                    },
                    getShowComponent: () => () => <></>
                },
            },
            hidden: () => false,
            main: true
        },
        {
            field:  {
                id: "clinical_study_id",
                label: "Clinical Study",
                meta: {
                    getFormComponent: () => <></>,
                    getFilterComponent: (handleFilter: any, res: any, value?: any) => {
                        return () => (
                            <AutocompleteSelectFilter
                                label={"Clinical study"}
                                resource={ClinicalStudiesResource}
                                handleSelect={handleFilter}
                                valuer={(clinicalStudy: ClinicalStudy) => clinicalStudy.name.toString()}
                                selectedRecord={value}
                                clearable={true}
                            />
                        )
                    },
                    getShowComponent: () => () => <></>
                },
            },
            hidden: () => true
        },
        {
            field:  {
                id: "status",
                label: "Status",
                meta: {
                    getFormComponent: () => <></>,
                    getFilterComponent: (handleFilter: any, res: any, value?: any) => {
                        return () => (
                            <StaticSelectFilter
                                label={"Status"}
                                filterId={value}
                                handleSelect={handleFilter}
                                valuePicker={"status"}
                                options={{
                                    processed: "processed",
                                    error: "error",
                                    canceled: "canceled"
                                }}
                                selectedValue={value}
                            />
                        )
                    },
                    getShowComponent: () => () => <></>
                },
            },
            hidden: () => true
        },
        {
            field:  {
                id: "source_type",
                label: "File type",
                meta: {
                    getFormComponent: () => <></>,
                    getFilterComponent: (handleFilter: any, res: any, value?: any) => {
                        return () => (
                            <StaticSelectFilter
                                label={"File type"}
                                filterId={value}
                                handleSelect={handleFilter}
                                valuePicker={"type"}
                                options={{
                                    mainClinical: "main clinicalData",
                                    other: "other"
                                }}
                                selectedValue={value}
                            />
                        )
                    },
                    getShowComponent: () => () => <></>
                },
            },
            hidden: () => true
        }
    ]
}
