import {ReactElement} from "react";
import {Grid} from "@mui/material";
import FiltersComponent from "../../lib/components/filters/filtersComponent/filtersComponent";
import ListTable from "../../lib/components/table/ListTable";
import {Document, DocumentFilter, DocumentType} from "../../resources/documents/document";
import {DocumentsResource} from "../../resources/documents/documentResource";
import {useNavigate} from "react-router-dom";
import {DocumentsStyles} from "./documentsStyles";
import {DateMeta} from "../../lib/meta/date/date";
import {SimpleTextMeta} from "../../lib/meta/simpleText/simpleText";
import {Field} from "../../lib/types/field";
import {ReactComponent as ExcelIcon} from "../../images/icons/symbols/ExcelIcon.svg";
import {ReactComponent as PDFIcon} from "../../images/icons/symbols/PDFIcon.svg";
import {ReactComponent as ImageIcon} from "../../images/icons/symbols/ImageIcon.svg";
import {ReactComponent as DocIcon} from "../../images/icons/symbols/DocIcon.svg";
import {ReactComponent as FolderIcon} from "../../images/icons/symbols/FolderIcon.svg";
import {ReactComponent as GenericFileIcon} from "../../images/icons/symbols/GenericFileIcon.svg";
import {uploadedByLink} from "./hyperlinks";

interface DocumentsListViewProps {
    data: Document[];
    handleNextPage: () => void;
    getFilter: (data?: DocumentFilter) => void;
    pageToken?: string;
}

export const icon = (doc?: Document) => {
    if (!doc) return <></>;
    const format = doc.tag_name.split(".")[1]
    if (format) {
        switch(format) {
            case "pdf": {
                return <PDFIcon style={{width:"2.5em", marginRight: "1em", marginLeft: "-0.5em"}}/>
            }
            case "jpg":
            case "jpeg":
            case "png": {
                return <ImageIcon style={{width:"2.5em", marginRight: "1em", marginLeft: "-0.5em"}}/>
            }
            case "xls":
            case"xlsx": {
                return <ExcelIcon style={{width:"2.5em", marginRight: "1em", marginLeft: "-0.5em"}}/>
            }
            case "csv":
            case "docx": {
                return <DocIcon style={{width:"2.5em", marginRight: "1em", marginLeft: "-0.5em"}}/>
            }
        }
        return <GenericFileIcon style={{width:"2.5em", marginRight: "1em", marginLeft: "-0.5em", marginTop: "-1em", marginBottom: "-1em"}}/>
    }
    if (doc.type === DocumentType.DIRECTORY) {
        return <FolderIcon style={{width:"1.8em", marginRight: "1em"}}/>
    }
    return <></>
}
export const DocumentsListView = ({data, ...props}: DocumentsListViewProps): ReactElement => {
    const classes = DocumentsStyles();

    const fields: Field<Document>[] = [
        {id: "tag_name", label: "Name", meta: new SimpleTextMeta({icon: icon})},
        {id: "created_at", label: "Created at", meta: new DateMeta<Document>({})},
        {id: "uploaded_by", label: "Created by", meta: uploadedByLink()},
        {id: "access_rule", label: "Livello di accesso", meta: new SimpleTextMeta({})}
    ]

    const navigate = useNavigate();
    const handleViewDetail = (row: Document) => {
        navigate(`/documents/documents/${row.id}`);
    }

    return <>
            <Grid className={classes.filterContainer}>
                <FiltersComponent data={data} getFilter={props.getFilter} resource={DocumentsResource}></FiltersComponent>
            </Grid>
            <Grid>
                <ListTable
                    data={data}
                    pageSize={10}
                    primaryKey={"id"}
                    showFields={fields}
                    handleViewDetail={handleViewDetail}
                    loadMore={{
                        loadMoreCallback: props.handleNextPage,
                        loadMoreText: () => props.pageToken === "" ? "No more data to show" : "LOAD MORE",
                        disableLoadMore: !props.pageToken
                    }}
                />
            </Grid>
    </>
}
