import {createContext, ReactElement, ReactNode, useContext} from "react";
import {GroupConfig} from "../types/group";


interface GroupContextValue {
    getConfig: () => GroupConfig
}

interface GroupContextProps {
    children: ReactNode;
    config: GroupConfig
}

const GroupContext = createContext<GroupContextValue | undefined>(undefined);
export default function GroupContextProvider(props: GroupContextProps): ReactElement {

    return <GroupContext.Provider value={{
        getConfig: () => {
            return props.config
        }
    }}>
        {props.children}
    </GroupContext.Provider>
}

export function useGroupContext(): GroupContextValue | undefined {
    return useContext<GroupContextValue | undefined>(GroupContext);
}



