import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button, CircularProgress,
    Divider,
    Grid,
    ListItem,
    Typography
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import NavTabs from "../../../../lib/components/tabs/NavTabs";
import { ClinicalDataShowTabStyles} from "./clinicalDataShowTabStyles";
import {ClinicalDataProvider} from "../../../../resources/clinicalData/clinicalData/clinicalDataProvider";
import {ClinicalDataResource} from "../../../../resources/clinicalData/clinicalData/clinicalDataResource";
import {ClinicalData} from "../../../../resources/clinicalData/clinicalData/clinicalData";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {SourceFileDataProvider} from "../../../../resources/clinicalData/sourceFile/sourceFileDataProvider";
import {SourceFile} from "../../../../resources/clinicalData/sourceFile/sourceFile";
import {SourceFileResource} from "../../../../resources/clinicalData/sourceFile/sourceFileResource";
import {getDetailErrorCause} from "../../../../resources/messages";

interface FileItem {
    id: string,
    name: string,
    data: {column_name: string, column_value: string}[]
}

export function ClinicalDataShowTabView(): ReactElement {
    const classes = ClinicalDataShowTabStyles();
    const [data, setData] = useState<ClinicalData[] | undefined>(undefined);
    const mainClinicalData = data?.filter((object) => object.file_type === "mainClinical");
    const otherFiles = data?.filter((object) => object.file_type === "other");

    const [metadata, setMetadata] = useState<SourceFile | undefined>(undefined);
    const [error, setError] = useState<number>();

    const fileId = data && data[0] ? data[0].file_id : undefined;

    const navigate = useNavigate();
    const {id} = useParams();
    const [searchParams]= useSearchParams()
    const clinicalStudyId = searchParams.get("clinical_study_id")

    const resource = ClinicalDataResource;

    const clinicalDataProvider = (resource.dataProvider as unknown as ClinicalDataProvider);
    const sourceFileDataProvider = (SourceFileResource.dataProvider as unknown as SourceFileDataProvider);

    useEffect(() => {
        initData();
        getMetaData();
    }, [resource, fileId])

    const initData = () => {
        if (!id || !clinicalStudyId) return;
        clinicalDataProvider.getDetails(clinicalStudyId, id)
            .then((data) => {
                setData(data.data);
            }).catch((error) => {
            setError(error.response.status)
        });
    }

    const getMetaData = () => {
        sourceFileDataProvider.getById(fileId!).then((fileData) => {
            setMetadata(fileData)
        })
    }

    const followUpFiles: { id: string; name: string, data: { column_name: string; column_value: string; }[]; }[] = [];
    otherFiles?.forEach(item => {
        const newItem: FileItem = {id: item.file_id, name: item.file_name, data: []};
        otherFiles?.forEach(innerItem => {
            if(innerItem.file_id === item.file_id){
                newItem.data = [...newItem.data,
                    {
                        column_name: innerItem.column_name,
                        column_value: innerItem.column_value
                    }
                ];
            }
        });
        if (!followUpFiles.find((item) => item.id === newItem.id)) {
            followUpFiles.push(newItem);
        }
    });

    return (
        <>
            <Grid container className={classes.mainContainer}>
                <Button onClick={() => navigate(-1)}>
                    <KeyboardBackspaceIcon color={"primary"}></KeyboardBackspaceIcon>
                    <Typography variant={"backButtonText"}>Go back</Typography>
                </Button>
                <Grid container direction={"column"}>
                    <Grid item>
                        <Grid container direction={"row"} className={classes.titleContainer}>
                            <Grid item>
                                <Typography variant={"pageTitlePrefix"}>{resource?.label} / &nbsp;</Typography>
                                <Typography variant={"pageTitle"} className={classes.title}>paziente {id}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid marginTop={10}>
                    </Grid>
                    {
                        (!clinicalStudyId || !fileId || error) &&
                        <Grid className={classes.errorContainer}>
                            <Typography variant={"dataTitle"} color={"error"}>
                                <strong>ATTENTION!</strong> {error ? getDetailErrorCause(error) : "Invalid patient or clinical study not selected."}
                            </Typography>
                        </Grid>
                    }
                    { fileId && clinicalStudyId &&
                    <Grid item>
                        <NavTabs
                            tabs={[
                                {
                                    label: "Main clinicalData",
                                    children: (
                                                <>
                                                    <Grid container direction={"row"} className={classes.listContainer}>
                                                        <Grid item md={5.5}>
                                                            <ListItem className={classes.showListItem}>
                                                                <Typography variant={"dataTitle"}>PATIENT ID</Typography><Typography>{id}</Typography>
                                                            </ListItem>
                                                            <Divider/>
                                                            {mainClinicalData?.slice(0, Math.ceil(mainClinicalData.length / 2))
                                                                .map((object) =>{
                                                                return (<>
                                                                    <ListItem className={classes.showListItem}>
                                                                        <Typography variant={"dataTitle"}>{object.column_name}</Typography>
                                                                        <Typography>{object.column_value}</Typography>
                                                                    </ListItem>
                                                                    <Divider/>
                                                                </>)
                                                            })}
                                                        </Grid>
                                                        <Grid md={5.5}>
                                                            {mainClinicalData?.slice(Math.ceil(mainClinicalData.length / 2), mainClinicalData.length)
                                                                .map((object) =>{
                                                                    return (<>
                                                                        <ListItem className={classes.showListItem}>
                                                                            <Typography variant={"dataTitle"}>{object.column_name}</Typography>
                                                                            <Typography>{object.column_value}</Typography>
                                                                        </ListItem>
                                                                        <Divider/>
                                                                    </>)
                                                                })
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </>
                                              )
                                },
                                {
                                    label: "Other",
                                    children: (
                                        <>
                                            {followUpFiles.length === 0 ?
                                                <Typography>No other files linked to this patient are found on miWeb.</Typography>
                                                :
                                                followUpFiles && followUpFiles.map((object) => {
                                                return (<>
                                                        <Accordion>
                                                            <AccordionSummary sx={{
                                                                pointerEvents: "none"
                                                            }} expandIcon={<ExpandMoreIcon sx={{
                                                                pointerEvents: "auto"
                                                            }}/>}>
                                                                <Grid container direction={"row"} className={classes.accordionSummary}>
                                                                    <Typography>ID: {object.id }
                                                                    </Typography>
                                                                    <Typography>Source: {object.name}</Typography>
                                                                </Grid>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <>
                                                                <Grid container direction={"row"} className={classes.listContainer}>
                                                                    <Grid md={5.5}>
                                                                        {object.data
                                                                            .slice(0, Math.ceil(object.data.length / 2))
                                                                            .map((data) => {
                                                                                return (<>
                                                                                    <ListItem className={classes.showListItem}>
                                                                                        <Typography variant={"dataTitle"}>{data.column_name}</Typography>
                                                                                        <Typography>{data.column_value}</Typography>
                                                                                    </ListItem>
                                                                                </>)
                                                                            })
                                                                        }
                                                                    </Grid>
                                                                    <Grid md={5.5}>
                                                                        {object.data
                                                                            .slice(Math.ceil(object.data.length / 2), object.data.length)
                                                                            .map((data) => {
                                                                                return (<>
                                                                                    <ListItem className={classes.showListItem}>
                                                                                        <Typography variant={"dataTitle"}>{data.column_name}</Typography>
                                                                                        <Typography>{data.column_value}</Typography>
                                                                                    </ListItem>
                                                                                </>)
                                                                            })
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                                </>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </>)
                                                })}
                                        </>)
                                },
                                {
                                    label: "Metadata",
                                    children: (
                                        <>
                                            <Grid container direction={"row"} className={classes.listContainer}>
                                                <Grid item md={5.5}>
                                                    <ListItem>
                                                        <Grid item className={classes.showListItem}>
                                                            <Typography variant={"dataTitle"}>Main clinical </Typography>
                                                            <Typography>(last update: {!metadata ? <CircularProgress size={"0.5em"}/> : `${metadata.updated_at}`})</Typography>
                                                        </Grid>
                                                    </ListItem>
                                                    <Divider/>
                                                    <ListItem className={classes.showListItem}>
                                                        <Typography>Other files</Typography><Typography>{followUpFiles.length}</Typography>
                                                    </ListItem>
                                                    <Divider/>
                                                    <ListItem className={classes.showListItem}>
                                                        <><Typography>Clinical study</Typography>{clinicalStudyId && clinicalStudyId}</>
                                                    </ListItem>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                }
                            ]
                            }
                        ></NavTabs>
                    </Grid>
                    }
                </Grid>
            </Grid>
        </>
    )
}
