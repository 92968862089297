import React, {ReactElement, useEffect, useState} from "react";
import {CircularProgress, Grid, Tooltip, Typography} from "@mui/material";
import {DefaultShowLayout} from "../../lib/views/crud/show/showLayout";
import {DocumentsListView, icon} from "./DocumentsListView";
import {DocumentsDetailView} from "./DocumentsDetailsView";
import {useParams} from "react-router-dom";
import {Document, DocumentFilter, DocumentType} from "../../resources/documents/document";
import {useDocumentsDataProvider} from "./providerHook";
import {DocumentsStyles} from "./documentsStyles";
import {getDetailErrorCause} from "../../resources/messages";

export const DocumentsView = (): ReactElement => {
    const { id } = useParams();
    const classes = DocumentsStyles();

    const [documents, setDocuments] = useState<Document[]>([]);
    const [document, setDocument] = useState<Document>();
    const [filter, setFilter] = useState<DocumentFilter>();

    const [pageToken, setPageToken] = useState<string>();
    const [error, setError] = useState<number>();

    const dataProvider = useDocumentsDataProvider();

    useEffect(() => {
        loadDocuments();
    }, [id, filter, pageToken])

    const loadDocuments = () => {
        if (!filter || filter.q === "") {
            if (id === null || id === undefined) {
                dataProvider.listDocuments(20, (filter?.q === "" ? undefined : pageToken), ({root: true} as DocumentFilter))
                    .then((res) => {
                        setPageToken(res.page_token)
                        return setDocuments(res.data)
                    }).catch((error) => {
                    setError(error.response.status)
                })
            } else {
                dataProvider.getById(id).then((data) => {
                    if (!data) return;
                    if (data.type === DocumentType.DIRECTORY) {
                        dataProvider.listDocuments(20, (filter?.q === "" ? undefined : pageToken),({parent_id: id, depth: 0} as DocumentFilter))
                            .then((doc) => {
                                setPageToken(doc.page_token)
                                setDocuments(doc.data)
                            }).catch((error) => {
                            setError(error.response.status)
                        })
                    }
                    return setDocument(data)
                })
            }
        } else {
            dataProvider.listDocuments(20, pageToken, filter)
                .then((res) => {
                    setPageToken(res.page_token)
                    return setDocuments(res.data)
                }).catch((error) => {
                setError(error.response.status)
            })
        }
    }

    const getFilter = (filter: DocumentFilter | undefined) => {
        setFilter(filter)
    }

    const handleNextPage = () => {
        dataProvider.listDocuments(20, pageToken, filter)
            .then((data) => {
                setDocuments((prevData) => {
                    return prevData?.concat(data.data);
                })
                setPageToken(data.page_token);
            }).catch((error) => {
            setError(error.response.status)
        });
    }

    const isFolder = document?.type === DocumentType.DIRECTORY

    return (
        <>
            <DefaultShowLayout
                data={[document, ...documents]}
                onActionCompleted={loadDocuments}
                previousPage={document?.parent_id ? `/documents/documents/${document?.parent_id}` : id? `/documents/documents` : undefined}
                titleVariant={!id? "pageTitle" : "pageTitlePrefix"}
                description={!id ?  "Here you can see only the documents you have access to." : ""}
                getTitle={() => (
                    <>
                    <Grid container direction={"column"}>
                        {id !== undefined && document &&
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Typography variant="pageTitle">/ &nbsp;</Typography>
                                <Tooltip title={document.name} placement={"top"}>
                                    <Typography variant="pageTitle" className={classes.title}>{` ${document.name}`}</Typography>
                                </Tooltip>
                                <div style={{marginLeft:"0.5em", marginTop:"0.5em"}}>{icon(document)}</div>
                            </div>
                        }
                    </Grid>

                    </>
                )}
            >
                {
                !id ?
                    <DocumentsListView
                        data={documents}
                        handleNextPage={handleNextPage} pageToken={pageToken} getFilter={getFilter}/>
                 :
                    isFolder?
                        error ?
                            <Grid className={classes.errorContainer}>
                                <Typography variant={"dataTitle"} color={"error"}>
                                    <strong>ATTENTION!</strong> {error ? getDetailErrorCause(error) : "This resource is not available."}
                                </Typography>
                            </Grid>
                            :
                            documents === undefined || document === undefined ?
                                <CircularProgress/>
                            :
                            <DocumentsListView
                                data={documents.sort((a, b) => {
                                    if (a.type === DocumentType.DIRECTORY && b.type !== DocumentType.DIRECTORY) {
                                        return -1;
                                    } else {
                                        return 0;
                                    }
                                })}

                                handleNextPage={handleNextPage} pageToken={pageToken} getFilter={getFilter}/>
                                :
                                error ?
                                    <Grid className={classes.errorContainer}>
                                        <Typography variant={"dataTitle"} color={"error"}>
                                            <strong>ATTENTION!</strong> {error ? getDetailErrorCause(error) : "This resource is not avaliable."}
                                        </Typography>
                                    </Grid>
                                :
                                document === undefined ?
                                    <CircularProgress/>
                                :
                                <DocumentsDetailView data={document}/>
                }
            </DefaultShowLayout>
        </>
    );
};
