import React from "react";
import { Login } from "../components/auth/login/Login";
import Recover from "../components/auth/register/Recover";
import {CustomAuthData, MiWebAuthProvider} from "./provider";
import AuthenticationConfig from "../lib/types/auth";
import {PrivacyContract} from "../components/auth/login/gdpr/PrivacyContract";

export const authConfig: AuthenticationConfig<CustomAuthData> = {
    loginRoute: '/auth/login',
    registerRoute: '/auth/recover',
    contractsRoute: '/privacy',
    login: <Login/>,
    register: <Recover />,
    contracts: <PrivacyContract />,
    fullAuth: true,
    provider: new MiWebAuthProvider(),
}
