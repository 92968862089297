import {ResourceConfig} from "../../lib/types/resource";
import {UserGuidePage} from "../../components/userGuide/guidePage";

interface UserGuide {
    id: string
}

export const UserGuideResource: ResourceConfig<UserGuide, UserGuide> = {
    id: "userGuide",
    label: "User Guide",
    singleton: true,
    fields: [
        {id: "id"}
    ],
    show: {
        component: <UserGuidePage/>,
        fields: []
    },
    primaryKey: "id"
}
