import {createStyles, makeStyles} from "@mui/styles";

const LongTextStyles = makeStyles((theme: any) =>
    createStyles({
        longTextInput: {
            maxWidth: "50%",
            textAlign: "justify",
            overflowWrap: "break-word"
        }
    }));

export {LongTextStyles};
