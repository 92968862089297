import { GroupConfig } from "../lib/types/group";
import HomeIcon from "../images/icons/tabs/home.svg";

export const home: GroupConfig = {
    id: 'home',
    label: 'Home',
    defaultResource: () => "profile",
    isDefault: true,
    image: HomeIcon,
}
