import { Button, Grid, Typography } from '@mui/material';
import { useRef } from 'react';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { Controller, useForm } from 'react-hook-form';
import mfaLogo from '../../../../images/icons/login/mfaLogo.svg';
import successfulAuth from '../../../../images/icons/login/mfaSuccessfulAuth.svg';
import { MfaFormStyle } from './mfaFormStyle';


interface FormData {
  authNumber: string;
}

interface MultiFactorAuthFormProps {
  submitCallback: (data: string) => void
  backCallback:()=>void
  authenticated?: boolean;
}

export function MultiFactorAuthForm(props: MultiFactorAuthFormProps) {
  const classes = MfaFormStyle();
  const authCodeRef = useRef<AuthCodeRef>(null);
  const { control, handleSubmit, formState, reset } = useForm<FormData>({
    mode: 'onChange',
  });

  const onSubmit = (data: FormData) => {
    reset();
    authCodeRef.current?.clear();
    props.submitCallback(data.authNumber);
  };

  return (
    <>
      <Grid container direction={'column'} className={classes.mainContainer}>
        <Grid item>
          <img src={mfaLogo} alt={"mfa"}/>
        </Grid>
        <Grid item mt={4}>
          <Typography variant='h6'>
            TWO-FACTOR AUTHENTICATION
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="center">
            Inserisci il codice generato dall'authenticator.
          </Typography>
        </Grid>
        {
          props.authenticated
            ?
            <>
              <Grid item mt={8}>
                <img src={successfulAuth} alt={"success"}/>
              </Grid>
              <Grid item mt={2}>
                <Typography fontWeight={'bold'} color={'rgba(18, 102, 138, 0.4)'}>
                  AUTHENTICATED
                </Typography>
              </Grid>
              <Grid item>
                Redirecting...
              </Grid>
            </>
            :
            <Grid item className={classes.mfaForm}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container direction={'column'} alignItems={'center'}>
                  <Grid item>
                    <Controller
                      name="authNumber"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: true,
                        pattern: /^[0-9]{6}$/,
                      }}
                      render={({ field }) => (
                        <AuthCode {...field} length={6} ref={authCodeRef}  allowedCharacters={'numeric'} inputClassName={classes.otpInput} containerClassName={classes.otpContainer} />
                      )} />
                  </Grid>
                  <Grid item mt={8}>
                    <Button
                      sx={{ width: '20em' }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!formState.isValid}
                    >
                      VERIFY
                    </Button>
                  </Grid>
                  <Grid item mt={2}>
                    <Button
                      sx={{ width: '20em' }}
                      variant="outlined"
                      color="primary"
                      onClick={()=>{
                        props.backCallback()
                      }}
                    >
                      BACK
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
        }
      </Grid>
    </>
  );
}

export default MultiFactorAuthForm;
