import {Button, Divider, Grid, ListItem, TextField, Typography} from "@mui/material";
import React, {ReactElement, useEffect, useState} from "react";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import NavTabs from "../../../lib/components/tabs/NavTabs";
import {RolesEditStyles} from "./rolesEditTabStyles";
import {RoleResource} from "../../../resources/appManager/roles/roleResource";
import {associatedMembersLink} from "../../../resources/appManager/roles/hyperlinkConfigs/associatedMembers";
import {Role} from "../../../resources/appManager/roles/role";
import {RolesDataProvider} from "../../../resources/appManager/roles/rolesDataProvider";
import {AccessDataProvider} from "../../../resources/authorizations/access/accessDataProvider";
import {AppManagerPermission} from "./subPages/appManager";
import {ClinicalDataPermissions} from "./subPages/clinicalData";
import {Access, AccessTargetResource} from "../../../resources/authorizations/access/access";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {DefaultModal} from "../../../lib/components/modal/defaultModal";
import {DefaultActionForm} from "../../../lib/actions/defaultActionForm";
import {useModalContext} from "../../../lib/context/ModalContext";
import {LongTextMeta} from "../../../lib/meta/longText/longText";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {DocumentsPermission} from "./subPages/documents";
import {Field} from "../../../lib/types/field";
import {LabTestPermissions} from "./subPages/labTest";
import {getDetailErrorCause} from "../../../resources/messages";
import {useFeedbackContext} from "../../../lib/context/FeedbackContext";
import {AxiosError} from "axios";
import {ErrorDTO} from "../../../hoc/axiosHandlerProvider/AxiosHandlerProvider";

interface RoleDetailsProps<Role> {
    editMode: boolean
    getTitle?: (data?: Role) => ReactElement;
}

export function RolesDetailsTabView(props: RoleDetailsProps<Role>): ReactElement {

    const modalCtx = useModalContext()
    const classes = RolesEditStyles();
    const navigate = useNavigate();
    const feedbackCtx = useFeedbackContext();

    const {id} = useParams();

    const [error, setError] = useState<number>();

    const [currentRoleData, setCurrentRoleData] = useState<Role | undefined>(undefined);

    const [newRoleData, setNewRoleData] = useState<Role | undefined>(undefined);

    const [currentPermissions, setCurrentPermissions] = useState<string[]>([]);
    const [currentAccesses, setCurrentAccesses] = useState<Access[]>([]);

    const [newPermissions, setNewPermissions] = useState<string[]>([]);
    const [newAccesses, setNewAccesses] = useState<Access[]>([]);
    const [totalAccesses, setTotalAccesses] = useState<Access[]>([]);

    const [loaded, setLoaded] = useState<boolean>(false);

    const [counter, setCounter] = useState(0)

    const [editMode,setEditMode] = useState<boolean>(props.editMode);
    const [descriptionError, setDescriptionError] = useState<boolean>(false);
    const [descriptionErrorMessage, setDescriptionErrorMessage] = useState<string>();
    const rolesDataProvider = (RoleResource.dataProvider as unknown as RolesDataProvider);

    const accessDataProvider = new AccessDataProvider()
    accessDataProvider.setAuthProvider(RoleResource.dataProvider?.getAuthProvider())

    const membersLink: Field<Role> = {id: "members", label: "Users", meta: associatedMembersLink()}

    const MembersShowComponent = membersLink.meta?.getShowComponent(currentRoleData, membersLink)

    useEffect(() => {
        initData();
    }, [RoleResource])

    function initData() {
        if (!id) return;
        Promise.all([
            rolesDataProvider.getById(id)
                .then((data) => {
                    setCurrentRoleData(data)
                    setNewRoleData(data)
                }),
            rolesDataProvider.getPermissions(id).then(p => {
                setCurrentPermissions(p.data)
                setNewPermissions(p.data)
            }),
            accessDataProvider.pagedList(undefined, undefined, ({resource: AccessTargetResource.CLINICAL} as Access)).then(tAcc => {
                rolesDataProvider.getAllAccesses(id).then(rAcc => {
                    const cAcc = tAcc.data.filter(t => rAcc.data.indexOf(t.pattern) !== -1)
                    setCurrentAccesses(prevState => [...prevState, ...cAcc])
                    setNewAccesses(prevState => [...prevState, ...cAcc])
                })
                setTotalAccesses(prevState => [...prevState, ...tAcc.data])
            }),
            accessDataProvider.pagedList(undefined, undefined, ({resource: AccessTargetResource.DOCUMENT} as Access)).then(tAcc => {
                rolesDataProvider.getAllAccesses(id).then(rAcc => {
                    const cAcc = tAcc.data.filter(t => rAcc.data.indexOf(t.pattern) !== -1)
                    setCurrentAccesses(prevState => [...prevState, ...cAcc])
                    setNewAccesses(prevState => [...prevState, ...cAcc])
                })
                setTotalAccesses(prevState => [...prevState, ...tAcc.data])
            }),
            accessDataProvider.pagedList(undefined, undefined, ({resource: AccessTargetResource.SAMPLE} as Access)).then(tAcc => {
                rolesDataProvider.getAllAccesses(id).then(rAcc => {
                    const cAcc = tAcc.data.filter(t => rAcc.data.indexOf(t.pattern) !== -1)
                    setCurrentAccesses(prevState => [...prevState, ...cAcc])
                    setNewAccesses(prevState => [...prevState, ...cAcc])
                })
                setTotalAccesses(prevState => [...prevState, ...tAcc.data])
            })
        ]).then(() => {
            setLoaded(true)
        }).catch((error) => {
            setError(error.response.status)
        })
    }


    const handleSubmit = () => {
        if (!id) {
            return
        }

        const assignablePermissions = newPermissions.filter(p => currentPermissions.indexOf(p) === -1)
        const removablePermissions = (currentPermissions).filter(p => !newPermissions.includes(p));

        const assignableAccesses = newAccesses.filter(a =>
            currentAccesses.map(acc => acc.pattern).indexOf(a.pattern) === -1
        ).map(a => a.pattern)

        const removableAccesses = currentAccesses.filter(a =>
            newAccesses.map(acc => acc.pattern).indexOf(a.pattern) === -1
        ).map(a => a.pattern)

        const promises: Promise<any>[] = []

        promises.push(rolesDataProvider.edit(newRoleData))

        if (assignablePermissions.length > 0) {
            promises.push(rolesDataProvider.assignPermissions(id, assignablePermissions))
        }

        if (removablePermissions.length > 0) {
            promises.push(rolesDataProvider.removePermissions(id, removablePermissions))
        }

        if (assignableAccesses.length > 0) {
            promises.push(rolesDataProvider.assignAccesses(id, assignableAccesses))
        }

        if (removableAccesses.length > 0) {
            promises.push(rolesDataProvider.removeAccesses(id, removableAccesses))
        }
        Promise.all(promises).then(() => {
            navigate(-1)
            setEditMode(false)
            setCurrentRoleData(newRoleData)
            setCurrentPermissions(newPermissions)
            setCurrentAccesses(newAccesses)
        }).catch((err) => {
            const axiosError = err as AxiosError<ErrorDTO, unknown>;
            feedbackCtx.openBottomErrorSnackbar(`Error:${axiosError.response?.data.msg}`)
        })
    }

    if (!id) {
        return <Navigate to={"/roles"}/>
    }

    const showInfo = (): ReactElement => {
        return <>
            <ListItem className={classes.showListItem}>
                <Grid item>
                    <Typography variant={"dataTitle"}>
                        {"Name"}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant={"data"}>
                        {currentRoleData?.name}
                    </Typography>
                </Grid>
            </ListItem>
            <Divider/>
            <ListItem className={classes.showListItem}>
                <Grid item>
                    <Typography variant={"dataTitle"}>
                        {"Created at"}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant={"data"}>
                        {currentRoleData?.created_at.toString()}
                    </Typography>
                </Grid>
            </ListItem>
            <Divider/>
            <ListItem className={classes.showListItem}>
                <Grid item>
                    <Typography variant={"dataTitle"}>
                        {"Description"}
                    </Typography>
                </Grid>
                <Grid item className={classes.description}>
                    <Typography variant={"data"}>
                        {currentRoleData?.description}
                    </Typography>
                </Grid>
            </ListItem>
            <Divider/>
            <ListItem className={classes.showListLinkItem}>
                {MembersShowComponent && MembersShowComponent({})}
            </ListItem>
            <Divider/>

        </>
    }
    const editInfo = (): ReactElement => {
        return (
            newRoleData ?
                <>
                    <div>
                        <ListItem className={classes.showListItem}>
                            <Grid item>
                                <Typography variant={"dataTitle"}>
                                    {"Name"}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"data"}>
                                    {currentRoleData?.name}
                                </Typography>
                            </Grid>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.showListItem}>
                            <Grid item>
                                <Typography variant={"dataTitle"}>
                                    {"Created at"}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"data"}>
                                    {currentRoleData?.created_at.toString()}
                                </Typography>
                            </Grid>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.descriptionInput}>
                            <Typography variant={"dataTitle"}>Description</Typography>
                            <TextField
                                value={newRoleData.description}
                                className={classes.textField}
                                onChange={(ev) => {
                                    setNewRoleData({...newRoleData, description: ev.target.value})
                                        if (ev.target.value.length < 50) {
                                            setDescriptionError(true);
                                            setDescriptionErrorMessage('Description is too short.');
                                        } else {
                                            setDescriptionError(false);
                                            setDescriptionErrorMessage(undefined);
                                        }
                                    }}
                                error={descriptionError}
                                helperText={descriptionErrorMessage}
                            />
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.showListLinkItem}>
                            {MembersShowComponent && MembersShowComponent({})}
                        </ListItem>
                        <Divider/>
                    </div>
                </> : <></>
        )
    }

    const openDeleteModal = () => {
        modalCtx?.openModal({
            component: <DefaultModal
                title={"Delete"}
                cancelLabel={(currentRoleData?.members || []).length === 0 ? undefined : "Go back"}
                handleCancel={() => modalCtx.closeModal()}
                description={
                    (currentRoleData?.members || []).length === 0 ?
                        "Are you sure you want to delete this role? This action is irreversible." :
                        `You cannot delete this role because there it has ${currentRoleData?.members.length} linked users. ` +
                        "Please, change role to the linked users before deleting this role."
                }>
                <>
                {(currentRoleData?.members || []).length === 0 &&
                <DefaultActionForm
                    fields={[
                        {
                            id: "text",
                            label: "Why do you want to delete this role?*",
                            meta: new LongTextMeta<any>(),
                            hidden: () => (currentRoleData?.members || []).length !== 0,
                            validationOptions: {
                                required: {
                                    value: true,
                                    message: "This field is required."
                                }
                            }
                        }
                    ]}
                    choiceAction={{
                        confirmLabel: (currentRoleData?.members || []).length === 0 ? "Delete": "",
                        cancelLabel: (currentRoleData?.members || []).length === 0 ? "Cancel" : "Go back",
                        description: "Why do you want to delete this role?",
                    }}
                    handleSubmit={(data) => {
                        return rolesDataProvider.hardDelete(id, data.text).then(() => {
                            navigate(-1)
                            feedbackCtx.openBottomSuccessSnackbar("Role deleted successfully.")
                        }).catch(() => {
                            feedbackCtx.openBottomErrorSnackbar("Error, please try later.")
                        })
                    }}
                    formStyle={{
                        buttonVariant: 'outlined',
                        buttonColor: 'error'
                    }}/>
                }
                </></DefaultModal>
        })
    }

    const onDiscard = () => {
        setCounter(counter + 1);
        setEditMode(false)
        navigate(-1)
    }

    return (
        <>
            <Grid container className={classes.mainContainer}>
                <Button onClick={() => navigate(-1)}>
                    <KeyboardBackspaceIcon color={"primary"}></KeyboardBackspaceIcon>
                    <Typography variant={"backButtonText"}>Go back</Typography>
                </Button>
                <Grid container direction={'column'}>
                    <Grid item>
                        <Grid container direction={"row"} className={classes.titleContainer}>
                            <Grid item>
                                <Typography variant={"pageTitlePrefix"}>Roles &nbsp;</Typography>
                                <>
                                    {props.getTitle ? (currentRoleData) && props.getTitle(currentRoleData) : ""}
                                </>
                            </Grid>
                            <Grid container className={classes.actionsContainer}>
                                {
                                    editMode ?
                                        <>
                                            <Button variant='contained'
                                                    type="submit"
                                                    disabled={descriptionError}
                                                    onClick={handleSubmit}>
                                                Save
                                            </Button>
                                            <Button variant="outlined"
                                                    color={"error"}
                                                    onClick={() => {
                                                        onDiscard()
                                                    }}>
                                                Discard
                                            </Button>
                                        </> :
                                        <>
                                            {!error &&
                                                <>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => {
                                                            setEditMode(true)
                                                            setNewRoleData(currentRoleData)
                                                            navigate(`edit`);
                                                        }}>
                                                        <BorderColorIcon></BorderColorIcon>
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        color={"error"}
                                                        variant="outlined"
                                                        onClick={() => {
                                                            openDeleteModal()
                                                        }}>
                                                        Delete
                                                    </Button>
                                                </>
                                            }
                                        </>
                                }

                            </Grid>
                        </Grid>
                    </Grid>
                    <>
                        {
                            error &&
                            <Grid className={classes.errorContainer}>
                                <Typography variant={"dataTitle"} color={"error"}>
                                    <strong>ATTENTION!</strong> {getDetailErrorCause(error)}
                                </Typography>
                            </Grid>
                        }
                        {!error &&
                            <>
                                <Grid marginTop={3.5}/>
                                <Grid item>
                                    <NavTabs
                                        tabs={[
                                            {
                                                label: "Info",
                                                children: <>
                                                    {
                                                        editMode ?
                                                            editInfo() :
                                                            showInfo()
                                                    }
                                                </>
                                            },
                                            {
                                                label: "App manager",
                                                children: <AppManagerPermission
                                                    key={`appManager-${counter}`}
                                                    editEnabled={editMode}
                                                    permissions={editMode ? newPermissions : currentPermissions}
                                                    onPermissionsChange={(p) => {
                                                        setNewPermissions(p)
                                                    }}
                                                />
                                            },
                                            {
                                                label: "Documents",
                                                children: <DocumentsPermission
                                                    key={`documents-${counter}`}
                                                    editEnabled={editMode}
                                                    totalAccesses={totalAccesses.filter(t => t.resource === AccessTargetResource.DOCUMENT)}
                                                    currentAccesses={editMode? newAccesses.filter(t => t.resource === AccessTargetResource.DOCUMENT)
                                                        : currentAccesses.filter(t => t.resource === AccessTargetResource.DOCUMENT)}
                                                    currentPermissions={editMode ? newPermissions : currentPermissions}
                                                    onPermissionsChange={(p) => {
                                                        setNewPermissions(p)
                                                    }}
                                                    onAccessesChange={(a) => {
                                                        const newAcc = [
                                                            ...newAccesses.filter(t => t.resource !== AccessTargetResource.DOCUMENT),
                                                            ...a
                                                        ]
                                                        setNewAccesses(
                                                            newAcc
                                                        )
                                                    }}
                                                />
                                            },
                                            {
                                                label: "Clinical",
                                                children: <ClinicalDataPermissions
                                                    key={`clinicalData-${counter}`}
                                                    editEnabled={editMode}
                                                    totalAccesses={totalAccesses.filter(t => t.resource === AccessTargetResource.CLINICAL)}
                                                    currentPermissions={editMode? newPermissions: currentPermissions}
                                                    currentAccesses={editMode? newAccesses.filter(t => t.resource === AccessTargetResource.CLINICAL)
                                                        : currentAccesses.filter(t => t.resource === AccessTargetResource.CLINICAL)}
                                                    onPermissionsChange={(p) => {
                                                        setNewPermissions(p)
                                                    }}
                                                    onAccessesChange={(a) => {
                                                        const newAcc = [
                                                            ...newAccesses.filter(t => t.resource !== AccessTargetResource.CLINICAL),
                                                            ...a
                                                        ]
                                                        setNewAccesses(
                                                            newAcc
                                                        )
                                                    }}
                                                />
                                            },
                                            {
                                                label: "Lab",
                                                children: <LabTestPermissions
                                                    key={`labTest-${counter}`}
                                                    editEnabled={editMode}
                                                    totalAccesses={totalAccesses.filter(t => t.resource === AccessTargetResource.SAMPLE)}
                                                    currentPermissions={editMode? newPermissions : currentPermissions}
                                                    currentAccesses={editMode? newAccesses.filter(t => t.resource === AccessTargetResource.SAMPLE)
                                                        : currentAccesses.filter(t => t.resource === AccessTargetResource.SAMPLE)}
                                                    onPermissionsChange={(p) => {
                                                        setNewPermissions(p)
                                                    }}
                                                    onAccessesChange={(a) => {
                                                        const newAcc = [
                                                            ...newAccesses.filter(t => t.resource !== AccessTargetResource.SAMPLE),
                                                            ...a
                                                        ]
                                                        setNewAccesses(
                                                            newAcc
                                                        )
                                                    }}
                                                />
                                            }
                                        ]
                                        }
                                    ></NavTabs>
                                </Grid>
                            </>
                        }
                    </>
                </Grid>
            </Grid>
        </>
    )
}
