import {ListMeta, MetaField} from "../../types/meta";
import React, {ReactElement} from "react";
import {Grid, Typography} from "@mui/material";
import {Field} from "../../types/field";

interface StateProps<T> {
    stateBadge: (data:T) => ReactElement;
}

function StateShow<T>(label?: string, data?: string): ReactElement {
    return (
        <>
            <Grid item>
                <Typography variant={"dataTitle"}>
                    {label}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant={"data"}>
                    {!data? "--" : data}
                </Typography>
            </Grid>
        </>
    )
}

export class StateMeta<T> implements MetaField<T> {
    stateBadge: (data: T) => ReactElement

    constructor(props: StateProps<T>) {
        this.stateBadge = props.stateBadge;
    }
    getFilterComponent?: ((handler?: ((data: T) => void) | undefined) => React.FC) | undefined;

    getListMeta(): ListMeta {
        return {
            type: "ReactElement",
            value: () => this.stateBadge
        };
    }

    getListComponent(data: T | undefined) {
        return () => {return <>{this.stateBadge(data!)}</>}
    }

    getFormComponent(): ReactElement {
        return <></>;
    }

    getShowComponent(data: T | undefined, field: Field<T>) {
        return () => {
            return StateShow(field.label, data ? data[field.id] as string : '')
        }
    }

}
