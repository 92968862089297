import React, {ReactElement, useEffect, useState} from "react";
import CreationPanel from "../../../components/creationPanel/CreationPanel";
import {useResourceContext} from "../../../context/ResourceContext";
import {ResourceConfig} from "../../../types/resource";
import {Field} from "../../../types/field";
import { useModalContext } from "../../../context/ModalContext";
import { useNavigate } from "react-router-dom";
import {DefaultListView} from "../list/default";

interface DefaultCreateProps<T> {
    fields: Field<T>[];
    id: string;
    pageSize?: number;
}

export function DefaultCreateView<T extends Record<string,any>, V>(props: DefaultCreateProps<T>): ReactElement {
    const anchor = "right";
    const navigate = useNavigate();

    const modalCtx = useModalContext();
    const resourceContext = useResourceContext()
    const resource = resourceContext.getConfig();

    const [data, setData] = useState<T[] | undefined>(undefined);
    const [filter, setFilter] = useState<V | undefined>(undefined);

    const isPaginated = props.pageSize !== undefined;

    useEffect(() => {
        if (!resource.filter?.filters.find(f => f.rendersElement) ||
            (resource.filter?.filters.find(f => f.rendersElement) && filter)) {
            if(isPaginated){
                resource?.dataProvider?.pagedList(5, undefined, filter).then((data) => {
                    setData(data.data as T[]);
                });
            }else{
                resource?.dataProvider?.list(5, filter).then((data) => {
                    setData(data as T[]);
                });
            }
        }

        modalCtx?.openSideModal({
            anchor: 'right',
            component: <CreationPanel open={true}
                                    anchor={anchor}
                                    resource={resource as ResourceConfig<Record<any, any>, unknown>}
                                    fields={props.fields}/>,
            onClose: () => navigate(-1)
        });
        return () => {
            modalCtx?.closeSideModal();
        }
    }, [filter])

    return (
        <>
            {resource.list?.fields &&
                <DefaultListView fields={resource.list.fields} pageSize={20}></DefaultListView>
            }
        </>
    )
}
