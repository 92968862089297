import {Button, ListItemButton, Typography} from "@mui/material";
import React, {FC, ReactElement} from "react";
import {DefaultModal} from "../components/modal/defaultModal";
import {useModalContext} from "../context/ModalContext";
import {useResourceContext} from "../context/ResourceContext";
import {Action, ActionType} from "../types/action";
import {ChoiceAction} from "../types/choiceAction";
import {Field} from "../types/field";
import {ActionProps, ActionStyle} from "./actionProps";
import IDataProvider from "../../hoc/dataProvider/IDataProvider";
import {DefaultSideModal} from "../components/modal/defaultSideModal";
import {IAuthenticationProvider} from "../types/auth";
import {CustomAuthData} from "../../auth/provider";
import {useAuthenticationContext} from "../context/AuthenticationContext";
import {AuthorizationContextValue, useAuthorizationContext} from "../context/AuthorizationContext";
import {FeedbackContextValue, useFeedbackContext} from "../context/FeedbackContext";

export class NoArgsAction<ActionArgument, Resource> implements Action<ActionArgument, Resource> {
    id: string;
    label: string;
    type: ActionType;
    primary?: (res?: Resource[] | Resource) => boolean;
    primaryActionColor?: 'primary' | 'secondary' | 'error' | 'success' | 'warning';
    hidden: (res?: Resource | Resource[], authNCtx?: AuthorizationContextValue) => boolean;
    fields?: Field<ActionArgument>[];
    modalDescription?: (data?: Resource | Resource[]) => string;
    handler?: (res: Resource | Resource[], dataProvider?: IDataProvider<any, any>, authProvider?: IAuthenticationProvider<CustomAuthData>, feedbackCtx?: FeedbackContextValue, formData?: string, args?: ActionArgument | undefined) => ((Promise<boolean | Resource | undefined>) | undefined)
    choiceAction?: ChoiceAction;
    actionStyle?: ActionStyle;
    modalType?: "defaultModal" | "sideModal";
    customModal?: ReactElement | ((data?: Resource, filter?: any) => ReactElement);
    icon?: ReactElement;
    onComplete?: () => void;
    redirectionRoute?: string


    constructor(props: ActionProps<ActionArgument, Resource>) {
        this.id = props.id;
        this.label = props.label;
        this.type = props.actionType;
        this.primary = props.primary;
        this.primaryActionColor = props.primaryActionColor;
        this.hidden = props.hidden ? props.hidden : () => false;
        this.choiceAction = props.choiceAction;
        this.actionStyle = props.actionStyle;
        this.handler = props.handler;
        this.modalDescription = props.modalDescription;
        this.modalType = props.modalType;
        this.fields = props.fields || [];
        this.customModal = props.customModal;
        this.icon = props.icon;
        this.onComplete = props.onComplete;
        this.redirectionRoute = props.redirectionRoute
    }

    getActionComponent(data?: Resource | Resource[] | undefined, isDisabled?: boolean, filter?: any): FC {

        return () => {
            const resConfig = useResourceContext().getConfig();
            const modalCtx = useModalContext();
            const authCtx = useAuthenticationContext<CustomAuthData>();
            const authNCtx = useAuthorizationContext();
            const feedbackCtx = useFeedbackContext();

            if (!modalCtx || this.hidden(data, authNCtx)) return <></>

            const handleClick = () => {
                const pk = resConfig.primaryKey;
                if (data) {
                    if (this.handler) {
                        this.handler(data, resConfig.dataProvider, authCtx.getProvider(), feedbackCtx)?.then((r) => {
                            if (r) {
                                this.onComplete && this.onComplete();
                                modalCtx?.closeModal();
                            }
                        })
                            .catch(() => {
                                // modalCtx?.closeModal();
                            })
                    } else {
                        resConfig.dataProvider?.actionEdit(this.id, data[pk])
                    }
                    if (Array.isArray(data)) {
                        const ids: string[] = (data as Resource[]).map((res) => (res[pk] as string | number).toString())
                        resConfig.dataProvider?.bulkEdit(this.id, ids);
                    }
                }
            }

            const generateModal = () => {
                const modalComponent = typeof this.customModal === "function" ? this.customModal(data as Resource, filter) : this.customModal;

                if (!this.choiceAction) return <></>
                return (
                    this.modalType === "sideModal" ?
                        modalComponent as ReactElement ??
                        <DefaultSideModal
                            title={this.label}
                            onClose={this.onComplete}
                            handleClose={() => modalCtx?.closeSideModal()}
                        />
                        :
                        modalComponent as ReactElement ??
                        <DefaultModal
                            title={this.label}
                            description={this.modalDescription ? this.modalDescription(data) : this.choiceAction.description!}
                            handleCancel={() => modalCtx?.closeModal()}
                            handleConfirm={handleClick}
                            buttonStyles={
                                {
                                    buttonColor: this.actionStyle?.buttonColor,
                                    buttonVariant: this.actionStyle?.buttonVariant
                                }}
                            cancelLabel={this.choiceAction?.cancelLabel}
                            confirmLabel={this.choiceAction?.confirmLabel}/>)
            }

            return (
                <>
                    {this.primary && this.primary(data) ?
                        <Button
                            variant={"outlined"}
                            sx={{justifyContent: "right"}}
                            color={this.primaryActionColor ?? 'primary'}
                            disabled={isDisabled}
                            onClick={this.choiceAction
                                ? () => {
                                    this.modalType === "sideModal" ?
                                        modalCtx?.openSideModal({
                                            anchor: "right",
                                            onClose: this.onComplete,
                                            component: generateModal()}
                                        )
                                        :
                                        modalCtx?.openModal({
                                            onClose: this.onComplete,
                                            component: generateModal()}
                                        )
                                }
                                : handleClick}
                        >
                            {this.icon}
                            {this.label}
                        </Button>
                        :
                        <ListItemButton
                            sx={{justifyContent: "right"}}
                            onClick={this.choiceAction
                                ? () => {
                                    this.modalType === "sideModal" ?
                                        modalCtx?.openSideModal({
                                            anchor: "right",
                                            onClose: this.onComplete,
                                            component: generateModal()}
                                        )
                                        :
                                        modalCtx?.openModal({
                                            onClose: this.onComplete,
                                            component: generateModal()}
                                        )
                                }
                                : handleClick}
                        >
                            <Typography variant={"smallButtonText"}
                                        color={this.actionStyle?.buttonColor || 'primary'}>{this.label.toUpperCase()}</Typography>
                        </ListItemButton>
                }
                </>
            )
        }
    }
}
