import {BaseDataProvider} from "../../baseDataProvider";
import axios from "axios";
import {BASE_URL} from "../../../App";
import {DownloadRequest, DownloadRequestStatus, FileRequestStatus} from "./downloadRequest";
import GenericSearch from "../../genericSearch";

function calculateRequestStatus(downloadRequest: DownloadRequest): string {
    let waiting = false, notAvailable = false
    downloadRequest.files.forEach((f) => {
        switch (f.status) {
            case FileRequestStatus.EXPIRED:
                notAvailable = true
                return
            case FileRequestStatus.RESTORING:
                waiting = true
                return
            case FileRequestStatus.INVALID:
            default:
        }
    })
    if (notAvailable) {
        return DownloadRequestStatus.NOT_AVAILABLE
    }
    if (waiting) {
        return DownloadRequestStatus.WAITING
    }
    return DownloadRequestStatus.AVAILABLE
}

function calculateRequestTotalSize(downloadRequest: DownloadRequest): string {
    let totalSize: number = 0
    downloadRequest.files.forEach((f) => {
        totalSize += f.lab_test_file.size;
    })
    if (totalSize >= Math.pow(2, 40)) {
        const tb = totalSize / Math.pow(2, 40);
        return tb.toFixed(2) + " TB";
    } else if (totalSize >= Math.pow(2, 30)) {
        const gb = totalSize / Math.pow(2, 30);
        return gb.toFixed(2) + " GB";
    } else if (totalSize >= Math.pow(2, 20)) {
        const mb = totalSize / Math.pow(2, 20);
        return mb.toFixed(2) + " MB";
    } else if (totalSize >= Math.pow(2, 10)) {
        const kb = totalSize / Math.pow(2, 10);
        return kb.toFixed(2) + " KB";
    } else {
        return totalSize.toString() + " B";
    }
}

export class DownloadRequestDataProvider extends BaseDataProvider<DownloadRequest, GenericSearch> {

    constructor(){
        super('lab_test_files/download_requests');
    }

    getById = async (id: string): Promise<DownloadRequest> => {
        return axios.get(`${BASE_URL}/lab_test_files/download_requests/${id}`, {
            headers:  await this.getHeaders({"Accept": "application/json"})
        }).then(r => {
            let downloadRequest =  r.data as DownloadRequest;
            downloadRequest.files = downloadRequest.files
                // Remove invalid files
                .filter(({status}) => status !== FileRequestStatus.INVALID)
                .map((f) => {
                    if (f.available_until && new Date(f.available_until) < new Date()) {
                        f.status = FileRequestStatus.EXPIRED
                    }
                    return f
                })
            downloadRequest.status = calculateRequestStatus(downloadRequest)
            downloadRequest.total_size = calculateRequestTotalSize(downloadRequest);
            return downloadRequest
        });
    }

    pagedList = async (page_size?: number, page_token?: string, filter?: GenericSearch): Promise<{ data: DownloadRequest[], page_token: string}> => {
        const headers = await this.getHeaders();
        return axios.get(`${BASE_URL}/lab_test_files/download_requests`, {
            headers: headers, params: { page_size, page_token, ...filter}
        }).then(r => {
            let res = r.data.data as DownloadRequest[];
            res = res.map((downloadRequest): DownloadRequest => {
                downloadRequest.files = downloadRequest.files
                    .filter(({status}) => status !== FileRequestStatus.INVALID)
                    .map((f) => {
                        if (f.available_until && new Date(f.available_until) < new Date()) {
                            f.status = FileRequestStatus.EXPIRED
                        }
                        return f
                    })
                downloadRequest.status = calculateRequestStatus(downloadRequest);
                downloadRequest.total_size = calculateRequestTotalSize(downloadRequest);
                return downloadRequest;
            })
            return {
                page_token: r.data.page_token,
                data: res,
            };
        })
    }

}