import {createStyles, makeStyles} from "@mui/styles";

const SideModalStyles = makeStyles(() =>
    createStyles({
        root: {
            width: "45vw",
        },
        container: {
            padding: "1em !important",
            justifyContent: "center"
        },
        title: {
            paddingLeft: "1em",
            paddingTop: "1em",
            width: "90%"
        },
        closeIcon: {
            paddingTop: "0.4em",
            width: "7%",
            cursor: "pointer",
            fontSize: "2.5rem !important"
        },
        modalContent: {
            marginTop: 50,
            width: "90%",
            alignSelf:"center"
        }
    }));

export {SideModalStyles};
