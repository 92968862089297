import {CircularProgress, Grid, Typography} from "@mui/material";
import React, {ReactElement, useEffect, useState} from "react";
import {ClinicalDataDownloadStyles} from "./downloadStyles";
import {ClinicalData} from "../../../../resources/clinicalData/clinicalData/clinicalData";
import {ClinicalStudiesDataProvider, Column} from "../../../../resources/appManager/clinicalStudies/clinicalStudiesDataProvider";
import {ClinicalStudiesResource} from "../../../../resources/appManager/clinicalStudies/clinicalStudyResource";
import {useNavigate} from "react-router-dom";
import {StepView} from "../../../../lib/views/other/step/step";
import {SourceFile} from "../../../../resources/clinicalData/sourceFile/sourceFile";
import {FileAccordion} from "./fileAccordion";
import {useFormatFilter} from "../../../filters";
import {FileFormat} from "../../../../resources/clinicalData/clinicalData/clinicalData";
import {useClinicalDataProvider} from "../hooks";


export function ClinicalDataDownloadView(): ReactElement {
    const classes = ClinicalDataDownloadStyles();

    const urlParams = new URLSearchParams(window.location.search);
    const clinicalStudyId = urlParams.get('clinical_study_id');

    const formatFilter = useFormatFilter();

    const navigate = useNavigate();

    const [filter, setFilter] = useState<ClinicalData>();
    const FormatFilterComponent = formatFilter.field.meta.getFilterComponent(
        ((data: FileFormat) => {
            setFilter({format: data} as ClinicalData);
        }),
        undefined,
        filter ? filter["format"] : "");

    const [files, setFiles] = useState<SourceFile[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedColumns, setSelectedColumns] = useState<Record<string, string[]>>({});
    const [fileColumns, setFileColumns] = useState<Record<string, Column[]>>({})

    const [downloading, setDownloading] = useState(false);
    const clinicalDataProvider = useClinicalDataProvider();
    const clinicalStudiesDataProvider = (ClinicalStudiesResource.dataProvider as unknown as ClinicalStudiesDataProvider)


    useEffect(() => {
        clinicalStudiesDataProvider.getColumns(clinicalStudyId!).then((columns) => {
            const currentFileColumns = {...fileColumns};
            columns.forEach(c => {
                c.memberships.forEach(m => {
                    currentFileColumns[m.file_id] = currentFileColumns[m.file_id] ?
                        [...currentFileColumns[m.file_id],
                            {
                                ...c, memberships: [{
                                    file_id: m.file_id,
                                    index_in_file: m.index_in_file,
                                    name_in_file: m.name_in_file
                                }]
                            }
                        ] :
                        [{
                            ...c, memberships: [{
                                file_id: m.file_id,
                                index_in_file: m.index_in_file,
                                name_in_file: m.name_in_file
                            }]
                        }]
                })
                return setFileColumns(currentFileColumns)
            })
        })
    }, [])

    const prepareFile = () => {
        setLoading(true)
        clinicalStudiesDataProvider.getFiles(clinicalStudyId || "").then(files => {
            setFiles(files.data.filter(f => f.status === "processed"))
            setLoading(false)
        })
    }

    const handleColumnSelection = (newSelectedColumns: string[], fileID: string) => {
        const newCols = {...selectedColumns};
        newCols[fileID] = newSelectedColumns
        setSelectedColumns(newCols)
    }

    const initiateDownload = () => {
        setDownloading(true);
        setTimeout(() => {
                clinicalDataProvider.download({
                    format: filter?.format || "xlsx",
                    clinical_study_id: clinicalStudyId || "",
                    columns: selectedColumns ?? {}
                })
                    .then((data) => {
                        const blob = new Blob([data], {type: "application/zip"});
                        const href = URL.createObjectURL(blob);
                        const download = Object.assign(document.createElement('a'), {
                            href: href,
                            download: `clinical_data_${clinicalStudyId}.zip`
                        });
                        document.body.append(download);
                        download.click();
                        download.remove();
                        URL.revokeObjectURL(href);
                    });
                setDownloading(false)
                navigate(-1);
            }
            , 1000)
    }
    return (
        <StepView
            title={
                <Grid item>
                    <Typography variant={"pageTitlePrefix"}>Clinical Data &nbsp;</Typography>
                    <Typography variant={"pageTitle"}>/ Download clinical data</Typography>
                </Grid>
            }
            steps={[
                {
                    description: "To complete the download, please select the desired file format.",
                    button: {
                        text: "NEXT",
                        isDisabled: !filter?.format,
                        isProcessing: downloading,
                        onClick: prepareFile
                    },
                    component: (
                        <>
                        {formatFilter && formatFilter.field.meta &&
                            <Grid container direction={"row"} className={classes.filterContainer}
                                  width={"fit-content !important"}>
                                <Grid item>
                                    <Typography
                                        variant={"dataTitle"}>Select format  &nbsp; &nbsp;</Typography>
                                </Grid>
                                <Grid item>
                                    {FormatFilterComponent()}
                                </Grid>
                            </Grid>
                        }
                        </>
                    )
                },
                {
                    description: `Select the columns you want to download for each file of the Clinical Study: ${clinicalStudyId}`,
                    button: {
                        text: "DOWNLOAD",
                        isDisabled: !selectedColumns,
                        isProcessing: downloading,
                        onClick: initiateDownload
                    },
                    component: <>
                        <Grid item className={classes.accordionContainer}>
                            {loading ? <CircularProgress/> :
                                files.map((file) => {
                                    const cFileColumns = fileColumns ? fileColumns[file.id] : []
                                    return (
                                        <>
                                            {
                                                cFileColumns && cFileColumns.length > 0 ?
                                                <FileAccordion
                                                    key={file.id}
                                                    file={file}
                                                    columns={cFileColumns}
                                                    columnSelectionCallback={(fileColumns) => {
                                                        handleColumnSelection(fileColumns, file.id)
                                                    }}/>
                                                :
                                                null
                                            }
                                        </>

                                    )
                            })
                            }
                        </Grid>
                    </>

                }
            ]}
        />
    )
}
