import {ReactElement} from "react";
import {Button, Grid, List, ListItem, Typography} from "@mui/material";
import {Control, Controller, Path, SubmitHandler, useForm} from "react-hook-form";
import {Document} from "../../../resources/documents/document";
import {useModalContext} from "../../../lib/context/ModalContext";
import {useFeedbackContext} from "../../../lib/context/FeedbackContext";
import {Field} from "../../../lib/types/field";
import {DefaultSideModal} from "../../../lib/components/modal/defaultSideModal";
import {SimpleTextMeta} from "../../../lib/meta/simpleText/simpleText";
import {CreationPanelStyles} from "../../../lib/components/creationPanel/CreationPanelStyles";
import {useDocumentsDataProvider} from "../providerHook";
import {DocumentsResource} from "../../../resources/documents/documentResource";

interface AddFolderModalProps {
    parentId: string | undefined
}
export const AddFolderModal = ({parentId}: AddFolderModalProps): ReactElement => {
    const classes = CreationPanelStyles();

    const nameField: Field<Document> = {id: "name", label: "Name", meta: new SimpleTextMeta<Document>({}), validationOptions: {required: true}};
    const {handleSubmit, reset, control, formState} = useForm<Document>({mode: "all"});

    const modalCtx = useModalContext();
    const feedbackCtx = useFeedbackContext();

    const dataProvider = useDocumentsDataProvider();

    const onSubmit: SubmitHandler<Document> = data => {
        let folder: Document = {...data};
        if (parentId) {
            folder = {...data, parent_id: parentId}
        }
        dataProvider.createFolder(folder).then(() => {
            feedbackCtx.openBottomSuccessSnackbar(`Folder created successfully.`)
            const createFolder = DocumentsResource.actions?.find((a) => a.id === "createFolder")
            if (createFolder) {
                createFolder.onComplete && createFolder.onComplete();
            }
            return modalCtx?.closeSideModal();
        }).catch(() => {
            feedbackCtx?.openBottomErrorSnackbar(`Error: the folder was not created.`)
            return modalCtx?.closeSideModal();
        })
        reset();
    };

    return (
        <DefaultSideModal title={"Create folder"} handleClose={() => modalCtx?.closeSideModal()}>
            <List>
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Grid className={classes.formContainer}>
                        <ListItem className={classes.listItem}>
                            <Grid item>
                                <Typography variant={"dataTitle"}>
                                    {nameField.label}*
                                </Typography>
                            </Grid>
                            <Grid item className={classes.formInput}>
                                <Controller
                                    name={"tag_name"}
                                    control={control}
                                    render={() => {
                                        return <>
                                            {
                                                nameField.meta?.getFormComponent(
                                                    control as unknown as Control,
                                                    nameField.id as Path<Document>,
                                                    nameField
                                                )
                                            }
                                        </>
                                    }}
                                />
                            </Grid>
                        </ListItem>
                    </Grid>
                    <Grid container direction={"column"} className={classes.actionContainer}>
                        <Typography>* required field</Typography>
                        <Button className={classes.addButton} type="submit"
                                disabled={!formState.isValid}
                        >
                            CREATE FOLDER
                        </Button>
                    </Grid>
                </form>
            </List>
        </DefaultSideModal>
        )
}
