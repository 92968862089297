import {createStyles, makeStyles} from "@mui/styles";

const UserGuideStyles = makeStyles(() =>
    createStyles({
        container: {
            marginBottom: "2em"
        },
        title: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        warningBox: {
            backgroundColor: "#89A1AE33",
            padding: "0.5em 1em 0.5em 1em"
        },
        frame: {
            border: "2px solid rgba(0, 0, 0, 0.2)",
            marginBottom: "1.5em"
        },
        icon: {
            marginRight: ".5em"
        },
        paragraph: {
            marginBottom: "2em",
            marginTop: "2em",
            textAlign: "justify"
        },
        text: {
            textAlign: "justify"
        }
    }));

export {UserGuideStyles};
