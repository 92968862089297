import {createStyles, makeStyles} from "@mui/styles";

const DefaultStyles = makeStyles(() =>
    createStyles({
        mainContainer: {
            marginTop: "7em",
            width: "90% !important",
            marginLeft: "auto",
            marginRight: "auto"
        },
        titleContainer: {
            marginBottom: "1em !important",
            justifyContent: "space-between !important"
        },
        filter: {
            marginRight: "2em !important",
            marginBottom: "2em !important"
        },
        tableContainer: {
            paddingTop: "2em !important"
        },
        bottomActions: {
            justifyContent:"space-between",
            marginTop:"-1em"
        },
        hiddenFilters: {
            padding: "1em",
            marginTop: "0.5em"
        }
    }));

export {DefaultStyles};
