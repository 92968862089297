import { GroupConfig } from "../lib/types/group";
import SampleIcon from "../images/icons/tabs/sample.svg";
export const labTest: GroupConfig = {
    id: 'lab',
    label: 'Lab',
    defaultResource: (authNCtx) => {
        if (authNCtx?.hasAccess("lab", "labTest")) {
            return "labTest"
        } else if (authNCtx?.hasAccess("lab", "metadataFiles")) {
            return "metadataFiles"
        } else {
            return ""
        }

    },
    image: SampleIcon
}
