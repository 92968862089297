import {Box, Container, Grid, Typography} from "@mui/material";
import React, { ReactElement } from "react";
import {ModalStyles} from "./modalStyles";
import CloseIcon from "@mui/icons-material/Close";

interface DefaultSideModalProps {
    title: string,
    handleClose: () => void,
    children?: ReactElement,
    onClose?: () => void;
}

export function DefaultSideModal (props: DefaultSideModalProps) {
    const classes = ModalStyles();
    return (
            <Box className={classes.root} role="presentation">
                <Container className={classes.container}>
                    <Grid container className={classes.container}>
                        <Grid container direction="row">
                            <Grid item className={classes.sideModalTitle}>
                                <Typography variant={"pageTitle"}>{props.title}</Typography>
                            </Grid>
                            <Grid item>
                                <CloseIcon className={classes.closeIcon}
                                           onClick={() => {
                                               props.handleClose();
                                               return props.onClose && props.onClose();
                                           }}
                                ></CloseIcon>
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"} className={classes.modalContent}>
                            {props.children}
                        </Grid>
                    </Grid>
                </Container>
            </Box>
    )
}
