import React, {ReactNode, useEffect} from "react";
import axios, {AxiosError} from "axios";
import {useFeedbackContext} from "../../lib/context/FeedbackContext";

interface AxiosHandlerProviderProps {
    children?: ReactNode
}

export interface ErrorDTO {
    code: string,
    msg: string,
    uuid: string
}

export function AxiosHandlerProvider({ children }: AxiosHandlerProviderProps) {
    const feedback = useFeedbackContext();

    useEffect(() => {
        const responseInterceptor = axios.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                if (axios.isAxiosError(error)) {
                    const axiosError = error as AxiosError<ErrorDTO, unknown>;
                    if (axiosError.response) {
                        feedback.openErrorSnackbar(`Operazione fallita: ${axiosError.response.data.msg}`)
                    }
                    if (axiosError.message) {
                        feedback.openErrorSnackbar(`Operazione fallita: ${axiosError.message}`)
                    }
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, []);

    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            response => response,
            error => Promise.reject(error)
        );
        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, []);

    return <>{children}</>
}
