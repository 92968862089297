import React, {useEffect, useState} from 'react';
import { SectionKeys } from './sectionKeys';
import { marked } from 'marked'
import ReactMarkdown from "react-markdown";
import {Divider, Grid} from '@mui/material';
import HomeIconBlue from "../../images/icons/tabs/homeBlue.svg";
import AppManagerIconBlue from "../../images/icons/tabs/managerBlue.svg";
import DocumentsIconBlue from "../../images/icons/tabs/documentsBlue.svg";
import ClinicalIconBlue from "../../images/icons/tabs/clinicalBlue.svg";
import LabIconBlue from "../../images/icons/tabs/sampleBlue.svg";
import LoginIcon from "@mui/icons-material/Login";
import {UserGuideStyles} from "./userGuideStyles";

interface UserGuideProps {
    refs: SectionKeys;
}

const UserGuide = ({ refs }: UserGuideProps) => {
    const [sections, setSections] = useState<Record<string, string>>({});
    const classes = UserGuideStyles();

    const fetchSection = (name: string, path: string) => {
        fetch(path)
            .then((res) => res.text())
            .then((text) => {
                setSections(prevSections => ({
                    ...prevSections,
                    [name]: marked(text),
                }));
            });
    }

    useEffect(() => {
        getUserGuide();
    }, []);

    const getUserGuide = () => {
        const sections = [
            { name: 'signup', path: require('../../files/user_guide_signup.md') },
            { name: 'signupE', path: require('../../files/signup_errors.md') },
            { name: 'login', path: require('../../files/user_guide_login.md') },
            { name: 'loginE', path: require('../../files/login_errors.md') },
            { name: 'profile', path: require('../../files/user_guide_profile.md') },
            { name: 'users', path: require('../../files/user_guide_users.md') },
            { name: 'roles', path: require('../../files/user_guide_roles.md') },
            { name: 'clinicalStudies', path: require('../../files/user_guide_clinicalStudies.md') },
            { name: 'laboratories', path: require('../../files/user_guide_laboratories.md') },
            { name: 'audit', path: require('../../files/user_guide_audit.md') },
            { name: 'documents', path: require('../../files/user_guide_documents.md') },
            { name: 'clinicalData', path: require('../../files/user_guide_clinicalData.md') },
            { name: 'sourceFiles', path: require('../../files/user_guide_sourceFiles.md') },
            { name: 'labTests', path: require('../../files/user_guide_labTests.md') },
            { name: 'metadataFiles', path: require('../../files/user_guide_metadataFiles.md') },
            { name: 'cliTool', path: require('../../files/user_guide_cli.md')}
        ];

        sections.forEach(({ name, path }) => {
            fetchSection(name, path);
        });
    }

    return (
            <>
                <Grid className={classes.container}>
                    <div className={classes.title}>
                        <LoginIcon fontSize={"large"} color={"primary"} className={classes.icon}/>
                        <ReactMarkdown>{"# GET STARTED"}</ReactMarkdown>
                    </div>

                    <Grid ref={refs.signingUp} className={classes.paragraph}>
                        <article dangerouslySetInnerHTML={{__html: sections.signup || ''}}></article>
                        <Grid className={classes.warningBox}>
                            <article dangerouslySetInnerHTML={{__html: sections.signupE || ''}}></article>

                        </Grid>
                    </Grid>
                    <Divider/>

                    <Grid ref={refs.loggingIn} className={classes.paragraph}>
                        <article dangerouslySetInnerHTML={{__html: sections.login || ''}}></article>
                        <Grid className={classes.warningBox}>
                            <article dangerouslySetInnerHTML={{__html: sections.loginE || ''}}></article>
                        </Grid>
                    </Grid>
                    <Divider/>
                </Grid>

                <Grid className={classes.container}>
                    <div className={classes.title}>
                        <img src={HomeIconBlue} alt={"home"} className={classes.icon}/>
                        <ReactMarkdown>{'# HOME'}</ReactMarkdown>
                    </div>
                    <Grid ref={refs.profile}>
                        <article dangerouslySetInnerHTML={{__html: sections.profile || ''}}></article>
                    </Grid>
                    <iframe
                        title={"api keys"}
                        className={classes.frame}
                        src="https://scribehow.com/embed/Generate_and_Manage_Access_Keys__Z0QwYWxaS7Gjms9-VAoTNg"
                        width="100%" height="640" allowFullScreen frameBorder="0"></iframe>
                </Grid>
                <Divider/>

                <Grid className={classes.container} ref={refs.appManager}>
                    <div className={classes.title}>
                        <img src={AppManagerIconBlue} alt={"appManager"} className={classes.icon}/>
                        <ReactMarkdown>{'# APP MANAGER'}</ReactMarkdown>
                    </div>
                    <div className={classes.text}>
                        <ReactMarkdown>{"The App Manager section is available to the miWeb administrators and consists of a panel of administration divided into five sections that allow to manage each user and their role, administrate clinical studies and laboratories, and view any operation performed on the platform by any user."}</ReactMarkdown>
                    </div>
                    <Divider/>

                    <Grid ref={refs.users} className={classes.paragraph}>
                        <article dangerouslySetInnerHTML={{__html: sections.users || ''}}></article>
                        <iframe
                            title={"users"}
                            className={classes.frame}
                            src="https://scribehow.com/embed/How_to_add_users_to_the_app_manager__fcO95O9mRpKkCaaFRFoROg"
                            width="100%" height="640" allowFullScreen frameBorder="0"></iframe>
                    </Grid>
                    <Divider/>

                    <Grid ref={refs.roles} className={classes.paragraph}>
                        <article dangerouslySetInnerHTML={{__html: sections.roles || ''}}></article>
                        <iframe
                            title={"roles"}
                            className={classes.frame}
                            src="https://scribehow.com/embed/Creating_a_role_and_assigning_permissions__-GUe0hvESzmtdU0sSm79Fw"
                            width="100%" height="640" allowFullScreen frameBorder="0"></iframe>
                    </Grid>
                    <Divider/>

                    <Grid ref={refs.clinicalStudies} className={classes.paragraph}>
                        <article dangerouslySetInnerHTML={{__html: sections.clinicalStudies || ''}}></article>
                    </Grid>
                    <Divider/>

                    <Grid ref={refs.laboratories} className={classes.paragraph}>
                        <article dangerouslySetInnerHTML={{__html: sections.laboratories || ''}}></article>
                    </Grid>
                    <Divider/>

                    <Grid ref={refs.audit} className={classes.paragraph}>
                        <article dangerouslySetInnerHTML={{__html: sections.audit || ''}}></article>
                    </Grid>
                    <Divider/>
                </Grid>

                <Grid ref={refs.documents} className={classes.container} >
                    <div className={classes.title}>
                        <img src={DocumentsIconBlue} alt={"documents"} className={classes.icon}/>
                        <ReactMarkdown>{'# DOCUMENTS'}</ReactMarkdown>
                    </div>

                    <Grid  className={classes.paragraph}>
                        <article dangerouslySetInnerHTML={{__html: sections.documents || ''}}></article>
                    </Grid>

                </Grid>
                <Divider/>

                <Grid ref={refs.clinical} className={classes.container} >
                    <div className={classes.title}>
                        <img src={ClinicalIconBlue} alt={"clinical"} className={classes.icon}/>
                        <ReactMarkdown>{'# CLINICAL'}</ReactMarkdown>
                    </div>

                    <Grid ref={refs.clinicalData} className={classes.paragraph}>
                        <article dangerouslySetInnerHTML={{__html: sections.clinicalData || ''}}></article>
                        <iframe
                            title={"download clinical data"}
                            className={classes.frame}
                            src="https://scribehow.com/embed/Downloading_Clinical_Data_files__k8NGVIARRNGtvpP1JdNS0A"
                            width="100%" height="640" allowFullScreen frameBorder="0"></iframe>
                    </Grid>
                    <Divider/>

                    <Grid ref={refs.sourceFiles} className={classes.paragraph}>
                        <article dangerouslySetInnerHTML={{__html: sections.sourceFiles || ''}}></article>
                        <iframe
                            title={"upload source files"}
                            className={classes.frame}
                            src="https://scribehow.com/embed/Uploading_Clinical_Source_Files__glnx1I29SQmrkF_8PMhDtw"
                            width="100%" height="640" allowFullScreen frameBorder="0"></iframe>
                    </Grid>

                </Grid>
                <Divider/>

                <Grid className={classes.container} ref={refs.lab}>
                    <div className={classes.title}>
                        <img src={LabIconBlue} alt={"lab"} className={classes.icon}/>
                        <ReactMarkdown>{'# LAB'}</ReactMarkdown>
                    </div>

                    <Grid ref={refs.labTests} className={classes.paragraph}>
                        <article dangerouslySetInnerHTML={{__html: sections.labTests || ''}}></article>
                        <iframe
                            title={"bulk download lab tests"}
                            className={classes.frame}
                            src="https://scribehow.com/embed/How_to_Download_and_Submit_a_Lab_Request_Form__Asahg1CRQru65-0Bw9I-mA"
                            width="100%" height="640" allowFullScreen frameBorder="0"></iframe>
                    </Grid>
                    <Divider/>
                    <Grid ref={refs.metadataFiles} className={classes.paragraph}>
                        <article dangerouslySetInnerHTML={{__html: sections.metadataFiles || ''}}></article>
                    </Grid>
                    <Grid top={50}/>
                    <Divider/>
                    <Grid ref={refs.cliTool} className={classes.paragraph}>
                        <article dangerouslySetInnerHTML={{__html: sections.cliTool || ''}}></article>
                        <iframe
                            title={"Download from terminal"}
                            src="https://scribehow.com/embed/Download_with_miweb_cli__XbXuxL5hTRK8-lnYx0j8Sg"
                            width="100%" height="640" allowFullScreen frameBorder="0"></iframe>
                    </Grid>
                    <Grid top={50}/>
                    <Divider/>
                </Grid>

            </>
        );
}

export default UserGuide;
