import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Checkbox,
    Table, TableBody, TableCell, TableHead, TableRow,
    Typography, TableContainer, Paper
} from "@mui/material";
import React, {ReactElement, SyntheticEvent, useState} from "react";
import {ClinicalStudiesDataProvider, Column} from "../../../../resources/appManager/clinicalStudies/clinicalStudiesDataProvider";
import {RoleResource} from "../../../../resources/appManager/roles/roleResource";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {Access, AccessObjectClinical} from "../../../../resources/authorizations/access/access";
import {SourceFileDataProvider} from "../../../../resources/clinicalData/sourceFile/sourceFileDataProvider";
import {SourceFile, SourceFileFilter} from "../../../../resources/clinicalData/sourceFile/sourceFile";

interface AccordionProps {
    editEnabled: boolean;
    clinicalStudyId: string;
    clinicalStudyName: string;
    availableAccesses: Access[];
    selectedAccesses: Access[];
    onAccessesChange: (accesses: Access[]) => void;
}

export function StudyAccordion({availableAccesses, onAccessesChange, ...props}: AccordionProps): ReactElement {

    const [checkedAccesses, setCheckedAccesses] = useState<Access[]>(props.selectedAccesses)
    const [columns, setColumns] = useState<Column[]>([])
    const [files, setFiles] = useState<SourceFile[]>([])
    const [loaded, setLoaded] = useState<boolean>(false)

    const areAllSelected = (): boolean => {
        let allSelected = false;
        checkedAccesses.forEach(a => {
            const clinicalAccess = a.access as AccessObjectClinical
            if (clinicalAccess.column_id === '*') {
                allSelected = true
                return
            }
        })
        return allSelected
    }

    const [allSelected, setAllSelected] = useState<boolean>(areAllSelected())

    const isColumnSelected = (columnId: string): boolean => {
        let selected = false;
        checkedAccesses.forEach(a => {
            const clinicalAccess = a.access as AccessObjectClinical
            if (clinicalAccess.column_id === columnId) {
                selected = true;
            }
        })

        return selected
    }

    const handleAllSelection = (checked: boolean): void => {
        const newAccesses = checked ?
            [...availableAccesses.filter(a => {
                const clinicalAccess = a.access as AccessObjectClinical
                return clinicalAccess.column_id === '*'
            })] :
            []
        setCheckedAccesses(newAccesses)
        onAccessesChange(newAccesses)
        setAllSelected(checked)

    }

    const handleColumnSelection = (columnId: string, checked: boolean): void => {
        const newAccesses = checked ?
            [...checkedAccesses, ...availableAccesses.filter(a => {
                const clinicalAccess = a.access as AccessObjectClinical
                return clinicalAccess.column_id === columnId
            })] :
            [...checkedAccesses.filter(a => {
                const clinicalAccess = a.access as AccessObjectClinical
                return clinicalAccess.column_id !== columnId
            })]
        setCheckedAccesses(newAccesses)
        onAccessesChange(newAccesses)
    }

    const clinicalStudiesDataProvider = new ClinicalStudiesDataProvider();
    clinicalStudiesDataProvider.setAuthProvider(RoleResource.dataProvider?.getAuthProvider());

    const sourceFileDataProvider = new SourceFileDataProvider();
    sourceFileDataProvider.setAuthProvider(RoleResource.dataProvider?.getAuthProvider());

    const onAccordionChange = (event: SyntheticEvent, expanded: boolean) => {
        if (expanded && columns.length === 0) {
            Promise.all([
                clinicalStudiesDataProvider.getColumns(props.clinicalStudyId).then((columns) => {
                    return setColumns(columns);
                }),
                sourceFileDataProvider.pagedList(undefined, undefined, {
                    clinical_study: {id: props.clinicalStudyId},
                    status: "processed"
                } as SourceFileFilter).then((files) => {
                    return setFiles(files.data)
                })
            ]).then(() => {
                setLoaded(true)
            })

        }
    }

    const getColumnFiles = (col: string): string[] => {
        if (files.length === 0 || columns.length === 0) {
            return []
        }
        let colFiles:string[] = [];
        for(let c of columns){
            if (c.id === col) {
                const fileCols = c.memberships.map(c=>c.file_id)
                colFiles = files.filter(f=>fileCols.includes(f.id)).map(f=>f.tag_name)
                break;
            }
        }
        return colFiles
    }

    return (
        <Grid item lg={12}>
            <Accordion onChange={onAccordionChange}>
                <AccordionSummary style={{height: "3.5em", paddingBottom: "1em", marginTop: '1vh'}}>
                    <Grid container alignItems={"center"} justifyContent={"space-between"}>
                        <Typography>{props.clinicalStudyName} &nbsp;</Typography>
                        {checkedAccesses.length !== 0 &&
                            <CheckCircleIcon color={"success"} fontSize={"small"}/>
                        }
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        loaded?
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead sx={{backgroundColor: "secondary.main"}}>
                                        <TableRow>
                                            <TableCell>
                                                <Checkbox
                                                    disabled={!props.editEnabled}
                                                    indeterminate={!allSelected && checkedAccesses.length > 0 && checkedAccesses.length !== availableAccesses.length - 1}
                                                    onChange={v => {
                                                        handleAllSelection(v.target.checked)
                                                    }}
                                                    checked={allSelected}
                                                />
                                                <Typography variant={"gridData"}>Select all</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <strong>Column</strong>
                                            </TableCell>
                                            <TableCell>
                                                <strong>File IDs</strong>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            availableAccesses
                                                .filter((access) => !access.pattern.includes("*"))
                                                .map(access => {
                                                const clinicalAccess = access.access as AccessObjectClinical
                                                if (clinicalAccess.column_name === "*") {
                                                    return null
                                                }
                                                return (
                                                    <TableRow
                                                        key={clinicalAccess.clinical_study_id + clinicalAccess.column_id}>
                                                        <TableCell>
                                                            <Checkbox
                                                                id={access.pattern}
                                                                key={clinicalAccess.clinical_study_id + clinicalAccess.column_id}
                                                                onChange={(ev) => {
                                                                    handleColumnSelection(clinicalAccess.column_id, ev.target.checked)
                                                                }}
                                                                checked={
                                                                    allSelected || isColumnSelected(clinicalAccess.column_id)
                                                                }
                                                                disabled={allSelected || !props.editEnabled}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            {clinicalAccess.column_name}
                                                        </TableCell>
                                                        <TableCell style={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            width: '25rem'
                                                        }}>
                                                            {getColumnFiles(clinicalAccess.column_id).join(", ")}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            null
                    }

                </AccordionDetails>
            </Accordion>
        </Grid>
    )

}
