import {createStyles, makeStyles} from "@mui/styles";

const MfaFormStyle = makeStyles(() =>
    createStyles({
        mainContainer: {
            width: "100%",
            alignItems: "center",
            marginTop: "15vh"
        },
        otpInput: {
            width: '1.5em',
            height: '2em',
            marginLeft: '2%',
            borderRadius: '4px',
            border: '1px solid #d6d6d6',
            fontSize: '2em',
            textAlign: 'center'
        },
        otpContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
        mfaForm: {
            marginTop: "5vh !important",
            width: "100%"
        }
    }));

export {MfaFormStyle};
