import React from "react";
import {Box, Button, Divider, Grid, List, ListItem, Typography} from "@mui/material";
import {HyperlinkMeta} from "../../lib/meta/hyperlink/hyperlink";
import {SimpleTextMeta} from "../../lib/meta/simpleText/simpleText";
import {UserDataProvider} from "../../resources/appManager/users/userDataProvider";
import IDataProvider from "../../hoc/dataProvider/IDataProvider";
import {User} from "../../resources/appManager/users/user";
import {Document} from "../../resources/documents/document";

export const uploadedByLink = () => {
    return new HyperlinkMeta<Document>({
        childMeta: new SimpleTextMeta({isLink: true}),
        title: "User Information",
        dataProvider: new UserDataProvider(),
        modalConfig: {
            getAdditionalData: async (dataProvider?, data?: Document): Promise<User | undefined> => {
                const userId = data?.uploaded_by;
                if (!dataProvider || !userId) return;
                try {
                    const res = await (dataProvider as IDataProvider<User, User>).getById(userId);
                    return res as User;
                } catch (error: any) {
                    console.error('Failed to fetch users:', error);
                    return error;
                }
            },
            getBody: (doc?: Document, user?: User) => {
                if (!user || (user as any).response) {
                    if ((user as any).response?.status === 401) {
                        return (
                            <Typography ml={"1em"}>
                                <strong>ATTENTION! </strong>You don't have the necessary permissions to access to further information about this resource.
                            </Typography>
                        );
                    }
                    return <Typography ml={"1em"}>No information available. This resource may have been removed or not visibile on the platform.</Typography>;
                }
                return (
                    <>
                        <Box style={{
                            justifyContent: "center",
                            width: "auto",
                            height: "70vh",
                            overflow: "hidden"
                        }}>
                            <Box style={{
                                overflowY: "scroll",
                                justifyContent: "center",
                                height: "100%",
                                paddingTop: 0,
                                paddingRight: "1em"
                            }}>
                                <List>
                                    <ListItem style={{justifyContent: "space-between", height: "3em"}}>
                                        <div>User ID</div>
                                        <div>{user.id}</div>
                                    </ListItem>
                                    <Divider/>
                                    <ListItem style={{justifyContent: "space-between", height: "3em"}}>
                                        <div>Name</div>
                                        <div>{user.first_name} {user.last_name}</div>
                                    </ListItem>
                                    <Divider/>
                                    <ListItem style={{justifyContent: "space-between", height: "3em"}}>
                                        <div>Email</div>
                                        <div>{user.email}</div>
                                    </ListItem>
                                    <Divider/>
                                    <Grid item style={{marginTop: "2em"}}>
                                        <Button variant={"outlined"} href={`/appManager/users/${user.id}`}>View
                                            Detail</Button>
                                    </Grid>
                                </List>
                            </Box>
                        </Box>
                    </>
                );
            },
        }
    })
}
