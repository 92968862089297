import axios from "axios";
import {BASE_URL} from "../../../App";
import {DownloadRequest, LabTest, LabTestFilter} from "./labTest";
import {BaseDataProvider} from "../../baseDataProvider";

export class LabTestDataProvider extends BaseDataProvider<LabTest, LabTestFilter> {
    constructor() {
        super("lab_test_files")
    }

    /*downloadList = async (filter?: LabTestFilter): Promise<LabTest[]> => {
        return axios.get<LabTest[]>(`${BASE_URL}/lab_test_files/batch_download_async`, {headers: await this.getHeaders(), params: {...filter}}).then(r => {
            return r.data;
        })
    }*/

    createDownloadRequest = async (files_ids?: string, filter?: LabTestFilter, description?: string): Promise<DownloadRequest> => {
        return axios.put<DownloadRequest>(`${BASE_URL}/lab_test_files/download_requests`, {description: description},{headers: await this.getHeaders(), params: {files_ids, ...filter}}).then(r => {
            return r.data;
        })
    }

    /*
    download = async (id: string) => {
        return axios.get(`${BASE_URL}/lab_test_files/${id}/download_async`, { headers: await this.getHeaders()}).then(r => {
            return r.data
        });
    }*/

    upload = async (data: {file_name: string, file_size: number, fingerprint: number[], tag_name?: string}): Promise<LabTest> => {
        return axios.post<LabTest>(`${BASE_URL}/lab_test_files/upload_file`, data, {headers: await this.getHeaders()}).then(r => {
            return r.data
        });
    }

    directUpload = async (data: FormData): Promise<LabTest> => {
        return axios.post(`${BASE_URL}/lab_test_files/upload_file`, data, {headers: await this.getHeaders()}).then(r => {
            return r.data
        });
    }

    hardDelete = async (id: string, hard_delete: boolean, reason?: string): Promise<boolean> => {
        return axios.put(`${BASE_URL}/lab_test_files/${id}/delete`, {hard_delete: hard_delete, reason: reason},{ headers: await this.getHeaders()})
            .then(r => { return r.data; });
    }

    getDetails = async (id: string) => {
        return axios.get<LabTest>(`${BASE_URL}/lab_test_files/${id}/details`, {headers: await this.getHeaders()})
            .then(r => {
                return r.data.data
            })
    }

}
