import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {Button, Grid, Typography} from "@mui/material";
import React, {ReactElement} from "react";
import {useNavigate} from "react-router-dom";
import {useResourceContext} from "../../../context/ResourceContext";
import {DefaultStyles} from "./defaultStyles";
import {DefaultActionsContainer} from "../../../components/actions/actionsContainer";

interface DefaultShowLayoutProps<T> {
    data?: T;
    children: ReactElement;
    titleVariant?: "pageTitle" | "pageTitlePrefix";
    getTitle?: (data?: T) => ReactElement;
    description?: string;
    previousPage?: string | number;
    onActionCompleted?: (data?: any) => void;
    error?: number
}

export const DefaultShowLayout = <T, V>({data, ...props}: DefaultShowLayoutProps<T>): ReactElement => {
    const classes = DefaultStyles({error: props.error});
    const resourceContext = useResourceContext<T, V>();
    const navigate = useNavigate();

    const resource = resourceContext.getConfig();

    const onActionCompleted = (updatedData: any) => {
        if (props.onActionCompleted) {
            props.onActionCompleted(updatedData);
        }
    }

    // @ts-ignore
    return (
        <>
            <Grid container className={classes.mainContainer}>
                {props.previousPage &&
                    //@ts-ignore
                    <Button onClick={() => navigate(props.previousPage)}>
                        <KeyboardBackspaceIcon color={"primary"}></KeyboardBackspaceIcon>
                        <Typography variant={"backButtonText"}>Go back</Typography>
                    </Button>
                }
                <Grid container direction={"column"}>
                    <Grid item>
                        <Grid container direction={"row"} className={classes.titleContainer}>
                            <Grid item display={"flex"} alignItems={"center"}>
                                <Typography pr={1} variant={props.titleVariant ?? "pageTitlePrefix" }>{resource?.label}</Typography>
                                <>
                                    {props.getTitle ? (data) && props.getTitle(data) : ""}
                                </>
                            </Grid>
                            {data &&
                                <DefaultActionsContainer
                                    resource={resource || []}
                                    data={data}
                                    onActionCompleted={onActionCompleted}
                                />
                            }
                        </Grid>
                        <Grid className={classes.description}>
                            <Typography variant={"pageSubtitle"}>{props.description && props.description}</Typography>
                        </Grid>
                    </Grid>
                {
                    props.children
                }
                </Grid>
            </Grid>
        </>
    )
}
