import {Box, Grid} from '@mui/material';
import React, { ReactElement } from 'react';
import {LayoutStyles} from "./layoutStyles";

interface LoginProps {
    children?: ReactElement
}

function AuthLayout(props: LoginProps) {
    const classes = LayoutStyles();

    return (
        <>
            <Grid container direction={'row'} className={classes.backgroundContainer}>
                <Grid item md={6}>
                    <Box className={classes.loginBox}/>
                </Grid>
                <Grid item md={6}>
                    <Grid container direction={'column'} className={classes.loginContainer}>
                        <Grid item className={classes.loginChildren}>
                            {props.children}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AuthLayout;
