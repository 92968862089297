import axios from "axios";
import { BASE_URL } from "../../../App";
import {PagedResponse} from "../../../types/pagedResponse";
import {ClinicalData} from "./clinicalData";
import {BaseDataProvider} from "../../baseDataProvider";

export class ClinicalDataProvider extends BaseDataProvider<ClinicalData, ClinicalData> {

    constructor(){
        super("clinical_data")
    }

    download = async (payload: { clinical_study_id: string,format:string, columns: Record<string, string[]>, }) => {

        return axios.post(`${BASE_URL}/clinical_data/download_table`,payload,{
            headers: await this.getHeaders({ 'Accept': 'application/octet-stream' }),
            responseType: "blob"
        }).then(r => {
            return r.data;
        });
    }

    pagedList = async (page_size?: number, page_token?: string, filter?: ClinicalData): Promise<{ data: ClinicalData[]; page_token: string }> => {
        return axios.get(`${BASE_URL}/clinical_data/table`, { headers: await this.getHeaders(), params: { page_size, page_token, ...filter } }).then(r => {
            return {
                page_token: r.data.page_token,
                data: r.data.rows,
            };
        });
    }

    getDetails = async <R extends PagedResponse<ClinicalData>>(clinicalStudyId: string, patientId: string): Promise<{ data: ClinicalData[]; page_token: any } | R> => {
        return axios.get(`${BASE_URL}/clinical_data/table_details/${clinicalStudyId}/${patientId}`, { headers: await this.getHeaders()}).then(r => {
            return {
                page_token: r.data.page_token,
                data: r.data.details,
            };
        });
    }
}
