import {Grid, TextField, Tooltip, Typography} from "@mui/material";
import {ListMeta, MetaField} from "../../types/meta";
import React, {ReactElement} from "react";
import {
    Control,
    FieldValues,
    Path, RegisterOptions,
    useController
} from "react-hook-form";
import { Field } from "../../types/field";

interface SimpleTextProps<T> {
    label?: string;
    data?: string | number | boolean;
    icon?: ((data?: T) => ReactElement) | undefined;
    field?: Field<T>;
    isLink?: boolean;
    valuer?: (data: T) => (string | ReactElement);
}

function SimpleTextShow<T>({label, data, icon, isLink, valuer}: SimpleTextProps<T>): ReactElement {
    return (
            <Grid container justifyContent={"space-between"}>
                <>
            {icon && icon}
            <Grid item>
                    <Typography variant={"dataTitle"}>
                        {label}
                    </Typography>
            </Grid>
            <Grid item paddingBottom={"1em"}>
                {valuer ?
                    <Typography variant={"body1"} fontWeight={isLink? 600 : 400}>
                        {valuer(data as T)}
                    </Typography>
                    :
                    <Tooltip title={data && data.toString()} placement={"top"}>
                        <Typography variant={"body1"} fontWeight={isLink? 600 : 400}>
                            {!data ? "--" : data.toString()}
                        </Typography>
                    </Tooltip>
                }
            </Grid>
                </>
            </Grid>
    )
}

function SimpleTextList<T>(data: T | undefined, {icon, field, isLink, valuer}: SimpleTextProps<T>) {
    return <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>{icon && icon(data!)}</div>
                <Tooltip title={data && field && data[field.id] as string} placement={"top"}>
                    <Typography noWrap variant={"body1"} fontWeight={isLink? 600 : 400}>{
                        data ? valuer ? valuer(data) :
                         field && data[field.id] && data[field.id] !== "" ? data[field.id] as string : "--" : "--"}</Typography>
                </Tooltip>
            </div>
}

function SimpleTextInput<T>(
    formControl: Control,
    name: Path<FieldValues>,
    validationOptions?: RegisterOptions
): React.ReactElement {
    const {field, fieldState} = useController({
        name: name,
        control: formControl,
        rules: validationOptions,
    });
    
    return (
        <>
            <TextField
                {...field}
                style={{width:"20em"}}
                error={fieldState.error !== undefined}
                helperText={fieldState.error?.message}
            />
        </>
    );
}

export class SimpleTextMeta<T> implements MetaField<T> {

    icon?: ((data?: T) => ReactElement) | undefined;
    isLink?: boolean;
    valuer?: (data: T) => (string | ReactElement);

    constructor(props: SimpleTextProps<T>) {
        this.icon = props.icon || undefined;
        this.isLink = props.isLink;
        this.valuer = props.valuer;
    }

    getFilterComponent?: ((handler?: ((data: T) => void) | undefined) => React.FC<{}>) | undefined;

    getListMeta(): ListMeta {
        return {
            type: "string",
            value: (arg: any) => arg,
        };
    }

    getFormComponent(
        control: Control,
        name: Path<FieldValues>,
        field: Field<T>): ReactElement {
        return SimpleTextInput<T>(control, name, field.validationOptions);
    }

    getShowComponent(data: T | undefined, field: Field<T>) {
        return () => {
            return SimpleTextShow({label: field.label, data: data ? data[field.id] as string : '', isLink: this.isLink, valuer: this.valuer})
        }
    }
    getListComponent(data: T | undefined, field: Field<T>) {
        return () => {
            return SimpleTextList(data, {icon: this.icon, field: field, isLink: this.isLink, valuer: this.valuer});
        }
    }

}
