import {Button, ListItemButton, Typography} from "@mui/material";
import React, {FC, ReactElement} from "react";
import {DefaultModal} from "../components/modal/defaultModal";
import {useModalContext} from "../context/ModalContext";
import {useResourceContext} from "../context/ResourceContext";
import {Action, ActionType} from "../types/action";
import {ChoiceAction} from "../types/choiceAction";
import {Field} from "../types/field";
import {ResourceConfig} from "../types/resource";
import {ActionProps, ActionStyle} from "./actionProps";
import {DefaultActionForm} from "./defaultActionForm";
import IDataProvider from "../../hoc/dataProvider/IDataProvider";
import {DefaultSideModal} from "../components/modal/defaultSideModal";
import {IAuthenticationProvider} from "../types/auth";
import {CustomAuthData} from "../../auth/provider";
import {AuthorizationContextValue, useAuthorizationContext} from "../context/AuthorizationContext";
import {FeedbackContextValue, useFeedbackContext} from "../context/FeedbackContext";
import {useAuthenticationContext} from "../context/AuthenticationContext";

export class SingleArgsAction<ActionArgument extends Record<string, any>, Resource> implements Action<ActionArgument, Resource> {
    id: string;
    label: string;
    type: ActionType;
    primary?: (res?: Resource[] | Resource) => boolean;
    primaryActionColor?: 'primary' | 'secondary' | 'error' | 'success' | 'warning';
    fields: Field<ActionArgument>[];
    choiceAction: ChoiceAction;
    modalDescription?: (data?: Resource) => string;
    hidden: (res?: Resource | Resource[], authNCtx?: AuthorizationContextValue) => boolean;
    handler?: (res: (Resource[] | Resource), dataProvider?: IDataProvider<any, any>, authProvider?: IAuthenticationProvider<CustomAuthData>, feedbackCtx?: FeedbackContextValue, formData?: string, args?: (ActionArgument | undefined)) => ((Promise<boolean | Resource | undefined>) | undefined);
    actionStyle?: ActionStyle;
    modalType?: "defaultModal" | "sideModal";
    customModal?: ReactElement | ((data?: Resource | Object) => ReactElement);
    onComplete?: () => void;
    icon?: ReactElement;

    constructor(props: ActionProps<ActionArgument, Resource>) {
        this.id = props.id;
        this.label = props.label;
        this.type = props.actionType;
        this.hidden = props.hidden ? props.hidden : () => false;
        this.primary = props.primary;
        this.primaryActionColor = props.primaryActionColor;
        this.fields = props.fields || [];
        this.choiceAction = props.choiceAction || {} as ChoiceAction;
        this.actionStyle = props.actionStyle;
        this.handler = props.handler;
        this.modalDescription = props.modalDescription;
        this.modalType = props.modalType;
        this.customModal = props.customModal;
        this.onComplete = props.onComplete;
        this.icon = props.icon;
    }

    getActionComponent(res: Resource | Resource[] | undefined): FC {

        return () => {
            const resConfig = useResourceContext().getConfig() as ResourceConfig<Resource, {}>;
            const modalCtx = useModalContext();
            const authNCtx = useAuthorizationContext();
            const authCtx = useAuthenticationContext<CustomAuthData>();
            const feedbackCtx = useFeedbackContext();

            if (!modalCtx || this.hidden(res, authNCtx)) return <></>;

            const handleAction = (data: ActionArgument): Promise<Resource | boolean | undefined> | undefined => {
                const pk = resConfig.primaryKey;
                const resId = (res as Resource)[pk] as any;
                this.onComplete && this.onComplete();
                return this.handler && res ? this.handler(res, resConfig.dataProvider, authCtx.getProvider(),feedbackCtx, data.id) : resConfig.dataProvider?.actionEdit(this.id, resId, data)
            }

            const openModal = (data?: Resource | Object) => {
                const modalComponent = typeof this.customModal === "function" ? this.customModal(data) : this.customModal;

                if (this.modalType === "sideModal") {
                    modalCtx?.openSideModal({
                        onClose: () => modalCtx.closeSideModal(),
                        anchor: 'right',
                        component: modalComponent as ReactElement ??
                            <DefaultSideModal
                                title={this.label}
                                handleClose={modalCtx?.closeSideModal}
                            >
                                <DefaultActionForm
                                    res={res as ActionArgument}
                                    fields={this.fields}
                                    choiceAction={this.choiceAction}
                                    handleSubmit={handleAction}
                                    formStyle={this.actionStyle}
                                />
                            </DefaultSideModal>
                    });
                } else {
                    modalCtx?.openModal({
                        onClose: () => modalCtx.closeModal(),
                        component: modalComponent as ReactElement ??
                            <DefaultModal
                                title={this.label}
                                description={this.modalDescription && this.modalDescription(res as Resource)}
                            >
                                <DefaultActionForm
                                    res={res as ActionArgument}
                                    fields={this.fields}
                                    choiceAction={this.choiceAction}
                                    handleSubmit={handleAction}
                                    formStyle={this.actionStyle}
                                />
                            </DefaultModal>
                    });
                }
            };

            return (
                <>
                    {this.primary && this.primary(res) ?
                        <Button
                            variant={"outlined"}
                            sx={{justifyContent: "right"}}
                            color={this.primaryActionColor ?? 'primary'}
                            onClick={() => {
                                openModal(res as Resource)
                            }}
                        >
                            {this.icon}
                            {this.label}
                        </Button>
                        :
                        <ListItemButton
                            sx={{justifyContent: "right"}}
                            onClick={() => openModal(res as Resource ?? undefined)}
                        >
                            <Typography variant={"smallButtonText"}
                                        color={this.actionStyle?.buttonColor || 'primary'}>{this.label.toUpperCase()}</Typography>
                        </ListItemButton>
                    }

                </>
            );
        }
    }

}
