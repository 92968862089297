import {Audit} from "../../appManager/audit/audit";
import {ClinicalStudy} from "../../appManager/clinicalStudies/clinicalStudy";


export enum MetadataFileStatus {
    PROCESSED = "processed",
    PROCESSING = "processing",
    ERROR = "error",
    CANCELED = "canceled"
}

export interface MetadataFile {
    id: string;
    name: string;
    tag_name: string;
    created_at: Date;
    updated_at: Date;
    uploaded_by: string;
    clinical_study_id: string;
    clinical_study_name: string;
    laboratory_id: string;
    laboratory_name: string;
    status: MetadataFileStatus;
    error: string;
    log: Audit[];

    file_id: string; // to check
}
export interface MetadataFileFilter {
    q: string,
    clinical_study: ClinicalStudy,
    clinical_study_id: string,
    status: string,
    source_type: string,
}
