import React, {ReactElement, useEffect, useState} from "react";
import {Button, Grid, Menu} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {Action, ActionType} from "../../types/action";
import {useNavigate} from "react-router-dom";
import {ResourceConfig} from "../../types/resource";
import {ActionsStyles} from "./actionsStyles";
import {useAuthorizationContext} from "../../context/AuthorizationContext";

interface DefaultActionsContainerProps<T> {
    data: T| T[] | undefined;
    resource: ResourceConfig<T, any>;
    onActionCompleted: (data?: any) => void;
}

export function DefaultActionsContainer<T>({resource, data, ...props}: DefaultActionsContainerProps<T>): ReactElement {
    const classes = ActionsStyles()
    const navigate = useNavigate()

    const authNCtx = useAuthorizationContext();

    const [actionsAnchor, setActionsAnchor] = useState<null | HTMLElement>(null)
    const actionsMenuOpen = Boolean(actionsAnchor);

    const [activeAction, setActiveAction] = useState<Action<any, T>>();

    const availableActions = (resource.actions || []).filter((action: Action<any, T>) => {
        return (action.type === ActionType.Single || action.type === ActionType.Redirect) && !action.hidden(data, authNCtx)
    })

    useEffect(() => {
        if (activeAction) {
            if (activeAction.id === "delete") {
                activeAction.onComplete = () => {
                    setTimeout(() => {
                        return navigate(-1)
                    }, 200)
                };
            } else {
                activeAction.onComplete = () => {
                    return props.onActionCompleted(data);
                }
            }
        }
    }, [activeAction, data]);

    const primaryActions = availableActions.filter(a => a.primary && a.primary(data))
    const subMenuActions = availableActions.filter(a => (!a.primary || !a.primary(data)))

    return <Grid container className={classes.container}>
        {
            primaryActions
                .filter((a) => a.type === ActionType.Redirect)
                .map((a, index) => {
                    return (
                            <Button key={index} onClick={() => navigate(a.redirectionRoute as unknown as number)}>
                                {a.icon}{a.label}
                            </Button>
                        )
                })
        }
        {resource.edit !== undefined &&
            <Button
                variant="outlined"
                onClick={() => {
                    navigate('edit');
                }}>
                <BorderColorIcon></BorderColorIcon>
                Edit
            </Button>
        }
        {
            primaryActions
                .filter((a) => a.type !== ActionType.Redirect)
                .map((a, index) => {
                    return (
                        <Grid onClick={() => setActiveAction(a)} key={index}>
                            {a.getActionComponent(data as T[])({})}
                        </Grid>
                    )
                })
        }
        {subMenuActions.length !== 0 &&
            <>
                <Button variant={"outlined"}
                        onClick={(ev) => setActionsAnchor(ev.currentTarget)}>
                    <MoreHorizIcon/>
                </Button>
                <Menu
                    open={actionsMenuOpen}
                    anchorEl={actionsAnchor}
                    onClose={() => setActionsAnchor(null)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {
                        subMenuActions
                            .map((action, index) => {
                                return <Grid onClick={() => {
                                    setActiveAction(action);
                                    return setActionsAnchor(null)
                                }}
                                             key={index}>
                                    {action.getActionComponent(data as T[] | undefined)({})}
                                </Grid>
                            })
                    }
                </Menu>
            </>
        }
    </Grid>
}
