import {Filters} from "../../../lib/types/filter";
import SimpleTextFilter from "../../../lib/components/filters/singleFilters/simpleText/simpleTextFilter";
import GenericSearch from "../../genericSearch";

export const clinicalStudyFilter: Filters<GenericSearch> = {
    filters: [
        {
            field:  {
                id: "q",
                label:"Search",
                meta: {
                    getFormComponent: () => {
                        return <></>
                    },
                    getFilterComponent: (handleFilter: any, value: any) => {
                        return () => (
                            <SimpleTextFilter fieldLabel={"Search"}
                                              handleResult={handleFilter}
                                              value={value}/>
                        )
                    },
                    getShowComponent: () => () => <></>
                },
            },
            hidden: () => false,
            main: true
        }
    ]
}
