import axios from "axios";
import { BASE_URL } from "../../../App";
import { BaseDataProvider } from "../../baseDataProvider";
import {Role} from "./role";
import {Access} from "../../authorizations/access/access";
import {PagedResponse} from "../../../types/pagedResponse";
import GenericSearch from "../../genericSearch";

export class RolesDataProvider extends BaseDataProvider<Role, GenericSearch> {

    constructor(){
        super('groups');
    }

    getAllAccesses = async (id?: string): Promise<{data: string[], next_page_token: string}> => {
        return axios.get(`${BASE_URL}/groups/${id}/access`, { headers: await this.getHeaders({"Accept": "application/json"})}).then(r => {
            return {
                data: r.data.accesses,
                next_page_token: r.data.next_page_token
            };
        });
    }

    assignAccesses = async (id: string, data: string[]): Promise<Access[]> => {
        return axios.post<Access[]>(`${BASE_URL}/groups/${id}/access`, {accesses: data}, { headers: await this.getHeaders({"Accept": "application/json"})})
            .then(r => {return r.data;});
    }

    removeAccesses = async (id: string, data: string[]): Promise<Access[]> => {
        return axios.put(`${BASE_URL}/groups/${id}/access_remove`, {accesses: data}, { headers: await this.getHeaders({"Accept": "application/json"})})
            .then(r => {return r.data;});
    }

    getPermissions =  async <R extends PagedResponse<any>>(id: string, page_size?: number, page_token?: string): Promise<{ data: string[], page_token: string } | R> => {
        return axios.get(`${BASE_URL}/groups/${id}/permission`, { headers: await this.getHeaders({"Accept": "application/json"}), params: { page_size, page_token } }).then(r => {
            return {
                page_token: r.data.next_page_token,
                data: r.data.permissions,
            };
        });
    }

    assignPermissions = async (id: string, data: string[]): Promise<string[]> => {
        return axios.post<string[]>(`${BASE_URL}/groups/${id}/permission`, {permissions: data} , { headers: await this.getHeaders({"Accept": "application/json"})})
            .then(r => {return r.data;});
    }

    removePermissions = async (id: string, data: string[]): Promise<Role> => {
        return axios.put<Role>(`${BASE_URL}/groups/${id}/permission_delete`, {permissions: data}, { headers: await this.getHeaders({"Accept": "application/json"})})
            .then(r => {return r.data;});
    }

    hardDelete = async (id: string, reason?: string): Promise<boolean> => {
        return axios.put(`${BASE_URL}/groups/${id}/delete`, {reason: reason},{ headers: await this.getHeaders()})
            .then(r => { return r.data; });
    }

}
