import {GroupConfig} from "../lib/types/group";
import AuditIcon from "../images/icons/tabs/audit.svg";

export const auditGroup: GroupConfig = {
    id: 'audit',
    label: 'Audit',
    defaultResource: () => "audits",
    image: AuditIcon,
    hidden: false
}
