import {createStyles, makeStyles} from "@mui/styles";

const SidebarStyles = makeStyles((theme: any) =>
    createStyles({
    wrapper: {
        paddingTop: "3.35em",
        height: "100vh",
        minWidth: "6vw",
        width: "6vw",
        backgroundColor: theme.palette.primary.main
    },
    tab: {
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "2em",
        paddingBottom: "1.5em"
    },
    tabContainer: {
        width: 110,
        justifyContent: "center"
    },
    selectedTab: {
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "2em",
        paddingBottom: "1.5em",
        backgroundColor: theme.palette.primary.hover,
        borderLeft: "0.3em solid #FFFFFF",
        cursor: "pointer"
    },
    tabTitle: {
        paddingTop: "1em"
    },
    selectedTabTitle: {
        paddingTop: "1em",
        fontWeight: 600 + "!important"
    }
}));

export {SidebarStyles};
