import React, {ReactElement, useEffect, useState} from "react";
import {Document} from "../../resources/documents/document";
import {Divider, Grid, List, ListItem, Typography} from "@mui/material";
import NavTabs from "../../lib/components/tabs/NavTabs";
import {DocumentsStyles} from "./documentsStyles";
import {Audit, AuditFilter} from "../../resources/appManager/audit/audit";
import {OneToMany} from "../../lib/meta/oneToMany/oneToMany";
import {AuditResource} from "../../resources/appManager/audit/auditResource";
import {auditFilter} from "../../resources/appManager/audit/auditFilter";
import {DateMeta} from "../../lib/meta/date/date";
import {SimpleTextMeta} from "../../lib/meta/simpleText/simpleText";
import {Field} from "../../lib/types/field";
import {uploadedByLink} from "./hyperlinks";
import {useAuthorizationContext} from "../../lib/context/AuthorizationContext";
import {useDocumentsDataProvider} from "./providerHook";

interface DocumentsDetailViewProps {
    data: Document
}

export const DocumentsDetailView = ({data}: DocumentsDetailViewProps): ReactElement => {
    const classes = DocumentsStyles();
    const authNCtx = useAuthorizationContext();
    const documentsDataProvider = useDocumentsDataProvider();
    const roleId = authNCtx.getRoleId();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    useEffect(() => {
        documentsDataProvider.getDocumentAccess(data.id).then((acc) => {
            if (acc.filter(a => a.access?.pattern.includes("*") && a.group_info.id === roleId).length !== 0) {
                setIsAdmin(true);
            }
        })
    }, [])

    const isDisabled = () => {
        return !authNCtx?.hasAccess("appManager", "audit") && !isAdmin
    }

    const AuditList = new OneToMany<Document, Audit, AuditFilter>({
        resource: AuditResource,
        defaultFilter: (data) => {
            return {object_id: data.id, object: "DOCUMENT"} as AuditFilter
        },
        otherFilters: auditFilter.filters.filter((f) => f.field.id !== "object"),
        fields: [
            {
                id: "created_at",
                label: "Date",
                meta: new DateMeta({})
            },
            {
                id: "action",
                label: "Action",
                meta: new SimpleTextMeta({})
            },
            {
                id: "user_id",
                label: "User ID",
                meta: new SimpleTextMeta({})
            },
            {
                id: "object",
                label: "Target",
                meta: new SimpleTextMeta({})
            }
        ],
        pageSize: 10
    }).getShowComponent(data, {id: "log", label: "log"});


    const bytes = parseFloat(data?.size.toString());

    const getDocumentSize = () => {
        if (bytes >= Math.pow(2, 40)) {
            const tb = bytes / Math.pow(2, 40);
            return tb.toFixed(2) + " TB";
        } else if (bytes >= Math.pow(2, 30)) {
            const gb = bytes / Math.pow(2, 30);
            return gb.toFixed(2) + " GB";
        } else if (bytes >= Math.pow(2, 20)) {
            const mb = bytes / Math.pow(2, 20);
            return mb.toFixed(2) + " MB";
        } else if (bytes >= Math.pow(2, 10)) {
            const kb = bytes / Math.pow(2, 10);
            return kb.toFixed(2) + " KB";
        } else {
            return bytes.toString() + " bytes";
        }
    }

    const membersLink: Field<Document> = {id: "uploaded_by", label: "Created by", meta: uploadedByLink()}
    const UploadedByShowComponent = membersLink.meta?.getShowComponent(data, membersLink)

    return (<>
            <Grid item className={classes.tabDataContainer}>
                <NavTabs
                    tabs={
                    [
                        {
                            label: "Data",
                            children: <>
                                        <List>
                                            <ListItem className={classes.listItem}>
                                                <Typography variant={"dataTitle"}>Name</Typography>
                                                <Typography variant={"data"}>{`${data?.tag_name}` ?? "--"}</Typography>
                                            </ListItem>
                                            <Divider/>
                                            <ListItem className={classes.listItemLink}>
                                                {UploadedByShowComponent && UploadedByShowComponent({})}
                                            </ListItem>
                                            <Divider/>
                                            <ListItem className={classes.listItem}>
                                                <Typography variant={"dataTitle"}>Upload date</Typography>
                                                <Typography variant={"data"}>{data?.created_at.toString() ?? "--"}</Typography>
                                            </ListItem>
                                            <Divider/>
                                            <ListItem className={classes.listItem}>
                                                <Typography variant={"dataTitle"}>Size</Typography>
                                                <Typography variant={"data"}>{getDocumentSize()}</Typography>
                                            </ListItem>
                                        </List>
                                      </>
                        },
                        {
                            label: "Log",
                            isDisabled: isDisabled(),
                            children: <>
                                {<AuditList/>}
                                </>
                        }
                    ]
                    }
                ></NavTabs>
            </Grid>
            </>
    )
}
