import React from "react";
import SimpleTextFilter from "../../../lib/components/filters/singleFilters/simpleText/simpleTextFilter";
import {LabTestFilter} from "./labTest";
import {Filters} from "../../../lib/types/filter";
import {SelectFilter as AutocompleteSelectFilter} from "../../../lib/meta/select/autocompleteSelect";
import {ClinicalStudiesResource} from "../../appManager/clinicalStudies/clinicalStudyResource";
import {ClinicalStudy} from "../../appManager/clinicalStudies/clinicalStudy";
import {Laboratory} from "../../appManager/laboratory/laboratory";
import {LaboratoryResource} from "../../appManager/laboratory/laboratoryResource";
import {StaticSelectFilter} from "../../../lib/meta/select/staticSelect";

export const labTestFilter: Filters<LabTestFilter> = {
    filters: [{
                field: {
                    id: "clinical_study_id",
                    label: "Clinical Study",
                    meta: {
                        getFormComponent: () => <></>,
                        getFilterComponent: (handleFilter: any, res: any, value?: any) => {
                            return () => (
                                <AutocompleteSelectFilter
                                    label={"Select Clinical Study"}
                                    resource={ClinicalStudiesResource}
                                    handleSelect={handleFilter}
                                    valuer={(clinicalStudy: ClinicalStudy) => clinicalStudy.name.toString()}
                                    selectedRecord={value}
                                    clearable={false}
                                />
                            )
                        },
                        getShowComponent: () => () => <></>
                    }
                },
                hidden: () => true,
                main: false
            },
            {
                field: {
                    id: "laboratory_id",
                    label: "Laboratory",
                    meta: {
                        getFormComponent: () => <></>,
                        getFilterComponent: (handleFilter: any, res: any, value?: any) => {
                            return () => (
                                <AutocompleteSelectFilter
                                    label={"Select Laboratory"}
                                    resource={LaboratoryResource}
                                    handleSelect={handleFilter}
                                    valuer={(lab: Laboratory) => lab.name.toString()}
                                    selectedRecord={value}
                                    clearable={false}
                                />
                            )
                        },
                        getShowComponent: () => () => <></>
                    }
                },
                hidden: () => true,
                main: false
            },
            {
                field: {
                    id: "status",
                    label: "Status",
                    meta: {
                        getFormComponent: () => <></>,
                        getFilterComponent: (handleFilter: any, res: any, value?: any) => {
                            return () => (
                                <StaticSelectFilter label={"Status"}
                                                    filterId={value}
                                                    handleSelect={handleFilter}
                                                    selectedValue={value}
                                                    options={{associated: "ASSOCIATED", notAssociated: "NOT ASSOCIATED"}}
                                />
                            )
                        },
                        getShowComponent: () => () => <></>
                    }
                },
                hidden: () => true,
                main: false
            },
            {
                field:  {
                    id: "q",
                    label:"Search",
                    meta: {
                        getFormComponent: () => {
                            return <></>
                        },
                        getFilterComponent: (handleFilter: any, value: any) => {
                            return () => (
                                <SimpleTextFilter fieldLabel={"Search"}
                                                  handleResult={handleFilter}
                                                  value={value}/>
                            )
                        },
                        getShowComponent: () => () => <></>
                    },
                },
                hidden: () => false,
                main: true
            }
    ]
}
