import {createStyles, makeStyles} from "@mui/styles";

const ActionsStyles = makeStyles(() =>
    createStyles({
        container: {
            width: "fit-content !important",
            justifyContent: "space-between !important"
        }
    }));

export {ActionsStyles};
