import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography, TableContainer, Paper, Checkbox, Grid, Fade
} from "@mui/material";
import {Field} from '../../types/field';
import {SimpleTextMeta} from "../../meta/simpleText/simpleText";
import React, {MutableRefObject, useEffect, useMemo, useRef, useState} from "react";

export interface TableProps<R> {
    showFields: Field<R>[];
    data: R[];
    pageSize: number;
    primaryKey: keyof R;
    checkboxSelection?: boolean;
    disableSelectionOnClick?: boolean;
    isRowSelectable?: (row: R) => boolean;
    handleViewDetail?: (row: R) => void;
    onDataSelected?: (rows: R[]) => void
    getAllData?: (data: boolean) => void
    loadMore?: {
        loadMoreCallback: () => void,
        disableLoadMore: boolean,
        loadMoreText: () => string,
    }

}

function ListTable<R>({data, loadMore, showFields, ...props}: TableProps<R>) {
    const [selected, setSelected] = useState<R[]>([])

    const loadMoreRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>

    const isLoadMoreShowing = useIsInViewport(loadMoreRef)

    const [allChecked, setAllChecked] = useState<boolean>(false)

    useEffect(() => {
        props.getAllData && props.getAllData(allChecked);
    }, [allChecked, data])

    const isSelected = (v: R): boolean => {
        return selected.includes(v)
    }

    const onSelectAll = (data: R[]): void => {
        let newSelection;
        if (!data.some((row) => props.isRowSelectable && !props.isRowSelectable(row))) {
            newSelection = selected.length === data.length ? [] : [...data]
        } else {
            newSelection = data.filter((d) => props.isRowSelectable && props.isRowSelectable(d))
        }
        setAllChecked(!allChecked);
        if (!allChecked) {
            if (props.onDataSelected) {
                props.onDataSelected(newSelection)
            }
            setSelected(newSelection);
        } else {
            if (props.onDataSelected) {
                props.onDataSelected([])
            }
            setSelected([])
        }

    }

    const onRowSelected = (d: R): void => {
        const newSelection = isSelected(d) ?
            [...selected.filter(s => s[props.primaryKey] !== d[props.primaryKey])] :
            [...selected, d]
        if (props.onDataSelected) {
            props.onDataSelected(newSelection)
        }
        setSelected(newSelection)
    }

    useEffect(() => {
        if (isLoadMoreShowing && loadMore && !loadMore.disableLoadMore) {
            setTimeout(() => {
                loadMore?.loadMoreCallback()
            }, 500)
        }
    }, [isLoadMoreShowing, loadMore])

    return (
        <Box sx={{display: 'flex'}}>
            <TableContainer component={Paper} sx={{height: 'auto', mb: 10, overflowX: "hidden"}}>
                <Table sx={{tableLayout: "fixed"}}>
                    <TableHead sx={{pt: 2, backgroundColor: "secondary.main"}}>
                        <TableRow>
                            {
                                props.checkboxSelection &&
                                <TableCell padding="normal">
                                    <Checkbox
                                        color="primary"
                                        indeterminate={selected.length > 0 && selected.length < data.length}
                                        disabled={data.length === 0}
                                        checked={data.length === 0 ? false : allChecked ? true : selected.length === data.length}
                                        onChange={() => onSelectAll(data)}
                                    />
                                    Select all
                                </TableCell>
                            }
                            {
                                showFields.map((f, index) => {
                                    return <TableCell key={index} variant={"head"}>
                                        <Typography
                                            noWrap
                                            variant={"body1"}><strong>{f.label}</strong></Typography>
                                    </TableCell>
                                })
                            }
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data?.map((d, index) => {
                                return (
                                    <Fade in={true} timeout={300} key={index}>
                                        <TableRow key={index}>
                                            {
                                                props.checkboxSelection &&
                                                <TableCell padding="normal">
                                                    { props.isRowSelectable && props.isRowSelectable(d) &&
                                                        <Checkbox
                                                                color="primary"
                                                                checked={allChecked ? true : isSelected(d)}
                                                                disabled={allChecked}
                                                                onChange={() => {
                                                                    onRowSelected(d)
                                                                }}
                                                                inputProps={{
                                                                    'aria-label': 'select all desserts',
                                                                }}
                                                        />
                                                        }
                                                </TableCell>
                                            }
                                            {
                                                showFields.map((f, index) => {
                                                    const ShowComponent = f.meta && f.meta.getListComponent
                                                        ? f.meta.getListComponent(d, f) :
                                                        (new SimpleTextMeta<R>({}).getListComponent(d, f))
                                                    return <TableCell key={index}>
                                                                <ShowComponent/>
                                                           </TableCell>
                                                })
                                            }
                                            {
                                                props.handleViewDetail &&
                                                <TableCell align={"center"}>
                                                    <SearchIcon color={"primary"} sx={{cursor: "pointer"}}
                                                                onClick={() => {
                                                                    if (props.handleViewDetail) {
                                                                        props.handleViewDetail(d)
                                                                    }
                                                                }}/>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    </Fade>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                {
                    loadMore &&
                    <Grid container>
                        <Grid item lg={12} display={"flex"} justifyContent={"center"} ref={loadMoreRef}>
                            <Button disabled={loadMore.disableLoadMore}
                                    onClick={() => {
                                        loadMore?.loadMoreCallback()
                                    }}>
                                {loadMore.loadMoreText()}
                            </Button>
                        </Grid>
                    </Grid>
                }
            </TableContainer>
        </Box>
    )
}

function useIsInViewport(ref: MutableRefObject<any>) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting),
            ),
        [],
    );

    useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}

export default ListTable;

