import {Button, Grid, List, ListItem, Typography} from "@mui/material";

import {
    Control,
    Controller,
    Path,
    SubmitHandler,
    useForm
} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {ResourceConfig} from "../../types/resource";
import {CreationPanelStyles} from "./CreationPanelStyles";
import {Field} from "../../types/field";
import {useResourceContext} from '../../context/ResourceContext';
import {useFeedbackContext} from "../../context/FeedbackContext";
import DefaultSideModal from "../sideModal/SideModal";
import {HyperlinkMeta} from "../../meta/hyperlink/hyperlink";
import {AxiosError} from "axios";
import {ErrorDTO} from "../../../hoc/axiosHandlerProvider/AxiosHandlerProvider";

type CreationPanelProps<T, V> = {
    anchor: 'right';
    open: boolean;
    resource?: ResourceConfig<T, V>;
    fields: Field<T>[];
}

function CreationPanel<T extends Record<string, any>, V>(props: CreationPanelProps<T, V>) {
    const classes = CreationPanelStyles();
    const navigate = useNavigate();
    const {handleSubmit, reset, control, formState} = useForm<T>({mode: "all"});
    const resource = useResourceContext().getConfig();
    const feedbackCtx = useFeedbackContext()

    function mergeField(field: Field<T>): Field<T> {
        const propsField = props.fields?.find(f => f.id === field.id);
        if (!propsField) return field;
        if (propsField.validationOptions) field.validationOptions = propsField.validationOptions;
        if (propsField.meta) field.meta = propsField.meta;
        if (propsField.label) field.label = propsField.label;
        return field;
    }

    const onSubmit: SubmitHandler<T> = data => {
        resource.dataProvider?.create(data).then(() => {
            feedbackCtx.openBottomSuccessSnackbar(`${props.resource?.label} created successfully.`)
        }).catch((error) => {
            const axiosError = error as AxiosError<ErrorDTO, unknown>;
            feedbackCtx?.openBottomErrorSnackbar(`Error: ${props.resource?.label} not created. Cause: ${axiosError.response?.data.msg} `)
        })
        reset();
    };

    const isDisabled = () => {
        if (formState.touchedFields) {
            if (Object.keys(formState.touchedFields).length === 0) {
                return true
            }
        }
        return !formState.isValid
    }

    return (
        <>
        <DefaultSideModal anchor={"right"} title={`Add ${props.resource?.label}`} onClose={() => navigate(-1)}>
            <List>
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Grid className={classes.formContainer}>
                        {props.resource?.fields
                            .filter(f => props.fields.find(ff => ff.id === f.id))
                            .map((f) => {
                                const mergedField = mergeField(f);
                                const field = mergedField.id as Path<T>;
                                return (
                                    <ListItem className={classes.listItem}>
                                        <Grid item>
                                            <Typography variant={"dataTitle"}>
                                                {mergedField.label}
                                                {mergedField.validationOptions?.required && <>*</>}
                                            </Typography>
                                        </Grid>
                                        <Grid item className={classes.formInput}>
                                            <Controller
                                                name={field}
                                                control={control}
                                                render={() => {
                                                    if (!mergedField.meta) return <></>;
                                                    if (mergedField.meta instanceof HyperlinkMeta) {
                                                        return <>
                                                                    {
                                                                        mergedField.meta.childMeta.getFormComponent(
                                                                            control as Control,
                                                                            mergedField.id as Path<T>,
                                                                            mergedField
                                                                        )
                                                                    }
                                                               </>
                                                    } else {
                                                        return <>
                                                                    {
                                                                        mergedField.meta?.getFormComponent(
                                                                            control as Control,
                                                                            mergedField.id as Path<T>,
                                                                            mergedField
                                                                        )
                                                                    }
                                                               </>
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </ListItem>
                                );
                            })
                        }
                    </Grid>
                    <Grid container direction={"column"} className={classes.actionContainer}>
                        <Typography>* required field</Typography>
                        <Button className={classes.addButton} type="submit"
                                disabled={isDisabled()}
                        >
                            ADD {props.resource?.label}
                        </Button>
                    </Grid>
                </form>
            </List>
        </DefaultSideModal>
        </>
    )
}

export default CreationPanel;
