
import {Box, Divider, Link, List, ListItem, Typography} from "@mui/material";
import React from "react";
import {DownloadRequest, DownloadRequestStatus, FileRequestStatus} from "../downloadRequest";
import {HyperlinkMeta} from "../../../../lib/meta/hyperlink/hyperlink";
import {NumberMeta} from "../../../../lib/meta/number/number";
import {BASE_URL} from "../../../../App";

export const associatedFilesLink = () => {
    return new HyperlinkMeta<DownloadRequest>({
        childMeta: new NumberMeta({isLink: true}),
        title: "Requested files",
        modalConfig: {
            getBody: (downloadRequest?: DownloadRequest) => {
                return (
                    <>
                        <Box style={{
                            justifyContent: "center",
                            height: "70vh",
                            overflow: "scroll",
                            width: "100%",
                        }}>
                            <Box style={{
                                overflowY: "scroll",
                                justifyContent: "center",
                                height: "100%",
                                paddingTop: 0,
                                paddingRight: "1em"
                            }}>
                                <List>
                                    {downloadRequest?.files.map((fileRequest, i) => {
                                        return (
                                            <div key={i}>
                                                <ListItem style={{justifyContent: "space-between", height: "3em"}}>
                                                    <div style={{width: "60%", textOverflow: "ellipsis", overflow: "hidden"}}>
                                                        <Link href={`${window.location.origin}/lab/labTest/${fileRequest.lab_test_file.id}`}>
                                                            {fileRequest.lab_test_file.name}
                                                        </Link>
                                                    </div>
                                                    <div style={{textAlign: "end"}}>
                                                        {
                                                            fileRequest.status === FileRequestStatus.RESTORED &&
                                                            fileRequest.available_until && new Date(fileRequest.available_until) > new Date() ?
                                                                <>
                                                                    {fileRequest.status.toUpperCase()}
                                                                    <br/>
                                                                    {new Date(fileRequest.available_until).toUTCString()}
                                                                </>
                                                                :
                                                                <>
                                                                    {fileRequest.status.toUpperCase()}
                                                                </>
                                                        }
                                                    </div>
                                                </ListItem>
                                                <Divider/>
                                            </div>
                                        );
                                    })}
                                </List>
                            </Box>
                        </Box>
                    </>
                );
            },
        }
    });
}
