import { Grid, Typography } from "@mui/material";
import { ReactElement } from "react";
import michLogo from '../../images/icons/login/michLogo.svg';
import {AuthStyles} from "./authStyles";


interface AuthFormHeaderProps {
    children: ReactElement
}
export function AuthFormHeader({ children }: AuthFormHeaderProps) {
    const classes = AuthStyles();

    return (
        <>
            <Grid container direction={'column'} className={classes.authFormContainer}>
                <Grid item className={classes.logoContainer}>
                    <img src={michLogo} alt={"logo"} className={classes.logo}></img>
                    <Typography variant="h5">MICHELANGELO</Typography>
                    <Typography variant="h6">WEB</Typography>
                </Grid>
                <Grid item mt={4} className={classes.children}>
                    {children}
                </Grid>
            </Grid>
        </>
    )
}
