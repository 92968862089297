import {Grid, ListItem, Switch, Typography} from "@mui/material";
import {Permissions} from "../../../../resources/authorizations/permission/permissions";
import React, {ReactElement, useEffect, useState} from "react";
import {RolesEditStyles} from "../rolesEditTabStyles";

interface AppManagerProps {
    key:string,
    editEnabled: boolean
    permissions: string[]
    onPermissionsChange: (data: string[]) => void
}

export function AppManagerPermission({permissions, onPermissionsChange, editEnabled,...props}: AppManagerProps): ReactElement {
    const classes = RolesEditStyles();

    const appManagerAllResources = Object.keys(Permissions["appManager"])

    const appManagerAllPermissions = ():string[]=>{
        let permissions: string[]= []
        appManagerAllResources.map(r => {
            permissions.push(...Permissions["appManager"][r].read.concat(Permissions["appManager"][r].write))
        })
        return permissions
    }
    const hasAppManagerPermissions = ():boolean=>{
        let hasPermissions = true
        appManagerAllPermissions().forEach(p=>{
            if(permissions.indexOf(p)===-1){
                hasPermissions=false
            }
        })
        return hasPermissions
    }

    const [isChecked, setIsChecked] = useState<boolean>(
        hasAppManagerPermissions()
    );


    useEffect(() => {
        let newPermissions = [...permissions]
        if (isChecked) {
            newPermissions = [
                ...new Set(
                    newPermissions.concat(
                        appManagerAllPermissions()
                    )
                )
            ]
        } else {
            newPermissions = newPermissions.filter(p => appManagerAllPermissions().indexOf(p) === -1)
        }
        onPermissionsChange(newPermissions)
    }, [isChecked])

    const handleChange = () => {
        setIsChecked(!isChecked)
    }
    return (
        <>
            <ListItem className={classes.showListItem}>
                <Typography variant={"dataTitle"}>App Manager</Typography>
                <>
                    <Grid container direction={"row"} className={classes.selectContainer} width={"fit-content"}>
                        <Typography>Admin Level &nbsp;</Typography>
                        <Switch
                            key={props.key}
                            focusVisibleClassName=".Mui-focusVisible"
                            disableRipple
                            className={classes.switch}
                            sx={{m: 1}}
                            checked={isChecked}
                            onChange={handleChange}
                            disabled={!editEnabled}/>
                    </Grid>
                </>
            </ListItem>
        </>
    )
}
