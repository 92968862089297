import React from "react";
import SimpleTextFilter from "../../../lib/components/filters/singleFilters/simpleText/simpleTextFilter";
import {Filters} from "../../../lib/types/filter";
import {MetadataFileFilter} from "./metadataFile";
import {SelectFilter as AutocompleteSelectFilter} from "../../../lib/meta/select/autocompleteSelect";
import {ClinicalStudiesResource} from "../../appManager/clinicalStudies/clinicalStudyResource";
import {ClinicalStudy} from "../../appManager/clinicalStudies/clinicalStudy";


export const metadataFileFilter: Filters<MetadataFileFilter> = {
    filters: [
        {
            field:  {
                id: "q",
                label:"Search",
                meta: {
                    getFormComponent: () => {
                        return <></>
                    },
                    getFilterComponent: (handleFilter: any, value: any) => {
                        return () => (
                            <SimpleTextFilter fieldLabel={"Search"}
                                              handleResult={handleFilter}
                                              value={value}/>
                        )
                    },
                    getShowComponent: () => () => <></>
                },
            },
            hidden: () => false,
            main: true
        },
        {
            field:  {
                id: "clinical_study_id",
                label: "Clinical Study",
                meta: {
                    getFormComponent: () => <></>,
                    getFilterComponent: (handleFilter: any, res: any, value?: any) => {
                        return () => (
                            <AutocompleteSelectFilter
                                label={"Clinical study"}
                                resource={ClinicalStudiesResource}
                                handleSelect={handleFilter}
                                valuer={(clinicalStudy: ClinicalStudy) => clinicalStudy.name.toString()}
                                selectedRecord={value}
                                clearable={true}
                            />
                        )
                    },
                    getShowComponent: () => () => <></>
                },
            },
            hidden: () => false,
            main: true
        },
    ]
}
