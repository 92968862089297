import {ResourceConfig} from "../../../lib/types/resource";
import {SimpleTextMeta} from "../../../lib/meta/simpleText/simpleText";
import {ClinicalData} from "./clinicalData";
import {ActionType} from "../../../lib/types/action";
import {StepAction} from "../../../lib/actions/stepAction";
import DownloadIcon from '@mui/icons-material/Download';
import {clinicalDataFilter} from "./clinicalDataFilter";
import {ClinicalDataShowTabView} from "../../../components/clinicalData/clinicalData/show/clinicalDataShowTab";
import React from "react";
import {ClinicalDataDownloadView} from "../../../components/clinicalData/clinicalData/download/download";
import {DefaultListView} from "../../../lib/views/crud/list/default";
import {ClinicalDataProvider} from "./clinicalDataProvider";

export const ClinicalDataResource: ResourceConfig<ClinicalData, ClinicalData> = {
    id: "clinicalData",
    label: "Clinical Data",
    customRoute: {
        path: "download",
        component: <ClinicalDataDownloadView/>
    },
    dataProvider: new ClinicalDataProvider(),
    actions: [
        new StepAction<{}, ClinicalData>({
            id: "download",
            label: "Download",
            actionType: ActionType.Step,
            icon: <DownloadIcon fontSize={"medium"}/>
        })
    ],
    filter: clinicalDataFilter,
    edit: undefined,
    fields: [
        {
            id: "patient_id",
            label: "PATIENT ID",
            meta: new SimpleTextMeta({})
        },
        {
            id: "clinical_study_id",
            label: "Study ID",
            meta: new SimpleTextMeta({})
        },
        {
            id: "clinical_study_name",
            label: "Study Name",
            meta: new SimpleTextMeta({})
        },
        {
            id: "file_id",
            label: "File ID",
            meta: new SimpleTextMeta({})
        },
        {
            id: "files_count",
            label: "File count"
        },
        {
            id: "format",
            label: "Format"
        },
    ],
    list: {
        component: <DefaultListView
            fields={[{id: "patient_id"}, {id: "clinical_study_id"}, {id: "clinical_study_name"}]}
            pageSize={20}
            optionalParam={"clinical_study_id"}
        />,
        id: "clinical_data",
        pageSize: 20,
        fields: [{id: "patient_id"}, {id: "clinical_study_id"}, {id: "clinical_study_name"}, {id: "files_count"}]
    },
    show: {
        component: <ClinicalDataShowTabView/>,
        fields: []
    },
    description: "List of the clinical data, filtered by the relative clinical study.",
    isDeletable: () => false,
    primaryKey: "patient_id",
    groupId: "clinical"

}
