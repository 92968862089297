import axios from "axios";
import {BASE_URL} from "../../../App";
import {BaseDataProvider} from "../../baseDataProvider";
import {MetadataFile, MetadataFileFilter} from "./metadataFile";
import {FileRow} from "../../../components/preload";


export class MetadataFilesDataProvider extends BaseDataProvider<MetadataFile, MetadataFileFilter> {
    constructor() {
        super("lab_source_files")
    }

    postPreload = async (data: FormData): Promise<FileRow[]> => {
        return axios.post<FileRow[]>(`${BASE_URL}/lab_source_files/preload`, data, { headers: await this.getHeaders({Accept: "multipart/form-data"}) }).then(r => {
            return r.data
        });
    }

    download = async (id: string) => {
        return axios.get(`${BASE_URL}/lab_source_files/${id}/download`, { headers: await this.getHeaders({'Accept': 'application/json'}), responseType:"blob" }).then(r => {
            return r.data
        });
    }

    hardDelete = async (id: string, hard_delete: boolean, reason?: string): Promise<boolean> => {
        return axios.put(`${BASE_URL}/lab_source_files/${id}/delete`, {hard_delete: hard_delete, reason: reason}, { headers: await this.getHeaders()})
            .then(r => { return r.data; });
    }


}
