import { Filters } from "../../../lib/types/filter"
import SimpleTextFilter from "../../../lib/components/filters/singleFilters/simpleText/simpleTextFilter";
import React from "react";
import {StaticSelectFilter} from "../../../lib/meta/select/staticSelect";
import {AuditAction, AuditFilter} from "./audit";

export const auditFilter: Filters<AuditFilter> = {
    filters: [
        {
            field:  {
                id: "q",
                label: "Search",
                meta: {
                    getFormComponent: () => {
                        return <></>
                    },
                    getFilterComponent: (handleFilter: any, res: any, value: any) => {
                        return () => (
                            <SimpleTextFilter fieldLabel={"Search..."} handleResult={handleFilter} value={value}/>)
                    },
                    getShowComponent: () => () => <></>
                },
            },
            hidden: () => false,
            main: true
        },
        {
            field:  {
                id: "action",
                label: "Action",
                meta: {
                    getFormComponent: () => <></>,
                    getFilterComponent: (handleFilter: any, res: any, value?: any) => {
                        return () => (
                            <StaticSelectFilter
                                label={"Action"}
                                filterId={value}
                                handleSelect={handleFilter}
                                selectedValue={value}
                                valuePicker={"action"}
                                options={AuditAction}
                                />
                        )
                    },
                    getShowComponent: () => () => <></>
                },
            },
            hidden: () => true
        },
        {
            field:  {
                id: "object",
                label: "Object",
                meta: {
                    getFormComponent: () => <></>,
                    getFilterComponent: (handleFilter: any, res: any, value?: any) => {
                        return () => (
                            <StaticSelectFilter
                                label={"Target"}
                                filterId={value}
                                handleSelect={handleFilter}
                                selectedValue={value}
                                valuePicker={"object"}
                                options={{
                                    GROUP: "ROLE",
                                    ACCESS: "ACCESS",
                                    PERMISSION: "PERMISSION",
                                    CLINICAL_STUDY: "CLINICAL STUDY",
                                    SOURCE_FILE: "SOURCE FILE",
                                    USER: "USER",
                                    LABORATORY: "LABORATORY",
                                    CLINICAL_DATA: "CLINICAL DATA",
                                    LAB_TEST: "LAB TEST",
                                    LAB_SOURCE_FILE: "METADATA FILE",
                                    USER_LOG: "USER LOG"
                                }}/>
                        )
                    },
                    getShowComponent: () => () => <></>
                },
            },
            hidden: () => true
        }
    ]
}
