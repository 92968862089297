import {createStyles, makeStyles} from "@mui/styles";
import loginBg from "../images/backgrounds/loginBackground.png";

const LayoutStyles = makeStyles(() =>
    createStyles({
        mainContainer: {
            position: "fixed",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
        },
        textContainer: {
            width: "80vw",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center"
        },
        sidebar: {
            position: "fixed"
        },
        navbar: {
            marginLeft:"auto !important",
            marginRight: "0 !important"
        },
        backgroundContainer: {
            height: "100vh"
        },
        loginBox: {
            width: '100%',
            height: '100%',
            backgroundImage: `url(${loginBg})`,
            backgroundSize: 'cover'
        },
        loginContainer: {
            display: "flex",
            alignItems: "center"
        },
        loginChildren: {
            width: "100%"
        },
        loggerContainer: {
            minHeight: "90vh"
        },
        loader: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }
    }));

export {LayoutStyles};
