import {Grid, TextField, Typography} from "@mui/material";
import React, { ReactElement } from "react";
import {
    Control,
    FieldValues,
    Path, RegisterOptions,
    useController
} from "react-hook-form";
import { Field } from "../../types/field";
import { ListMeta, MetaField } from "../../types/meta";
import {LongTextStyles} from "./longTextStyles";

interface longTextProps {
    label?: string;
    data?: string;
}

function LongTextShow({label, data}: longTextProps): ReactElement {
    const classes = LongTextStyles();
    return (
        <>
                <Grid item>
                    <Typography variant={"dataTitle"}>
                        {label}
                    </Typography>
                </Grid>
                <Grid item className={classes.longTextInput} paddingBottom={"1em"}>
                    <Typography variant={"data"}>
                        {!data? "--" : data}
                    </Typography>
                </Grid>
        </>
    )
}

function LongTextInput<T>(
    formControl: Control,
    name: Path<FieldValues>,
    validationOptions?: RegisterOptions
): React.ReactElement {
    const {field, fieldState} = useController({
        name: name,
        control: formControl,
        rules: validationOptions
    });

    return (
        <>
            <TextField
                {...field}
                style={{minWidth:"20em"}}
                multiline
                minRows={5}
                error={fieldState.error !== undefined}
                helperText={fieldState.error?.message}
            />
        </>
    );
}

export class LongTextMeta<T> implements MetaField<T> {

    getFilterComponent?: ((handler?: ((data: T) => void) | undefined) => React.FC<{}>) | undefined;

    getListMeta(): ListMeta {
        return {
            type: "string",
            value: (arg: any) => arg,
        };
    }

    getFormComponent(
        control: Control,
        name: Path<FieldValues>,
        field: Field<T>): ReactElement {
        return LongTextInput<T>(control, name, field.validationOptions);
    }

    getShowComponent(data: T | undefined, field: Field<T>) {
        return () => {
            return LongTextShow({label: field.label, data: data ? data[field.id] as string : ''})
        }
    }

}
