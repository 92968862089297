import {Box, Button, Grid, ListItemIcon, MenuItem, MenuList, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {GuidePageStyles} from "./guidePageStyles";
import {useNavigate, useSearchParams} from "react-router-dom";
import {SectionKeys} from "./sectionKeys";
import LoginIcon from '@mui/icons-material/Login';
import SettingsIcon from '@mui/icons-material/Settings';
import ClinicalIcon from "../../images/icons/tabs/clinicalGrey.svg";
import HomeIcon from "../../images/icons/tabs/homeGrey.svg";
import LabIcon from "../../images/icons/tabs/sampleGrey.svg";
import DocIcon from "../../images/icons/tabs/documentsGrey.svg";
import UserGuide from "./userGuide";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export const UserGuidePage = () => {
    const classes = GuidePageStyles();
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});

    const [params] = useSearchParams();

    const sectionRefs: SectionKeys = {
        signingUp: useRef<HTMLDivElement>(null),
        loggingIn: useRef<HTMLDivElement>(null),
        profile: useRef<HTMLDivElement>(null),
        appManager: useRef<HTMLDivElement>(null),
        users: useRef<HTMLDivElement>(null),
        roles: useRef<HTMLDivElement>(null),
        clinicalStudies: useRef<HTMLDivElement>(null),
        laboratories: useRef<HTMLDivElement>(null),
        audit: useRef<HTMLDivElement>(null),
        clinical: useRef<HTMLDivElement>(null),
        clinicalData: useRef<HTMLDivElement>(null),
        sourceFiles: useRef<HTMLDivElement>(null),
        documents: useRef<HTMLDivElement>(null),
        lab: useRef<HTMLDivElement>(null),
        labTests: useRef<HTMLDivElement>(null),
        metadataFiles: useRef<HTMLDivElement>(null),
        cliTool: useRef<HTMLDivElement>(null)
    };
    const scrollToSection = (sectionKey: keyof SectionKeys) => {
        const sectionRef = sectionRefs[sectionKey];
        if (sectionRef && sectionRef.current) {
            // COMPUTING BECAUSE OF THE CONTAINER'S TOP MARGIN
            const yOffset = sectionRef.current.getBoundingClientRect().top + window.scrollY - 100;
            window.scrollTo({ top: yOffset, behavior: 'auto' });
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (params.get("section")) {
                scrollToSection(params.get("section") as keyof SectionKeys);
            }
        }, 100)
    }, [])

    const toggleMenu = (menuKey: string) => {
        setExpanded((prevState) => ({
            [menuKey]: !prevState[menuKey],
        }));
    };

    return (
            <Grid container direction={"row"} className={classes.container}>
                <Grid className={classes.textContainer}>
                    <Button
                        onClick={() => navigate(-1)}>
                        <KeyboardBackspaceIcon color={"primary"}></KeyboardBackspaceIcon>
                        <Typography variant={"backButtonText"}>Go back</Typography>
                    </Button>
                    <Grid container direction={"column"}>
                        <Typography variant={"pageTitle"} className={classes.title}>miWeb User Guide</Typography>
                        <Grid>
                            <UserGuide  refs={sectionRefs}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.indexContainer}>
                    <Box>
                        <MenuList>
                            <MenuItem
                                key={"intro"}
                                className={classes.expandableItem}
                                onClick={() => toggleMenu("intro")}>
                                <div className={classes.menuTitle}>
                                    <ListItemIcon>
                                        <LoginIcon/>
                                    </ListItemIcon>
                                    GET STARTED
                                </div>
                                {expanded["intro"] ? <KeyboardArrowUpIcon className={classes.menuArrow}/> :<KeyboardArrowDownIcon className={classes.menuArrow}/>}
                            </MenuItem>
                                {expanded["intro"] &&
                                <>
                                    <MenuItem
                                        className={classes.menuItem}
                                        onClick={() => scrollToSection('signingUp')}>Signing up</MenuItem>
                                    <MenuItem
                                        className={classes.menuItem}
                                        onClick={() => scrollToSection('loggingIn')}>Logging in</MenuItem>
                                </>
                                }
                            <MenuItem
                                key={"profile"}
                                className={classes.expandableItem}
                                onClick={() => toggleMenu("profile")}>
                                <div className={classes.menuTitle}>
                                    <ListItemIcon>
                                        <img src={HomeIcon} alt={"home"}/>
                                    </ListItemIcon>
                                    HOME
                                </div>
                                {expanded["profile"] ? <KeyboardArrowUpIcon className={classes.menuArrow}/> :<KeyboardArrowDownIcon className={classes.menuArrow}/>}
                            </MenuItem>
                            {expanded["profile"] &&
                                <MenuItem
                                    className={classes.menuItem}
                                    onClick={() => scrollToSection('profile')}>Profile</MenuItem>
                            }
                            <MenuItem
                                key={"appManager"}
                                className={classes.expandableItem}
                                onClick={() => toggleMenu("appManager")}>
                                <div className={classes.menuTitle}>
                                    <ListItemIcon>
                                        <SettingsIcon/>
                                    </ListItemIcon>
                                    APP MANAGER
                                </div>
                                {expanded["appManager"] ? <KeyboardArrowUpIcon className={classes.menuArrow}/> :<KeyboardArrowDownIcon className={classes.menuArrow}/>}
                            </MenuItem>
                                {expanded["appManager"] &&
                                    <>
                                        <MenuItem
                                            className={classes.menuItem}
                                            onClick={() => scrollToSection('users')}
                                        >Users</MenuItem>
                                        <MenuItem
                                            className={classes.menuItem}
                                            onClick={() => scrollToSection('roles')}
                                        >Roles</MenuItem>
                                        <MenuItem
                                            className={classes.menuItem}
                                            onClick={() => scrollToSection('clinicalStudies')}
                                        >Clinical Studies</MenuItem>
                                        <MenuItem
                                            className={classes.menuItem}
                                            onClick={() => scrollToSection('laboratories')}
                                        >Laboratories</MenuItem>
                                        <MenuItem
                                            className={classes.menuItem}
                                            onClick={() => scrollToSection('audit')}
                                        >Audit</MenuItem>
                                    </>
                                }
                            <MenuItem
                                key={"documents"}
                                className={classes.expandableItem}
                                onClick={() => toggleMenu("documents")}>
                                <div className={classes.menuTitle}>
                                    <ListItemIcon>
                                        <img src={DocIcon} alt={"documents"}/>
                                    </ListItemIcon>
                                    DOCUMENTS
                                </div>
                                {expanded["documents"] ? <KeyboardArrowUpIcon className={classes.menuArrow}/> :<KeyboardArrowDownIcon className={classes.menuArrow}/>}
                            </MenuItem>
                                {expanded["documents"] &&
                                    <MenuItem
                                        className={classes.menuItem}
                                        onClick={() => scrollToSection('documents')}>Documents</MenuItem>
                                }
                            <MenuItem
                                key={"clinical"}
                                className={classes.expandableItem}
                                onClick={() => toggleMenu("clinical")}>
                                <div className={classes.menuTitle}>
                                    <ListItemIcon>
                                        <img src={ClinicalIcon} alt={"clinical"}/>
                                    </ListItemIcon>
                                    CLINICAL
                                </div>
                                {expanded["clinical"] ? <KeyboardArrowUpIcon className={classes.menuArrow}/> :<KeyboardArrowDownIcon className={classes.menuArrow}/>}
                            </MenuItem>
                            {expanded["clinical"] &&
                                <>
                                    <MenuItem
                                        className={classes.menuItem}
                                        onClick={() => scrollToSection('clinicalData')}
                                    >Clinical Data</MenuItem>
                                    <MenuItem
                                        className={classes.menuItem}
                                        onClick={() => scrollToSection('sourceFiles')}
                                    >Source Files</MenuItem>
                                </>
                            }
                            <MenuItem
                                key={"lab"}
                                className={classes.expandableItem}
                                onClick={() => toggleMenu("lab")}>
                                <div className={classes.menuTitle}>
                                    <ListItemIcon>
                                        <img src={LabIcon} alt={"lab"}/>
                                    </ListItemIcon>
                                    LAB
                                </div>
                                {expanded["lab"] ? <KeyboardArrowUpIcon className={classes.menuArrow}/> :<KeyboardArrowDownIcon className={classes.menuArrow}/>}
                            </MenuItem>
                                {expanded["lab"] &&
                                    <>
                                        <MenuItem
                                            className={classes.menuItem}
                                            onClick={() => scrollToSection('labTests')}
                                        >Lab tests</MenuItem>
                                        <MenuItem
                                            className={classes.menuItem}
                                            onClick={() => scrollToSection('metadataFiles')}
                                        >Metadata Files</MenuItem>
                                        <MenuItem
                                            className={classes.menuItem}
                                            onClick={() => scrollToSection('cliTool')}
                                        >Miweb cli tool</MenuItem>
                                    </>
                                }
                        </MenuList>
                    </Box>
                </Grid>
            </Grid>
    )
}
