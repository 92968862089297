import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Button, Grid, IconButton, InputAdornment, Link, TextField} from "@mui/material";
import {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {AuthStyles} from "../authStyles";

export interface LoginFormInputs {
    email: string;
    password: string;
}

interface LoginFormProps {
    submitCallback: (data: LoginFormInputs) => void
    openForgotPasswordModal: () => void
}

export function LoginForm(props: LoginFormProps) {
    const classes = AuthStyles();
    const [showPassword, setShowPassword] = useState(false);
    const {
        register,
        handleSubmit,
        formState
    } = useForm<LoginFormInputs>({mode: 'all'});

    const onSubmit: SubmitHandler<LoginFormInputs> = (data) => {
        props.submitCallback(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction={'column'} className={classes.loginFormContainer}>
                <Grid item className={classes.loginFormItem}>
                    <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        margin="normal"
                        {...register('email', {
                            required: {
                                value: true,
                                message: 'This field is required'
                            },
                            pattern: {
                                value: /^[\S]+@([\w-]+\.)+[\w-]{2,4}(\.[a-zA-Z]{2,4})?$/,
                                message: "Invalid email format"
                            }
                        })}
                        error={!!formState.errors.email}
                        helperText={formState.errors.email ? formState.errors.email.message : ''}
                    />
                </Grid>
                <Grid item className={classes.loginFormItem}>
                    <TextField
                        fullWidth
                        label="Password"
                        variant="outlined"
                        margin="normal"
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (<InputAdornment position="start">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>)
                        }}
                        {...register('password', {
                            required: {
                                value: true,
                                message: 'This field is required'
                            },
                            pattern: {
                                value: /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-_+.]){1,}).{8,}$/,
                                message: 'Password must be at least 8 characters long and contain at least one uppercase letter, one number, and one special character [!@#$%^&*()-_+.]'
                            }
                        })}
                        error={!!formState.errors.password}
                        helperText={formState.errors.password
                            ?
                            formState.errors.password.message : ''}
                    />
                </Grid>
                <Grid item marginLeft={'auto'}>
                    <Link variant="caption" align="right" href="#" onClick={()=>{
                        props.openForgotPasswordModal()
                    }}>Password dimenticata?</Link>
                </Grid>
                <Grid item mt={4} width={'100%'}>
                    <Button
                        className={classes.loginFormItem}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!formState.isValid}>
                        Login
                    </Button>
                </Grid>
            </Grid>
        </form>

    )
}
