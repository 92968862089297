import {createStyles, makeStyles} from "@mui/styles";

const PrivacyContractStyles = makeStyles((theme) =>
    createStyles({
        contractBox: {
            [theme.breakpoints.down("lg")]: {
                width: "60%",
            },
            height: "63vh",
            width: "50%",
            justifyContent: "space-between",
            textAlign: "justify",
            alignSelf:"center",
            overflowY: "scroll",
            marginBottom:"4vh",
            marginTop: "1.5vh"
        },
        logo: {
            marginTop:"2em",
            maxHeight: "15vh"
        },
        checkbox: {
            display: "flex",
            alignItems: "center"
        },
        buttonsContainer: {
            width: "50%",
            maxHeight: "10vh",
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignSelf:"center"
        },
        redirectPageContainer: {
            width: "100%",
            alignItems: "center"
        },
        redirectPageText: {
            textAlign: "center"
        },
        redirectButtons: {
            display: "flex",
            // @ts-ignore
            flexDirection: "column !important"
        }
    }));

export {PrivacyContractStyles};
