import {createStyles, makeStyles} from "@mui/styles";

const DocumentsStyles = makeStyles(() =>
    createStyles({
        title: {
            width: "fit-content",
            maxWidth: "12em",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        filterContainer: {
            marginTop: -10,
            marginBottom: "2em !important"
        },
        tabDataContainer: {
            width: "100% !important"
        },
        listItem: {
            height: "4em",
            justifyContent: "space-between !important"
        },
        listItemLink: {
            height: "4em",
            justifyContent: "space-between !important",
            marginTop: "0.7em",
            marginBottom: "-0.3em",
        },
        errorContainer: {
            marginTop: "-1em",
            marginBottom: "-2em"
        },
    }));

export {DocumentsStyles};
