import { createContext, ReactNode, useContext, useState } from "react";
import { GroupConfig } from "../types/group";
import { ResourceConfig } from "../types/resource";


export interface AdminContextValue {
    registerResource: (res: ResourceConfig<any, any>, groupId?: string) => void,
    unregisterResource: (resId: string) => void,
    registerGroup: (group: GroupConfig) => void,
    unregisterGroup: (groupId: string) => void,
    getResources: () => ResourceGroupWrapper<any, any>[],
    getGroups: () => GroupConfig[];
    getResourcesByGroup: (groupId: string) => ResourceConfig<any, any>[]
}

interface AdminContextProps {
    children: ReactNode;
}

interface ResourceGroupWrapper<T, V> {
    resource: ResourceConfig<T, V>,
    groupId: string | undefined,
}

export const AdminContext = createContext<AdminContextValue | undefined>(undefined);

export default function AdminContextProvider(props: AdminContextProps) {
    const [resources, setResources] = useState<ResourceGroupWrapper<any,any>[]>([])
    const [groups, setGroups] = useState<GroupConfig[]>([])

    const registerResource = (res: ResourceConfig<any, any>, groupId?: string): void => {

        setResources((prevRes) => {
            const newRes = [...prevRes];
            const resources = newRes.map(rw => rw.resource)
             if (newRes.find((newRes) => newRes.resource.id === res.id) && !resources.includes(res)) {
                console.warn(`A resource with ID ${res.id} already exists.`)
                throw new Error(`Error: conflicting resource ID.`)
            }

            const fieldIds = new Set();
            res.fields.forEach((field) => {
                fieldIds.add(field.id);
            });
            if (fieldIds.size !== res.fields.length) {
                console.warn("Fields within the same resource must have unique IDs.");
                throw new Error("Error: duplicate field IDs.");
            }

            if (!resources.includes(res)) newRes.push({resource: res, groupId: groupId});

            return newRes;
        })
    }

    const unregisterResource = (resId: string): void => {
        setResources((prevRes) => {
            return prevRes.filter(r => r.resource.id !== resId)
        })
    }

    const registerGroup = (group: GroupConfig): void => {
        setGroups((prevGroups) => {
            const newGroups = [...prevGroups];
            newGroups.push(group);
            return newGroups;
        })
    }

    const unregisterGroup = (groupId: string): void => {
        setGroups((prevGroups) => {
            return prevGroups.filter(g => g.id !== groupId)
        })
    }

    const getGroups = () => {
        return groups;
    }

    const getResources = () => {
        return resources;
    }

    const getResourcesByGroup = (groupId: string) => {
        return resources.reduce((filteredList: ResourceConfig<unknown,unknown>[], resWrapped) => {
            if (resWrapped.groupId === groupId) filteredList.push(resWrapped.resource);
            return filteredList;
        }, [])
    }

    return <AdminContext.Provider value={{
        registerResource: registerResource,
        unregisterResource: unregisterResource,
        getGroups: getGroups,
        registerGroup: registerGroup,
        unregisterGroup: unregisterGroup,
        getResourcesByGroup: getResourcesByGroup,
        getResources: getResources,
    }}>
        {props.children}
    </AdminContext.Provider>

}


export function useAdminContext(): AdminContextValue {
    const adminContext = useContext<AdminContextValue | undefined>(AdminContext);
//     if (!adminContext) throw  new Error("Cannot call useAdminContext outside Admin component");
    return adminContext as AdminContextValue;

}
