import {Box, Button, Grid, Typography} from "@mui/material";
import React, {ReactElement} from "react";
import {ModalStyles} from "./modalStyles";
import {ActionStyle} from "../../actions/actionProps";

interface DefaultModalProps {
    title: string,
    description?: string,
    handleCancel?: () => void,
    handleConfirm?: () => void,
    cancelLabel?: string,
    confirmLabel?: string,
    confirmButtonDisabled?: boolean;
    cancelButtonDisabled?: boolean;
    buttonStyles?: ActionStyle,
    children?: ReactElement,
    onClose?: () => void;
}

export const DefaultModal = (props: DefaultModalProps) => {
    const classes = ModalStyles();
    return (
            <Grid container direction={"column"} className={classes.mainContainer} rowSpacing={5}>
                <Box>
                <Grid item className={classes.headerContainer}>
                    <Grid item className={classes.defaultModalTitle}>
                        <Typography variant="pageTitle">{props.title}</Typography>
                    </Grid>
                    {props.description &&
                        <Grid item className={classes.defaultModalDescription}>
                            {props.description}
                        </Grid>
                    }
                </Grid>
                <Grid item>
                    {props.children}
                </Grid>
                <Grid item className={classes.buttonsContainer}>
                    {props.cancelLabel && props.handleCancel &&
                        <Button
                            disabled={props.cancelButtonDisabled}
                            onClick={() => props.handleCancel && props.handleCancel()} variant='outlined'>{props.cancelLabel}</Button>
                    }
                    {props.confirmLabel && props.handleConfirm &&
                        <Button
                            disabled={props.confirmButtonDisabled}
                            onClick={() => {props.handleConfirm && props.handleConfirm()}}
                                variant={props.buttonStyles?.buttonVariant || "outlined"}
                                color={props.buttonStyles?.buttonColor || "primary"}>
                            {props.confirmLabel}
                        </Button>
                    }
                </Grid>
                </Box>
            </Grid>
    )
}
