import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

interface FormInputs {
    newPassword: string;
    confirmNewPassword: string;
}

interface NewPasswordFormProps {
    submitCallback: (password: string) => void,
    email: string | null | undefined
}

const NewPasswordForm = (props: NewPasswordFormProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const { register, handleSubmit, formState, watch } = useForm<FormInputs>({ mode: 'all' });

    const onSubmit = (data: FormInputs) => {
        props.submitCallback(data.confirmNewPassword);
    };


    const newPassword = watch('newPassword');
    const confirmNewPassword = watch('confirmNewPassword');

    return (
        <Grid container direction={'column'} alignItems={'center'}>
            {
                props.email &&
                <>
                    <Grid item>
                        <Typography>
                            You have been invited as
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography sx={{fontWeight: 'bold'}}>
                            {props.email}
                        </Typography>
                    </Grid>
                </>
            }
            <Grid item mt={4}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container direction={'column'} display={'flex'} alignItems={'center'}>
                        <Grid item>
                            <TextField
                                sx={{ width: '22em' }}
                                {...register('newPassword', {
                                    required: {
                                        value: true,
                                        message: 'This field is required'
                                    },
                                    pattern: {
                                        value: /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-_+.]){1,}).{8,}$/,
                                        message: 'Password must be at least 8 characters long and contain at least one uppercase letter, one number, and one special character [!@#$%^&*()-_+.]'
                                    },
                                })}
                                label="New Password"
                                name="newPassword"
                                type={showPassword ? 'text' : 'password'}
                                error={!!formState.errors.newPassword}
                                helperText={
                                    formState.errors.newPassword
                                        ?
                                        formState.errors.newPassword.message
                                        :
                                        ''
                                }
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    endAdornment: (<InputAdornment position="start">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>)
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                {...register('confirmNewPassword', {
                                    required: true,
                                    validate: (value) => value === newPassword,
                                })}
                                sx={{ width: '22em' }}
                                label="Confirm New Password"
                                name="confirmNewPassword"
                                type={showPassword ? 'text' : 'password'}
                                error={!!formState.errors.confirmNewPassword}
                                helperText={
                                    formState.errors.confirmNewPassword
                                        ?
                                        'Passwords do not match'
                                        :
                                        ''
                                }
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    endAdornment: (<InputAdornment position="start">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>)
                                }}
                            />
                        </Grid>
                        <Grid item mt={4} width={'100%'}>
                            <Button
                                sx={{ width: '100%' }}
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={!newPassword || !confirmNewPassword || newPassword !== confirmNewPassword}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

export default NewPasswordForm;
