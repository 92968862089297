import {
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    ListItem,
    MenuItem,
    Select,
    Switch,
    Typography
} from "@mui/material";
import React, {ReactElement, useState} from "react";
import {RolesEditStyles} from "../rolesEditTabStyles";
import {PermissionLevel, Permissions} from "../../../../resources/authorizations/permission/permissions";
import {Access, AccessObjectClinical} from "../../../../resources/authorizations/access/access";
import {StudyAccordion} from "../accordion/studyAccordion";

interface ClinicalDataEditTabProps {
    key: string
    editEnabled: boolean;
    totalAccesses: Access[];
    currentAccesses: Access[];
    currentPermissions: string[];
    onPermissionsChange: (data: string[]) => void;
    onAccessesChange: (data: Access[]) => void;
}

export const ClinicalDataPermissions = ({totalAccesses, ...props}: ClinicalDataEditTabProps): ReactElement => {
    const classes = RolesEditStyles();
    const [currentPermissions, setCurrentPermissions] = useState<string[]>(props.currentPermissions);

    const [currentAccesses, setCurrentAccesses] = useState<Access[]>(props.currentAccesses);

    const hasSourceFilePermissions = (): boolean => {
        let hasPermissions = true
        const sourceFileAllPermissions = Permissions["clinical"]["sourceFiles"].read.concat(Permissions["clinical"]["sourceFiles"].write);
        sourceFileAllPermissions.forEach(p=>{
            if(currentPermissions.indexOf(p)===-1){
                hasPermissions=false
            }
        })
        return hasPermissions
    }

    const [sourceFilePermissions, setSourceFilePermissions] = useState<boolean>(hasSourceFilePermissions());

    const getCurrentPermissionLevel = (): PermissionLevel => {
        if (currentPermissions.includes(Permissions["clinical"]["clinicalData"].read[0])) {
            if (props.currentAccesses.find(a => {return (a.access as AccessObjectClinical).clinical_study_id === "*"})) {
                return PermissionLevel.ADMIN
            }
            return PermissionLevel.DOWNLOAD
        }
        return PermissionLevel.NONE
    }

    const [permissionLevel, setPermissionLevel] = useState<PermissionLevel>(getCurrentPermissionLevel());

    const onPermissionLevelChanged = (val: string | PermissionLevel) => {
        const newPermissionLevel = val as PermissionLevel
        let newAccesses: Access[] = []
        let newPermissions = []
        switch (newPermissionLevel) {
            case PermissionLevel.ADMIN:
                newAccesses = [...totalAccesses.filter(a => {
                    const clinicalAccess = a.access as AccessObjectClinical
                    return clinicalAccess.clinical_study_id === '*'
                })]
                newPermissions = [
                    ...new Set(currentPermissions.concat(...Permissions["clinical"]["clinicalData"].read))
                ]
                break;
            case PermissionLevel.DOWNLOAD:
                newPermissions = [
                    ...new Set(currentPermissions.concat(...Permissions["clinical"]["clinicalData"].read))
                ]
                newAccesses = []
                break;
            case PermissionLevel.NONE:
                newAccesses = []
                newPermissions = currentPermissions.filter(p => Permissions["clinical"]["clinicalData"].read.indexOf(p) === -1)
                break;
            default:
                return
        }
        setCurrentPermissions(newPermissions)
        setCurrentAccesses(newAccesses)
        props.onAccessesChange(newAccesses)
        props.onPermissionsChange(newPermissions)
        setPermissionLevel(newPermissionLevel)
    }

    const onSourceFilePermissionChanged = () => {
        const newSourceFilePermissions = !sourceFilePermissions

        const sourceFileAllPermissions = Permissions["clinical"]["sourceFiles"].read.concat(Permissions["clinical"]["sourceFiles"].write);

        const newPermissions = newSourceFilePermissions ?
            [
                ...new Set(currentPermissions.concat(sourceFileAllPermissions))
            ] :
            currentPermissions.filter(p => !sourceFileAllPermissions.includes(p))
        setCurrentPermissions(newPermissions)
        setSourceFilePermissions(newSourceFilePermissions)
        props.onPermissionsChange(newPermissions)
    }


    const clinicalStudiesSection = (): ReactElement => {
        switch (permissionLevel) {
            case PermissionLevel.NONE:
                return (<Typography variant={"h5"}>Has no access to any clinical study.</Typography>)
            case PermissionLevel.ADMIN:
                return (<Typography variant={"h5"}>Has access to every clinical study.</Typography>)
            case PermissionLevel.DOWNLOAD:
                return renderClinicalStudiesAccordions()
            default:
                return <></>
        }
    }


    const renderClinicalStudiesAccordions = (): ReactElement => {
        const accesses: Record<string, Access[]> = {}
        totalAccesses.forEach((a) => {
            const clinicalAccess = a.access as AccessObjectClinical
            if (clinicalAccess.clinical_study_id !== "" && clinicalAccess.clinical_study_id !== "*") {
                accesses[clinicalAccess.clinical_study_id] = accesses[clinicalAccess.clinical_study_id] ? [...accesses[clinicalAccess.clinical_study_id], a] : [a]
            }
        })
        const onClinicalStudyAccessesChange = (clinicalStudyId: string, accesses: Access[]): void => {
            const newAccesses = [...currentAccesses.filter(a => {
                const clinicalAccess = a.access as AccessObjectClinical
                return clinicalAccess.clinical_study_id !== clinicalStudyId
            }), ...accesses]
            props.onAccessesChange(newAccesses)
            setCurrentAccesses(newAccesses)
        }

        return <Grid container>
            {
                Object.keys(accesses).map(a => {
                    const clinicalAccess = accesses[a][0].access as AccessObjectClinical
                    if (accesses[a].length === 0) {
                        return <></>
                    }
                    return <StudyAccordion
                        editEnabled={props.editEnabled}
                        key={a}
                        clinicalStudyId={a}
                        clinicalStudyName={clinicalAccess.clinical_study_name}
                        availableAccesses={accesses[a]}
                        selectedAccesses={currentAccesses.filter(current => {
                            const clinicalAccess = current.access as AccessObjectClinical
                            return clinicalAccess.clinical_study_id === a
                        })}
                        onAccessesChange={(acc) => {
                            onClinicalStudyAccessesChange(a, acc)
                        }}
                    />
                })
            }
        </Grid>
    }

    return (
        <>
            <ListItem className={classes.showListItem}>
                <Typography variant={"dataTitle"}>Source file</Typography>
                <FormGroup>
                    <FormControlLabel control={
                        <Switch
                            key={props.key}
                            focusVisibleClassName=".Mui-focusVisible"
                            disableRipple
                            className={classes.switch}
                            sx={{m: 1}}
                            disabled={!props.editEnabled}
                            checked={sourceFilePermissions}
                            onChange={() => {
                                onSourceFilePermissionChanged()
                            }}/>}
                              label={"All permissions"}
                              labelPlacement={"start"}
                    ></FormControlLabel>
                </FormGroup>
            </ListItem>
            <Divider/>
            <ListItem className={classes.showListItem}>
                <Typography variant={"dataTitle"}>Clinical Data</Typography>
                <>
                    <Grid container direction={"row"} className={classes.selectContainer}
                          width={"fit-content"}>
                        <Typography>Permission Level &nbsp;</Typography>
                        <Select
                            disabled={!props.editEnabled}
                            onChange={(ev) => {
                                onPermissionLevelChanged(ev.target.value)
                            }}
                            value={permissionLevel}
                            className={!props.editEnabled ? classes.disabledSelect : classes.enabledSelect}
                        >
                            <MenuItem value={PermissionLevel.NONE}>
                                None
                            </MenuItem>
                            <MenuItem value={PermissionLevel.ADMIN}>
                                Admin
                            </MenuItem>
                            <MenuItem value={PermissionLevel.DOWNLOAD}>
                                Download
                            </MenuItem>
                        </Select>
                    </Grid>
                </>
            </ListItem>
            <Divider/>
            <Grid container paddingTop={2} minWidth={"100%"}>
                <Grid item lg={12} display={"flex"} justifyContent={"center"}>
                    {clinicalStudiesSection()}
                </Grid>
            </Grid>
        </>
    )
}
