import {Divider, Grid, ListItem, Typography} from "@mui/material";
import React, {ReactElement, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useResourceContext} from "../../../context/ResourceContext";
import {Field} from "../../../types/field";
import {DefaultStyles} from "./defaultStyles";
import {DefaultShowLayout} from "./showLayout";
import {getDetailErrorCause} from "../../../../resources/messages";

interface DefaultShowViewProps<T> {
    id: string;
    fields: Field<T>[];
    getTitle?: (data?: T) => ReactElement;
    previousPage?: string | number;
}

export function DefaultShowView<T, V>(props: DefaultShowViewProps<T>): ReactElement {
    const [error, setError] = useState<number>();
    const classes = DefaultStyles({error: error});
    const resourceContext = useResourceContext<T, V>();
    const [data, setData] = useState<T | undefined>(undefined);
    const {id} = useParams();

    const resource = resourceContext.getConfig();

    useEffect(() => {
        setData(undefined)
        initData();
    }, [resource])


    const initData = () => {
        if (!id) return;
        resource?.dataProvider?.getById(id)
            .then((data) => {
                setData(data);
            })
            .catch((error) => {
                setError(error.response.status)
            });
    }

    function mergeField(field: Field<T>): Field<T> {
        const propsField = props.fields?.find(f => f.id === field.id);
        if (!propsField) return field;
        if (propsField.validationOptions) field.validationOptions = propsField.validationOptions;
        if (propsField.meta) field.meta = propsField.meta;
        if (propsField.label) field.label = propsField.label;
        return field;
    }

    return (

            <DefaultShowLayout
                data={data}
                getTitle={props.getTitle}
                previousPage={props.previousPage}
            >
                <>
                {
                    error &&
                    <Grid className={classes.errorContainer}>
                        <Typography variant={"dataTitle"} color={"error"}>
                            <strong>ATTENTION!</strong> {getDetailErrorCause(error)}
                        </Typography>
                    </Grid>
                }
                <Grid item className={classes.dataContainer}>
                    <Grid container direction={'column'}>
                        {resource.fields
                            .filter(f => props.fields.find(ff => ff.id === f.id))
                            .map((f) => {
                                const field = mergeField(f);
                                return (
                                    <>
                                        <ListItem className={classes.showListItem}>
                                            {field.meta?.getShowComponent(data, field)({})}
                                        </ListItem>
                                        <Divider/>
                                    </>
                                )
                            })
                        }
                    </Grid>
                </Grid>
                </>
            </DefaultShowLayout>
        );
}
