import {Context, createContext, ReactElement, useContext} from "react";
import {ResourceConfig} from "../types/resource";
import { useAuthenticationContext } from "./AuthenticationContext";

interface ResourceContextValue<T, V> {
    getConfig: () => ResourceConfig<T, V>;
}

interface ResourceContextProps<T, V> {
    children: ReactElement;
    config: ResourceConfig<T, V>;
}

const ResourceContext = createContext<ResourceContextValue<{}, {}> | undefined>(undefined);


function getResourceContext<T, V>(): Context<ResourceContextValue<T, V>> {
    return (ResourceContext as unknown) as Context<ResourceContextValue<T, V>>;
}

export default function ResourceContextProvider<T, V>(props: ResourceContextProps<T, V>): ReactElement {
    const ctx = getResourceContext<T,V>();
    const authContext = useAuthenticationContext();
    props.config.dataProvider?.setAuthProvider(authContext?.getProvider());

    return (<ctx.Provider value={{
                getConfig: () => {
                    return props.config
                }
                }}>
                {props.children}
            </ctx.Provider>)

}

export function useResourceContext<T, V>(): ResourceContextValue<T, V> {
    return useContext<ResourceContextValue<T, V>>(
        (ResourceContext as unknown) as Context<ResourceContextValue<T, V>>
    );

}
