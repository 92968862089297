import {AuthenticationData, IAuthenticationProvider} from "../lib/types/auth";
import jwtDecode, {JwtPayload} from "jwt-decode";
import {ModalContextValue} from "../lib/context/ModalContext";
import MultiFactorAuthForm from "../components/auth/login/mfa/mfaForm";
import axios from "axios";
import {BASE_URL} from "../App";


export interface ICustomAuthData extends AuthenticationData {
    accessToken: string | null,
    refreshToken: string | null,
}

export class CustomAuthData implements ICustomAuthData {

    constructor(accessToken: string | null, refreshToken: string | null) {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
    }

    setAccessToken(accessToken: string | null) {
        this.accessToken = accessToken;
    }

    accessToken: string | null;

    isAuthenticated(): boolean {
        return this.accessToken != null && this.refreshToken != null;
    }

    refreshToken: string | null;

}

export interface MiWebRefreshTokenPayload extends JwtPayload {
    permissions: string[];
    email: string;
    group_id: string;
    group_name: string;
    accesses: string[];
    terms_and_conditions: {
        ContractId: string,
        Terms: boolean,
        Explicit: boolean,
        Date: Date
    }
}


export class MiWebAuthProvider implements IAuthenticationProvider<ICustomAuthData> {

    modalContext: ModalContextValue | undefined;

    setModalCtx(modalCtx?: ModalContextValue): void {
        this.modalContext = modalCtx;
    }

    isValidToken(payload: string | null): boolean {
        if (payload == null) return false;
        try {
            const token = jwtDecode<MiWebRefreshTokenPayload>(payload)
            return Math.floor(Date.now() / 1000) < (token.exp || 0)
        } catch (error) {
            return false;
        }
    }

    getAuthData(): CustomAuthData {
        let authData = {
            accessToken: sessionStorage.getItem('access-token') || null,
            refreshToken: localStorage.getItem('refresh-token') || null,
        };
        if (!this.isValidToken(authData.refreshToken)) {
            authData.refreshToken = null
        }
        if (!this.isValidToken(authData.accessToken)) {
            authData.accessToken = null
        }
        return new CustomAuthData(authData.accessToken, authData.refreshToken);
    }

    getUserName(): string {
        const authData = this.getAuthData()
        if (authData.accessToken === null) {
            return ""
        }
        const token = jwtDecode<MiWebRefreshTokenPayload>(authData.accessToken)
        return token.email
    }

    setAuthData(data: CustomAuthData): void {
        localStorage.setItem('refresh-token', data.refreshToken || '');
        sessionStorage.setItem('access-token', data.accessToken || '');
    }

    logout(allSessions: boolean): Promise<boolean> {
        if (allSessions) {
            return new Promise((resolve, reject) => {
                this.getAuthHeaders(false).then(headers => {
                    axios.put(`${BASE_URL}/auth/logout`, {}, {headers: headers}).then(() => {
                        localStorage.removeItem('refresh-token');
                        sessionStorage.removeItem('access-token');
                        resolve(true)
                    })
                })

            })
        } else {
            return new Promise((resolve, reject) => {
                localStorage.removeItem('refresh-token');
                sessionStorage.removeItem('access-token');
                resolve(true)
            })
        }
    }

    getAuthHeaders(accessTokenRequired: boolean = true): Promise<Record<string, string>> {
        const data: CustomAuthData = this.getAuthData();
        if (data.accessToken !== null && data.refreshToken !== null) {
            return Promise.resolve({
                'X-Auth-Access': `Bearer ${data.accessToken}`,
                'X-Auth-Refresh': `Bearer ${data.refreshToken}`,
            });
        }
        if (data.refreshToken === null) {
            return Promise.reject();
        } else if (!accessTokenRequired) {
            return Promise.resolve({
                'X-Auth-Refresh': `Bearer ${data.refreshToken}`,
            });
        }
        return new Promise((resolve, reject) => {
            this.modalContext?.openModal({
                component: <MultiFactorAuthForm
                    backCallback={() => {
                    }}
                    submitCallback={(otp: string) => {
                        axios.get(`${BASE_URL}/users/profile?check_otp=true`, {
                            headers: {
                                'X-Auth-Refresh': `Bearer ${data.refreshToken}`,
                                'X-Auth-Otp': otp
                            }
                        })
                            .then((resp) => {
                                this.modalContext?.closeModal();
                                if (resp.headers['x-auth-access']) {
                                    let authData = this.getAuthData()
                                    // const authCtx = useAuthenticationContext()
                                    authData.setAccessToken(resp.headers['x-auth-access'])
                                    this.setAuthData(authData);
                                    // authCtx.setAuthenticated(true);
                                    resolve({
                                        'X-Auth-Access': `Bearer ${this.getAuthData().accessToken}`,
                                        'X-Auth-Refresh': `Bearer ${this.getAuthData().refreshToken}`,
                                    });
                                }
                            })
                            .catch((err) => {
                            });
                    }}/>, onClose: () => {}
            });
        })
    }
}
