import {ResourceConfig} from "../../lib/types/resource";
import {SimpleTextMeta} from "../../lib/meta/simpleText/simpleText";
import {DefaultListView} from "../../lib/views/crud/list/default";
import Variable from "./variable";
import {VariablesDataProvider} from "./variablesDataProvider";
import GenericSearch from "../genericSearch";

export const VariablesResource: ResourceConfig<Variable, GenericSearch> = {
    id: "variables",
    label: "Variables",
    dataProvider: new VariablesDataProvider(),
    actions: [],
    edit: undefined,
    fields: [
        {
            id: "id",
            label: "ID",
            meta: new SimpleTextMeta({})
        },
        {
            id: "name",
            label: "Name",
            meta: new SimpleTextMeta({})
        },
        {
            id: "description",
            label: "Description",
            meta: new SimpleTextMeta({})
        },
        {
            id: "dataType",
            label: "DataType",
            meta: new SimpleTextMeta({})
        }
    ],
    list: {
        component: <DefaultListView
            fields={[{id: "id"}, {id: "name"}, {id: "description"}]}
            pageSize={20}
        />,
        id: "variables",
        pageSize: 20,
        fields: [{id: "id"}, {id: "name"}, {id: "description"}]
    },
    create: {
        pageSize: 5,
        fields: [
            {
                id: "name",
                validationOptions: {
                    required: {
                        value: true,
                        message: "Name is required."
                    }
                },
            },
            {
                id: "description",
                validationOptions: {
                    required: {
                        value: true,
                        message: "Description is required."
                    }
                },
            },
            {
                id: "dataType",
                validationOptions: {
                    required: {
                        value: true,
                        message: "Data type is required."
                    },
                },
            }
        ]
    },
    description: "List of the variables",
    isDeletable: () => true,
    primaryKey: "id",
    groupId: "appManager"

}
