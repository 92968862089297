import React from 'react';
import Sidebar from "../lib/components/sidebar/Sidebar";
import {Grid, Typography, useMediaQuery} from '@mui/material';
import Navbar from '../lib/components/navbar/Navbar';
import {useTheme} from "@mui/styles";
import {LayoutStyles} from "./layoutStyles";

interface LayoutProps {
    children: React.ReactNode,
    getPermission?: Promise<{ data: string[]; }>
}

function BaseLayout(props: LayoutProps) {
    const theme = useTheme();
    const smallerScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const classes = LayoutStyles();
    return (
        smallerScreen?
            <Grid container className={classes.mainContainer}>
                <Grid item className={classes.textContainer}>
                    <Typography variant={"pageTitle"}>To use this app, please use a wider screen.</Typography>
                </Grid>
            </Grid>
            :
            <Grid container direction={"row"}>
                <Grid item md={1} xl={1} className={classes.sidebar}>
                    <Sidebar/>
                </Grid>
                <Grid item md={11} xl={11} className={classes.navbar}>
                    <Navbar>
                        {props.children}
                    </Navbar>
                </Grid>
            </Grid>
    );
}

export default BaseLayout;
