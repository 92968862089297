import {
    Box,
    Button,
    CircularProgress,
    Grid, IconButton,
    Link,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import AuthCode from "react-auth-code-input";
import {Controller, useForm} from "react-hook-form";
import QRCode from "react-qr-code";
import {MfaFormStyle} from "../login/mfa/mfaFormStyle";
import googleAuthLogo from '../../../images/icons/login/googleAuthLogo.svg';
import {ContentCopy} from "@mui/icons-material";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";


interface FormData {
    authNumber: string;
}

const QR_TYPE = 'qr';
const CODE_TYPE = 'code';

interface QRCodeGeneratorProps {
    url?: string
    secret?: string
    submitCallback: (data: string) => void
}

const QRCodeGenerator: React.FC<QRCodeGeneratorProps> = (props: QRCodeGeneratorProps) => {
    const [codeType, setCodeType] = useState(QR_TYPE);
    const mfaStyle = MfaFormStyle();
    const {control, handleSubmit, formState} = useForm<FormData>({
        mode: 'onChange',
    });
    const [secretCopied, setSecretCopied] = useState<boolean>(false)

    const onSubmit = (data: FormData) => {
        props.submitCallback(data.authNumber);
    };

    function handleSelect(e: SelectChangeEvent) {
        setCodeType(e.target.value)
    }

    return (
        <Grid container direction={'column'} alignItems={'center'}>
            <Grid item>
                <img src={googleAuthLogo} width={'65vh'} alt={"Google Auth"}/>
            </Grid>
            <Grid item mt={1}>
                <Typography variant="h6">
                    GOOGLE TWO-FACTOR AUTHENTICATION
                </Typography>
            </Grid>
            <Grid item>
                <Link href="#">How it works</Link>
            </Grid>
            <Grid item mt={6}>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={codeType}
                    onChange={handleSelect}
                >
                    <MenuItem value={QR_TYPE}>QR CODE</MenuItem>
                    <MenuItem value={CODE_TYPE}>COPY SEQUENCE</MenuItem>
                </Select>
            </Grid>
            <Grid item mt={4}>
                {
                    codeType === QR_TYPE
                        ?
                        <Box>
                            <Typography align="center">
                                Scan this image with your app. You will see a 6-digit code on your screen.
                            </Typography>
                            <Box textAlign="center" mt={4}>
                                {props.url ? <QRCode size={300} value={props.url}/> : <CircularProgress/>}
                            </Box>
                        </Box>
                        :
                        <Box>
                            <Typography align="center">
                                Copy this sequence in your app. You will see a 6-digit code on your screen.
                            </Typography>
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={"center"} mt={4}>
                                <Typography variant="h5">
                                    {props.secret}
                                </Typography>
                                <CopyToClipboard text={props.secret || ""}
                                                 onCopy={() => {
                                                     setSecretCopied(true)
                                                 }}
                                >
                                    <IconButton>
                                        {
                                            secretCopied ?
                                                <CheckCircleIcon
                                                    color={"primary"}
                                                /> :
                                                <ContentCopy
                                                    color={"primary"}
                                                />
                                        }
                                    </IconButton>
                                </CopyToClipboard>
                            </Box>
                        </Box>
                }

            </Grid>
            <Grid item mt={4}>
                <Typography align="center">
                    Enter the code below to verify your account and login.
                </Typography>
            </Grid>
            <Grid item mt={2}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container direction={'column'} alignItems={'center'}>
                        <Grid item>
                            <Controller
                                name="authNumber"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: true,
                                    pattern: /^[0-9]{6}$/,
                                }}
                                render={({field}) => (
                                    <AuthCode {...field} length={6}
                                              allowedCharacters={'numeric'}
                                              inputClassName={mfaStyle.otpInput}
                                              containerClassName={mfaStyle.otpContainer}/>
                                )}/>
                        </Grid>
                        <Grid item mt={8} mb={10}>
                            <Button
                                sx={{width: '20em'}}
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={!formState.isValid}
                            >
                                VERIFY
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

export default QRCodeGenerator;
