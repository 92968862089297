import {createStyles, makeStyles} from "@mui/styles";

const OTMStyles = makeStyles(() =>
    createStyles({
        root: {
            width: "45vw",
        },
        container: {
            justifyContent: "center"
        },
        titleContainer: {
            width: "100%",
            border: "2px solid red",
            display: "flex",
            justifyContent: "space-between"
        },
        title: {
            paddingLeft: "1em",
            paddingTop: "1em",
            width: "fit-content"
        },
        closeIcon: {
            paddingTop: "0.4em",
            width: "7%",
            cursor: "pointer",
            fontSize: "2.5rem !important"
        },
        modalContent: {
            marginTop: 50,
            width: "90%",
            alignSelf:"center"
        },
        listItem: {
            justifyContent: "space-between !important",
            height: "3em",
            marginTop: "1em"
        },
        filter: {
            marginRight: "2em !important"
        },
        hiddenFilters: {
            padding: "1em",
            marginTop: "0.5em"
        },

    }));

export {OTMStyles};
