import {StaticSelectFilter} from "../lib/meta/select/staticSelect";
import React from "react";


export const useFormatFilter = () => {
    return {
        field: {
            id: "format",
            meta: {
                getFilterComponent: (handleFilter: any, res: any, value?: any) => {
                    return () => (
                        <StaticSelectFilter label={"Format"}
                                            filterId={value}
                                            handleSelect={handleFilter}
                                            selectedValue={value}
                                            options={{csv: "csv", xlsx: "xlsx"}}/>
                    )
                },
                getFormComponent: () => {
                    return <></>
                },
                getShowComponent: () => () => <></>
            },
        },
        hidden: () => false,
        main: false
    };
}
