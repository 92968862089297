import React, {ReactElement, useState} from "react";
import {DefaultModal} from "../../../lib/components/modal/defaultModal";
import {useModalContext} from "../../../lib/context/ModalContext";
import {useFeedbackContext} from "../../../lib/context/FeedbackContext";
import {useLabTestDataProvider} from "../../../resources/labTest/hooks";
import {DownloadRequest, LabTestFilter} from "../../../resources/labTest/labTest/labTest";
import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import {ModalStyles} from "./modalStyles";
import {AxiosError} from "axios";
import {ErrorDTO} from "../../../hoc/axiosHandlerProvider/AxiosHandlerProvider";
import { useController, useForm} from "react-hook-form";

interface BulkDownloadModalProps {
    ids: string[]
    filters?: LabTestFilter
    onClose?: () => void
}

type DescriptionArgument = {
    description: string;
}

export const BulkDownloadTestModal = ({ids, filters, onClose}: BulkDownloadModalProps): ReactElement => {
    const classes = ModalStyles();
    const modalCtx = useModalContext();
    const feedbackCtx = useFeedbackContext()

    const [loading, setLoading] = useState<boolean>(false);
    const [request, setRequest] = useState<DownloadRequest>();

    const dataProvider = useLabTestDataProvider();

    const {control} = useForm<DescriptionArgument>({mode: "onChange"});
    const {field, fieldState} = useController({
        name: "description",
        control: control,
        defaultValue: undefined,
        rules: {
            required: {
                value: true,
                message: "This field is required"
            }
        }
    });

    const generateRequest = () => {
        setLoading(true)
        dataProvider.createDownloadRequest(
            ids.length !== 0 ? ids.join(",") : undefined, filters, field.value).then((res) => {
            setLoading(false)
            setRequest(res)
            return feedbackCtx.openBottomSuccessSnackbar("Download request generated successfully.")
        }).catch((error) => {
            const axiosError = error as AxiosError<ErrorDTO, unknown>;
            setLoading(false)
            return feedbackCtx.openBottomErrorSnackbar(`Error: the code was not generated. Cause ${axiosError.response?.data.msg}`)
        })
    }

    const onModalClose = () => {
        modalCtx?.closeModal();
        onClose && onClose();
    }

    return (
            <DefaultModal
                title={"Download lab tests"}
                description={
                    loading? "Generating new request..." :
                    request? "Request generated successfully. You will receive an email when your downloads are available" :
                    "Generate new request for the bulk download."}
                handleCancel={onModalClose}
                handleConfirm={generateRequest}
                cancelLabel={request? "Done" : "Cancel"}
                confirmLabel={request? undefined : "Create request"}
                confirmButtonDisabled={loading || !field.value}
                cancelButtonDisabled={loading}
            >
                <>
                    {
                        request === undefined &&
                        <Box className={classes.downloadBox}>
                                <TextField
                                    className={classes.descriptionInputText}
                                    multiline
                                    minRows={5}
                                    placeholder={"Why do you want to download this files?"}
                                    onChange={field.onChange}
                                    value={field.value}
                                    error={fieldState.error !== undefined}
                                    helperText={fieldState.error?.message}
                                />
                        </Box>

                    /*code &&
                        <Box>
                            <div className={classes.downloadBox}>
                                <Typography
                                    className={classes.code}
                                ><strong>{code}</strong></Typography>
                                <Button
                                    className={classes.copyButton}
                                    variant="outlined"
                                    onClick={handleCopy}
                                    disabled={copied}>
                                    <ContentCopyIcon fontSize={"small"}/>
                                    {copied ? "Copied" : "Copy Code"}
                                </Button>
                            </div>
                        </Box>*/
                    }
                </>
            </DefaultModal>
    )
}
