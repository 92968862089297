import {createTheme, FormHelperText, Grid, MenuItem, Select, Typography} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { Control, FieldValues, Path, RegisterOptions, useController } from "react-hook-form";
import { Field } from "../../types/field";
import { ListMeta, MetaField } from "../../types/meta";
import { ResourceConfig } from "../../types/resource";
import { SelectStyles } from "./selectStyles";
import {ButtonsVariant, CustomTheme} from "../../../theme";


interface SelectMetaProps<R, T, V> {
    resource?: ResourceConfig<T, V>;
    badgeColor?: (data?: R) => string
    filter?: (res: R, primaryKey?: string) => V;
    valuer: (data: T) => string,
    secondaryValuer?: (data: R) => string;
}

export class SelectBadgeMeta<R, T, V> implements MetaField<R> {
    resource?: ResourceConfig<T, V>;
    badgeColor?: (data?: R) => string
    filter?: (res: R) => V | undefined;
    valuer: (data: any) => string;
    secondaryValuer?: (data: R) => string;

    constructor(props: SelectMetaProps<R, T, V>) {
        this.resource = props.resource;
        this.badgeColor = props.badgeColor;
        this.filter = props.filter;
        this.valuer = props.valuer;
        this.secondaryValuer = props.secondaryValuer;
    }

    getListMeta(): ListMeta {
        return {
            type: 'string',
            value: (arg: any) => {
                return arg;
            },
        };
    }

    getFormComponent(control: Control, name: Path<FieldValues>, field: Field<R>, data?: R): ReactElement {
        return SelectForm<R, T, V>({
            data: data,
            resource: this.resource ?? undefined,
            formControl: control,
            name: name,
            filter: this.filter,
            valuer: this.valuer,
            secondaryValuer: this.secondaryValuer,
            validationOptions: field.validationOptions}
        );
    };

    getShowComponent(data: R | undefined, field: Field<R>) {
        return () => {
            return SelectShow({ label: field.label, data: data ?? undefined, field: field, badgeColor: this.badgeColor })
        }
    };
}

interface SelectShowProps<R> {
    label?: string;
    data?: R;
    field?: Field<R>;
    badgeColor?: (data?: R) => string
}

const theme = createTheme(CustomTheme(), ButtonsVariant());

function SelectShow<R>({ label, data, field, badgeColor }: SelectShowProps<R>) {
    const classes = SelectStyles();
    const backgroundColor = badgeColor ? badgeColor(data) : theme.palette.primary.main;
    return (
        <Grid container justifyContent={"space-between"}>
            <Grid item paddingBottom={"1em"}>
                <Typography variant={"dataTitle"}>
                    {label}</Typography>
            </Grid>
            <Grid item className={classes.badge} style={{backgroundColor: backgroundColor}}>
                {!data || !field? "--" : (data[field.id] as string).toUpperCase()}
            </Grid>
        </Grid>)
}

interface SelectFormProps<R, T, V> {
    data?: R;
    resource?: ResourceConfig<T, V>,
    filter?: (res: R) => V | undefined;
    valuer: (data: T) => string,
    secondaryValuer?: (data: R) => string;
    formControl: Control,
    name: Path<any>,
    validationOptions?: RegisterOptions | undefined,
}

export function SelectForm<R, T, V>(props: SelectFormProps<R, T, V>) {
    const [data, setData] = useState<T[]>([]);
    const [value, setValue] = useState<string>()
    const { field, fieldState } = useController({
        name: props.name,
        control: props.formControl,
        rules: props.validationOptions,
        defaultValue: props.name
    });
    useEffect(() => {
        props.resource?.dataProvider?.pagedList(undefined, undefined, undefined)
            .then((data) => {
            setData(data.data);
        });
    }, []);

    return (
        <>
        {
            data &&
            <>
            <Select
                {...field}
                style={{width:"20em"}}
                error={fieldState.error !== undefined}
                displayEmpty
                value={value}
                renderValue={() => <Typography style={{opacity:0.6, overflow: "hidden", textOverflow: "ellipsis", width: '11rem'}}>
                                        {value? value : props.data? props.secondaryValuer && props.secondaryValuer(props.data) : "Select..."}
                                   </Typography>
            }
            >
                {data.map((item) => (
                    <MenuItem
                        onClick={() => setValue(props.valuer(item))}
                        value={props.resource && props.resource.primaryKey && item[props.resource.primaryKey] as string}>
                        {props.valuer(item)}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText error>{fieldState.error?.message}</FormHelperText>
            </>
        }
        </>
    )
}
