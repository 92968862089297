import { Filters } from "../../../lib/types/filter"
import { ClinicalData } from "./clinicalData"
import {SelectFilter as AutocompleteSelectFilter} from "../../../lib/meta/select/autocompleteSelect";
import {ClinicalStudiesResource} from "../../appManager/clinicalStudies/clinicalStudyResource";
import SimpleTextFilter from "../../../lib/components/filters/singleFilters/simpleText/simpleTextFilter";
import React from "react";
import {ClinicalStudy} from "../../appManager/clinicalStudies/clinicalStudy";
import {StaticSelectFilter} from "../../../lib/meta/select/staticSelect";


export const clinicalDataFilter: Filters<ClinicalData> = {
    filters: [
        {
            field:  {
                id: "clinical_study_id",
                label: "Clinical Study",
                meta: {
                    getFormComponent: () => <></>,
                    getFilterComponent: (handleFilter: any, res: any, value?: any) => {
                        return () => (
                            <AutocompleteSelectFilter
                                          label={"Select Clinical study"}
                                          resource={ClinicalStudiesResource}
                                          handleSelect={handleFilter}
                                          valuer={(clinicalStudy: ClinicalStudy) => clinicalStudy.name.toString()}
                                          selectedRecord={value}
                                          clearable={false}
                            />
                        )
                    },
                    getShowComponent: () => () => <></>
                },
            },
            hidden: () => false,
            main: true,
            rendersElement: true
        },
        {
            field: {
                id: "patient_id",
                meta: {
                    getFilterComponent: (handleFilter: any, res: any, value?: any) => {
                        return () => <SimpleTextFilter fieldLabel={"Patient ID"} handleResult={handleFilter} value={value}/>
                    },
                    getFormComponent: () => {
                        return <></>
                    },
                    getShowComponent: () => () => <></>
                },
            },
            hidden: () => true,
        },
        {
            field: {
                id: "format",
                meta: {
                    getFilterComponent: (handleFilter: any, res: any, value?: any) => {
                        return () => (
                            <StaticSelectFilter label={"Format"}
                                                filterId={value}
                                                handleSelect={handleFilter}
                                                selectedValue={value}
                                                options={{csv: "csv", xlsx: "xlsx"}}/>
                        )
                    },
                    getFormComponent: () => {
                        return <></>
                    },
                    getShowComponent: () => () => <></>
                },
            },
            hidden: () => false,
            main: false
        }
    ]
}
