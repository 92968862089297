import {ListMeta, MetaField} from "../../types/meta";
import React, {FC, ReactElement} from "react";
import {Grid, Typography} from "@mui/material";
import { Field } from "../../types/field";
import {Control, FieldValues, Path} from "react-hook-form";

interface IdProps {
    label?: string;
    data?: string;
}
const IdShow = ({label, data}: IdProps): ReactElement => {
    return (
        <>
            <Grid item paddingBottom={"1em"}>
                <Typography variant={"dataTitle"}>
                    {label}
                </Typography>
            </Grid>
            <Grid item paddingBottom={"1em"}>
                <Typography variant={"data"}>
                    {!data? "--" : data}
                </Typography>
            </Grid>
        </>
    )
}

export class IDMeta<T> implements MetaField<T> {

    getListMeta(): ListMeta {
        return ({
            type: 'number',
            value: (arg: any) => { return arg },
        })
    };

    getFormComponent(
        control: Control,
        name: Path<FieldValues>,
        field: Field<T>,
        data?: T): React.ReactElement {
        return <Grid item paddingBottom={"1em"}><Typography variant={"data"}>{!data ? "--" : data[field.id] as string}</Typography></Grid>
    }

    getShowComponent(data: T | undefined, field: Field<T>): FC {
        return () => {
            return IdShow({label: field.label, data: data ? data[field.id] as string : ''})
        }
    }

}
