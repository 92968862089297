import {Divider, Grid, ListItem, Typography} from "@mui/material";
import React, {Fragment, ReactElement, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import NavTabs from "../../../components/tabs/NavTabs";
import {useResourceContext} from "../../../context/ResourceContext";
import {DefaultStyles} from "./defaultStyles";
import {Field} from '../../../types/field';
import {DefaultShowLayout} from "./showLayout";
import {getDetailErrorCause} from "../../../../resources/messages";

interface SubTab<T> {
    label: string
    fields: (keyof T)[]
    isDisabled?: (data?: T) => boolean
}

interface DefaultShowTabViewProps<T> {
    id: string;
    tabs: SubTab<T>[];
    fields?: Field<T>[];
    getTitle?: (data?: T) => ReactElement;
    previousPage?: string | number;
}

export function DefaultShowTabView<T, V>(props: DefaultShowTabViewProps<T>): ReactElement {
    const [error, setError] = useState<number>();
    const classes = DefaultStyles({error: error});
    const resourceContext = useResourceContext<T, V>()
    const [data, setData] = useState<T | undefined>(undefined);
    const {id} = useParams();

    const resource = resourceContext.getConfig();

    useEffect(() => {
        setData(undefined)
        initData();
    }, [resource])

    function initData() {
        if (!id) return;
        resource?.dataProvider?.getById(id)
            .then((data) => {
                setData(data);
            })
            .catch((error) => {
                setError(error.response.status)
            });
    }

    function getField(fieldId: string) {
        const field = resource.fields.find(f => f.id === fieldId);
        if (!field) throw Error('Field not found!')
        return field;
    }

    function mergeField(field: Field<T>): Field<T> {
        const propsField = props.fields?.find(f => f.id === field.id);
        if (!propsField) return field;
        if (propsField.validationOptions) field.validationOptions = propsField.validationOptions;
        if (propsField.meta) field.meta = propsField.meta;
        if (propsField.label) field.label = propsField.label;
        return field;
    }

    return (
        <DefaultShowLayout
            data={data}
            getTitle={props.getTitle}
            previousPage={props.previousPage}
            onActionCompleted={() => {initData()}}
            error={error}
        >
            <>
            {
                error &&
                <Grid className={classes.errorContainerTabs}>
                    <Typography variant={"dataTitle"} color={"error"}>
                        <strong>ATTENTION!</strong> {getDetailErrorCause(error)}
                    </Typography>
                </Grid>
            }
            <Grid item className={classes.tabDataContainer}>
                <NavTabs
                    tabs={props.tabs.map((tab) => {
                        return (
                            {
                                label: tab.label,
                                isDisabled: tab.isDisabled && data && tab.isDisabled(data),
                                children: (
                                    <Grid marginTop={"-1em"}> {tab.fields
                                        .map((f: keyof T, index) => {
                                            const field = getField(f.toString());
                                            const mergedField = mergeField((field as Field<T>));
                                            if (!mergedField.meta)
                                                return <></>;
                                            const ShowComponent = mergedField.meta.getShowComponent(data, mergedField);
                                            return (<Fragment key={index}>
                                                        <ListItem className={classes.showListItem}>
                                                            {ShowComponent({})}
                                                        </ListItem>
                                                        <Divider/>
                                                    </Fragment>
                                            );
                                        })}
                                    </Grid>
                                )
                            }
                        );
                    })}
                ></NavTabs>
            </Grid>
            </>
        </DefaultShowLayout>
    )
}
