import {Grid, Link} from "@mui/material";
import {MetaField} from "../../types/meta";
import React, {ReactElement} from "react";
import { Field } from "../../types/field";
import {useModalContext} from "../../context/ModalContext";
import DefaultSideModal from "../../components/sideModal/SideModal";
import IDataProvider from "../../../hoc/dataProvider/IDataProvider";
import {AuthenticationContextValue, useAuthenticationContext} from "../../context/AuthenticationContext";
import {AuthenticationData, IAuthenticationProvider} from "../../types/auth";
import {CustomAuthData} from "../../../auth/provider";

export interface ModalConfig<T, V> {
    getAdditionalData?: (dataProvider?: IDataProvider<unknown, unknown> | unknown, data?: T, authProvider?: IAuthenticationProvider<AuthenticationData | CustomAuthData>) => Promise<V | V[]>;
    getBody?: (data?: T, additionalData?: V | V[], authCtx?: AuthenticationContextValue<AuthenticationData>) => (ReactElement | Promise<JSX.Element>);
}
interface HyperlinkProps<T, V> {
    childMeta: MetaField<T>;
    title?: string;
    dataProvider?: IDataProvider<any, any>;
    modalConfig?: ModalConfig<T,V>;
}

interface HyperlinkShowProps<T, V> extends HyperlinkProps<T, V> {
    data: T | undefined,
    field: Field<T>
}

function HyperlinkShow<T, V>(props: HyperlinkShowProps<T, V>, component: ReactElement): ReactElement {
    const modalContext = useModalContext();

    const dataProvider = props.dataProvider;

    const authCtx = useAuthenticationContext();
    dataProvider?.setAuthProvider(authCtx.getProvider());
    const openSideModal = async () => {
        const modalData = props.modalConfig?.getAdditionalData && await props.modalConfig?.getAdditionalData(dataProvider, props.data, authCtx.getProvider());
        const modalBody = props.modalConfig?.getBody && await props.modalConfig?.getBody(props.data, modalData, authCtx);

        modalContext?.openSideModal({
            anchor: 'right',
            component: (
                <DefaultSideModal anchor={"right"} title={props.title!} onClose={modalContext?.closeSideModal}>
                    {modalBody}
                </DefaultSideModal>
            ),
        });
    };

    return (
        <>
            <Grid style={{ cursor: "pointer", width:"100vw"}} onClick={openSideModal}>
                <Link fontWeight={"bolder"}>{component}</Link>
            </Grid>
        </>
    );
}

export class HyperlinkMeta<T> implements MetaField<T> {
    childMeta: MetaField<T>;
    title?: string;
    dataProvider?: IDataProvider<any, any>;
    modalConfig?: {
        onOpen?: (dataProvider: IDataProvider<unknown, unknown>) => Promise<any>;
        getBody?: (data?: T, additionalData?: any) => (ReactElement | Promise<JSX.Element>);
    };

    constructor(props: HyperlinkProps<T, any>) {
        this.childMeta = props.childMeta;
        this.title = props.title;
        this.dataProvider = props.dataProvider;
        this.modalConfig = props.modalConfig;
    }

    getFilterComponent?: ((handler?: ((data: T) => void) | undefined) => React.FC) | undefined;

    getFormComponent(): ReactElement {
        return <></>;
    }

    getShowComponent(data: T | undefined, field: Field<T>) {
        const ShowComponent = this.childMeta.getShowComponent(data, field)
        return () => {
            return HyperlinkShow({
                childMeta: this.childMeta,
                title: this.title,
                dataProvider: this.dataProvider,
                modalConfig: this.modalConfig,
                data: data, field: field
            }, <ShowComponent />)
        }
    }
    getListComponent(data: T | undefined, field: Field<T>) {
        const ListComponent = this.childMeta.getListComponent && this.childMeta.getListComponent(data, field);
        return () => {
            if (ListComponent) {
                return HyperlinkShow({
                    childMeta: this.childMeta,
                    title: this.title,
                    dataProvider: this.dataProvider,
                    modalConfig: this.modalConfig,
                    data: data, field: field
                }, <Grid width={"10em"}><ListComponent/></Grid>)
            }
            return <></>;
        }
    }
}
