import {createStyles, makeStyles} from "@mui/styles";


const AuthStyles = makeStyles(() =>
    createStyles({
        authFormContainer: {
            alignItems: "center",
            height: "100vh"
        },
        logoContainer: {
            display: "flex",
            // @ts-ignore
            flexDirection: "column !important",
            justifyContent: "center",
            height: "20vh",
            alignItems: "center",
            marginTop: "15% !important"
        },
        logo: {
            height: "20vh",
            maxHeight: "150px"
        },
        children: {
            width: "50%"
        },
        loginFormContainer: {
            display: "flex",
            alignItems: "center"
        },
        loginFormItem: {
            width: "100%"
        }
    }));

export {AuthStyles};
