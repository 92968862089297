import {createContext, ReactElement, ReactNode, useContext} from "react";
import {IAuthorizationProvider} from "../types/auth";
import {useAuthenticationContext} from "./AuthenticationContext";

export enum Verb {
    WRITE = "write",
    READ = "read"
}
export interface AuthorizationContextValue {
    hasAccess: (group: string, resource?: string, verb?: Verb) => boolean;
    hasSignedContract: boolean;
    getRoleName: () => string;
    getRoleId: () => string;
}

interface AuthorizationContextProps {
    provider: IAuthorizationProvider;
    children: ReactNode;
}

const AuthorizationContext = createContext<AuthorizationContextValue>({
    hasAccess(group: string, resource?: string, verb?: Verb): boolean {
        return false
    },
    hasSignedContract: false,
    getRoleName: () => "",
    getRoleId: () => ""
});

export function useAuthorizationContext(): AuthorizationContextValue {
    return useContext<AuthorizationContextValue>(AuthorizationContext);
}

export default function AuthorizationContextProvider(props: AuthorizationContextProps): ReactElement {
    const authenticationContext = useAuthenticationContext()
    return <AuthorizationContext.Provider value={
        {
            hasAccess:
                (group: string,
                 resource?: string, verb?: Verb) => props.provider.hasAccess(authenticationContext.getProvider(), group, resource, verb),
            hasSignedContract: props.provider.getContractSigned(authenticationContext.getProvider()),
            getRoleName: () => props.provider.getRoleName(authenticationContext.getProvider()),
            getRoleId: () => props.provider.getRoleId(authenticationContext.getProvider()),
        }
    }>
        {props.children}
    </AuthorizationContext.Provider>
}
