import {createStyles, makeStyles} from "@mui/styles";

const SourceFileUploadStyles = makeStyles(() =>
    createStyles({
        title: {
            width: "fit-content",
            maxWidth: "15em",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        formContainer: {
            marginTop:"3em !important",
            justifyContent: "space-between !important",
            height:"200px"
        },
        fileDropBox: {
            backgroundColor: "#DDDDDD",
            borderRadius: "0.5em",
            marginTop: "1em"
        },
        dropBoxContainer: {
            justifyContent:"center"
        },
        fileSelectButton: {
            cursor: "pointer"
        },
        buttonContainer: {
            alignItems:"center !important"
        },
        keyValueSelect: {
            marginTop: "2em",
            marginLeft: "-1em",
            width: "35em !important"
        },
        keyValueSelectLabel: {
            marginRight: "2em",
            width: "25em"
        },
        input: {
            width: "15em !important"
        }
    }));

export {SourceFileUploadStyles};
