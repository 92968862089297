import {Document, DocumentFilter} from "../../resources/documents/document"
import axios from "axios";
import {BASE_URL} from "../../App";
import {IAuthenticationProvider} from "../../lib/types/auth";
import {CustomAuthData} from "../../auth/provider";
import {Role} from "../../resources/appManager/roles/role";

export enum DocumentAccessRule {
    NONE = "NONE",
    READ = "READ",
    WRITE = "WRITE",
    ADMIN = "ADMIN"
}

interface Access {
    access: {
        document_id: string,
        document_name: string
    },
    pattern: string,
    resource: string,
    rule: DocumentAccessRule
}

export interface DocumentAccess {
    access: Access,
    group_info: Role
}

export class DocumentsDataProvider {
    constructor(private authProvider: IAuthenticationProvider<CustomAuthData>) {
    }

    getHeaders = async (p: { Accept: string }): Promise<Record<string, string>> => {
        let headers = p;
        if (this.authProvider) {
            await this.authProvider.getAuthHeaders().then((authHeaders) => {
                headers = {...headers, ...authHeaders}
            })
        }
        return Promise.resolve(headers)
    }

    getById = async (id: string): Promise<Document | undefined> => {
        return axios.get<Document>(`${BASE_URL}/documents/${id}`, {headers: await this.getHeaders({"Accept": "application/json"})})
            .then(r => {
                return r.data;
            });
    }

    getDocumentAccess = async (id: string): Promise<DocumentAccess[]> => {
        return axios.get<DocumentAccess[]>(`${BASE_URL}/documents/${id}/access`, {headers: await this.getHeaders({"Accept": "application/json"})})
            .then(r => {
                return r.data;
            });
    }

    listDocuments = async (page_size?: number, page_token?: string, filter?: DocumentFilter): Promise<{
        data: Document[],
        page_token: string
    }> => {
        const headers = await this.getHeaders({"Accept": "application/json"});
        return axios.get(`${BASE_URL}/documents`, {headers: headers, params: {page_size, page_token, ...filter}})
            .then(r => {
                return r.data
            })
            .catch((err) => {
                if (err.response) {
                    throw err.response.status;
                }
                throw err;
            });
    }

    assignDocumentAccesses = async (id: string, data: { access_rule: string, group_id: string }): Promise<boolean> => {
        return axios.post<boolean>(`${BASE_URL}/documents/${id}/access`, data, {headers: await this.getHeaders({"Accept": "application/json"})})
            .then(r => {
                return r.data;
            });
    }

    removeDocumentAccesses = async (id: string, data: { access_rule: string, group_id: string }): Promise<boolean> => {
        return axios.put<boolean>(`${BASE_URL}/documents/${id}/remove_access`, data, {headers: await this.getHeaders({"Accept": "application/json"})})
            .then(r => {
                return r.data;
            });
    }


    delete = async (id: string): Promise<boolean> => {
        return axios.delete(`${BASE_URL}/documents/${id}`, {headers: await this.getHeaders({"Accept": "application/json"})})
            .then(r => {
                return r.data;
            });
    }

    createFolder = async (data: Document): Promise<Document> => {
        return axios.post<Document>(`${BASE_URL}/documents/mkdir`, data, {headers: await this.getHeaders({'Accept': 'application/json'})})
            .then(r => {
                return r.data;
            });
    }

    download = async (id: string) => {
        return axios.get(`${BASE_URL}/documents/${id}/download`, {
            headers: await this.getHeaders({"Accept": "application/json"}),
            responseType: "blob"
        }).then(r => {
            return r.data
        });
    }

    upload = async (parentId: string, data: FormData): Promise<Document> => {
        return axios.post<Document>(`${BASE_URL}/documents/${parentId}/upload`,
            data,
            {headers: await this.getHeaders({'Accept': 'multipart/form-data'})}).then(r => {
            return r.data
        });
    }
}
