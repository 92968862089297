import React, {ReactElement, useRef, useState} from "react";
import {DefaultModal} from "../../../lib/components/modal/defaultModal";
import {useModalContext} from "../../../lib/context/ModalContext";
import {CircularProgress, Grid, IconButton, Typography} from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import {ModalStyles} from "./modalStyles";
import {useFeedbackContext} from "../../../lib/context/FeedbackContext";
import {useLabTestDataProvider} from "../../../resources/labTest/hooks";
import CancelIcon from "@mui/icons-material/Cancel";
import {AxiosError} from "axios";
import {ErrorDTO} from "../../../hoc/axiosHandlerProvider/AxiosHandlerProvider";

export const UploadTestModal = (): ReactElement => {
    const classes = ModalStyles();
    const modalCtx = useModalContext();
    const feedbackCtx = useFeedbackContext()

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const [testId, setTestId] = useState<string>();

    const [uploading, setUploading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const dataProvider = useLabTestDataProvider();

    const handleUpload = () => {
        if (!selectedFile) return;

        const blob = new Blob([selectedFile], {type: "multipart/form-data"});
        const formData = new FormData();
        formData.append("tagName", selectedFile?.name.split(".").slice(0, -1).join("."));
        formData.append("document", blob, selectedFile?.name);

        setUploading(true);

        setTimeout(() => {
            dataProvider.directUpload(formData).then((res) => {
                    setUploading(false);
                    setTestId(res.id);
                    feedbackCtx.openBottomSuccessSnackbar(`Lab test uploaded successfully.`);
                    modalCtx?.closeModal();
                }
            ).catch((error) => {
                    const axiosError = error as AxiosError<ErrorDTO, unknown>;
                    setUploading(false);
                    setError(axiosError.response?.data.msg || "unknown");
                    feedbackCtx.openBottomErrorSnackbar(`Error: the lab test was not uploaded correctly.`);
                }
            )
        }, 1000)
    }

    const handleCancel = () => {
        if (testId) {
            dataProvider.delete(testId).then(() => {})
        }
        modalCtx?.closeModal()
    }

    return (
        error ?
            <DefaultModal
                title={"Upload file"}
                description={`Error during the upload. Cause: ${error}`}
                handleCancel={() => modalCtx?.closeModal()}
                cancelLabel={"Go back"}
                confirmButtonDisabled={false}
            >
                <></>
            </DefaultModal>
            :
        <DefaultModal
        title={"Upload file"}
        description={uploading? "Uploading..." : "Select the lab test you want to upload."}
        handleCancel={handleCancel}
        handleConfirm={handleUpload}
        cancelLabel={"Cancel"}
        confirmLabel={"Upload"}
        confirmButtonDisabled={!selectedFile}
        >
        <Grid container direction={"column"} className={classes.uploadContainer}>
            <FolderOpenIcon fontSize={"large"}/>
            {
                uploading? <CircularProgress/>
                    :
                    selectedFile ?
                        <Grid item container className={classes.fileName}>
                            <IconButton onClick={() => {
                                setSelectedFile(undefined)
                                if (fileInputRef.current) {
                                    fileInputRef.current.files = null
                                    fileInputRef.current.value = ""
                                }
                            }}>
                                <CancelIcon/>
                            </IconButton>
                            <Typography variant={"body1"}>
                                {selectedFile.name}
                            </Typography>
                        </Grid>
                        :
                        <>
                            <label htmlFor="fileInput">
                                <Typography variant={"dataTitle"} className={classes.uploadLabel}
                                >Browse</Typography>
                            </label>
                            <input id="fileInput"
                                   onChange={(ev) => {
                                       if (ev.target.files !== null && ev.target.files.length > 0) {
                                           setSelectedFile(ev.target.files[0])
                                       }
                                   }}
                                   hidden
                                   ref={fileInputRef}
                                   type="file"
                            />
                        </>
            }
        </Grid>
    </DefaultModal>
    )
}
