import React, {ReactElement, useState} from "react";
import {Access, AccessObjectLabTest} from "../../../../resources/authorizations/access/access";
import {RolesEditStyles} from "../rolesEditTabStyles";
import {PermissionLevel, Permissions} from "../../../../resources/authorizations/permission/permissions";
import {
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    List,
    ListItem,
    MenuItem,
    Select,
    Switch,
    Typography
} from "@mui/material";

interface LabTestPermissionsProps {
    editEnabled: boolean;
    totalAccesses: Access[];
    currentAccesses: Access[];
    currentPermissions: string[];
    onPermissionsChange: (data: string[]) => void;
    onAccessesChange: (data: Access[]) => void;
}

export function LabTestPermissions(props: LabTestPermissionsProps): ReactElement {
    const classes = RolesEditStyles();

    const [currentPermissions, setCurrentPermissions] = useState<string[]>(props.currentPermissions);
    const [currentAccesses, setCurrentAccesses] = useState<Access[]>(props.currentAccesses);

    const hasMetadataFilesPermissions = (): boolean => {
        let hasPermissions = true
        const sourceFileAllPermissions = Permissions["lab"]["metadataFiles"].read.concat(Permissions["lab"]["metadataFiles"].write);
        sourceFileAllPermissions.forEach(p=>{
            if(currentPermissions.indexOf(p)===-1){
                hasPermissions=false
            }
        })
        return hasPermissions
    }

    const [metadataFilesPermissions, setMetadataFilesPermissions] = useState<boolean>(hasMetadataFilesPermissions());

    const getCurrentPermissionLevel = (): PermissionLevel => {
        if (currentPermissions.includes(Permissions["lab"]["labTest"].read[0])) {
            if (props.currentAccesses.length > 0) {
                if (props.currentAccesses.find(a => {return (a.access as AccessObjectLabTest).clinical_study_id === "*"})) {
                    return PermissionLevel.ADMIN
                }
                return PermissionLevel.DOWNLOAD
            }
            return PermissionLevel.EXTERNAL
        }
        return PermissionLevel.NONE
    }

    const [permissionLevel, setPermissionLevel] = useState<PermissionLevel>(getCurrentPermissionLevel());

    const onPermissionLevelChanged = (val: string | PermissionLevel) => {
        const newPermissionLevel = val as PermissionLevel
        let newAccesses: Access[] = []
        let newPermissions: string[] = []
        switch (newPermissionLevel) {
            case PermissionLevel.ADMIN:
                newAccesses = [...props.totalAccesses.filter(a => {
                    const labTestAccess = a.access as AccessObjectLabTest
                    return labTestAccess.clinical_study_id === '*'
                })]
                newPermissions = [
                    ...new Set(currentPermissions.concat(...Permissions["lab"]["labTest"].read, ...Permissions["lab"]["labTest"].write))
                ]
                break;
            case PermissionLevel.DOWNLOAD:
                newPermissions = [
                    ...new Set(currentPermissions.filter((p => [...Permissions["lab"]["labTest"].write].indexOf(p) === -1)).concat(...Permissions["lab"]["labTest"].read))
                ]
                newAccesses = []
                break;
            case PermissionLevel.EXTERNAL:
                newPermissions = [
                    ...new Set(currentPermissions.concat(...Permissions["lab"]["labTest"].read, ...Permissions["lab"]["labTest"].write))
                ]
                newAccesses = []
                break;
            case PermissionLevel.NONE:
                newAccesses = []
                newPermissions = currentPermissions.filter(p => [...Permissions["lab"]["labTest"].read, ...Permissions["lab"]["labTest"].write].indexOf(p) === -1)
                break;
            default:
                return
        }
        setCurrentPermissions(newPermissions)
        setCurrentAccesses(newAccesses)
        props.onAccessesChange(newAccesses)
        props.onPermissionsChange(newPermissions)
        setPermissionLevel(newPermissionLevel)
    }

    const onMetadataFilesPermissionChanged = () => {
        const newSourceFilePermissions = !metadataFilesPermissions

        const sourceFileAllPermissions = Permissions["lab"]["metadataFiles"].read.concat(Permissions["lab"]["metadataFiles"].write);

        const newPermissions = newSourceFilePermissions ?
            [
                ...new Set(currentPermissions.concat(sourceFileAllPermissions))
            ] :
            currentPermissions.filter(p => !sourceFileAllPermissions.includes(p))
        setCurrentPermissions(newPermissions)
        setMetadataFilesPermissions(newSourceFilePermissions)
        props.onPermissionsChange(newPermissions)
    }

    const labTestSection = (): ReactElement => {
        switch (permissionLevel) {
            case PermissionLevel.NONE:
                return (<Typography variant={"h5"}>Has no access to any clinical study.</Typography>)
            case PermissionLevel.ADMIN:
                return (<Typography variant={"h5"}>Has access to every clinical study.</Typography>)
            case PermissionLevel.DOWNLOAD:
                const onLabTestAccessesChange = (clinicalStudyId: string): void => {
                    const isChecked = currentAccesses.find((a) => (a.access as AccessObjectLabTest).clinical_study_id === clinicalStudyId);
                    let newAccesses: Access[];

                    if (isChecked) {
                        newAccesses = currentAccesses.filter((a) => (a.access as AccessObjectLabTest).clinical_study_id !== clinicalStudyId);
                    } else {
                        newAccesses = [
                            ...currentAccesses,
                            ...props.totalAccesses.filter((a) => {
                                const labTestAccess = a.access as AccessObjectLabTest;
                                return labTestAccess.clinical_study_id === clinicalStudyId;
                            })
                        ];
                    }

                    props.onAccessesChange(newAccesses);
                    setCurrentAccesses(newAccesses);
                }
                return (
                    <List>
                        {
                        props.totalAccesses
                            .filter((a) => (a.access as AccessObjectLabTest).clinical_study_id !== "*")
                            .map((a) => {
                            const labTestAccess = a.access as AccessObjectLabTest;

                            return (labTestAccess &&
                                <>
                                    <ListItem className={classes.listItem}>
                                        <div>{labTestAccess.clinical_study_name}</div>
                                        <div>
                                            <Switch sx={{m: 1}}
                                                className={classes.switch}
                                                disabled={!props.editEnabled}
                                                key={(a.access as AccessObjectLabTest).clinical_study_id}
                                                checked={
                                                    currentAccesses.find(
                                                        (a) => (a.access as AccessObjectLabTest).clinical_study_id === labTestAccess.clinical_study_id)
                                                    !== undefined
                                                }
                                                onChange={() => {
                                                    onLabTestAccessesChange((a.access as AccessObjectLabTest).clinical_study_id)
                                                }}/>
                                        </div>
                                    </ListItem>
                                    <Divider/>
                                </>
                        )})
                    }
                    </List>
                )
            default:
                return <></>
        }
    }


    return (
        <>
            <ListItem className={classes.showListItem}>
                <Typography variant={"dataTitle"}>Metadata file</Typography>
                <FormGroup>
                    <FormControlLabel control={
                        <Switch
                            focusVisibleClassName=".Mui-focusVisible"
                            disableRipple
                            className={classes.switch}
                            sx={{m: 1}}
                            disabled={!props.editEnabled}
                            checked={metadataFilesPermissions}
                            onChange={() => {
                                onMetadataFilesPermissionChanged()
                            }}/>}
                                      label={"All permissions"}
                                      labelPlacement={"start"}
                    ></FormControlLabel>
                </FormGroup>
            </ListItem>
            <Divider/>
            <ListItem className={classes.showListItem}>
                <Typography variant={"dataTitle"}>Lab Test</Typography>
                <>
                    <Grid container direction={"row"} className={classes.selectContainer}
                          width={"fit-content"}>
                        <Typography>Permission Level &nbsp;</Typography>
                        <Select
                            disabled={!props.editEnabled}
                            onChange={(ev) => {
                                onPermissionLevelChanged(ev.target.value)
                            }}
                            value={permissionLevel}
                            className={!props.editEnabled ? classes.disabledSelect : classes.enabledSelect}
                        >
                            <MenuItem value={PermissionLevel.NONE}>
                                None
                            </MenuItem>
                            <MenuItem value={PermissionLevel.ADMIN}>
                                Admin
                            </MenuItem>
                            <MenuItem value={PermissionLevel.DOWNLOAD}>
                                Download
                            </MenuItem>
                            <MenuItem value={PermissionLevel.EXTERNAL}>
                                External
                            </MenuItem>
                        </Select>
                    </Grid>
                </>
            </ListItem>
            <Divider/>
            <Grid container paddingTop={2} minWidth={"100%"}>
                <Grid item lg={12} display={"flex"} justifyContent={"center"}>
                    {labTestSection()}
                </Grid>
            </Grid>
        </>
    )
}
