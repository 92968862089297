import {
    Grid, Icon, IconButton,
    List,
    ListItem,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React, {ReactElement, useEffect, useState} from "react";
import Card from '@mui/material/Card';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {MetadataFilesPreloadStyles} from "./preloadStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {CardData, FileRow} from "../../../preload";

export interface PreloadProps {
    data: FileRow[]
    clinicalStudyId: string;
    patientId: number;
    columnSelectionCallback: (data: SelectedColumn[]) => void
    testName: number;
}

export interface SelectedColumn {
    name: string,
    index: number,
    data_type?: string
}

export function MetadataFilesPreloadView({data, ...props}: PreloadProps): ReactElement {
    const classes = MetadataFilesPreloadStyles();

    const [cardsData, setCardsData] = useState<CardData[]>([]);

    useEffect(() => {
        props.columnSelectionCallback(
            cardsData.map((c: CardData): SelectedColumn => {
                return {
                    data_type: c.data_type ?? "string",
                    index: c.file_index,
                    name: c.column_name
                }
            })
        )
    }, [cardsData])

    useEffect(() => {
        const newCardsData = [...cardsData]

        data.slice(0, 3).forEach(r => {
            r.Cells.filter(c => c.index !== props.patientId && c.index !== props.testName).forEach(
                (c, idx) => {
                    const alreadyExisting = idx < newCardsData.length
                    if (alreadyExisting) {
                        newCardsData[idx] = {
                            cells: [...newCardsData[idx].cells, c.value],
                            column_name: c.name,
                            data_type: c.dataType,
                            file_index: c.index,

                        }
                    } else {
                        newCardsData.push({
                            cells: [c.value],
                            column_name: c.name,
                            data_type: c.dataType,
                            file_index: c.index,
                        })
                    }

                }
            )
        })
        setCardsData(newCardsData)
    }, [])

    return (
        <>
            <Grid container height={"30vw"} className={classes.mainContainer} flexWrap={'nowrap'}>
                <Grid container className={classes.innerContainer} flexDirection={"column"}>
                    {cardsData.map((c: CardData, index: number) => {
                        return (
                            <Card className={classes.card}>
                                <Grid container direction={"column"}>
                                    <Select
                                        className={classes.select}
                                        key={index}
                                        displayEmpty={true}
                                        value={c.data_type}
                                        onChange={(ev) => {
                                            const newCardsData = [...cardsData]
                                            newCardsData[index].data_type = ev.target.value
                                            setCardsData(newCardsData)
                                        }}

                                    >
                                        <MenuItem value={"string"}>String</MenuItem>
                                        <MenuItem value={"number"}>Number</MenuItem>
                                    </Select>
                                    <List className={classes.columnsList}>
                                        {
                                            <ListItem className={classes.columnTitle}>
                                                {c.edited_name !== undefined ?
                                                    <TextField
                                                        onChange={(ev) => {
                                                            const newCardsData = [...cardsData]
                                                            newCardsData[index].edited_name = ev.target.value
                                                            setCardsData(newCardsData)
                                                        }}
                                                        value={c.edited_name}
                                                        placeholder={c.column_name}
                                                    />
                                                    :
                                                    <Typography variant={"smallButtonText"}
                                                                className={classes.columnTitleText}>
                                                        {
                                                            c.column_name
                                                        }
                                                    </Typography>
                                                }
                                                <Icon
                                                />
                                                <IconButton
                                                    onClick={() => {
                                                        const newCardsData = [...cardsData]
                                                        const eName = newCardsData[index].edited_name
                                                        const isEdited = eName !== undefined && eName !== ""
                                                        newCardsData[index].edited_name = eName === undefined ? "" : undefined
                                                        newCardsData[index].column_name = isEdited ? eName : newCardsData[index].column_name
                                                        setCardsData(newCardsData)

                                                    }}>
                                                    {
                                                        c.edited_name === undefined ?
                                                            <BorderColorIcon fontSize={"small"} color={"primary"}/>
                                                            :
                                                            <CheckCircleIcon fontSize={"small"} color={"primary"}/>
                                                    }

                                                </IconButton>
                                            </ListItem>
                                        }
                                        {c.cells.map((cell, idx) => {
                                            return (
                                                <>
                                                    <ListItem key={idx} style={{
                                                        justifyContent: "center",
                                                        backgroundColor: (idx % 2 === 0 ? "#F6F6F6" : "#DDDDDD")
                                                    }}>
                                                        <Typography variant={"gridData"}>{cell !== "" ? cell : "--"}</Typography>
                                                    </ListItem>
                                                </>
                                            )
                                        })
                                        }
                                    </List>
                                </Grid>
                            </Card>
                        )
                    })}
                </Grid>
            </Grid>
        </>
    )
}
