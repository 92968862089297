import {createStyles, makeStyles} from "@mui/styles";

const DefaultStyles = makeStyles(() =>
    createStyles({
        mainContainer: {
            marginTop: "7em",
            width: "90% !important",
            marginLeft: "auto",
            marginRight: "auto"
        },
        titleContainer: {
            justifyContent: "space-between !important",
            paddingBottom: "3em !important"
        },
        actionsContainer: {
            width: "fit-content !important",
            justifyContent: "space-between !important"
        },
        showListItem: {
            justifyContent: "space-between !important"
        },
        form: {
            width: "100% !important"
        },
        tabDataContainer: {
            width: "100%!important"
        },
    }));

export {DefaultStyles};
