import React, {ReactElement, useRef, useState} from "react";
import {DefaultModal} from "../../../lib/components/modal/defaultModal";
import {useModalContext} from "../../../lib/context/ModalContext";
import {CircularProgress, Grid, IconButton, Typography} from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import {DocumentsModalsStyles} from "./documentsModalsStyles";
import CancelIcon from "@mui/icons-material/Cancel";
import {useFeedbackContext} from "../../../lib/context/FeedbackContext";
import {useDocumentsDataProvider} from "../providerHook";
import {DocumentsResource} from "../../../resources/documents/documentResource";

interface UploadFileModalProps {
    parentId: string
}

export const UploadFileModal = ({parentId}: UploadFileModalProps): ReactElement => {
    const classes = DocumentsModalsStyles();
    const modalCtx = useModalContext();
    const feedbackCtx = useFeedbackContext()

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const [uploading, setUploading] = useState<boolean>(false);

    const dataProvider = useDocumentsDataProvider();

    const handleUpload = () => {
        if (!selectedFile) return;

        setUploading(true);
        const blob = new Blob([selectedFile], {type: "multipart/form-data"});
        const formData = new FormData();
        formData.append("tagName", selectedFile?.name);
        formData.append("document", blob, selectedFile?.name);

        setTimeout(() => {
            dataProvider.upload(parentId, formData).then((res) => {
                    setUploading(false);
                    const createFolder = DocumentsResource.actions?.find((a) => a.id === "upload")
                    if (createFolder) {
                        createFolder.onComplete && createFolder.onComplete();
                    }
                    feedbackCtx.openBottomSuccessSnackbar(`File ${res.name} uploaded successfully.`)
                    return modalCtx?.closeModal();
                }
            ).catch(() => {
                    setUploading(false);
                    return feedbackCtx.openBottomErrorSnackbar(`Error: the file was not uploaded.`)
                }
            )
        }, 1000)
    }

    return <DefaultModal
        title={"Upload file"}
        description={"Select the file to upload."}
        handleCancel={() => modalCtx?.closeModal()}
        handleConfirm={handleUpload}
        cancelLabel={"Cancel"}
        confirmLabel={"Upload"}
        confirmButtonDisabled={!selectedFile}
    >
        <Grid container direction={"column"} className={classes.uploadContainer}>
            <FolderOpenIcon fontSize={"large"}/>
            {
                uploading? <CircularProgress/>
                    :
                selectedFile ?
                    <Grid item container className={classes.fileName}>
                        <IconButton onClick={() => {
                            setSelectedFile(undefined)
                            if (fileInputRef.current) {
                                fileInputRef.current.files = null
                                fileInputRef.current.value = ""
                            }
                        }}>
                            <CancelIcon/>
                        </IconButton>
                        <Typography variant={"body1"}>
                            {selectedFile.name}
                        </Typography>
                    </Grid>
                    :
                    <>
                        <label htmlFor="fileInput">
                            <Typography variant={"dataTitle"} className={classes.uploadLabel}
                            >Browse</Typography>
                        </label>
                        <input id="fileInput"
                               onChange={(ev) => {
                                   if (ev.target.files !== null && ev.target.files.length > 0) {
                                       setSelectedFile(ev.target.files[0])
                                   }
                               }}
                               hidden
                               ref={fileInputRef}
                               type="file"
                        />
                    </>
            }
        </Grid>
    </DefaultModal>
}
