import {createStyles, makeStyles} from "@mui/styles";

const GuidePageStyles = makeStyles((theme) =>
    createStyles({
        container: {
            marginTop: "7em !important",
            marginLeft: "5em !important",
            marginRight: "4.45em !important",
            justifyContent: "space-between",
        },
        textContainer:{
            width: "78%"
        },
        indexContainer: {
            maxWidth: "16.5em",
            width: "19%",
            backgroundColor: "#89A1AE33",
            position: "sticky",
            top: "10em",
            height: "fit-content"
        },
        menuItem: {
            marginLeft: "2.2em !important"
        },
        title: {
            marginBottom: "1em !important"
        },
        expandableItem: {
            justifyContent: "space-between !important"
        },
        menuTitle: {
            display: "flex"
        },
        menuArrow: {
            color: theme.palette.text.primary
        }
    }));

export {GuidePageStyles};
