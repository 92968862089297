import {Context, createContext, ReactElement, ReactNode, useContext, useState} from "react";
import {Route, Routes} from "react-router-dom";
import AuthenticationConfig, {AuthenticationData, IAuthenticationProvider} from "../types/auth";
import Authenticated from "../components/auth/authenticated";
import {useModalContext} from "./ModalContext";

export interface AuthenticationContextValue<T extends AuthenticationData> {
    getConfig: () => AuthenticationConfig<T>,
    getProvider: () => IAuthenticationProvider<T>
    isAuthenticated: () => boolean,
    setAuthenticated: (auth: boolean) => void,
    getUserName: () => string,
    logout: (allSessions: boolean) => Promise<boolean>
}

interface AuthenticationContextProps<T extends AuthenticationData> {
    children: ReactNode;
    config: AuthenticationConfig<T>;
}

const AuthenticationContext = createContext<AuthenticationContextValue<AuthenticationData> | undefined>(undefined);

export function useAuthenticationContext<T extends AuthenticationData>(): AuthenticationContextValue<T> {
    return useContext<AuthenticationContextValue<T>>((AuthenticationContext as unknown) as Context<AuthenticationContextValue<T>>);
}

export default function AuthenticationContextProvider<T extends AuthenticationData>(props: AuthenticationContextProps<T>): ReactElement {

    props.config.provider.getAuthData()
    const [authenticated, setAuthenticated] = useState<boolean>(props.config.provider.getAuthData().isAuthenticated());
    const modalCtx = useModalContext();
    props.config.provider.setModalCtx(modalCtx);

    return <AuthenticationContext.Provider value={{
        getConfig: () => props.config,
        getProvider: () => props.config.provider,
        isAuthenticated: () => authenticated,
        getUserName: () => props.config.provider.getUserName(),
        setAuthenticated: (auth: boolean) => setAuthenticated(auth),
        logout: (allSessions) => {
            return props.config.provider.logout(allSessions).then(a => {
                setAuthenticated(false)
                return a
            })
        }
    }}>
        <Routes>
            <Route path={`${props.config.loginRoute}`} element={
                props.config.login
            }/>
            <Route path={`${props.config.registerRoute}`} element={props.config.register}/>
            <Route path={`${props.config.contractsRoute}`} element={props.config.contracts}/>
            <Route path="*" element={
                props.config.fullAuth
                    ?
                    <Authenticated>
                        {props.children}
                    </Authenticated>
                    :
                    <>{props.children}</>
            }/>
        </Routes>
    </AuthenticationContext.Provider>
}
