import {createStyles, makeStyles} from "@mui/styles";

const FiltersComponentStyles = makeStyles(() =>
    createStyles({
        filter: {
            marginRight: "2em !important",
        },
        mainFiltersContainer: {
            marginTop: "2em !important",
            width: "70vw",
            display: "flex",
            flexDirection: "row"
        },
        hiddenFilters: {
            padding: "1em",
            marginTop: "2em !important"
        }
    }));

export {FiltersComponentStyles};
