import {Typography} from "@mui/material";
import {DateMeta} from "../../../lib/meta/date/date";
import {IDMeta} from "../../../lib/meta/id/id";
import {SimpleTextMeta} from "../../../lib/meta/simpleText/simpleText";
import {ResourceConfig} from "../../../lib/types/resource";
import {BaseDataProvider} from "../../baseDataProvider";
import {Audit, AuditAction, AuditFilter, AuditObject} from "./audit";
import {auditFilter} from "./auditFilter";
import React from "react";
import {userInfoLink} from "./hyperlikConfigs/userInfo";
import {DefaultShowTabView} from "../../../lib/views/crud/show/defaultTab";

export const AuditResource: ResourceConfig<Audit, AuditFilter> = {
    id: "audits",
    label: "Audit",
    dataProvider: new BaseDataProvider<Audit, AuditFilter>("audit"),
    filter: auditFilter,
    actions: [],
    fields: [
        {
            id: "id",
            label: "ID",
            meta: new IDMeta()
        },
        {
            id: "created_at",
            label: "Created at",
            meta: new DateMeta({})
        },
        {
            id: "user_id",
            label: "User Id",
            meta: userInfoLink()
        },
        {
            id: "action",
            label: "Action",
            meta: new SimpleTextMeta({
                valuer: (data: Audit) => {
                    const actionValue = Object.entries(AuditAction).find(
                        ([key, value]) => key === data.action
                    );
                    if (!data) return "--";
                    return actionValue ? actionValue[1] : data.action ? data.action.valueOf() : data.toString();
                }
            })
        },
        {
            id: "object",
            label: "Target",
            meta: new SimpleTextMeta({
                valuer: (data: Audit) => {
                    const actionValue = Object.entries(AuditObject).find(
                        ([key, value]) => key === data.object
                    );
                    if (!data) return "--";
                    return actionValue ? actionValue[1] : data.object ? data.object.valueOf() : data.toString();
                }
            })
        },
        {
            id: "object_id",
            label: "Target ID",
            meta: new SimpleTextMeta({})
        },
        {
            id: "body",
            label: "Additional info",
            meta: new SimpleTextMeta({
                valuer: (data) => {
                    if (!data || (data as unknown as string) === "") {
                        return "--"
                    }
                    if (data && (data as unknown as string).trim() !== '') {
                        return (
                            <div style={{textAlign: "end"}}>
                                {
                                    Object.entries(JSON.parse(data as unknown as string)).map(([key, value]) => ({ key, value }))
                                        .map((record) => {
                                            if (!(record.value instanceof Object)) {
                                                try {
                                                    if (typeof record.value === "number") {
                                                        return <div style={{marginBottom: "0.8em"}}>
                                                            <strong>{`${record.key.replace(/[_-]/g, " ")}: `}</strong>
                                                            {`${record.value.toString() ?? "--"}`}
                                                        </div>
                                                    }
                                                    return (
                                                        <div>
                                                            <strong>{record.key}: </strong>
                                                            {
                                                                Object.entries(JSON.parse(record.value as string)).map(([key, value]) => ({ key, value }))
                                                                    .map((subrecord) => {
                                                                        const jsonObject = JSON.parse(JSON.stringify(subrecord.value));
                                                                        const text: string[] = []
                                                                        Object.keys(jsonObject).forEach((key) => {
                                                                            text.push(`${key}: ${jsonObject[key]}`)
                                                                        })
                                                                        return <div>{text.join(", ")}</div>
                                                                    })
                                                            }
                                                        </div>
                                                    )
                                                } catch {
                                                    console.log(record)
                                                    return <div style={{marginBottom: "0.8em"}}>
                                                        <strong>{`${record.key.replace(/[_-]/g, " ")}: `}</strong>
                                                        {`${record.value === "" || record.value === null || record.value === undefined ? "--" : record.value}`}
                                                    </div>
                                                }
                                            } else {
                                                return Object.entries(record.value)
                                                    .map(([key, value]) => ({key, value}))
                                                    .map((record, index) => {
                                                        return <div style={{marginBottom: "0.8em"}}>
                                                            <strong>{`${record.key.replace(/[_-]/g, " ")}: `}</strong>
                                                            {`${record.value ?? "--"}`}
                                                        </div>
                                                    })
                                            }
                                        })
                                }
                            </div>
                        );
                    }
                    return "--";
                }
            })
        },
    ],
    list: {
        id: "audit",
        pageSize: 20,
        fields:[{id:"action"},{id:"object"},{id:"object_id"},{id: "user_id"},{id:"created_at"}]
    },
    show: {
        component: <DefaultShowTabView
                        id={"audit"}
                        tabs={[{label: "info", fields: ["id", "user_id", "created_at", "action", "object", "object_id", "body"]}]}
                        fields={[{id: "id"},{id: "user_id"},{id: "created_at"},{id: "action"},{id: "object"},{id:"object_id"}, {id: "body"}]}
                        getTitle={(data?:Audit) => {
                            return (
                                <>
                                    <Typography variant={"pageTitle"}
                                                style={{
                                                    width: "fit-content",
                                                    maxWidth: "15em",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }}
                                    >/ {data?.id}</Typography>
                                </>)
                        }}
                        previousPage={-1}
        />,
        fields: [{id: "id"},{id: "user_id"}, {id: "created_at"},{id: "action"},{id: "object"},{id:"object_id"}]
    },
    primaryKey: "id",
    description: "List of all the traceable actions performed on miWeb.",
    isDeletable: () => false,
    groupId: "appManager"
}



