import {InputAdornment, TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';


export interface SimpleTextFilterProps<T, V> {
    handleResult: (data: any) => void,
    fieldLabel: keyof V,
    placeholder?: string
    value: string | undefined
}

function SimpleTextFilter<T, V>(props: SimpleTextFilterProps<T, V>) {
    function handleChange(e: any) {
        props.handleResult(e.target.value);
    }

    return (
        <>
            <TextField
                   id={"outlined-basic"}
                   label={`${props.fieldLabel.toString()}`}
                   variant={"outlined"}
                   onChange={handleChange}
                   placeholder={props.placeholder || props.fieldLabel.toString()}
                   value={props.value}
                   InputProps={{
                       endAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
                   }}
            />
        </>
    )

}

export default SimpleTextFilter;
