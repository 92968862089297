import { Button, Grid, Typography } from "@mui/material";
import { Control, Controller, Path, SubmitHandler, useForm } from "react-hook-form";
import { useModalContext } from "../context/ModalContext";
import { ChoiceAction } from "../types/choiceAction";
import { Field } from "../types/field";
import {ActionStyle} from "./actionProps";
import {ModalStyles} from "../components/modal/modalStyles";


interface DefaultActionFormProps<ActionArgument, Resource>{
    fields: Field<ActionArgument>[],
    choiceAction: ChoiceAction,
    handleSubmit: (args: ActionArgument) => Promise<Resource> | undefined
    res?: ActionArgument,
    formStyle?: ActionStyle
    disabledAction?: ((data?: Resource) => boolean) | boolean
}

export const DefaultActionForm = <ActionArgument extends Record<string,any>, Resource>(props: DefaultActionFormProps<ActionArgument, Resource>) => {
    const {handleSubmit, reset, control, formState} = useForm<ActionArgument>({mode: "all"});
    const modalCtx = useModalContext();

    const classes = ModalStyles();

    const onSubmit: SubmitHandler<ActionArgument> = (data) => {
        if (Object.keys(formState.errors).length > 0) {
            console.error("Form errors: " + formState.errors);
            return;
        }
        props.handleSubmit(data);
        modalCtx?.closeModal();
    };

    const isDisabled = () => {
        return !formState.isValid || props.disabledAction === true
    }

    return (
            <>
                <Grid container direction={"column"} className={classes.container}>
                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        {props.fields
                            .filter((f) => f.hidden ? !f.hidden(props.res) : !f.hidden)
                            .map((field) => {
                                            return (
                                                    <Grid container direction={"column"} className={classes.fields}>
                                                        <Typography variant={"dataTitle"} marginBottom={"1em"}>
                                                            {field.label}
                                                        </Typography>
                                                        <Controller
                                                            name={field.id as Path<ActionArgument>}
                                                            control={control}
                                                            render={() => {
                                                                return <Grid item>
                                                                    {field.meta?.getFormComponent(
                                                                    control as Control,
                                                                    field.id as Path<ActionArgument>,
                                                                    field
                                                                    )}
                                                                    </Grid>
                                                            }}
                                                        />
                                                    </Grid>
                                            );
                                        })}
                            <Grid container className={classes.formButtonsContainer}>
                                <Button onClick={() => {reset(); modalCtx?.closeModal()}} variant='outlined'>{props.choiceAction.cancelLabel}</Button>
                                <Button onClick={() => onSubmit} type="submit" disabled={isDisabled()} variant={props.formStyle?.buttonVariant} color={props.formStyle?.buttonColor}
                                >{props.choiceAction.confirmLabel}</Button>
                            </Grid>
                        </form>
                    </Grid>
                </>
    )
}
