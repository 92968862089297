import React, {ReactElement, useEffect, useState} from "react";
import {
    Autocomplete,
    Box,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {DocumentsModalsStyles} from "./documentsModalsStyles";
import {useModalContext} from "../../../lib/context/ModalContext";
import {DefaultModal} from "../../../lib/components/modal/defaultModal";
import {RolesDataProvider} from "../../../resources/appManager/roles/rolesDataProvider";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import {useAuthenticationContext} from "../../../lib/context/AuthenticationContext";
import {AccessDataProvider} from "../../../resources/authorizations/access/accessDataProvider";
import {useDocumentsDataProvider} from "../providerHook";
import {DocumentAccess, DocumentAccessRule} from "../dataProvider";
import {useAuthorizationContext} from "../../../lib/context/AuthorizationContext";
import {DocumentType} from "../../../resources/documents/document";


interface EditPermissionsModalProps {
    parentId: string
    parentName: string,
    documentType: DocumentType
}

export const EditPermissionsModal = ({parentId, parentName, documentType}: EditPermissionsModalProps): ReactElement => {
    const classes = DocumentsModalsStyles();
    const modalCtx = useModalContext();
    const authCtx = useAuthenticationContext();
    const authNCtx = useAuthorizationContext()
    const rolesDataProvider = new RolesDataProvider();
    rolesDataProvider.setAuthProvider(authCtx.getProvider());

    const accessDataProvider = new AccessDataProvider()
    accessDataProvider.setAuthProvider(authCtx.getProvider())

    const documentsDataProvider = useDocumentsDataProvider();

    const [initialSelectedRoles, setInitialSelectedRoles] = useState<DocumentAccess[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<DocumentAccess[]>([]);
    const [roles, setRoles] = useState<DocumentAccess[]>([]);
    const roleId = authNCtx.getRoleId();

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        Promise.all([
            documentsDataProvider.getDocumentAccess(parentId).then(acc => {
                setRoles(acc.filter(acc => !acc.access?.pattern.includes("*") && acc.group_info.id !== roleId))
                setSelectedRoles(acc.filter((acc) => acc.access !== null && !acc.access?.pattern.includes("*") && acc.group_info.id !== roleId))
            })
        ]).then(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        Promise.all([
            documentsDataProvider.getDocumentAccess(parentId).then(acc => {
                setInitialSelectedRoles(acc.filter((acc) => acc.access !== null && !acc.access?.pattern.includes("*") && acc.group_info.id !== roleId))
            })
        ]).then(() => {
            setLoading(false)
        })
    }, [])

    const handleSubmit = () => {
        const removedRoles = initialSelectedRoles.filter(r => !selectedRoles.map(m => m.group_info.id).includes(r.group_info.id));
        const newRoles = selectedRoles.filter(r => !initialSelectedRoles.map(m => m.group_info.id).includes(r.group_info.id));

        const editedRoles: { old: DocumentAccess, new: DocumentAccess }[] = [];

        selectedRoles.forEach(newRole => {
            const oldRole = initialSelectedRoles.find((o) => o.group_info.id === newRole.group_info.id);
            if (oldRole && oldRole.access.rule !== newRole.access.rule) {
                editedRoles.push({
                    old: oldRole,
                    new: newRole,
                })
            }
        })

        const promises: Promise<unknown>[] = [];


        removedRoles.forEach((r => {
            promises.push(documentsDataProvider.removeDocumentAccesses(parentId, {
                access_rule: r.access.rule,
                group_id: r.group_info.id
            }))
        }))
        newRoles.forEach((r => {
            promises.push(documentsDataProvider.assignDocumentAccesses(parentId, {
                access_rule: r.access.rule,
                group_id: r.group_info.id
            }))
        }))
        editedRoles.forEach((r => {
            promises.push(documentsDataProvider.removeDocumentAccesses(parentId, {
                access_rule: r.old.access.rule,
                group_id: r.old.group_info.id
            }))
            promises.push(documentsDataProvider.assignDocumentAccesses(parentId, {
                access_rule: r.new.access.rule,
                group_id: r.new.group_info.id
            }))
        }))
        Promise.all(promises).then(() => {
            modalCtx?.closeModal()
        })
    }

    return (
        <DefaultModal
            title={"Permission management"}
            handleCancel={() => modalCtx?.closeModal()}
            handleConfirm={() => handleSubmit()}
            cancelLabel={"Cancel"}
            confirmLabel={"Save"}
            buttonStyles={{buttonVariant: "contained"}}
        >
            <Box>
            <Grid item className={classes.modalContainer}>
                <div className={classes.permissionsModalDescription}>
                    <Typography variant={"dataTitle"} >{documentType === DocumentType.DIRECTORY ? "Folder" : "File"} &nbsp;</Typography>
                    <Typography variant={"dataTitle"}><strong>{`${parentName}`}</strong></Typography>
                </div>
                <Grid item className={classes.permissionModalHeader}>
                    <Autocomplete
                        onChange={(ev, value) => {
                            if (value) {
                                setSelectedRoles([...selectedRoles, {
                                    group_info: {
                                        id: value.value,
                                        name: value.label,
                                        created_at: new Date(),
                                        description: "",
                                        members: []
                                    },
                                    access: {
                                        access: {
                                            document_id: parentId,
                                            document_name: ""
                                        },
                                        rule: DocumentAccessRule.READ,
                                        pattern: "",
                                        resource: ""
                                    }
                                }])
                            }
                        }}
                        renderInput={(params) =>
                            <TextField {...params} label={"Select a Role"}/>}
                        options={roles
                            .filter((role) => {
                                return !selectedRoles.map(r => r.group_info.id).includes(role.group_info.id)
                            })
                            .map((role) => ({
                                label: role.group_info.name,
                                value: role.group_info.id
                            }))}
                    />
                </Grid>
                <Typography variant={"pageTitlePrefix"}>Roles with permission</Typography>
                <Box className={classes.permissionModalList}>
                    <Box className={classes.scrollableList}>
                        {loading ?
                            <CircularProgress/>
                            :
                            <List>
                                {
                                    selectedRoles
                                        .map((role, idx) => {
                                                return <ListItem className={classes.listItem}>
                                                    <div className={classes.roleWrapper}>
                                                        <AccountCircleIcon fontSize={"large"} className={classes.roleIcon}/>
                                                        <Typography
                                                            className={classes.roleName}>{role.group_info.name}</Typography>
                                                    </div>
                                                    <FormControl fullWidth style={{width: "12em"}}>
                                                        <InputLabel id="cs-label">Permission level</InputLabel>
                                                        <Select fullWidth
                                                                value={role.access.rule}
                                                                onChange={(ev) => {
                                                                    if (ev.target.value === DocumentAccessRule.NONE) {
                                                                        setSelectedRoles(selectedRoles.filter((selectedRole) => selectedRole.group_info.id !== role.group_info.id));
                                                                    } else {
                                                                        const updatedRoles = [...selectedRoles]
                                                                        updatedRoles[idx].access.rule = ev.target.value as DocumentAccessRule
                                                                        setSelectedRoles(updatedRoles);
                                                                    }
                                                                }}
                                                                labelId={"cs-label"}
                                                                label={"Permission level"}
                                                        >
                                                            <MenuItem value={DocumentAccessRule.READ}>Viewer</MenuItem>
                                                            <MenuItem value={DocumentAccessRule.WRITE}>Editor</MenuItem>
                                                            <MenuItem value={DocumentAccessRule.ADMIN}>Admin</MenuItem>
                                                            <MenuItem
                                                                value={DocumentAccessRule.NONE}
                                                                className={classes.menuItem}
                                                            >
                                                                <DeleteIcon/>
                                                                Remove access
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </ListItem>
                                            }
                                        )
                                }
                            </List>}
                    </Box>
                </Box>
            </Grid>
            </Box>
        </DefaultModal>
    )
}
