import { GroupConfig } from "../lib/types/group";
import ClinicalIcon from "../images/icons/tabs/clinical.svg";

export const clinicalData: GroupConfig = {
    id: 'clinical',
    label: 'Clinical',
    defaultResource: (authNCtx) => {
        if (authNCtx?.hasAccess("clinical", "clinicalData")) {
            return "clinicalData"
        } else if (authNCtx?.hasAccess("clinical", "sourceFiles")) {
            return "sourceFiles"
        } else {
            return ""
        }

    },
    image: ClinicalIcon,

}
