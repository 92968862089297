import {createStyles, makeStyles} from "@mui/styles";
import GreenCheck from "../../../images/icons/symbols/GreenCheck.svg";

const RolesEditStyles = makeStyles((theme: any) =>
    createStyles({
        mainContainer: {
            marginTop: "7em",
            width: "90% !important",
            marginLeft: "auto",
            marginRight: "auto"
        },
        titleContainer: {
            justifyContent: "space-between !important",
        },
        actionsContainer: {
            width: "fit-content !important"
        },
        listItem: {
            width:"80vw !important",
            justifyContent: "space-between !important"
        },
        showListItem: {
            marginTop: "2em",
            marginBottom: "1em",
            justifyContent: "space-between !important"
        },
        showListLinkItem: {
            marginTop: "2em",
            justifyContent: "space-between !important",
            width: "80vw !important"
        },
        form: {
            width: "100% !important"
        },
        filesList: {
            justifyContent: "space-between !important",
            alignItems: "center",
            marginTop: "1em"
        },
        selectContainer: {
            alignItems:"center",
            justifyContent:"right !important"
        },
        columnList: {
            marginTop: "-1em !important"
        },
        enabledSelect: {
            width: "20em !important",
            alignSelf: "right",
            marginRight: "0 !important"
        },
        disabledSelect: {
            "& .MuiSelect-icon": {
                opacity: 0,
            },
            "& .css-1rbhgel-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
                opacity: 0.5
            },
            width: "20em !important",
            alignSelf: "right",
            marginRight: "0 !important",
            backgroundColor: "#E4E9EC"
        },
        textField: {
            width: "50em !important"
        },
        description: {
            maxWidth: "50% !important",
            textAlign: "justify",
            overflowWrap: "break-word"
        },
        descriptionInput: {
            marginTop: "1em",
            justifyContent: "space-between !important"
        },
        switch: {
            paddingTop: "5px !important",
            paddingLeft: 0 + "!important",
            paddingBottom: "8px !important",
            '& .MuiSwitch-switchBase': {
                paddingTop: "7px !important",
                paddingLeft: "2px !important",
                paddingBottom: 0 + "!important",
                paddingRight: 0 + "!important",
                transitionDuration: "300ms !important",
                '& .MuiSwitch-thumb': {
                    backgroundColor: "#FFFFFF"
                },
                '&.Mui-checked': {
                    transform: "translateX(21px) !important",
                    alignSelf: "center",
                    backgroundImage: `url('data:image/svg+xml;utf8,${GreenCheck}')`,
                    '&.Mui-disabled+.MuiSwitch-track': {
                        opacity: 0.5 + "!important"
                    },
                    '&+.MuiSwitch-track': {
                        backgroundColor: theme.palette.success.main,
                        borderRadius: "20px !important"
                    },
                },
                '&.Mui-disabled .MuiSwitch-thumb': {
                    color: theme.palette.grey[100]
                }
            },
            '& .MuiSwitch-track': {
                backgroundColor: '#E9E9EA',
                opacity: 1 + "!important",
                borderRadius: "20px !important",
                transition: theme.transitions.create(['background-color'], {
                    duration: 500 + "!important",
                }),
            },
        },
        errorContainer: {
            marginTop: "2em"
        },
    }));

export {RolesEditStyles};
