import {ResourceConfig} from "../../lib/types/resource";
import {SimpleTextMeta} from "../../lib/meta/simpleText/simpleText";
import {DateMeta} from "../../lib/meta/date/date";
import {EmailMeta} from "../../lib/meta/email/email";
import {ProfileDetailView} from "../../components/profile/detail";
import {SelectBadgeMeta} from "../../lib/meta/select/selectBadge";
import {RoleResource} from "../appManager/roles/roleResource";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import dayjs from "dayjs";

export interface Profile {
    id: string | number;
    created_at: Date;
    first_name: string;
    last_name: string;
    email: string;
    group: string;
    group_name: string;
    exp_date: Date;
    terms_and_conditions:  TermsAndConditions
}

export interface TermsAndConditions {
    contract_id: string,
    date: Date,
    explicit: boolean,
    terms: boolean
}

export const ProfileResource: ResourceConfig<Profile, Profile> = {
    id: "profile",
    label: "Profile",
    actions: [],
    dataProvider: undefined,
    singleton: true,
    fields: [
        {
            id: "first_name",
            label: "Name",
            meta: new SimpleTextMeta({}),
            validationOptions: {
                required: true
            }
        },
        {
            id: "last_name",
            label: "Surname",
            meta: new SimpleTextMeta({}),
            validationOptions: {
                required: true
            }
        },
        {
            id: "email",
            label: "E-mail",
            meta: new EmailMeta(),
            validationOptions: {
                required: true
            }
        },
        {
            id: "group_name",
            label: "Role",
            meta: new SelectBadgeMeta({
                resource: RoleResource,
                valuer: (role) => role.name.toString()
            }),
            validationOptions: {
                required: true
            }
        },
        {
            id: "exp_date",
            label: "Expiry Date",
            meta: new DateMeta({
                valuer: (data) => {
                    const date = new Date((data as Profile).exp_date);
                    return dayjs(date).format("DD/MM/YYYY, HH:mm").toString()
                },
                minDate: new Date(),
                defaultDate: (data) => data ? new Date((new Date(data.created_at)).setFullYear((new Date(data.created_at)).getFullYear() + 1)) : new Date(),
                clearable: false
            }),
        },
        {
            id: "terms_and_conditions",
            label: "Contract signed on",
            meta: new SimpleTextMeta({
                valuer: (data) => {
                    const termsData = data as unknown as TermsAndConditions
                    if (!termsData.date) {
                        return (
                            <div style={{display: "flex", flexDirection: "row", alignItems:"center"}}>
                                {"--"}<CancelIcon color={"error"} fontSize={"small"}/>
                            </div>
                        )
                    } else {
                        const date = new Date(termsData.date?.toString()!);
                        return  <div style={{display: "flex", flexDirection: "row", alignItems:"center"}}>
                            {dayjs(date).format("DD/MM/YYYY, HH:mm:ss").toString()}<CheckCircleIcon color={"success"} fontSize={"small"}/>
                        </div>
                    }
                }
            })
        }
    ],
    create: undefined,
    show: {
        component: <ProfileDetailView
            key={"profileShow"}
            id={"profile"}
            fields={[]}
            editMode={false}
        />,
        fields: [
            {id: "first_name"},
            {id: "last_name"},
            {id: "email"},
            {id: "group_name"},
            {id: "exp_date"},
            {id: "terms_and_conditions"}
        ]
    },
    edit: {
        component: <ProfileDetailView
            key={"profileEdit"}
            id={"profile"}
            fields={[]}
            editMode={true}
        />,
        fields: [{id: "first_name"}, {id: "last_name"}]
    },
    list: undefined,
    primaryKey: "id",
    groupId: "home"
}
