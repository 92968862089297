import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Checkbox,
    Paper,
    Table, TableBody, TableCell, TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, {ReactElement, useState} from "react";
import {Column} from "../../../../resources/appManager/clinicalStudies/clinicalStudiesDataProvider";
import {SourceFile} from "../../../../resources/clinicalData/sourceFile/sourceFile";

interface FileAccordionProps {
    file: SourceFile;
    columns: Column[];
    columnSelectionCallback: (data: string[]) => void;
}

export function FileAccordion({columns, file, ...props}: FileAccordionProps): ReactElement {
    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
    const [allSelected, setAllSelected] = useState<boolean>(false);

    const isColumnSelected = (colId: string): boolean => {
        return selectedColumns.includes(colId);
    }

    const handleAllSelection = (checked: boolean): void => {
        const checkedColumns = checked ? [...selectedColumns, ...columns.map((col) => col.id)] : []
        setAllSelected(checked)
        setSelectedColumns(checkedColumns)

        props.columnSelectionCallback(checkedColumns)
    }

    const handleColumnSelection = (columnId: string, checked: boolean): void => {
        const filteredSelection = selectedColumns.filter((col) => col !== columnId)
        const checkedColumns =
            checked ? [...selectedColumns, columnId]
                : filteredSelection

        setSelectedColumns(checkedColumns)
        props.columnSelectionCallback(checkedColumns)
    }

    return (
        <>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>File: &nbsp;</Typography><Typography
                    variant={"smallButtonText"}>{`${file.tag_name}`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        columns &&
                        <>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead sx={{backgroundColor: "secondary.main"}}>
                                        <TableRow>
                                            <TableCell>
                                                <Checkbox
                                                    indeterminate={!allSelected}
                                                    onChange={v => {
                                                        handleAllSelection(v.target.checked)
                                                    }}
                                                    checked={allSelected}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <strong>Column</strong>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            columns.map(col => {
                                                return (
                                                    <TableRow
                                                        key={col.id}>
                                                        <TableCell>
                                                            <Checkbox
                                                                id={col.id}
                                                                key={col.id}
                                                                onChange={(ev) => {
                                                                    handleColumnSelection(col.id, allSelected || ev.target.checked)
                                                                }}
                                                                checked={
                                                                    allSelected || isColumnSelected(col.id)
                                                                }
                                                                disabled={allSelected}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            {col.name}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    }
                </AccordionDetails>
            </Accordion>
        </>
    )
}
