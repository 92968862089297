export const CustomTheme= () => ({
    breakpoints: {
        values: {
            md: 900,
            lg: 1200,
            xl: 1536
        }
    },
    palette: {
        primary: {
            main: '#12668A',
            light: '#E4E9EC',
            dark: '#0E5270',
            hover: '#BFCAD14D'
        },
        secondary: {
            main: '#FBFBFB',
            light: '#FFFFFF',
            hover: '#89A1AE33'
        },
        error: {
            main: '#B60F37',
            light: '#D80000',
        },
        warning: {
            main: '#C77700',
            light: '#D11945',
        },
        text: {
            primary: '#343434',
            secondary: '#000000',
            disabled: '#303030',
        },
        success: {
            main: '#2CA319',
        },
        roleBadge: {
            main: '#8A1270'
        }
    },
    typography: {
        fontFamily: '"Montserrat"',
        pageTitle: { /*Page title*/
            fontFamily: '"Montserrat"',
            fontSize: "32px",
            fontWeight: 600,
            lineHeight: "39.01px",
            color: "#343434"
        },
        pageTitlePrefix: { /*Page title prefix before / */
            fontFamily: '"Montserrat"',
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "29.26px",
            color: "#343434"
        },
        pageSubtitle: { /*Subtitle*/
            fontFamily: '"Montserrat"',
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "19.5px",
            color: "#343434"
        },
        tabTitle: { /*Tab titles*/
            fontFamily: '"Montserrat"',
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "17.07px",
            color: "#343434"
        },
        sideTabTitle: { /*Side tab titles*/
            fontFamily: '"Montserrat"',
            fontSize: "13px",
            fontWeight: 500,
            lineHeight: "13.85px",
            color: "#FFFFFF"
        },
        sideTabTitleSmall: { /*Side tab titles smaller screen*/
            fontFamily: '"Montserrat"',
            fontSize: "11px",
            fontWeight: 500,
            lineHeight: "13.85px",
            color: "#FFFFFF"
        },
        gridTabTitle: { /*Grid titles*/
            fontFamily: '"Montserrat"',
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "20px",
            color: "#000000"
        },
        gridData: { /*Grid data & ADMIN subtitle*/
            fontFamily: '"Montserrat"',
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "17.07px",
            color: "#000000"
        },
        dataTitle: { /*Data field title*/
            fontFamily: '"Montserrat"',
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "24px",
            color: "#343434"
        },
        data: { /*Field data*/
            fontFamily: '"Montserrat"',
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "20px",
            color: "#343434"
        },
        backButtonText: { /*Go back button text*/
            fontFamily: '"Montserrat"',
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "24.38px",
            color: "#12668A"
        },
        clearButtonText: { /*Grid data*/
            fontFamily: '"Montserrat"',
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "21.94px"
        },
        smallButtonText: { /*Small modal button text &  ADMIN title*/
            fontFamily: '"Montserrat"',
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "19.5px"
        },
        bigButtonText: { /*Big side panel button text*/
            fontFamily: '"Montserrat"',
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "29.26px"
        },
        stateBadge: {
            fontFamily: '"Montserrat"',
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "17.07px"
        },
        password: {
            fontFamily: 'password',
            fontStyle: 'normal',
            fontWeight: 400,
            src: "url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf)"

        },
        button: {
            fontSize: "1rem",
            fontWeight: 600
        }
    },
    shape: {
        borderRadius: 10,
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: 'grey',
                }
            }
        }
    }
    
});

export const ButtonsVariant = () => ({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    paddingLeft: 0,
                    paddingRight: 0,
                    marginRight: 10,
                },
                outlined: {
                    paddingLeft: 40,
                    paddingRight: 40,
                    border: `2px solid`,
                    '&:hover': {
                        border: "2px solid",
                        backgroundColor: "#bedbe8"
                    }
                },
                contained: {
                    paddingLeft: 40,
                    paddingRight: 40
                },
              },
        },
    },
})
