import {createStyles, makeStyles} from "@mui/styles";

const SelectStyles = makeStyles((theme: any) =>
    createStyles({
        badge: {
            minWidth: "8em",
            width: "fit-content",
            textAlign: "center",
            borderRadius: "0.3em",
            color: theme.palette.secondary.main,
            fontWeight: 600 + "!important",
            marginBottom: "1em !important",
            padding:'5px !important'
        },
        select: {
            text: "red",
            width: "20em !important",
        },
        container: {
            width:"fit-content !important",
            justifyContent:"space-between"
        },
        label: {
            marginRight: "1em !important"
        }
    }));

export {SelectStyles};
