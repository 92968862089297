import {Audit} from "../../appManager/audit/audit";
import {ClinicalStudy} from "../../appManager/clinicalStudies/clinicalStudy";

export enum SourceType {
    MAIN = "mainClinical",
    OTHER = "other"
}

export enum SourceFileStatus {
    PROCESSED = "processed",
    CANCELED = "canceled",
    UPLOADING = "uploading",
    ERROR = "error"
}
export interface SourceFile {
    id: string;
    created_at: Date;
    updated_at: Date;
    file_id: string;
    clinical_study: ClinicalStudy;
    name: string;
    tag_name: string;
    source_type: SourceType;
    status: SourceFileStatus;
    error: string;
    log: Audit[];
}

export interface SourceFileFilter {
    q: string,
    clinical_study: ClinicalStudy,
    clinical_study_id: string,
    status: string,
    source_type: string,

}
