import {Button, Checkbox, Grid, Typography} from "@mui/material";
import axios from "axios";
import {BASE_URL} from "../../../../App";
import {useAuthenticationContext} from "../../../../lib/context/AuthenticationContext";
import {CustomAuthData} from "../../../../auth/provider";
import React, {useState} from "react";
import Logo from "../../../../images/icons/login/michLogo2.svg"
import ReactMarkdown from "react-markdown";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {useNavigate} from "react-router-dom";
import {useFeedbackContext} from "../../../../lib/context/FeedbackContext";
import {AuthFormHeader} from "../../authFormHeader";
import {PrivacyContractStyles} from "./PrivacyContractStyles";
import {Contract} from "../../../../resources/termsAndConditions/contract";


export const PrivacyContract = () => {
    const classes = PrivacyContractStyles();
    const authCtx = useAuthenticationContext<CustomAuthData>();
    const authConfig = authCtx?.getConfig();
    const authProvider = authConfig?.provider;
    const navigate = useNavigate();
    const feedbackCtx = useFeedbackContext();

    const [contract, setContract] = useState<string>();
    const [contractId, setContractId] = useState<string>();

    const [isContractView, setIsContractView] = useState<boolean>(false);
    const [isChecked1, setIsChecked1] = useState<boolean>(false);
    const [isChecked2, setIsChecked2] = useState<boolean>(false);

    const headers = {
        headers: {
            'X-Auth-Refresh': `Bearer ${authProvider.getAuthData().refreshToken}`,
            'X-Auth-Access': `Bearer ${authProvider.getAuthData().accessToken}`
        }
    }


    const getContract = () => {
        axios.get<Contract>(`${BASE_URL}/contracts/terms_and_conditions`, headers)
            .then((res) => {
                setContract(res.data.body)
                setContractId(res.data.id)
        })
    }

    const signContract = () => {
        axios.put<boolean>(`${BASE_URL}/users/accept_terms_and_conditions`,
            {
                "contract_id": contractId,
                "explicit": true,
                "terms": true
            },
            headers
        ).then(() => {
            axios.get(`${BASE_URL}/users/profile`, headers).then(user => {
                if (user.data.terms_and_conditions.terms && user.data.terms_and_conditions.explicit) {
                    navigate("/home/profile");
                }
            })
            feedbackCtx.openSuccessSnackbar("The contract was signed. Login successful.")
        }).catch(() => {
            feedbackCtx.openErrorSnackbar(`Error, try again later.`)
        })
    }

    const logout = () => {
        navigate("/auth/login");
    }

    const viewContract = () => {
        setIsContractView(true);
        getContract();
    }

    const handleExit = () => {
        setIsContractView(false);
        setIsChecked1(false);
        setIsChecked2(false);
    }

    return (
        <>
            {
                isContractView ?
                    <Grid container direction={"column"}>
                        <img src={Logo} alt={"logo"} className={classes.logo}/>
                        <Grid className={classes.contractBox}>
                            {
                                contract &&
                                <ReactMarkdown>{contract}</ReactMarkdown>
                            }
                            <Grid>
                                <div className={classes.checkbox}>
                                <Checkbox
                                    size={"small"}
                                    onChange={() => setIsChecked1(!isChecked1)}/>
                                <Typography fontSize={"12px"}>Example text 1</Typography>
                                </div>
                                <div className={classes.checkbox}>
                                <Checkbox
                                    size={"small"}
                                    onChange={() => setIsChecked2(!isChecked2)}/>
                                <Typography fontSize={"12px"}>Example text 2</Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid className={classes.buttonsContainer}>
                            <Button onClick={handleExit}>
                                <KeyboardBackspaceIcon color={"primary"}></KeyboardBackspaceIcon>
                                <Typography variant={"backButtonText"}>Go back</Typography>
                            </Button>
                            <Button
                                variant={"contained"}
                                disabled={!isChecked1 || !isChecked2}
                                onClick={signContract}
                            >
                                ACCEPT
                            </Button>
                        </Grid>
                    </Grid>
                    :
                    <AuthFormHeader>
                        <Grid container direction={'column'} className={classes.redirectPageContainer}>
                            <Grid item mt={4} className={classes.redirectPageText}>
                                <Typography>
                                    Before continuing on the platform, please read and sign the privacy contract.
                                </Typography>
                            </Grid>
                            <Grid item mt={4} className={classes.redirectButtons}>
                                <Button variant={"contained"} onClick={() => viewContract()}>VIEW CONTRACT</Button>
                                <Button onClick={logout}>
                                    <KeyboardBackspaceIcon color={"primary"} fontSize={"small"}></KeyboardBackspaceIcon>
                                    <Typography variant={"backButtonText"} fontSize={"small"}>Go back</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </AuthFormHeader>
            }
        </>
    )
}
