import {FormHelperText, Grid, MenuItem, Select, SelectChangeEvent, Typography} from "@mui/material";
import React, {FC, ReactElement, useEffect, useState} from "react";
import { Control, FieldValues, Path, RegisterOptions, useController } from "react-hook-form";
import { Field } from "../../types/field";
import { ListMeta, MetaField } from "../../types/meta";
import { ResourceConfig } from "../../types/resource";
import {SelectStyles} from "./selectStyles";

interface SelectMetaProps<R, T, V> {
    resource?: ResourceConfig<T, V>;
    filter?: (res: R) => V | undefined;
    valuer?: (data: T) => string;
}

interface SelectFormProps<R, T, V> extends SelectMetaProps<R, T, V>{
    parentData: R | undefined,
    selectedItem?: T,
    formControl: Control,
    name: Path<any>,
    validationOptions?: RegisterOptions | undefined,
}

interface SelectShowProps<R,T,V> extends SelectMetaProps<R,T,V>{
    label?: string;
    data?: R;
}


export class SimpleSelectMeta<R, T, V> implements MetaField<R> {
    resource?: ResourceConfig<T, V>;
    filter?: (res: R) => V | undefined;
    valuer?: (data: T) => string;

    constructor(props: SelectMetaProps<R, T, V>) {
        this.resource = props.resource;
        this.filter = props.filter;
        this.valuer = props.valuer;
    }

    getListMeta(): ListMeta {
        return {
            type: 'string',
            value: (arg: any) => {
                return arg;
            },
        };
    }

    getListComponent(data: R | undefined, field: Field<R>) {
        return () => {
            return (<div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography noWrap variant={"body1"}>{
                        data ? this.valuer?
                            this.valuer(data as T)
                            : data[field.id] && data[field.id] !== "" ? data[field.id] as string : "--" : "--"}</Typography>
                </div>
            )
        }
    }
    getFormComponent(control: Control, name: Path<FieldValues>, field: Field<R>, data?: R): ReactElement {
        return SelectForm<R, T, V>({
            resource: this.resource ?? undefined,
            formControl: control,
            name: name,
            parentData: data,
            filter: this.filter,
            valuer: this.valuer,
            selectedItem: data ? data[field.id] as T : undefined,
            validationOptions: field.validationOptions
        });
    };

    getShowComponent(data: R | undefined, field: Field<R>) {
        return () => {
            return SelectShow({ label: field.label, data: data ? data : undefined, valuer: this.valuer })
        }
    };


    getFilterComponent(handler: (data: any) => void, data?: R, fieldValue?: any): FC{
        return () => <SelectFilter<R, T, V> 
                            handleSelect={handler}
                            filter={this.filter}
                            valuer={this.valuer}
                            resource={this.resource}
                            selectedValue={fieldValue}
                            parentRes={data} />
    }
}
function SelectShow<R,T,V>(props: SelectShowProps<R,T,V>) {
    return (
        <>
            <Grid item paddingBottom={"1em"}>
                <Typography variant={"dataTitle"}>
                    {props.label}</Typography>
            </Grid>
            <Grid item paddingBottom={"1em"}>
                {!props.data? "--" : props.valuer && props.valuer(props.data as T)}
            </Grid>
        </>
        )
}

export function SelectForm<R, T, V>(props: SelectFormProps<R, T, V>) {
    const [data, setData] = useState<T[]>([]);
    const { field, fieldState } = useController({
        name: props.name,
        control: props.formControl,
        rules: props.validationOptions,
        defaultValue: props.selectedItem ? props.resource?.primaryKey && props.selectedItem[props.resource.primaryKey] as unknown as string : '',
    });

    useEffect(() => {
        props.resource?.dataProvider?.pagedList(undefined, undefined, undefined).then((data) => {
            setData(data.data);
        });
    }, []);

    return (
        <>
            <Select
                {...field}
                style={{width:"20em"}}
                error={fieldState.error !== undefined}
            >
                {data.map((item) => (
                    <MenuItem
                        value={props.resource && item[props.resource.primaryKey] as string}>
                        {props.valuer && props.valuer(item)}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
        </>
    )
}


interface SelectFilterProps<R, T, V> extends SelectMetaProps<R, T, V>{
    handleSelect: (data: any) => void,
    selectedValue: string,
    parentRes?: R
}

export function SelectFilter<R, T, V>(props: SelectFilterProps<R, T, V>) {
    
    const [data, setData] = useState<T[]>([]);
    const classes = SelectStyles();

    useEffect(() => {
        props.resource?.dataProvider?.list(undefined,
            props.parentRes && props.filter ? props.filter(props.parentRes) : undefined
        ).then((data) => {
            setData(data);
        });
    }, []);

    function handleChange(event: SelectChangeEvent) {
        props.handleSelect(event.target.value)
    }

    return (
        <Select
            fullWidth
            value={props.selectedValue}
            onChange={handleChange}
        >
                {data.map((item) => (
                    <MenuItem value={props.resource && item[props.resource.primaryKey] as string | number}>
                        {props.valuer && props.valuer(item)}
                    </MenuItem>
                ))}
        </Select>
    )
}
