import {createStyles, makeStyles} from "@mui/styles";

const CreationPanelStyles = makeStyles((theme: any) =>
    createStyles({
        formContainer: {
            minHeight: "70vh"
        },
        disabledInput: {
            '&::placeholder': {
                color: theme.palette.secondary.main + "!important",
                opacity: 1 + "!important",
                fontSize: "1.5rem !important",
            },
            background: "linear-gradient(to bottom," + theme.palette.primary.main + "," + theme.palette.primary.dark + ") !important",
            borderRadius: 5,
            fontWeight: 600
        },
        addButton: {
            "&:disabled": {
                background: theme.palette.primary.hover
            },
            background: "linear-gradient(to bottom," + theme.palette.primary.main + "," + theme.palette.primary.dark + ")",
            width: "60%",
            color: theme.palette.secondary.main + "!important",
            fontSize: "1.5rem !important",
            fontWeight: 600 + "!important",
            borderRadius: "0.5rem !important",
            alignSelf: "center !important"
        },
        listItem: {
            justifyContent: "space-between !important",
            marginBottom: "2em"
        },
        formInput: {
            width: "auto"
        },
        actionContainer: {
            justifyContent:"center",
            alignItems:"center"
        }
    }));

export {CreationPanelStyles};
