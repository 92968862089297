import { ReactElement, ReactNode, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAdminContext } from "../../context/AdminContext";
import GroupContextProvider from "../../context/GroupContext";
import { GroupConfig } from "../../types/group";
import {useAuthorizationContext} from "../../context/AuthorizationContext";

interface GroupProps {
    config: GroupConfig;
    children: ReactNode;
}

const resourceContext = require.context('../../../groups', true, /\.tsx?$/);

const groups: Record<string, GroupConfig> = {};

resourceContext.keys().forEach((key) => {
    const module = resourceContext(key);
    for (const id in module) {
        groups[id] = module[id];
    }
});

export const Group = (props: GroupProps): ReactElement => {
    const adminContext = useAdminContext();
    const authorizationContext = useAuthorizationContext()
    const defaultGroup = Object.values(groups).find((group) => {
        return group.isDefault === true
    });

    useEffect(() => {
        if (authorizationContext.hasAccess(props.config.id)){
            adminContext?.registerGroup(props.config)
        }

        return () => {
            adminContext.unregisterGroup(props.config.id)
        }
    }, [])

    return <>
        <GroupContextProvider config={props.config}>
            {props.children}
            <Routes>
                <Route path={props.config.id}>
                    <Route index element={<Navigate to={props.config.defaultResource(authorizationContext)}/>}/>
                </Route>
                <Route path="/" element={<Navigate to={defaultGroup?.id ?? Object.values(groups)[0].id}/>}/>
            </Routes>
        </GroupContextProvider>
        </>
}
