import {ResourceConfig} from "../../types/resource";
import React, {ReactElement, useEffect} from "react";
import ResourceContextProvider from "../../context/ResourceContext";
import {Route, Routes} from "react-router-dom";
import {useAdminContext} from "../../context/AdminContext";
import {useGroupContext} from "../../context/GroupContext";
import ModalContextProvider from "../../context/ModalContext";
import {DefaultEditView} from "../../views/crud/edit/default";
import {DefaultListView} from "../../views/crud/list/default";
import {DefaultCreateView} from "../../views/crud/create/default";
import {DefaultShowView} from "../../views/crud/show/default";
import {useAuthorizationContext} from "../../context/AuthorizationContext";

interface ResourceProps<T, V> {
    config: ResourceConfig<T, V>
}

export default function Resource<T, V>(props: ResourceProps<T, V>): ReactElement {
    const adminContext = useAdminContext();
    const groupConfig = useGroupContext()?.getConfig();
    const authorizationContext = useAuthorizationContext();

    useEffect(() => {
        if (authorizationContext.hasAccess(groupConfig?.id || "", props.config.id)) {
            adminContext.registerResource(props.config, groupConfig?.id);
        }
        return () => {
            adminContext.unregisterResource(props.config.id)
        }
    }, [])

    const renderList = (): ReactElement => {
        return (
            <>
                {
                    props.config.list ?
                        props.config.list.component === undefined ?
                            <DefaultListView fields={props.config.list.fields}
                                             pageSize={props.config.list.pageSize ?? undefined}/>
                            :
                            React.cloneElement(props.config.list.component, {
                                id: props.config.id,
                                fields: props.config.list.fields
                            } as any)
                        :
                        null
                }
            </>
        )
    }
    const renderShow = (): ReactElement => {
        return (
            <>
                {
                    props.config.show ?
                        props.config.show.component === undefined ?
                            <DefaultShowView fields={props.config.show.fields} id={props.config.id}/>
                            :
                            React.cloneElement(props.config.show.component, {
                                id: props.config.id,
                                fields: props.config.show.fields
                            } as any)
                        : null
                }
            </>
        )
    }
    const renderEdit = (): ReactElement => {
        return (
            <>
                {
                    props.config.edit ?
                        props.config.edit.component === undefined ?
                            <DefaultEditView fields={props.config.edit.fields as any}
                                             id={props.config.id}/>
                            :
                            React.cloneElement(props.config.edit.component, {
                                id: props.config.id,
                                fields: props.config.edit.fields
                            } as any)
                        :
                        null
                }
            </>
        )
    }
    const renderCreate = (): ReactElement => {
        return (
            <>
                {
                    props.config.create ?
                        props.config.create.component === undefined ?
                            <DefaultCreateView fields={props.config.create.fields as any}
                                               id={props.config.id}/>
                            :
                            React.cloneElement(props.config.create.component, {
                                id: props.config.id,
                                fields: props.config.create.fields
                            } as any)
                        : null
                }
            </>
        )
    }
    return <>
        <ResourceContextProvider config={props.config}>
            <ModalContextProvider>
                <Routes>
                    <Route path={groupConfig ? `/${groupConfig.id}/${props.config.id}` : props.config.id}>
                        <Route index element={
                            props.config.singleton ? renderShow() : renderList()
                        }/>
                        {
                            props.config.singleton ?
                                <Route path={"edit"} element={renderEdit()}/>
                                :
                                <Route path={"create"} element={renderCreate()}/>
                        }

                        {props.config.customRoute ?
                            <Route path={`${props.config.customRoute?.path}`}>
                                <Route index element={props.config.customRoute.component}/>
                            </Route> : null
                        }

                        {
                            !props.config.singleton &&
                            <Route path={":id"}>
                                {
                                    <Route index element={renderShow()}/>
                                }

                                {
                                    <Route path="edit" element={renderEdit()}/>
                                }
                            </Route>
                        }

                    </Route>
                </Routes>
            </ModalContextProvider>
        </ResourceContextProvider>
    </>
}
