import {useNavigate, useSearchParams} from "react-router-dom";
import AuthLayout from "../../../layout/authLayout";
import {AuthFormHeader} from "../authFormHeader";
import NewPasswordForm from "./newPasswordForm";
import axios, {AxiosError} from "axios";
import jwtDecode from "jwt-decode";
import {BASE_URL} from "../../../App";
import {ErrorDTO} from "../../../hoc/axiosHandlerProvider/AxiosHandlerProvider";
import {useFeedbackContext} from "../../../lib/context/FeedbackContext";

interface TokenData {
    sub: string
    Claims: {
        UserEmail: string;
    };
}

export default function Recover() {
    const [search, ] = useSearchParams();
    const navigate = useNavigate();
    const feedbackCtx = useFeedbackContext();
    const token = search.get("token")
    if (!token) {
        navigate("/")
    }

    const tok = jwtDecode<TokenData>(token || "")


    const onPasswordSubmit = (p:string):void=>{
        axios.post(`${BASE_URL}/auth/set_password`,{password:p,token:token}).then(a =>{
            navigate("/")
        }).catch((e) => {const axiosError = e as AxiosError<ErrorDTO, unknown>;
            if (axiosError.response) {
                feedbackCtx.openErrorSnackbar(`Operazione fallita: ${axiosError.response.data.msg}`)
            }
            if (axiosError.message) {
                feedbackCtx.openErrorSnackbar(`Operazione fallita: ${axiosError.message}`)
            }
        })
    }

    return (
        <AuthLayout>
            {
                <AuthFormHeader>
                    <NewPasswordForm email={tok.Claims.UserEmail} submitCallback={onPasswordSubmit}/>
                </AuthFormHeader>
            }
        </AuthLayout>
    )
}
