import {ResourceConfig} from "../../../types/resource";
import {Button, Grid, Paper} from "@mui/material";
import React, {useState} from "react";
import {FiltersComponentStyles} from "./filtersComponentStyles";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

export interface FiltersComponentProps<T, V> {
    resource: ResourceConfig<T, V>
    data: T[] | undefined
    getFilter: (data: V | undefined) => void
    getMainFilter?: (data: V | undefined) => void
}

function FiltersComponent<T, V>({resource, data, getFilter, getMainFilter}: FiltersComponentProps<T, V>) {
    const classes = FiltersComponentStyles();
    const [filter, setFilter] = useState<V>();
    const [mainFilter, setMainFilter] = useState<V>();
    const [showHiddenFilters, setShowHiddenFilters] = useState<boolean>(false);

    const renderHiddenFilters = () => {
        if (resource.filter) {
            const totalFilters = resource.filter.filters.filter(f => f)
            const hiddenFilters = resource.filter.filters.filter(f => f.hidden() && f.main !== true)
            if (hiddenFilters.length > 0) {
                return <Paper elevation={3} className={classes.hiddenFilters}>
                    <Grid container direction={'row'} columnSpacing={6} rowSpacing={2}>
                        <>
                            {hiddenFilters.map((f, index) => {
                                if (!f.field.meta) return <></>
                                if (!f.field.meta.getFilterComponent) return <></>;
                                const FilterComponent = f.field.meta.getFilterComponent(((data: V) => {
                                        getFilter({...filter, [f.field.id]: data} as V)
                                        setFilter({...filter, [f.field.id]: data} as V);
                                    }),
                                    undefined,
                                    filter ? filter[f.field.id as keyof V] : '');
                                return <Grid item key={index}>{FilterComponent({})}</Grid>
                            })}
                            <Grid item marginLeft={'auto'}>
                                <Button onClick={() =>
                                    totalFilters.map((f) => {
                                        if (f.rendersElement) {
                                            getFilter({...filter, [f.field.id]: data} as V)
                                            return setFilter({...filter, [f.field.id]: data} as V)
                                        } else {
                                            getFilter(undefined)
                                            setFilter(undefined)
                                        }
                                    })
                                }
                                >Reset filters
                                </Button>
                            </Grid>
                        </>
                    </Grid>
                </Paper>
            }
        }
    }

    const renderFilters = () => {
        return <Grid item className={classes.mainFiltersContainer}>
            {resource.filter?.filters.filter(f => !f.hidden() && f.main === true)
                .map((f, index) => {
                    if (!f.field.meta) return <></>;
                    if (!f.field.meta.getFilterComponent)
                        return <></>;
                    let FilterComponent;
                    if (f.rendersElement) {
                        FilterComponent = f.field.meta.getFilterComponent(((data) => {
                            getMainFilter && getMainFilter({...mainFilter, [f.field.id]: data} as V)
                            setMainFilter({...mainFilter, [f.field.id]: data} as V);
                        }), undefined, mainFilter ? mainFilter[f.field.id as keyof V] : '');
                    } else {
                        FilterComponent = f.field.meta.getFilterComponent(((data) => {
                            getFilter({...filter, [f.field.id]: data} as V)
                            setFilter({...filter, [f.field.id]: data} as V);
                        }), undefined, filter ? filter[f.field.id as keyof V] : '');
                    }
                    return <Grid
                            key={index}
                            item className={classes.filter}>{FilterComponent({})}</Grid>;
                })}
        </Grid>
    }

    return (
        <>
        <Grid container direction={'column'}>
            <Grid container direction={'row'} alignItems={"center"}>
                <>
                    {renderFilters()}
                </>
                {(!resource.filter?.filters.find(f => f.rendersElement) ||
                        (resource.filter?.filters.find(f => f.rendersElement) && mainFilter)) &&
                    resource.filter?.filters.find(f => f.hidden()) &&
                    <Grid item marginLeft={'auto'}>
                        <Button sx={{marginTop: 'auto'}}
                                onClick={() => setShowHiddenFilters(!showHiddenFilters)}>
                            {
                                showHiddenFilters ?
                                    <>
                                        <RemoveIcon/>
                                        Less Filters
                                    </> :
                                    <>
                                        <AddIcon/>
                                        More Filters
                                    </>
                            }
                        </Button>
                    </Grid>
                }
            </Grid>
            <Grid item>
                {showHiddenFilters ? renderHiddenFilters() : null}
            </Grid>
            <Grid/>
        </Grid>
        </>
    )

} export default FiltersComponent;
