import {createStyles, makeStyles} from "@mui/styles";

const ModalStyles = makeStyles(() =>
    createStyles({
        uploadContainer: {
            justifyContent:"center",
            alignItems:"center !important",
            height:"200px",
            backgroundColor:"#D9D9D9"
        },
        fileName: {
            alignItems:"center",
            textAlign: "center",
            alignSelf: "center !important",
            width: "fit-content !important"
        },
        uploadLabel: {
            fontColor: "#12668A !important",
            cursor: "pointer",
            textDecoration: "underline"
        },
        downloadBox: {
            paddingTop: "1.5em",
            paddingBottom: "1.5em",
            paddingRight: "1em",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        descriptionInputText: {
            width: "100%"
        }
        /*code: {
            width: "20em",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        copyButton: {
            width: "fit-content",
            fontSize: "0.7em !important",
            paddingLeft: "1em",
            paddingRight: "1em"
        }*/
    }));

export {ModalStyles};
