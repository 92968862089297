import {BaseDataProvider} from "../baseDataProvider";
import GenericSearch from "../genericSearch";
import axios from "axios";
import {BASE_URL} from "../../App";
import Entity from "./entities";


export class EntitiesDataProvider extends BaseDataProvider<Entity, GenericSearch> {

    constructor() {
        super('entities');
    }
    list = async (page_size?: number, filter?: GenericSearch ): Promise<Entity[]> => {
        return axios.get(`${BASE_URL}/${this.resourceId}`, { headers:  await this.getHeaders({"Accept": "application/json"}), params: {page_size, ...filter} }).then(r => {
            return r.data;
        });
    }
}
