import {Drawer, Modal} from "@mui/material";
import {Container} from "@mui/system";
import {createContext, ReactElement, useContext, useState} from "react";
import { DefaultModalStyle } from "../style/defaultModalStyel";


export interface ModalContextValue {
    openModal: (data: ModalData) => void;
    closeModal: () => void;
    openSideModal: (data: ModalData) => void;
    closeSideModal: () => void;
}

interface ModalContextProps {
    children: ReactElement;
}

export interface ModalData {
    component: ReactElement,
    anchor?: "left" | "top" | "right" | "bottom" | undefined,
    onClose?: () => void
}

const ModalContext = createContext<ModalContextValue | undefined>(undefined);


export default function ModalContextProvider(props: ModalContextProps) {
    const [data, setData] = useState<ModalData | undefined>(undefined);
    const defaultModalStyle = DefaultModalStyle();

    return <ModalContext.Provider value={{
        openModal: (data: ModalData) => setData(data),
        closeModal: () => {
            setData(undefined)
            return data?.onClose && data?.onClose()
        },
        openSideModal: (data: ModalData) => setData(data),
        closeSideModal: () => {
            setData(undefined)
            return data?.onClose && data?.onClose()
        },
    }}>
        {props.children}
        {
            data?.anchor
                ?
                <Drawer
                    anchor={data?.anchor}
                    open={data !== undefined}
                    onSubmit={() => {if (data?.onClose) {
                        return data.onClose()
                    }}}
                    onClose={() => {
                        if (data.onClose) {
                            return data.onClose()
                        }
                    }}
                >
                    {data?.component}
                </Drawer>
                :
                <Modal open={data !== undefined}
                       onSubmit={() => {if (data?.onClose) {
                           return data.onClose()
                       }}}
                       onClose={() => {
                        if (data?.onClose) {
                            return data.onClose()
                        }
                }}>
                    <>
                        <Container className={defaultModalStyle.modalContainer}>
                            {data?.component}
                        </Container>
                    </>
                </Modal>
        }
    </ModalContext.Provider>

}


export function useModalContext() {
    return useContext(ModalContext)
}
