import React, {useEffect, useState} from "react";
import {Box, Grid, Typography, Tabs, useMediaQuery} from "@mui/material";
import {SidebarStyles} from "./SidebarStyles";
import { useNavigate, useLocation } from 'react-router-dom';
import {Tab} from "../navbar/Navbar";
import { useAdminContext } from "../../context/AdminContext";
import {useTheme} from "@mui/styles";
export interface GroupTab {
    label: string;
    route: string
    onClick: () => void;
    image?: any;
    active: boolean
}

export interface SidebarProps {
    tabs?: GroupTab[];
}

const Sidebar = (props : SidebarProps) => {
    const classes = SidebarStyles();
    const [tabs, setTabs] = useState<Tab[]>();
    const [hoveredTab, setHoveredTab] = useState(-1);
    const location = useLocation();
    const navigate = useNavigate();    
    const adminContext = useAdminContext();
    const groups = adminContext.getGroups();
    const theme = useTheme();
    const lgScreen = useMediaQuery(theme.breakpoints.down(1700));
    const smScreen = useMediaQuery(theme.breakpoints.down(1450));

    useEffect(() => {
        const groupId = location.pathname.split("/")[1];
        setHoveredTab(-1);
        const newTabs: Tab[] =
            groups.map((group) => {
                return {
                    label: group.label,
                    onClick: () => {navigate(group.id)},
                    image: group.image,
                    active: groupId === group.id,
                    position: "left"
                }
            })
        setTabs(newTabs);
    }, [location.pathname, groups])

    const list = (props : SidebarProps) => (
        <Box className={classes.wrapper} id={"sidebar"}>
            <Grid container paddingTop={2}>
                {tabs && (props.tabs ?? tabs).map((tab, index) => (
                        <Grid container direction="column"
                              key={index}
                              className={`${classes.tab} ${index === hoveredTab || tab.active ? classes.selectedTab : ""}`}
                              onMouseEnter={() => setHoveredTab(index)}
                              onMouseLeave={() => tab.active ? setHoveredTab(index) : setHoveredTab(-1)}
                              onClick={() => {
                                  tab.onClick();
                                  localStorage.removeItem("tabValue")
                              }}
                        >
                            {tab.image !== undefined &&
                                <Grid item>
                                    <img src={tab.image} alt={tab.label} />
                                </Grid>
                            }
                            <Typography variant={lgScreen? "sideTabTitleSmall" : "sideTabTitle"} className={tab.active ? classes.selectedTabTitle : classes.tabTitle}>
                                {!smScreen && tab.label}
                            </Typography>
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );

    return (
        <div>
           <Box>
                <Tabs value={0}>
                    {list(props)}
                </Tabs>
           </Box>
        </div>
    );
};

export default Sidebar;
