import axios from "axios";
import {BASE_URL} from "../../../App";
import {BaseDataProvider} from "../../baseDataProvider";
import {MetadataFile} from "../../labTest/metadataFiles/metadataFile";
import {Laboratory} from "./laboratory";
import GenericSearch from "../../genericSearch";

export class LaboratoryDataProvider extends BaseDataProvider<Laboratory, GenericSearch> {

    constructor() {
        super("laboratories")
    }

    upload = async (laboratoryId: string, data: FormData): Promise<MetadataFile> => {
        return axios.post<MetadataFile>(`${BASE_URL}/laboratories/${laboratoryId}/upload_file`,
            data,
            {headers: await this.getHeaders({'Accept': 'multipart/form-data'})}).then(r => {
            return r.data
        });
    }

}
