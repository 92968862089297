import {HyperlinkMeta} from "../../../../lib/meta/hyperlink/hyperlink";
import {User} from "../user";
import IDataProvider from "../../../../hoc/dataProvider/IDataProvider";
import {Box, Button, Divider, Grid, List, ListItem, Typography} from "@mui/material";
import React from "react";
import {Role} from "../../roles/role";
import {SelectBadgeMeta} from "../../../../lib/meta/select/selectBadge";
import {RoleResource} from "../../roles/roleResource";
import {RolesDataProvider} from "../../roles/rolesDataProvider";
import {UserResource} from "../userResource";

export const roleInfoLink = (theme: any) => {
    return new HyperlinkMeta<User>({
        childMeta: new SelectBadgeMeta({
            resource: RoleResource,
            badgeColor: () => theme.palette.roleBadge.main,
            valuer: (role) => role.name.toString(),
            secondaryValuer: (user) => user.group_name.toString()
        }),
        title: "Role Information",
        dataProvider: new RolesDataProvider(),
        modalConfig: {
            getAdditionalData: async (dataProvider?, data?: User): Promise<Role | undefined> => {
                const roleId = data?.group;
                if (!dataProvider || !roleId) return;
                try {
                    const res = await (dataProvider as IDataProvider<Role, Role>).getById(roleId);
                    return res as Role;
                } catch (error: any) {
                    console.error('Failed to fetch users:', error);
                    return error;
                }
            },
            getBody: (user?: User, role?: Role) => {
                if (!role || (role as any).response) {
                    if ((role as any)?.response?.status === 401) {
                        return (
                            <Typography ml={"1em"}>
                                <strong>ATTENTION! </strong>You don't have the necessary permissions to access to further information about this resource.
                            </Typography>
                        );
                    }
                    return <Typography ml={"1em"}>No information available. This resource may have been removed or not visibile on the platform.</Typography>;
                }
                return (
                    <>
                        <Box style={{
                            justifyContent: "center",
                            width: "auto",
                            height: "70vh",
                            overflow: "hidden"
                        }}>
                            <Box style={{
                                overflowY: "scroll",
                                justifyContent: "center",
                                height: "100%",
                                paddingTop: 0,
                                paddingRight: "1em"
                            }}>
                                <List>
                                    <ListItem style={{justifyContent: "space-between", height: "3em"}}>
                                        <div>Role ID</div>
                                        <div>{role.id}</div>
                                    </ListItem>
                                    <Divider/>
                                    <ListItem style={{justifyContent: "space-between", height: "3em"}}>
                                        <div>Name</div>
                                        <div>{role.name}</div>
                                    </ListItem>
                                    <Divider/>
                                    <Grid item style={{marginTop: "2em"}}>
                                        <Button variant={"outlined"} href={`/appManager/roles/${role.id}`}>View
                                            Detail</Button>
                                    </Grid>
                                </List>
                            </Box>
                        </Box>
                    </>
                );
            },
        }
    })
}
