import {createStyles, makeStyles} from "@mui/styles";

const DefaultStyles = makeStyles((theme: any) =>
    createStyles({
        mainContainer: {
            marginTop: "7em",
            width: "90% !important",
            marginLeft: "auto",
            marginRight: "auto"
        },
        titleContainer: (props: {error?: number}) => ({
            justifyContent: "space-between !important",
            paddingBottom: props.error ? "3em !important" : "1em !important"
        }),
        description: {
            paddingBottom: "0.6em !important"
        },
        actionsContainer: {
            width: "fit-content !important",
            justifyContent: "space-between !important"
        },
        tabDataContainer: {
            width: "100%!important"
        },
        dataContainer: {
            marginTop: "3em !important",
            width: "100%!important"
        },
        showListItem: {
            marginTop: "1em",
            justifyContent: "space-between !important"
        },
        customData: {
            paddingBottom: "1em"
        },
        dropdownContainer: {
            justifyContent:"end",
            marginTop:"0.5em",
            paddingRight:"0.5em"
        },
        actionsDropdown: {
            border:"2px solid" + theme.palette.primary.main,
            alignSelf:"right",
            width:"fit-content !important",
            borderRadius:"0.7em"
        },
        errorContainer: {
            marginTop: "-1em",
            marginBottom: "-2em"
        },
        errorContainerTabs: {
            marginTop: "-2.5em",
            marginBottom: "1em"
        }
    }));

export {DefaultStyles};
