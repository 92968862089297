import {
    Grid,
    ListItem,
    Switch,
    Typography
} from "@mui/material";
import {
    Permissions
} from "../../../../resources/authorizations/permission/permissions";
import React, {ReactElement, useEffect, useState} from "react";
import {RolesEditStyles} from "../rolesEditTabStyles";
import {Access, AccessObjectDocument} from "../../../../resources/authorizations/access/access";

interface AppManagerProps {
    key: string
    editEnabled: boolean;
    totalAccesses: Access[];
    currentAccesses: Access[];
    currentPermissions: string[];
    onPermissionsChange: (data: string[]) => void;
    onAccessesChange: (data: Access[]) => void;
}
export function DocumentsPermission(props: AppManagerProps): ReactElement {
    const classes = RolesEditStyles();

    const documentsAllPermissions = (): string[] => {
        let permissions: string[]= []
        Object.keys(Permissions["documents"]).map(r=>{
            permissions.push(...Permissions["documents"][r].read.concat(Permissions["documents"][r].write))
        })
        return permissions
    }

    const hasDocumentsPermissions = ():boolean=>{
        let hasAdminAccess = true
        let hasPermissions = true
        if (!props.currentAccesses.some(ca => ca.pattern.includes("*"))) {
            hasAdminAccess = false
        }
        documentsAllPermissions().forEach(p => {
            if (props.currentPermissions.indexOf(p) === -1){
                hasPermissions = false
            }
        })
        return hasPermissions && hasAdminAccess
    }

    const [isChecked, setIsChecked] = useState<boolean>(
        hasDocumentsPermissions()
    );

    useEffect(() => {
        let newPermissions = [...props.currentPermissions]
        let newAccesses = [...props.currentAccesses]
        if (isChecked) {
            newAccesses = [...props.totalAccesses.filter(a => {
                const documentAccess = a.access as AccessObjectDocument
                return documentAccess.document_id === '*'
            })
            ]
            newPermissions = [
                ...new Set(
                    newPermissions.concat(
                        documentsAllPermissions()
                    )
                )
            ]
        } else {
            newAccesses = newAccesses.filter(a => !a.pattern.includes("*"))
        }
        props.onPermissionsChange(newPermissions)
        props.onAccessesChange(newAccesses)
    }, [isChecked])

    const handleChange = () => {
        setIsChecked(!isChecked)
    }

    return (
        <>
            <ListItem className={classes.showListItem}>
                <Typography variant={"dataTitle"}>Document</Typography>
                <>
                    <Grid container direction={"row"} className={classes.selectContainer} width={"fit-content"}>
                        <Typography>Admin Level &nbsp;</Typography>
                        <Switch
                            key={props.key}
                            focusVisibleClassName=".Mui-focusVisible"
                            disableRipple
                            className={classes.switch}
                            sx={{m: 1}}
                            checked={isChecked}
                            onChange={handleChange}
                            disabled={!props.editEnabled}
                        />
                    </Grid>
                </>
            </ListItem>
        </>
    )
}
