import {Button} from "@mui/material";
import React, {FC, ReactElement} from "react";
import {Action, ActionType} from "../types/action";
import {ChoiceAction} from "../types/choiceAction";
import {Field} from "../types/field";
import {ActionProps, ActionStyle} from "./actionProps";
import {useNavigate} from "react-router-dom";
import IDataProvider from "../../hoc/dataProvider/IDataProvider";
import {IAuthenticationProvider} from "../types/auth";
import {CustomAuthData} from "../../auth/provider";
import {AuthorizationContextValue, useAuthorizationContext} from "../context/AuthorizationContext";
import {FeedbackContextValue} from "../context/FeedbackContext";
export class StepAction<ActionArgument extends Record<string, any>, Resource> implements Action<ActionArgument, Resource> {
    id: string;
    label: string;
    type: ActionType;
    fields: Field<ActionArgument>[];
    choiceAction: ChoiceAction;
    hidden: (res?: Resource | Resource[], authNCtx?: AuthorizationContextValue) => boolean;
    handler?: ((res: Resource | Resource[], dataProvider?: IDataProvider<any, any>, authProvider?: IAuthenticationProvider<CustomAuthData>, feedbackCtx?: FeedbackContextValue, formData?: string, args?: ActionArgument | undefined) => Promise<Resource>) | undefined;
    actionStyle?: ActionStyle;
    icon?: ReactElement;

    constructor(props: ActionProps<ActionArgument, Resource>) {
        this.id = props.id;
        this.label = props.label;
        this.type = props.actionType
        this.hidden = props.hidden ? props.hidden : () => false;
        this.fields = props.fields || [];
        this.choiceAction = props.choiceAction || {} as ChoiceAction;
        this.actionStyle = props.actionStyle;
        this.icon = props.icon;
    }


    getActionComponent(res: Resource | Resource[] | undefined, isDisabled?: boolean, filter?: string): FC {

        return () => {
            const navigate = useNavigate();
            const authNCtx = useAuthorizationContext();

            if (!res || this.hidden(res, authNCtx)) return <></>

            return (
                <>
                    <Button variant={this.actionStyle?.buttonVariant || 'outlined'}
                            color={this.actionStyle?.buttonColor || 'primary'}
                            disabled={isDisabled}
                            onClick={() => navigate(filter? `${this.id}?${filter}` : `${this.id}`)}>
                        {this.icon}
                        {this.label}
                    </Button>
                </>
            )

            }
    }

}
