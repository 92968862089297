import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ErrorBoundary from './errorManagement/ErrorBoundary';
import { appManager } from './groups/appManager';
import { clinicalData } from "./groups/clinicalData";
import { documents } from "./groups/documents";
import { home } from "./groups/home";
import { labTest } from "./groups/labTest";
import CustomThemeProvider from "./hoc/themeProvider/CustomThemeProvider";
import { Admin } from "./lib/components/admin/Admin";
import { Group } from "./lib/components/group/Group";
import Resource from "./lib/components/resource/Resource";
import { AuditResource } from './resources/appManager/audit/auditResource';
import { ClinicalDataResource } from './resources/clinicalData/clinicalData/clinicalDataResource';
import { ClinicalStudiesResource } from './resources/appManager/clinicalStudies/clinicalStudyResource';
import { LaboratoryResource } from './resources/appManager/laboratory/laboratoryResource';
import { ProfileResource } from './resources/profile/profile';
import { RoleResource } from './resources/appManager/roles/roleResource';
import {LabTestResource} from './resources/labTest/labTest/labTestResource';
import { UserResource } from './resources/appManager/users/userResource';
import {SourceFileResource} from "./resources/clinicalData/sourceFile/sourceFileResource";
import {DocumentsResource} from "./resources/documents/documentResource";
import {authConfig} from "./auth/config";
import BaseLayout from "./layout/BaseLayout";
import AuthorizationProvider from "./auth/authorization";
import {MetadataFileResource} from "./resources/labTest/metadataFiles/metadataFileResource";
import {AxiosHandlerProvider} from "./hoc/axiosHandlerProvider/AxiosHandlerProvider";
import {UserGuideResource} from "./resources/userGuide/userGuideResource";
import {DownloadRequestsListResource} from "./resources/profile/downloadRequests/downloadRequestResource";
import {usersGroup} from "./groups/users";
import {auditGroup} from "./groups/audit";
import {EntitiesResource} from "./resources/entities/entitiesResource";
import {VariablesResource} from "./resources/variables/variablesResource";

export const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:9098/api'

function App() {
    return (
        <CustomThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ErrorBoundary>
                        <AxiosHandlerProvider>
                        <Admin authentication={authConfig} authorization={new AuthorizationProvider()}>
                            <BaseLayout>
                                <Resource config={UserGuideResource} />
                                <Group config={home}>
                                    <Resource config={ProfileResource} />
                                    <Resource config={DownloadRequestsListResource} />
                                </Group>
                                <Group config={usersGroup}>
                                    <Resource config={UserResource} />
                                    <Resource config={RoleResource} />
                                </Group>
                                <Group config={appManager}>
                                    <Resource config={EntitiesResource}/>
                                    <Resource config={VariablesResource}/>
                                    <Resource config={ClinicalStudiesResource} />
                                    <Resource config={LaboratoryResource} />
                                </Group>
                                <Group config={auditGroup}>
                                    <Resource config={AuditResource} />
                                </Group>
                                <Group config={documents}>
                                    <Resource config={DocumentsResource} />
                                </Group>
                                <Group config={clinicalData}>
                                    <Resource config={ClinicalDataResource} />
                                    <Resource config={SourceFileResource} />
                                </Group>
                                <Group config={labTest}>
                                    <Resource config={LabTestResource} />
                                    <Resource config={MetadataFileResource} />
                                </Group>
                            </BaseLayout>
                        </Admin>
                        </AxiosHandlerProvider>
                    </ErrorBoundary>
            </LocalizationProvider>
        </CustomThemeProvider>
    );
}

export default App;
