import CloseIcon from '@mui/icons-material/Close';
import {Box, Grid, Typography} from "@mui/material";
import {Container} from "@mui/system";
import {SideModalStyles} from "./SideModalStyles";
import {ReactElement} from "react";

type DefaultSideModalProps<T, V> = {
    anchor: 'right';
    title: string;
    children?: ReactElement;
    onClose: () => void;
}

function DefaultSideModal<T extends Record<string, any>, V>(props: DefaultSideModalProps<T, V>) {
    const classes = SideModalStyles();

    return (
        <>
            <Box className={classes.root} role="presentation">
                <Container className={classes.container}>
                    <Grid container className={classes.container}>
                        <Grid container direction="row">
                            <Grid item className={classes.title}>
                                <Typography variant={"pageTitle"}>{props.title}</Typography>
                            </Grid>
                            <Grid item>
                                <CloseIcon className={classes.closeIcon}
                                           onClick={props.onClose}
                                ></CloseIcon>
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"} className={classes.modalContent}>
                            {props.children}
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default DefaultSideModal;
