import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent, Tooltip,
    Typography
} from "@mui/material";
import React, {FC, ReactElement, useState} from "react";
import { Control, FieldValues, Path, RegisterOptions, useController } from "react-hook-form";
import { Field } from "../../types/field";
import { ListMeta, MetaField } from "../../types/meta";
import { SelectStyles } from "./selectStyles";
import {SimpleTextMeta} from "../simpleText/simpleText";

interface SelectMetaProps<R, T, V> {
    label: string;
    //filterId?: string;
    options: {[key: string]: string};
    filter?: (res: R) => V | undefined;
    valuer?: (data: T) => string,
    listValuer?: (data: R) => string,
    valuePicker?: keyof T
}

export class StaticSelectMeta<R, T, V> implements MetaField<R> {
    label: string;
    //filterId?: string;
    options: {[key: string]: string};
    filter?: (res: R) => V | undefined;
    valuer?: (data: T) => string;
    listValuer?: (data: R) => string;

    constructor(props: SelectMetaProps<R, T, V>) {
        this.label = props.label;
        //this.filterId = props.filterId;
        this.options = props.options;
        this.filter = props.filter;
        this.valuer = props.valuer;
        this.listValuer = props.listValuer;
    }

    getListComponent(data: R | undefined, field: Field<R>, valuer?: (d: R) => string | number) {
        return () => {
            return <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title={data && field && data[field.id] as string} placement={"top"}>
                    <Typography noWrap variant={"body1"} fontWeight={400}>{
                        data ? this.listValuer ? this.listValuer(data!) :
                            field && data[field.id] && data[field.id] !== "" ? data[field.id] as string : "--" : "--"}</Typography>
                </Tooltip>
            </div>
        }
    }

    getListMeta(): ListMeta {
        return {
            type: 'string',
            value: (arg: any) => {
                console.log(arg);
                return this.valuer ? this.valuer(arg) : arg as string;
            },
        };
    }

    getFormComponent(control: Control, name: Path<FieldValues>, field: Field<R>, data?: R): ReactElement {
        return <SelectForm<R, T, V>
            label={this.label}
            //filterId={this.filterId}
            formControl={control}
            name={name}
            filter={this.filter}
            valuer={this.valuer}
            options={this.options}
            validationOptions={field.validationOptions}
            selectedItem={data ? data[field.id] as T : undefined} />;
    };

    getShowComponent(data: R | undefined, field: Field<R>) {
        return () => {
            return SelectShow({ label: field.label, data: data ? data[field.id] as string : '' })
        }
    };


    getFilterComponent(handler: (data: any) => void, data?: R, fieldValue?: any): FC{
        return () => <StaticSelectFilter<R, T, V>
                            label={this.label}
                            //filterId={this.filterId}
                            handleSelect={handler}
                            filter={this.filter}
                            valuer={this.valuer}
                            options={this.options}
                            selectedValue={fieldValue}
                            parentRes={data}/>
    }
}

interface SelectShowProps {
    label?: string;
    data?: string;
}

function SelectShow(props: SelectShowProps) {
    const classes = SelectStyles();
    return (
        <>
            <Grid container className={classes.container}>
                <Grid item className={classes.label}>
                    <Typography>{props.label}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant={"smallButtonText"}>{!props.data? "--" : props.data?.toUpperCase() ?? ""}</Typography>
                </Grid>
            </Grid>
        </>
    )
}

interface SelectFormProps<R, T, V> extends SelectMetaProps<R, T, V>{
    selectedItem?: T,
    formControl: Control,
    name: Path<any>,
    options: {[key: string]: string};
    validationOptions?: RegisterOptions | undefined,
}

export function SelectForm<R, T, V>(props: SelectFormProps<R, T, V>) {
    const classes = SelectStyles();
    const { field, fieldState } = useController({
        name: props.name,
        control: props.formControl,
        rules: {...props.validationOptions}
    });

    return (
        <>
            <Select
                {...field}
                className={classes.select}
                style={{width:"20em"}}
                error={fieldState.error !== undefined}
            >
                {Object.keys(props.options).map((option) => (
                        <MenuItem
                            value={option.valueOf() as string}>
                            {props.options[option]}
                        </MenuItem>
                ))}
            </Select>
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
        </>
    )
}


interface SelectFilterProps<R, T, V> extends SelectMetaProps<R, T, V>{
    handleSelect: (data: any) => void,
    selectedValue: string,
    parentRes?: R
}

export function StaticSelectFilter<R, T, V>(props: SelectFilterProps<R, T, V> & { filterId?: string }) {
    const classes = SelectStyles();
    const [optionName, setOptionName] = useState<string>('');

    function handleChange(event: SelectChangeEvent) {
        const selectedOption = event.target.value as string;
        props.handleSelect(selectedOption);
        setOptionName(props.options[selectedOption]);
    }

    const isCurrentFilter = props.filterId === props.selectedValue;

    return (
        <FormControl focused={false}>
            <InputLabel id="label">{props.label}</InputLabel>
            <Select
                style={{width:"10em"}}
                key={props.filterId}
                className={classes.select}
                value={props.selectedValue}
                labelId="label"
                label={props.label}
                renderValue={() =>
                    { if (isCurrentFilter && props.selectedValue !== "") {
                         return (<Typography style={{ opacity: 1, overflow: "hidden", textOverflow: "ellipsis", width: '11rem' }}>
                                    {optionName}
                                </Typography>)
                    }}
                }
                onChange={handleChange}
            >
                {Object.entries(props.options).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                        {value}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
