import axios from "axios";
import { BASE_URL } from "../../../App";
import { BaseDataProvider } from "../../baseDataProvider";
import { User } from "./user";
import GenericSearch from "../../genericSearch";


export class UserDataProvider extends BaseDataProvider<User, GenericSearch> {

    constructor(){
        super('users');
    }

    create = async (data: User): Promise<User> => {
        return axios.post<User>(`${BASE_URL}/${this.resourceId}/create`, data, { headers: await this.getHeaders({"Accept": "application/json"})})
            .then(r => {return r.data;});
    }

    list = async (page_size?: number, filter?: GenericSearch ): Promise<User[]> => {
        return axios.get(`${BASE_URL}/${this.resourceId}`, { headers:  await this.getHeaders({"Accept": "application/json"}), params: {page_size, ...filter} }).then(r => {
            return r.data;
        });
    }

    edit = async (data: User): Promise<User> => {
        return axios.put<User>(`${BASE_URL}/${this.resourceId}/${data.id}/update`, {...data, disable_mfa : !data.mfa_enabled}, { headers:  await this.getHeaders({"Accept": "application/json"})}).then(r => {
            return r.data;
        });
    }
}
