import { CircularProgress } from "@mui/material";
import { ReactNode } from "react";
import {Navigate} from "react-router-dom";
import { useAuthenticationContext } from "../../context/AuthenticationContext";


interface AuthenticatedProps {
    children: ReactNode
}

export default function Authenticated(props: AuthenticatedProps) {
    const authCtx = useAuthenticationContext();
    const isAuthenticated = authCtx.isAuthenticated()

    
    return (
        <>
            {
                !authCtx
                ?
                <CircularProgress />
                :
                    isAuthenticated?
                        <>{props.children}</>
                        :
                        <Navigate to={`${authCtx.getConfig().loginRoute}`}/>
            }
        </>
    )
}
