
import {Button, Fade, Menu, MenuItem} from "@mui/material";
import React, {ReactElement} from "react";
import axios from "axios";
import {FeedbackContextValue} from "../../../lib/context/FeedbackContext";

const toolName = "miweb"

type SystemInfo = {
    operatingSystem: string;
    architectures: Array<{ name: string; link: string }>;
};

const systemData: SystemInfo[] = [
    {
        operatingSystem: "Windows",
        architectures: [
            { name: "64-bit", link: "https://miweb-dev.s3.eu-central-1.amazonaws.com/bin/miweb-windows-amd64.exe" },
        ],
    },
    {
        operatingSystem: "Linux",
        architectures: [
            { name: "AMD64", link: "https://miweb-dev.s3.eu-central-1.amazonaws.com/bin/miweb-linux-amd64" },
        ],
    },
    {
        operatingSystem: "macOS",
        architectures: [
            { name: "Intel", link: "https://miweb-dev.s3.eu-central-1.amazonaws.com/bin/miweb-darwin-amd64" },
            { name: "Apple Silicon", link: "https://miweb-dev.s3.eu-central-1.amazonaws.com/bin/miweb-darwin-arm64" },
        ],
    },
];

export const CliToolMenu = (props: {feedbackCtx: FeedbackContextValue}): ReactElement => {
    //const [systemInfo, setSystemInfo] = useState<SystemInfo>();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    /*useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        // Detect OS
        if (userAgent.indexOf('win') !== -1) {
            setSystemInfo(systemData[OS.Win]);
        } else if (userAgent.indexOf('os') !== -1) {
            setSystemInfo(systemData[OS.MacOS]);
        } else if (userAgent.indexOf('linux') !== -1) {
            setSystemInfo(systemData[OS.Linux]);
        } else {

        }
    }, []);*/

    const startDownload = async (os: string, link: string) => {
        return axios.get(link, {responseType: "blob",})
            .then(r => {
                return new Blob([r.data], { type: r.headers['content-type'] });
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                let name = toolName
                if (os === "Windows") {
                    name = name + ".exe"
                }
                a.href = url;
                a.download = name;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
        })
    }

    return (
        <>
            <Button
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="outlined"
            >
                Download CLI
            </Button>

            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {
                    systemData.map((system, i) => (
                        system.architectures.map((arch, j) => (
                            <MenuItem
                                onClick={() => {
                                    startDownload(system.operatingSystem, arch.link).catch(e => {
                                        return props.feedbackCtx?.openErrorSnackbar(`Download failed`)
                                    })
                                    handleClose()
                                }}
                            >
                                Download for {system.operatingSystem} ({arch.name})
                            </MenuItem>
                        ))
                    ))
                }
            </Menu>

        </>
    );
}
