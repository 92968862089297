import {createStyles, makeStyles} from "@mui/styles";

const ClinicalDataDownloadStyles = makeStyles(() =>
    createStyles({
        filterContainer: {
            justifyContent: "space-between",
            alignItems: "center !important",
            marginTop: "2em !important"
        },
        accordionContainer: {
            marginTop: "3em !important"
        },
        buttonBox: {
            width: "20em !important",
            height: "30vh !important",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center !important"
        }
    }));

export {ClinicalDataDownloadStyles};
