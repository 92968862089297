export enum AccessRule {
    READ = "READ",
    WRITE = "WRITE",
    ADMIN = "ADMIN"
}

export enum AccessTargetResource {
    CLINICAL = "CLINICAL",
    DOCUMENT = "DOCUMENT",
    SAMPLE = "SAMPLE"
}

export interface AccessObjectLabTest{
    clinical_study_id: string;
    clinical_study_name: string;
}

export interface AccessObjectClinical {
    clinical_study_id: string;
    clinical_study_name: string;
    column_name: string;
    column_id: string;
}

export interface AccessObjectDocument {
    document_id: string;
    document_name: string;
}

export interface Access {
    access: AccessObjectClinical | AccessObjectDocument | AccessObjectLabTest;
    pattern: string;
    rule: AccessRule;
    resource: AccessTargetResource;
}
