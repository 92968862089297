import {createStyles, makeStyles} from "@mui/styles";

const stepStyles = makeStyles(() =>
    createStyles({
        mainContainer: {
            marginTop: "7em",
            width: "90% !important",
            marginLeft: "auto",
            marginRight: "auto"
        },
        titleContainer: {
            justifyContent: "space-between"
        },
        subtitle: {
            marginTop: "1em !important"
        },
        buttonBox: {
            width: "20em !important",
            height: "30vh !important",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center !important"
        }
    }));

export {stepStyles};
