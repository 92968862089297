import {
    Autocomplete,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { Control, FieldValues, Path, RegisterOptions, useController } from "react-hook-form";
import { Field } from "../../types/field";
import { ListMeta, MetaField } from "../../types/meta";
import { ResourceConfig } from "../../types/resource";
import {SelectStyles} from "./selectStyles";

interface SelectMetaProps<R, T, V> {
    resource: ResourceConfig<T, V>;
    filter?: (res: R) => V | undefined;
    valuer?: (data: T) => string;
    clearable: boolean;
}

export class AutocompleteSelectMeta<R, T, V> implements MetaField<R> {
    resource: ResourceConfig<T, V>;
    filter?: (res: R) => V | undefined;
    valuer?: (data: T) => string;
    clearable: boolean;

    constructor(props: SelectMetaProps<R, T, V>) {
        this.resource = props.resource;
        this.filter = props.filter;
        this.valuer = props.valuer;
        this.clearable = props.clearable;
    }

    getListMeta(): ListMeta {
        return {
            type: 'string',
            value: (arg: any) => {
                return arg;
            },
        };
    }

    getFormComponent(control: Control, name: Path<FieldValues>, field: Field<R>, data?: R): ReactElement {

        return <SelectForm<R, T, V>
                            formControl={control}
                            name={name}
                            resource={this.resource}
                            parentData={data}
                            filter={this.filter}
                            valuer={this.valuer}
                            selectedItem={data ? data[field.id] as T : undefined}
                            clearable={this.clearable}
        />;
    };

    getShowComponent(data: R | undefined, field: Field<R>) {
        return () => {
            return SelectShow({ label: field.label, data: data ? data[field.id] as string : '' })
        }
    };


    getFilterComponent(handler: (data: any) => void, data?: R, fieldValue?: any, label?: string): FC{
        return () => <SelectFilter<R, T, V>
                            label={label || "Select..."}
                            handleSelect={handler}
                            filter={this.filter}
                            valuer={this.valuer}
                            resource={this.resource}
                            selectedRecord={fieldValue}
                            parentRes={data}
                            clearable={this.clearable}
        />
    }
}

interface SelectShowProps {
    label?: string;
    data?: string;
}

function SelectShow(props: SelectShowProps) {
    return (
        <>
            <Grid item>
                <Typography variant={"dataTitle"}>
                    {props.label}</Typography>
            </Grid>
            <Grid item paddingBottom={"1em"}>
                {!props.data? "--" : props.data?.toUpperCase()}
            </Grid>
        </>
    )
}

interface SelectFormProps<R, T, V> extends SelectMetaProps<R, T, V>{
    parentData: R | undefined,
    selectedItem?: T,
    formControl: Control,
    name: Path<any>,
    validationOptions?: RegisterOptions | undefined
}

export function SelectForm<R, T, V>(props: SelectFormProps<R, T, V>) {
    const [data, setData] = useState<T[]>([]);
    const { field, fieldState } = useController({
        name: props.name,
        control: props.formControl,
        rules: props.validationOptions,
        defaultValue: props.selectedItem ? props.resource?.primaryKey && props.selectedItem[props.resource.primaryKey] as unknown as string : '',
    });

    useEffect(() => {
        props.resource?.dataProvider?.list(undefined,
            props.parentData ? props.filter? props.filter(props.parentData) : undefined : undefined
        ).then((data) => {
            setData(data);
        });
    }, []);

    return (
        <>
            <Select
                {...field}
                fullWidth
                error={fieldState.error !== undefined}
            >
                {data.map((item, index) => (
                    <MenuItem
                        key={index}
                        value={props.resource && item[props.resource.primaryKey] as string}>
                        {props.valuer && props.valuer(item)}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
        </>
    )
}

interface selectedRecord {
    label?: string,
    value?: string | number
}

interface SelectFilterProps<R, T, V> extends SelectMetaProps<R, T, V>{
    label?: string;
    handleSelect: (data: any) => void,
    selectedRecord: selectedRecord,
    parentRes?: R
    clearable: boolean
}

export function SelectFilter<R, T, V>(props: SelectFilterProps<R, T, V>) {
    const [data, setData] = useState<T[]>([]);
    const [selectedOption, setSelectedOption] = useState<string>();
    const classes = SelectStyles();


    useEffect(() => {
        props.resource?.dataProvider?.pagedList(undefined, undefined).then((data) => {
            setData(data.data);
        });
    }, [props.selectedRecord]);
    function handleChange(event: any, newValue: selectedRecord | null) {
        setSelectedOption(newValue?.label)
        props.handleSelect(newValue?.value)
    }

    let option: {label?: string, value: string | number};
    const options = data.map((item) => (
        option = {
            label: props.valuer && props.valuer(item).toString(),
            value: (item[props.resource.primaryKey] as string | number).toString()
        }
    ))

    const renderOptions = (props: React.HTMLAttributes<HTMLLIElement>, option: Partial<any>) => {
        return (
            <li {...props} key={option.value}>
                {option.label}
            </li>
        )
    }

    return (<Autocomplete
                selectOnFocus
                disableClearable={!props.clearable}
                className={classes.select}
                onChange={handleChange}
                renderInput={(params) =>
                    <TextField {...params} key={option && option.value} label={props.label}/>
                }
                options={options}
                value={props.selectedRecord as string === "" ||  props.selectedRecord as string === undefined? {value: "", label: ""} : {value: props.selectedRecord as string, label: selectedOption}}
                renderOption={renderOptions}
            />
    )

}
